import { IconButton, Tooltip } from '@mui/material'
import AccountBoxIcon from '@mui/icons-material/AccountBox'

interface ContactButtonProps {
  setOpenDialog: (open: boolean) => void
}

export const ContactButton = ({
  setOpenDialog
}: ContactButtonProps) => {

  return (
    <Tooltip title="View the companys' Contacts">
      <IconButton onClick={() => setOpenDialog(true)} style={{ height: 'fit-content', marginTop: '10px' }}>
        <AccountBoxIcon />
      </IconButton>
    </Tooltip>
  )
}

import { api } from './api'
import { queries } from '../utility'
import { setGeolocations } from '../store/global'
import { store } from '../store'

export const geolocationsApi = api.injectEndpoints({
  reducerPath: 'geolocationsApi',
  endpoints: (build) => ({
    getGeolocations: build.query({
      queryFn: async () => {
        const response = await queries.getGeolocations()
        store.dispatch(setGeolocations(response))
        return { data: response }
      },
    }),
  }),
})

export const {
  useGetGeolocationsQuery
} = geolocationsApi

import { useState } from 'react'
import { Alert, AlertTitle, Button, CircularProgress, Dialog, DialogTitle,
        DialogContent, DialogContentText, DialogActions, FormControl,
        InputLabel, MenuItem, Select } from '@mui/material'
import { Copied } from '../../Popover'

export function OTP ({ 
  otp,
  contactIds,
  openDialog,
  handleClose,
  loading,
  completed = false,
  error
}) {
  const [selectedContact, setSelectedContact] = useState<number>(0)
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => handleClose(null)}
      >
        <DialogTitle>
          Generate OTP
        </DialogTitle>
        <DialogContent>
          {(!completed) ? (
            <>
              <DialogContentText sx={{ paddingBottom: '20px' }}>
                Would you like to generate a One-Time-Password?<br />
                <i>This temporary password will expire within 1 hour of generation.</i>
              </DialogContentText>
              <FormControl fullWidth>
                <InputLabel>Contact</InputLabel>
                <Select
                  value={selectedContact}
                  label="Contact"
                  onChange={(e) => setSelectedContact(Number(e.target.value))}
                  disabled={loading}
                >
                  {(contactIds?.length === 0 || !contactIds) ? (
                    <MenuItem disabled>
                      <CircularProgress color="inherit" size={30} />
                    </MenuItem>
                  ) : (
                    contactIds.map((contact) => (
                      <MenuItem key={contact.id} value={contact.id}>{contact.email} ({contact.id})</MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </>
          ) : (
            <DialogContentText>
              {error ? (
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  Error generating OTP.
                </Alert>
              ) : (
                <Alert severity="success">
                  <AlertTitle>Success</AlertTitle>
                  Your unique OTP (One-Time Password) was generated:
                  <div className="flex flex-col gap-[6px] pt-[16px]">
                    <span>
                      Email: <b>{contactIds.find(contact => Number(contact.id) === selectedContact)?.email || ''}</b>
                      <Copied toCopy={contactIds.find(contact => Number(contact.id) === selectedContact)?.email || ''} />
                    </span>
                    <span>
                      OTP: <b>{otp}</b>
                      <Copied toCopy={otp} />
                    </span>
                  </div>
                </Alert>
              )}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={() => handleClose(false)}>
            {!completed ? 'Cancel' : 'Close'}
          </Button>
          {!completed && (
            <Button disabled={loading || selectedContact === 0} endIcon={loading ? <CircularProgress size="0.99rem" /> : null} onClick={() => handleClose(true, selectedContact)} autoFocus>
              Generate
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

import React, { PropsWithChildren, createContext, useEffect, useState } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import { createTheme } from '@mui/material/styles'

type ColorModeContextType = {
  mode: string
  setMode: () => void
}

const lightThemes = {
  slx: createTheme({
    palette: {
      primary: {
        light: '#808ef5',
        main: '#6172F3',
        dark: '#434faa',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ff784e',
        main: '#ff5722',
        dark: '#b23c17',
        contrastText: '#000',
      }
    }
  }),
  gmt: createTheme({
    palette: {
      primary: {
        light: '#65ca67',
        main: '#3FBD41',
        dark: '#2c842d',
        contrastText: '#fff',
      },
      secondary: {
        light: '#89898b',
        main: '#6c6c6e',
        dark: '#4b4b4d',
        contrastText: '#000',
      }
    }
  }),
  sme: createTheme({
    palette: {
      primary: {
        light: '#3bb7ef',
        main: '#0BA5EC',
        dark: '#0773a5',
        contrastText: '#fff',
      },
      secondary: {
        light: '#fbbb63',
        main: '#faab3d',
        dark: '#af772a',
        contrastText: '#000',
      }
    }
  }),
  jdm: createTheme({
    palette: {
      primary: {
        light: '#fd668c',
        main: '#fd4070',
        dark: '#b12c4e',
        contrastText: '#fff',
      },
      secondary: {
        light: '#00E8B8',
        main: '#00BE98',
        dark: '#00E8B8',
        contrastText: '#000',
      }
    }
  })
}

const darkThemes = {
  slx: createTheme({
    palette: {
      primary: {
        light: '#a8b2ff',
        main: '#7b86e8',
        dark: '#4d579d',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ffa182',
        main: '#ff7246',
        dark: '#b83e1e',
        contrastText: '#000',
      },
      mode: 'dark'
    }
  }),
  gmt: createTheme({
    palette: {
      primary: {
        light: '#82d378',
        main: '#5cbf61',
        dark: '#409949',
        contrastText: '#fff',
      },
      secondary: {
        light: '#9a9a9c',
        main: '#747476',
        dark: '#505052',
        contrastText: '#000',
      },
      mode: 'dark'
    }
  }),
  sme: createTheme({
    palette: {
      primary: {
        light: '#6ea7d7',
        main: '#3395d3',
        dark: '#0d73aa',
        contrastText: '#fff',
      },
      secondary: {
        light: '#ffbc80',
        main: '#ff944b',
        dark: '#c76e26',
        contrastText: '#000',
      },
      mode: 'dark'
    }
  }),
  jdm: createTheme({
    palette: {
      primary: {
        light: '#fc86a3',
        main: '#fc688d',
        dark: '#b04862',
        contrastText: '#fff',
      },
      secondary: {
        light: '#00E8B8', //#00E8B8
        main: '#00E8B8', //#00a280
        dark: '#00E8B8',
        contrastText: '#000',
      },
      mode: 'dark'
    }
  })
}

export const ColorModeContext = createContext<ColorModeContextType>({} as ColorModeContextType)

export const ColorModeContextProvider: React.FC<PropsWithChildren> = ({ children }) => {

  const colorModeFromLocalStorage = localStorage.getItem('colorMode')
  const isSystemPreferenceDark = window?.matchMedia('(prefers-color-scheme: dark)').matches

  const systemPreference = isSystemPreferenceDark ? 'dark' : 'light'
  const [mode, setMode] = useState(colorModeFromLocalStorage || systemPreference)
  const [brand, setBrand] = useState('slx')

  useEffect(() => {
    window.localStorage.setItem('colorMode', mode)
  }, [mode])

  const setColorMode = () => {
    setMode(prevMode => (prevMode === 'light' ? 'dark' : 'light'))
  }

  return (
    <ColorModeContext.Provider
      value={{
        setMode: setColorMode,
        mode,
      }}>
      <ThemeProvider theme={mode === 'dark' ? darkThemes[brand?.toLowerCase()] : lightThemes[brand?.toLowerCase()]}>
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useGridApiRef, gridPageCountSelector, gridPaginatedVisibleSortedGridRowEntriesSelector } from '@mui/x-data-grid-pro'
import { RootState } from '../../interface/RootState'
import { useParams, useNavigate } from 'react-router-dom'
import { DataTable } from '../Table'
import { DataDialog as CaseDataDialog, ReceiptDialog } from '../Cases/Dialogs'
import { DataDialog as FormDataDialog } from '../Forms/Dialogs'
import { Confirmation, UpsertForm, KeywordCreateForm } from '../Forms/Popup' //! REWORD INTO DIALOGS ABOVE
import { GridSortDirection, GridSortItem } from '@mui/x-data-grid'
import { Container, Snackbar } from '@mui/material'
import Alert, { AlertProps } from '@mui/material/Alert'
import { RestrictedAlert } from '../Alert'
import { canRead, queries } from '../../utility'
import { scrollToLastRow } from '../../utility/helpers'
import Slide from '@mui/material/Slide'
import Fade from '@mui/material/Fade'
import { useSubmitCaseMutation } from '../../services/casesApi'
import { LoadingData } from '../Loading'

interface ParamProps {
  id: number
  case_number: string
}

export function TableTemplateNew ({
  CustomToolbar,
  dataTitle,
  columnFields,
  initialForm = null,
  upsertFields = null,
  createEmptyRow = null,
  createQuery = null,
  tableData = null,
  loading = true,
  updateQuery = null,
  deleteQuery = null,
  refreshOnUpdate = true, // refresh the page on row update?
  tableSortBy = 'created_at', // what to sort the table by
  tableSortDir = 'asc' as GridSortDirection,
  permissionProp = null,
  pinLeft = [''],
  pinRight = ['edit', 'delete'],
  autoRowHeight = false,
  goBackLevel = 0,
  validateParams = null
}) {
  //* STATES ----------
  // Dialogs
  const [confirmationDialog, setConfirmationDialog] = useState<boolean>(false) // yes/no dialog
  const [upsertDialog, setUpsertDialog] = useState<boolean>(false) // the upsert (either create, or update) dialog box
  const [isCreate, setIsCreate] = useState<boolean>(true) // state used to control whether to display create, or update form for upsert dialog
  const [receiptDialog, setReceiptDialog] = useState<boolean>(false) // receipt dialog (for cases)
  const [dataDialog, setDataDialog] = useState<boolean>(false) // generic dialog box containing a selected rows data
  // Loading
  const [loadingPopup, setLoadingPopup] = useState<boolean>(false) // loading spinner when confirming a dialog
  const [reloadTrigger, setReloadTrigger] = useState(false) //! TODO: remove and refresh through redux if we need to
  // Data
  const [formData, setFormData] = useState(initialForm) // The table form data (all rows)
  const [queryData, setQueryData] = useState(tableData) // stored data from a query (temporary)
  const [selectedRow, setSelectedRow] = useState({}) // data from the selected row
  const [receiptData, setReceiptData] = useState({}) // receipt data (exclusive to cases
  // Other
  const [sortModel, setSortModel] = useState<GridSortItem[]>([{ field: tableSortBy, sort: tableSortDir }])
  const [method, setMethod] = useState<string>('')
  const [param, setParams] = useState<ParamProps>()

  // Extract params from url, if they exist
  const { customerId, keywordId, productId } = useParams()
  const navigate = useNavigate()

  const apiRef = useGridApiRef()

  useEffect(() => {
    if (tableData) setQueryData(tableData)
  }, [tableData])

  //* Extract data from Redux
  const { email: userEmail, managers, permissions } = useSelector((store: RootState) => store.global.user)
  const geolocations = useSelector((store: RootState) => store.global.geolocations)
  const subscriptionsCb = useSelector((store: RootState) => store.global.company.subscriptions)
  const companyDetails = useSelector((store: RootState) => store.global.company.details)
  const [submitCase] = useSubmitCaseMutation()

  //* SNACKBAR ----------
  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)

  const handleCloseSnackbar = () => setSnackbar(null)

  //* CRUD HANDLERS ----------
  const handleCreateQuery = async (params) => {
    try {
      setLoadingPopup(true)
      const validateMsg = validateParams ? validateParams(params) : null // Validate function
      const newRow = createEmptyRow(params, customerId, keywordId, productId, userEmail, managers, companyDetails)
      // Submit create query to rtk
      const createRes = await createQuery({ body: newRow, subscriptionId: productId, keywordId }).unwrap()
      if (!createRes) {
        return setSnackbar({ children: `Error creating ${dataTitle}`, severity: 'error' })
      }
      // Update react formdata
      setQueryData((prevData) => {
        if (!Array.isArray(prevData)) { return []}
        return [...prevData, newRow]
      })
      scrollToLastRow(
        gridPageCountSelector,
        gridPaginatedVisibleSortedGridRowEntriesSelector,
        apiRef
      )
      setSnackbar({ children: `Successfully created ${dataTitle}`, severity: 'success' })
    } catch (error) {
      setSnackbar({ children: `Error creating ${dataTitle}: ${error?.message}`, severity: 'error' })
    } finally {
      setLoadingPopup(false)
      setUpsertDialog(false)
      setReloadTrigger(prev => !prev) // force a reload of the table on creation/failure to create new row, so that the table reflects live database
    }
  }
  const handleUpdateQuery = async (params) => {
    try {
      setLoadingPopup(true)
      const validateMsg = validateParams ? validateParams(params) : null // Validate function
      // Update local state to re-render table with updated details
      const updatedRow = {}
      Object.keys(formData).forEach((key) => {
        updatedRow[key] = params[key] || formData[key]
      })
      setFormData(updatedRow)

      // Update Supabase  table
      const updateRes = await updateQuery({ itemId: params.id, body: updatedRow, subscriptionId: productId, keywordId, customerId }).unwrap()
      if (!updateRes) {
        return setSnackbar({ children: `Error updating ${dataTitle}`, severity: 'error' })
      }
      setQueryData((prevData) => {
        const idx = prevData.findIndex((k) => k.id === params.id)
        return idx !== -1 ? [...prevData.slice(0, idx), updatedRow, ...prevData.slice(idx + 1)] : [...prevData, updatedRow]
      })
      setSnackbar({ children: `Successfully updated ${dataTitle}`, severity: 'success' })
    } catch (error) {
      setSnackbar({ children: `Error updating ${dataTitle}: ${error?.message}`, severity: 'error' })
    } finally {
      setLoadingPopup(false)
      setUpsertDialog(false)
      if (refreshOnUpdate) { setReloadTrigger(prev => !prev) } // force a reload of the table on row update, so that the table reflects live database
    }
  }
  const handleDeleteQuery = async (confirm) => {
    if (confirm) {
      try {
        setLoadingPopup(true)
        const deleteRes = await deleteQuery({ itemId: param.id, customerId, subscriptionId: productId, keywordId }).unwrap()
        if (!deleteRes) {
          return setSnackbar({ children: `Error deleting ${dataTitle}`, severity: 'error' })
        }
        const updatedData = queryData.filter((data) => String(data.id) !== String(param.id))
        setQueryData(updatedData)
        setSnackbar({ children: `Successfully deleted ${dataTitle}`, severity: 'success' })
      } catch (error) {
        setSnackbar({ children: `Error deleting ${dataTitle}`, severity: 'error' })
      } finally {
        setLoadingPopup(false)
        setConfirmationDialog(false)
      }
    } else {
      setConfirmationDialog(false)
    }
  }

  const handleSubmitBtn = async (confirm, body) => { // the inline submit button used in the table row
    if (confirm) {
      try {
        setLoadingPopup(true)
        const submitRes = await submitCase({ body, status: null }).unwrap()
        if (!submitRes) {
          return setSnackbar({ children: 'Error submitting case', severity: 'error' })
        }
        // Update React state
        setFormData({ ...formData })
        const updatedData = queryData.map(caseItem => caseItem.case_number === body.case_number ? { ...caseItem, status: 'Awaiting Approval' } : caseItem)
        setQueryData(updatedData)
        if (submitRes?.response?.productReceipts) {
          setSnackbar({ children: 'Case was automatically approved', severity: 'success' })
          setReceiptData(submitRes.response)
          setReceiptDialog(true)
        } else {
          setSnackbar({ children: 'Successfully submitted case', severity: 'success' })
        }
      } catch (error) {
        setSnackbar({ children: 'Error submitting case', severity: 'error' })
      } finally {
        setLoadingPopup(false)
        setConfirmationDialog(false)
      }
    } else {
      setConfirmationDialog(false)
    }
  }

  const handleRecallBtn = async (confirm, caseNo) => { // the inline recall button
    if (confirm) {
      try {
        setLoadingPopup(true)
        const recallRes = await queries.recallCase(caseNo)
        if (!recallRes) {
          return setSnackbar({ children: 'Error recalling case', severity: 'error' })
        }
        setFormData({ ...formData })
        setQueryData(prevQueryData => {
          const updatedQueryData = [...prevQueryData]
          const index = updatedQueryData.findIndex(caseItem => caseItem.case_number === caseNo)
          if (index !== -1) {
            updatedQueryData[index] = { ...updatedQueryData[index], status: 'New' }
          }
          return updatedQueryData
        })      
        setSnackbar({ children: 'Successfully recalled case', severity: 'success' })
      } catch (error) {
        setSnackbar({ children: 'Error recalling case', severity: 'error' })
      } finally {
        setLoadingPopup(false)
        setConfirmationDialog(false)
      }
    } else {
      setConfirmationDialog(false)
    }
  }

   //* FORM HANDLERS ----------
  // Handle delete dialog boxes
  const handleDialogOpen = (params, dialogMethod) => {
    setConfirmationDialog(true)
    setParams(params)
    setMethod(dialogMethod)
  }
  const handleDialogClose = (confirm) => {
    if (method === 'Delete') { handleDeleteQuery(confirm) }
    if (method === 'Submit') { handleSubmitBtn(confirm, param) }
    if (method === 'Recall') { handleRecallBtn(confirm, param?.case_number) }
  }
  // Handle create/update forms
  const openCreateForm = () => {
    if (dataTitle === 'Case') {
      navigate('new', { replace: false })
    }
    setIsCreate(true) // set to display create form
    setUpsertDialog(true)
  }
  // Update the form state in react
  const openUpdateForm = (params) => {
    setIsCreate(false)
    const toSetForm = formData
    Object.keys(formData).forEach((key) => {
      // Check for undefined or null, do not fallback on FALSE values
      if (params.row[key] !== undefined && params.row[key] !== null) {
        toSetForm[key] = params.row[key]
      } else {
        toSetForm[key] = formData[key]
      }
    })
    setFormData(toSetForm)
    setUpsertDialog(true)
  }
  const closeForm = () => {
    setUpsertDialog(false)
    setFormData(initialForm)
  }
  const handleReceiptOpen = (selected) => {
    setReceiptData(selected?.receipts)
    setSelectedRow(selected)
    setReceiptDialog(true)
  }
  const handleDataDialogOpen = (selected) => {
    setSelectedRow(selected)
    setDataDialog(true)
  }

  //* RENDERS ----------
  if (loading || canRead(permissions, permissionProp) === null) {
    return <LoadingData />
  }
  if (!canRead(permissions, permissionProp)) {
    return <RestrictedAlert />
  }

  return (
    <Slide in direction="left">
      <Fade in>
        <Container style={{ padding: '0px', paddingTop: '24px', paddingLeft: '16px', margin: '0px', maxWidth: '100%' }}>
          <DataTable
            title={dataTitle}
            columnFields={columnFields}
            queryData={queryData}
            openCreateForm={openCreateForm}
            sortModel={sortModel}
            setSortModel={setSortModel}
            openUpdateForm={openUpdateForm}
            handleDialogOpen={handleDialogOpen}
            CustomToolbar={CustomToolbar}
            subscriptionsCb={subscriptionsCb}
            geolocations={geolocations}
            permissionProp={permissionProp}
            handleReceiptOpen={handleReceiptOpen}
            handleDataDialogOpen={handleDataDialogOpen}
            pinLeft={pinLeft}
            pinRight={pinRight}
            autoRowHeight={autoRowHeight}
            goBackLevel={goBackLevel}
            userEmail={userEmail}
            managers={managers}
            permissions={permissions}
            apiRef={apiRef}
          />
          {(dataTitle === 'Keyword' && isCreate) ? (
            <KeywordCreateForm
              setLoadingPopup={setLoadingPopup}
              createEmptyRow={createEmptyRow}
              createQuery={createQuery}
              setSnackbar={setSnackbar}
              upsertDialog={upsertDialog}
              setUpsertDialog={setUpsertDialog}
              closeForm={closeForm}
              formData={formData}
              setFormData={setFormData}
              setQueryData={setQueryData}
              setReloadTrigger={setReloadTrigger}
              loading={loading}
              geolocations={geolocations}
              scrollToLastRow={scrollToLastRow}
              apiRef={apiRef}
            />
          ) : (
            <UpsertForm
              upsertFields={upsertFields}
              title={dataTitle}
              upsertDialog={upsertDialog}
              closeForm={closeForm}
              formData={formData}
              setFormData={setFormData}
              isCreate={isCreate}
              handleUpdateQuery={handleUpdateQuery}
              handleCreateQuery={handleCreateQuery}
              loading={loadingPopup}
            />
          )}
          <Confirmation
            openDialog={confirmationDialog}
            handleClose={handleDialogClose}
            itemName={dataTitle}
            loading={loadingPopup}
            text={method}
          />
          {(dataTitle === 'Case') && (
            <>
              <ReceiptDialog
                caseData={selectedRow}
                receiptData={receiptData}
                open={receiptDialog}
                handleClose={() => setReceiptDialog(false)}
              />
              <CaseDataDialog
                data={selectedRow}
                open={dataDialog}
                handleClose={() => setDataDialog(false)}
              />
            </>
          )}
          {(dataTitle === 'Form') && (
            <FormDataDialog
              data={selectedRow}
              open={dataDialog}
              handleClose={() => setDataDialog(false)}
            />
          )}
          {!!snackbar && (
            <Snackbar
              open
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              onClose={handleCloseSnackbar}
              autoHideDuration={6000}
            >
              <Alert
                {...snackbar}
                onClose={handleCloseSnackbar}
                variant="filled"
              />
            </Snackbar>
          )}
        </Container>
      </Fade>
    </Slide>
  )
}

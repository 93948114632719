import { Alert, Button, TextField, Container } from '@mui/material'
import { Link } from 'react-router-dom'

export const RestrictedAlert = () => {
  return (
    <Container maxWidth="lg">
      <Alert severity="error" style={{ padding: '15px', marginBottom: '15px' }}>
        Access to this page is restricted. You do not have the necessary permissions to view its content.
      </Alert>
    </Container>
  )
}

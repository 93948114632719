import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../interface/RootState'
import { queries } from '../utility'
import { Link, useNavigate } from 'react-router-dom'
import { useParams } from 'react-router-dom'
import Snackbar from '@mui/material/Snackbar'
import { AlertProps } from '@mui/material/Alert'
import { Button, Container, CircularProgress, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Paper, Select, Typography } from '@mui/material'
import { Form } from '@flipbyte/formik-json'
import { Alert } from '../components/Alert'
import { LoadingTable } from '../components/Loading'
import { Confirmation } from '../components/Forms/Popup'
import { formatAsDayMonthYear } from '../utility/helpers/dateHelpers'
import { emailToName } from '../utility/helpers/miscHelpers'
import { formatGoogleAdsId } from '../utility/helpers/subscriptionHelpers'
import { useUpdateFormResultMutation } from '../services/formsApi'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp'
import '../components/Forms/common-styles.css'

const FORM_ID = {
  FACEBOOK_SALES: 1,
  ADS_SALES: 2,
  SEO_SALES: 3,
  ADS_SETUP: 4,
  FACEBOOK_SETUP: 5,
  DIFM_SETUP: 6,
  CONVERTO_SETUP: 7,
  CONVERTO_PLANNER: 8
}

interface ResultsQueryProps {
  created_at: Date
  customer_id: string
  data: object
  form_version_id: number
  id: number
  updated_at: Date
  updated_by: string
  subscription_id: string
}

//* CRUD functions from Supabase
const getSchemaQuery = async (formId: string) => (
  queries.getFormSchema(formId)
)
const getResultsQuery = (customerId: string, resultId: string) => (  
  queries.getFormResults(customerId, resultId)  
)
const updateProductQuery = async (body, formId) => {
  // check if this is the setup ads questionnaire that is being sumbitted
  if (Number(formId) === FORM_ID.ADS_SETUP) {
    const toUpdate = {
      google_ads_id: formatGoogleAdsId(body?.data?.googleadsid),
      analytics_property_id: body?.data?.googleanalyticspropertyid,
      analytics_web_stream: body?.data?.googleanalyticsid,
      analytics_linked_to: body?.data?.analyticsaccess,
      tag_manager_id: body?.data?.gtmtrackingid,
      tag_manager_linked_to: body?.data?.whereisgtmaccess,
      merchant_id: body?.data?.merchantid,
      merchant_linked_to: body?.data?.merchantaccess
    }
    await queries.updateSubscriptionInfo(body.subscription_id, toUpdate)
    return true
  } else {
    return false
  }
}

export function FormPage() {
  const [schema, setSchema] = useState<object>({})
  const [formName, setFormName] = useState<string>('')
  const [version, setVersion] = useState<number>(0)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [formVersionId, setFormVersionId] = useState<number>(0)
  const [results, setResults] = useState<ResultsQueryProps[]>()
  const [loading, setLoading] = useState<boolean>(true)
  const [loadingPopup, setLoadingPopup] = useState<boolean>(false)
  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)
  const [formData, setFormData] = useState<object | undefined>(undefined)
  const [subscriptionId, setSubscriptionId] = useState('')
  const { customerId, formId, resultId } = useParams()
  const formRef = useRef(null)
  const selectRef = useRef(null)
  const navigate = useNavigate()

  //* Extract data from Redux
  const userEmail = useSelector((store: RootState) => store.global.user.email)
  const subscriptionsCb = useSelector((store: RootState) => store.global.company.subscriptions)
  const [updateFormResult] = useUpdateFormResultMutation()

  //* Handlers
  const updateButton = () => {
    // check if subscriptionId is filled
    if (!subscriptionId) {
      selectRef.current.scrollIntoView({ behavior: 'smooth' })
      return setSnackbar({ children: 'Subscription Type not selected', severity: 'error' })
    }
    // check for other invalid fields in json schema
    if (formRef.current) {
      if (!formRef.current.isValid) {
        const errors = Object.values(formRef.current.errors as Record<string, string>).map(errorMessage => errorMessage.split(' is required')[0]).join(', ')
        return setSnackbar({ children: `Invalid fields detected${errors ? ': ' + errors : ''}`, severity: 'error' })
      }
      setFormData(formRef.current.values) // update the formData variable
      formRef.current.submitForm() // submit form on success
    }
  }
  const handleCloseSnackbar = () => setSnackbar(null)
  const submitHandler = async () => {
    try {
      const body = {
        form_version_id: formVersionId,
        data: JSON.parse(JSON.stringify(formData).replace(/"true"/g, 'true').replace(/"false"/g, 'false')),
        updated_at: new Date().toISOString(),
        updated_by: userEmail || null,
        customer_id: customerId,
        subscription_id: subscriptionId
      }
      setFormData(body)
      await updateFormResult({ body, resultId }).unwrap()
      const productRes = await updateProductQuery(body, formId) //! TODO: convert query to RTK + associated Reducer
      const children = productRes
        ? 'Successfully created form, and attached relevant information to subscription'
        : 'Successfully created form'

      setSnackbar({ children, severity: 'success' })
      navigate(`/customers/show/${customerId}/forms`) // redirect back to form table page
    } catch (error) {
      console.error('Error creating form:', error.message)
      setSnackbar({ children: 'Error creating form', severity: 'error' })
    } finally {
      setOpenDialog(false)
      setLoadingPopup(false)
    }
  }
  const handleUpdateClose = (confirm) => {
    if (confirm) {
      setLoadingPopup(true)
      submitHandler()
    } else {
      setOpenDialog(false)
    }
  }

  const getSchemaHandler = async () => {
    try {
      const res = await getSchemaQuery(formId)
      if (res) {
        const formIdData = Array.isArray(res?.form_id) ? res?.form_id[0] : res?.form_id
        setSchema(res?.schema_json || {})
        setFormName(formIdData?.name || '')
        setVersion(res?.version)
        setFormVersionId(res?.id)
      } else {
        setSnackbar({ children: 'Error fetching form schema', severity: 'error' })
      }
    } catch (error) {
      setSnackbar({ children: 'Error fetching form schema', severity: 'error' })
    }
  }

  // load in values into the form
  const getResultsHandler = async () => {
    try {
      const res: ResultsQueryProps[] = await getResultsQuery(customerId, resultId)
      if (res?.length > 0) {
        setResults(res)
        setFormData(res[res.length - 1]?.data)
        setSubscriptionId(res[res.length - 1]?.subscription_id) // set the default subscription, if existing form
      }
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      await getSchemaHandler()
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  useEffect(() => {
    if (formVersionId) {
      if (Number(formId) === 2) {
        setFormData(prevFormData => ({
          ...prevFormData,
          salesperson: emailToName(userEmail)
        }))
      }
      if (window.location.href.split('forms/')[1]?.length > 1) { // if we are the form create url
        getResultsHandler() // pre-populate the table with values
      } else {
        setLoading(false)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVersionId])
  
  //* Renders
  if (loading) {
    return (
      <Container maxWidth="lg">
        <Typography variant="h5" align="center" gutterBottom style={{ padding: '10px' }}>
          Loading form...
        </Typography>
        <LinearProgress color="secondary" />
        <Paper elevation={3} style={{ padding: '20px' }}>
          <LoadingTable />
        </Paper>
      </Container>
    )
  }

  return (
    <Container maxWidth="lg">
      <div className="flex flex-col gap-[5px] p-[5px] pb-[10px]">
        <Typography ref={selectRef} variant="h5" align="center" gutterBottom style={{ padding: '10px' }}>
          {formName}
        </Typography>
      </div>
      <Grid item xs={12}>
        <Paper elevation={3} style={{ padding: '20px' }}>
          <div className="flex justify-between items-center pb-[12px]">
            <Link to="../.." relative="path">
              <Button color="secondary" style={{ display: 'flex', gap: '8px', borderRadius: '50px' }} variant="outlined">
                Go Back
              </Button>
            </Link>
            <div className="flex gap-[15px]">
              {results && (
                <div className="content-center">
                  <Typography variant="body2" sx={{ opacity: '70%' }}>
                    <b>Last Modified: </b>{formatAsDayMonthYear(results[0].updated_at)}
                  </Typography>
                  <Typography variant="body2" sx={{ opacity: '70%' }}>
                    <b>Modified By: </b>{emailToName(results[0].updated_by)}&nbsp;
                    <AccountCircleIcon />
                  </Typography>
                </div>
              )}
              <FormControl>
                <InputLabel>Subscription Type</InputLabel>
                <Select
                  sx={{ width: '200px' }}
                  value={subscriptionId}
                  label="Subscription Type"
                  onChange={(e) => setSubscriptionId(e.target.value)}
                >
                  {subscriptionsCb?.length === 0 ? (
                    <MenuItem disabled>
                      <CircularProgress color="inherit" size={30} />
                    </MenuItem>
                  ) : (
                    subscriptionsCb.map((val) => (
                      <MenuItem key={val.id} value={val.id}>
                        {val.name} <span style={{ textTransform: 'capitalize', opacity: '60%', fontSize: 'smaller' }}>&nbsp;({val.status})</span>
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="w-auto flex flex-col gap-[15px]">
            <Form
              ref={formRef} // Assign the ref to the form
              initialValues={formData}
              schema={schema}
              onSubmit={() => setOpenDialog(true)}
            />
            <div className="flex justify-center">
              <Button
                size="large"
                style={{ display: 'flex', gap: '8px', borderRadius: '50px' }}
                variant="contained"
                color="primary"
                onClick={() => updateButton()}
              >
                <ArrowCircleUpIcon /> {resultId ? 'Update Form' : 'Create Form'}
              </Button>
            </div>
          </div>
        </Paper>
      </Grid>
      <Confirmation
        openDialog={openDialog}
        handleClose={handleUpdateClose}
        itemName="Form"
        loading={loadingPopup}
        text="Update"
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert
            {...snackbar}
            onClose={handleCloseSnackbar}
            variant="filled"
          />
        </Snackbar>
      )}
    </Container>
  )
}

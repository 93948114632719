import { memo } from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import { Container, Typography, Paper } from '@mui/material'
import Slide from '@mui/material/Slide'
import Fade from '@mui/material/Fade'
import { LoadingTable } from './LoadingTable'

export const LoadingData = memo(() => {
  return (
    <Slide in direction="left">
      <div>
        <Fade in>
          <Container maxWidth="lg">
            <Typography variant="h5" sx={{ paddingTop: '24px', paddingBottom: '12px' }} >
              Loading...
            </Typography>
            <LinearProgress color="secondary" />
            <Paper elevation={3} style={{ padding: '20px' }}>
              <LoadingTable />
            </Paper>
          </Container>
        </Fade>
      </div>
    </Slide>
  )
})

import { createClient } from '@refinedev/supabase'

const url =  process.env.REACT_APP_SUPABASE_URL
const key =  process.env.REACT_APP_SUPABASE_KEY

export const supabaseClient = createClient(url, key, {
  db: {
    schema: 'public',
  },
  auth: {
    persistSession: true
  }
})

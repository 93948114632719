/**
 * Format a google ads id
 *
 * @param {string} id The google ads id string
 * @returns {string}
 */
export const formatGoogleAdsId = (id: string): string => {
  const digits = id.replace(/\D/g, '')
  if (digits.length >= 10) {
    const firstGroup = digits.slice(0, 3)
    const secondGroup = digits.slice(3, 6)
    const thirdGroup = digits.slice(6, 10)
    return `${firstGroup}-${secondGroup}-${thirdGroup}`
  } else {
    return digits.match(/\d{1,3}/g)?.join('-') || ''
  }
}

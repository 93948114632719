/**
 * Get disabled styling, if a case is closed/rejected
 *
 * @param {string} caseStatus Case Status
 * @returns {string}
 */
export const getDisabledStyle = (caseStatus: string): string => {
  const resultStyle = ['Closed', 'Awaiting Approval'].includes(caseStatus) ? 'opacity-[50%] pointer-events-none' : ''
  return resultStyle
}

import { useState } from 'react';
import { FormControl, InputAdornment, InputLabel, OutlinedInput, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export function InvoiceSearch({ invoices, setInv }) {
  const [searchParam, setSearchParam] = useState('')

  const handleFiltering = (e) => {
    setSearchParam(e.target.value)
    const filtered = invoices.filter(inv => inv.id.includes(String(e.target.value)))
    setInv(filtered)
  }

  return (
    <div className="w-full sm:w-[300px]">
      <FormControl fullWidth variant="outlined">
        <InputLabel size="small" htmlFor="Filter Invoices">Filter Invoices</InputLabel>
        <OutlinedInput
          type="text"
          size="small"
          endAdornment={
            <InputAdornment position="end">
              <IconButton edge="end">
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          }
          label="Filter Invoices"
          value={searchParam}
          onChange={handleFiltering}
          required
        />
      </FormControl>
    </div>
  );
}

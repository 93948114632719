/**
 * Convert an email to name format
 *
 * @param {string} email The raw email string
 * @returns {string}
 */
export const emailToName = (email: string): string => {
  if (!email || email?.length === 0) return email
  const name = email?.split('@')[0]
  if (!name || name?.length === 0) return email
  if (name?.split('.')?.length === 1) {
    // Split according to dashes in name
    return name?.split('-').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ') || email
  } else {
    // Split according to . in name
    return name?.split('.').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ') || email
  }
}

import { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Chip, Container, Divider, Fade, Slide, Typography } from '@mui/material'
import { Searchbar, SelectCaseStatus } from './Filters'
import type { CaseQueries } from '../../interface/queries/CaseQueries'
import { CaseStepper } from './CaseStepper'

export function TeamCases() {
  const { teamCases } = useOutletContext<CaseQueries>()
  const [filteredCases, setFilteredCases] = useState(teamCases)

  return (
    <Slide in direction="left">
      <Fade in>
        <Container maxWidth="lg" style={{ padding: '0px', paddingTop: '24px', paddingLeft: '16px' }}>
          <Typography variant="h5" gutterBottom>
            Team Cases
          </Typography>
          <div className="flex flex-row items-center justify-between">
            <Typography component="div" style={{ opacity: '70%' }}>
              You have  <Chip size="small" label={teamCases?.length || 0} color="primary" />  Team case{teamCases?.length === 1 ? '' : 's'} sitting underneath you. You can override your teams cases here.
            </Typography>
            {(teamCases?.length > 0) && (
              <div className="flex flex-row">
                <Searchbar
                  originalCases={teamCases}
                  filteredCases={filteredCases}
                  setFilteredCases={setFilteredCases}
                />
                <SelectCaseStatus
                  originalCases={teamCases}
                  filteredCases={filteredCases}
                  setFilteredCases={setFilteredCases}
                />
              </div>
            )}
            </div>
            <Typography component="div" style={{ opacity: '70%' }}>
              {filteredCases?.length !== teamCases?.length ? filteredCases?.length + ' filtered cases' : ''}
            </Typography>

            {(teamCases?.length > 0) ? (
              <>
                <Divider sx={{ paddingBottom: '20px' }} />
                <CaseStepper
                  cases={filteredCases}
                  caseType="Team"
                />
              </>
            ) : (
              <Typography style={{ opacity: '70%' }}>
                You have no Team cases to review.
              </Typography>
            )}
        </Container>
      </Fade>
    </Slide>
  )
}

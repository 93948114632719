import { useParams } from 'react-router-dom'
import { useDocumentTitle } from '@refinedev/react-router-v6'
import { Chip, Container, Tooltip, Typography } from '@mui/material'
import { NoDataAlert } from '../components/Alert'
import { extendBrandAbbr } from '../utility/helpers/brandHelpers'
import { sortByLatestForms } from '../utility/helpers/formHelpers'
import { CustomerTabs } from '../components/Tabs'
import { LoadingPage } from '../components/Loading'
import { CustomerBreadcrumb as Breadcrumb } from '../components/Breadcrumbs'
import { useGetCompanyDetailsQuery, useGetContactTokensQuery, useGetBrandDetailsQuery } from '../services/companyApi'
import { useGetSubscriptionsQuery, useGetSubscriptionInfoQuery, useGetInvoicesQuery } from '../services/subscriptionsApi.js'
import { useGetGeolocationsQuery } from '../services/geolocationsApi'
import { useGetCasesQuery } from '../services/casesApi'
import { useGetFormSchemaQuery, useGetFormTypesQuery, useGetFormResultsQuery } from '../services/formsApi'
import { useGetClientLoginsQuery } from '../services/clientLoginsApi'
import { useGetKeywordsQuery } from '../services/keywordsApi'
import { useGetBacklinksCustomerQuery, useGetBacklinksKeywordQuery } from '../services/backlinksApi'
import { useGetCreditCardQuery } from '../services/cardApi'
import type { CompanyDataInterface } from '../interface/data/CompanyDataInterface'
import type { BrandDetailsDataInterface } from '../interface/data/BrandDetailsDataInterface'
import type { TokenDataInterface } from  '../interface/data/TokenDataInterface'
import type { CaseDataInterface } from  '../interface/data/CaseDataInterface'
import type { GeolocationDataInterface } from  '../interface/data/GeolocationDataInterface'
import type { SubDataInterface } from  '../interface/data/SubDataInterface'
import type { SubInfoDataInterface } from  '../interface/data/SubInfoDataInterface'
import type { InvoiceDataInterface } from  '../interface/data/InvoiceDataInterface'
import type { FormResultsDataInterface } from  '../interface/data/FormResultsDataInterface'
import type { FormTypesDataInterface } from  '../interface/data/FormTypesDataInterface'
import type { ClientLoginsDataInterface } from  '../interface/data/ClientLoginsDataInterface'
import type { KeywordsDataInterface } from  '../interface/data/KeywordsDataInterface'
import type { BacklinksDataInterface } from  '../interface/data/BacklinksDataInterface'
import type { CreditCardDataInterface } from  '../interface/data/CreditCardDataInterface'

export function CustomerPage() {
  const { customerId, keywordId, productId } = useParams()

  //*--- Initialie RTK Queries at Top Level
  // Company Specific
  const { data: companyData, isLoading: companyLoading } = useGetCompanyDetailsQuery<CompanyDataInterface>(customerId)
  const { data: brandDetailsData } = useGetBrandDetailsQuery<BrandDetailsDataInterface>(companyData?.brand, { skip: !companyData })
  const { data: tokenData = [] } = useGetContactTokensQuery<TokenDataInterface>(companyData, { skip: !companyData })
  const { data: casesData = [], isLoading: casesLoading } = useGetCasesQuery<CaseDataInterface>(customerId)
  const { data: subscriptionsData, isLoading: subscriptionsLoading } = useGetSubscriptionsQuery<SubDataInterface>(customerId)
  const skipSubscriptionInfo = !subscriptionsData || subscriptionsData.length === 0
  const { data: subscriptionInfoData } = useGetSubscriptionInfoQuery<SubInfoDataInterface>(subscriptionsData?.map(sub => sub.id), { skip: skipSubscriptionInfo })
  const { data: invoicesData = [], isLoading: invoicesLoading } = useGetInvoicesQuery<InvoiceDataInterface>(customerId)
  const { data: formResultsData = [], isLoading: formResultsLoading } = useGetFormResultsQuery<FormResultsDataInterface>({ customerId, resultId: null })
  const filteredForms = formResultsData && !formResultsLoading ? sortByLatestForms(formResultsData) : []
  const { data: clientLoginsData = [], isLoading: clientLoginsLoading } = useGetClientLoginsQuery<ClientLoginsDataInterface>(customerId)
  const { data: keywordsData = [], isLoading: keywordsLoading } = useGetKeywordsQuery<KeywordsDataInterface>(productId, { skip: !productId })
  const { data: backlinksCustomerData = [], isLoading: backlinksCustomerLoading } = useGetBacklinksCustomerQuery<BacklinksDataInterface>(customerId, { skip: !customerId })
  const { data: backlinksKeywordData = [], isLoading: backlinksKeywordLoading } = useGetBacklinksKeywordQuery<BacklinksDataInterface>(keywordId, { skip: !keywordId })
  const { data: creditCardData = [], isLoading: creditCardLoading } = useGetCreditCardQuery<CreditCardDataInterface>(customerId, { skip: !customerId })

  // Global
  const { data: geolocationsData } = useGetGeolocationsQuery<GeolocationDataInterface>()
  const { data: formTypesData } = useGetFormTypesQuery<FormTypesDataInterface>()

  useDocumentTitle(`${companyData ? companyData?.name : customerId} | Admin Panel`)

  if (companyLoading) {
    return <LoadingPage Breadcrumb={Breadcrumb} />
  }

  if (!companyData) {
    return <NoDataAlert dataType="Customer" />
  }

  return (
    <div className="max-w-[90vw]">
      {(companyData) && (
        <Container style={{ display: 'flex', flexFlow: 'column',  margin: '0px', maxWidth: '100%', alignItems: 'start' }}>
          <div className="w-max ml-[50px]">
            <div className="flex justify-start">
              <Breadcrumb
                subscriptionsData={subscriptionsData}
                companyData={companyData}
              />
            </div>
            <div className="flex flex-col gap-[8px] pt-[16px] pb-[16px] justify-center">
              <div className="flex flex-row gap-[10px] items-center">
                <Typography variant="h4">
                  {companyData?.name}
                </Typography>
                {companyData?.active ? (
                  <Chip label="ACTIVE" color="success" variant="outlined" />
                ) : (
                  <Tooltip title="This is an inactive customer. Please refrain from making any further changes.">
                    <Chip label="INACTIVE" color="error" variant="outlined" />
                  </Tooltip>
                )}
              </div>
              <Typography variant="body1" sx={{ display: 'flex', gap: '16px' }}>
                {(companyData?.brand) && (
                  <img className="w-[24px] h-[24px]" src={`/images/logo_natural_${companyData?.brand.toUpperCase()}.svg`}/>
                )}
                {extendBrandAbbr(companyData?.brand)}
              </Typography>
            </div>
          </div>
          <CustomerTabs
            companyData={companyData}
            companyLoading={companyLoading}
            brandDetailsData={brandDetailsData}
            tokenData={tokenData}
            casesData={casesData}
            casesLoading={casesLoading}
            geolocationsData={geolocationsData}
            subscriptionsData={subscriptionsData}
            subscriptionsLoading={subscriptionsLoading}
            subscriptionInfoData={subscriptionInfoData}
            formResultsData={filteredForms}
            formResultsLoading={formResultsLoading}
            formTypesData={formTypesData}
            clientLoginsData={clientLoginsData}
            clientLoginsLoading={clientLoginsLoading}
            invoicesData={invoicesData}
            invoicesLoading={invoicesLoading}
            keywordsData={keywordsData}
            keywordsLoading={keywordsLoading}
            backlinksCustomerData={backlinksCustomerData}
            backlinksCustomerLoading={backlinksCustomerLoading}
            backlinksKeywordData={backlinksKeywordData}
            backlinksKeywordLoading={backlinksKeywordLoading}
            creditCardData={creditCardData}
            creditCardLoading={creditCardLoading}
          />
        </Container>
      )}
    </div>
  )
}

import { useState, useEffect } from 'react'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import PausePresentationIcon from '@mui/icons-material/PausePresentation'
import FilePresentIcon from '@mui/icons-material/FilePresent'
import PresentToAllIcon from '@mui/icons-material/PresentToAll'

export function SelectCaseType ({
    originalCases,
    filteredCases,
    setFilteredCases
  }) {
  const [filter, setFilter] = useState('All')

  useEffect(() => {
    if (filter !== 'All') {
      const newCases = originalCases.filter(c => c.case_type === filter)
      setFilteredCases(newCases)
    } else {
      setFilteredCases(originalCases)
    }
  }, [filter, originalCases, setFilteredCases])

  return (
    <FormControl sx={{ m: 1, width: 200 }} size="small">
      <InputLabel>Filter by case type</InputLabel>
      <Select
        label="Filter by case type"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
      >
        <MenuItem value="All">
          All
        </MenuItem>
        <MenuItem value="Cancel">
          <CancelPresentationIcon color="error" sx={{ marginRight: 1 }} />
          Cancels
        </MenuItem>
        <MenuItem value="Pause">
          <PausePresentationIcon color="warning" sx={{ marginRight: 1 }} />
          Pauses
        </MenuItem>
        <MenuItem value="Altered Products">
          <PresentToAllIcon color="info" sx={{ marginRight: 1 }} />
          Altered Products
        </MenuItem>
        <MenuItem value="Billing Alterations Only">
          <FilePresentIcon color="success" sx={{ marginRight: 1 }} />
          Billing Alterations
        </MenuItem>
      </Select>
      <FormHelperText>Filter by Cancel, Pause etc.</FormHelperText>
    </FormControl>
  )
}

interface userData {
  id: number
  email: string
  manager: string
}

/**
 * Extract case system managers for a specified user email
 *
 * @param {Array} allUsers An object of all users from the users table with id, email and manager columns
 * @param {string} email Raw email string
 * @returns {Array}
 */
export const extractCaseManagers = (
  allUsers: userData[],
  email: string
): string[] => {
  const managerData = []
  let username = (email || '').split('@')[0] || ''
  let i = 0
  while (username && username !== process.env.REACT_APP_CASE_SYSTEM_APPROVER) {
    if (i === 12) break // only look for 12 manager chains, to avoid infinite loops
    const userData = allUsers?.filter(user => user?.email?.split('%')[0].includes(username))
    username = userData[0]?.manager
    managerData.push(username)
    i++
  }
  return managerData
}

import { SeoIcon, GoogleAdsIcon, OtherIcon } from './'
import FacebookIcon from '@mui/icons-material/Facebook'
import YouTubeIcon from '@mui/icons-material/YouTube'
import InstagramIcon from '@mui/icons-material/Instagram'
import LinkedInIcon from '@mui/icons-material/LinkedIn'

interface GetIconByProductInterface {
  name: string
  mode: string
}

export const GetIconByProduct = ({name, mode}: GetIconByProductInterface) => {
  const lowerCaseName = name?.toLowerCase()

  if (lowerCaseName.includes('seo')) {
    return <SeoIcon mode={mode} />
  }
  if (lowerCaseName.includes('ads') || lowerCaseName.includes('-ga') || lowerCaseName.includes('adwords')) {
    return <GoogleAdsIcon mode={mode} />
  }
  if (lowerCaseName.includes('facebook') || lowerCaseName.includes('social')) {
    return <FacebookIcon sx={{ opacity: '66%' }} />
  }
  if (lowerCaseName.includes('instagram')) {
    return <InstagramIcon sx={{ opacity: '66%' }} />
  }
  if (lowerCaseName.includes('youtube')) {
    return <YouTubeIcon sx={{ opacity: '66%' }} />
  }
  if (lowerCaseName.includes('linkedin')) {
    return <LinkedInIcon sx={{ opacity: '66%' }} />
  }
  return <OtherIcon mode={mode} />
}

import React from 'react'
import { Radio, RadioGroup, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormControl from '@mui/material/FormControl'
import { Copied } from '../../Popover'

interface FormDataProps {
  label: string
  value: string | boolean | number | string[] | boolean[] | number[] | null
}

export function FormDataFormatted ({
  formData = null
}: {
  formData: FormDataProps | null
}) {

  //* HANDLERS
  const radioGroup = (option: string) => {
    const value = option === 'true' ? 'yes' : 'no'
    return (
      <FormControl>
        <RadioGroup value={value}>
          <FormControlLabel disabled value="yes" control={<Radio size="small" />} label="Yes" />
          <FormControlLabel disabled value="no" control={<Radio size="small" />} label="No" />
        </RadioGroup>
      </FormControl>
    )
  }

  const textData = (data: string) => {
    return (
      <div className="flex items-center">
        <Typography variant="body1" sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {(data?.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}<br/>
            </React.Fragment>
          )))}
        </Typography>
        <Copied toCopy={data} />
      </div>
    )
  }

  const handleRenderData = (value) => {
    if (value === null || value === undefined || value === '') {
      return ''
    }
    switch (typeof value) {
      case 'boolean':
        return radioGroup(String(value))
      case 'string':
        return textData(String(value))
      case 'number':
        return textData(String(value)) //! TODO: format numbers better
      default:
        return null
    }
  }

  return (
    <Table sx={{ width: '100%' }}>
      <TableHead>
        <TableRow>
          <TableCell>
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              Field Title
            </Typography>
          </TableCell>
          <TableCell>
            <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
              Value
            </Typography>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {Object.entries(formData).map(([key, value]) => (
          <TableRow key={key}>
            <TableCell>
              <Typography variant="button" sx={{ fontWeight: 500 }}>
                {value?.label}
              </Typography>
            </TableCell>
            <TableCell sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {handleRenderData(value?.value)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}

import { useState, useEffect, memo } from 'react'
import { useLocation, Link, Outlet } from 'react-router-dom'
import Badge, { BadgeProps } from '@mui/material/Badge'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'

const routes = [ // append routes you want to showcase on tabs here
  { name: 'outstanding', label: 'Outstanding' },
  { name: 'submitted', label: 'Submitted' },
  { name: 'team', label: 'Team Cases' },
  { name: 'summary', label: 'Cases Table' }
]

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -5,
    top: -2,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))

export function CaseTabs(props) {
  const { pathname } = useLocation()
  const [value, setValue] = useState('outstanding')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  useEffect(() => {
    const tabValue = routes.find(route => pathname.includes(route?.name))?.name || 'outstanding'
    setValue(tabValue)
  }, [pathname])

  return (
    <div className="pt-[16px]">
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} >
          <TabList onChange={handleChange} variant="scrollable">
            {routes.map((route, index) => (
              <Tab
                label={
                  <StyledBadge
                    badgeContent={route?.name !== 'summary' ? eval(props[route?.name === 'outstanding' ? 'outstandingCases' : route?.name + 'Cases'])?.length : 0}
                    invisible={
                      route?.name !== 'outstanding'
                        ?
                      true
                        :
                      props?.outstandingCases?.length === 0
                        ?
                      true
                        :
                      false
                      }
                    color="error"
                  >
                    {route.label}
                  </StyledBadge>
                }
                key={index}
                value={route.name} 
                component={Link}
                to={route.name}
              />
            ))}
          </TabList>
        </Box>
        {[...Array(routes?.length)].map((_, index) => (
          <TabPanel key={index} value={index.toString()} sx={{ padding: '0px' }} />
        ))}
      </TabContext>
      <Outlet context={props} />
    </div>
  )
}

import { useState, useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../interface/RootState'
import { Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { useParams } from 'react-router-dom'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import { ColorModeContext } from '../../../contexts/color-mode'

export function UpsertForm ({
  upsertFields = null,
  title = 'Data',
  upsertDialog = false,
  closeForm,
  formData,
  setFormData,
  isCreate,
  handleUpdateQuery,
  handleCreateQuery,
  loading
}) {

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const { customerId } = useParams()

  const { mode, setMode } = useContext(ColorModeContext)

  const [showPassword, setShowPassword] = useState(false)
  const [filteredFormTypes, setFilteredFormTypes] = useState([])
  const handleClickShowPassword = () => setShowPassword((show) => !show)

  //* Extract data from Redux
  const geolocations = useSelector((store: RootState) => store.global.geolocations) //! TODO pass these in as props from TableTemplate, not useSelector (temporary)
  const subscriptionsCb = useSelector((store: RootState) => store.global.company.subscriptions)
  const formTypes = useSelector((store: RootState) => store.global.formTypes)
  const invoices = useSelector((store: RootState) => store.global.company.invoices)

  useEffect(() => {
    if (formTypes && formTypes?.length > 0) {

      setFilteredFormTypes(formTypes.filter(type => type.id !== 6)) //! TODO: filter based on permissions
    }
  }, [formTypes])

  const upsertRender = upsertFields ? upsertFields(isCreate, formData, setFormData, customerId, geolocations, filteredFormTypes, subscriptionsCb, showPassword, handleClickShowPassword, invoices, mode) : null
  
  return (
    <Dialog
      open={upsertDialog}
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '700px',
          },
        },
      }}
      onClose={closeForm}
      PaperProps={{
        component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            isCreate ? handleCreateQuery(formData) : handleUpdateQuery(formData)
          },
      }}
    >
      <DialogTitle style={{ fontSize: '20px', fontWeight: '500' }}>
        {!isCreate ? `${title} Details` : `New ${title}`}
      </DialogTitle>
      <DialogContent style={{ display: 'flex', flexFlow: 'column', gap: '20px', padding: '30px', paddingTop: '10px', paddingBottom: '10px' }}>
        {upsertRender}
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'flex-end', padding: '15px' }}>
        <Button variant="text" style={{ display: 'flex', gap: '8px' }} color="primary" disabled={loading} onClick={closeForm}>
          Cancel
        </Button>
        <Button variant="contained" style={{ display: 'flex', gap: '8px' }} color="primary" disabled={loading} endIcon={loading ? <CircularProgress size="0.99rem" /> : null} type="submit">
          {!isCreate ? 'Save' : 'Create'}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

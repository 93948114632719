import { Alert, Container } from '@mui/material'

export const NoDataAlert = ({
  dataType = 'page'
}) => {
  return (
    <Container maxWidth="lg">
      <Alert severity="error" style={{ padding: '15px', marginBottom: '15px' }}>
        Error 404: The {dataType} was not found.
      </Alert>
    </Container>
  )
}

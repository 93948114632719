import { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../interface/RootState'
import { queries } from '../../../utility'
import { emailToName } from '../../../utility/helpers/miscHelpers'
import { formatAsDayMonthYear } from '../../../utility/helpers/dateHelpers'
import { extractCaseManagers } from '../../../utility/helpers/caseHelpers'
import type { CaseDataInterface } from '../../../interface/data/CaseDataInterface'
import Alert, { AlertProps } from '@mui/material/Alert'
import { Box, Container, Divider, Fade, IconButton, Link, List, Pagination, Slide, Snackbar, Step, StepLabel, Stepper, TablePagination, Tooltip, Typography } from '@mui/material'
import { StepLabelProps } from '@mui/material/StepLabel'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import ReplayIcon from '@mui/icons-material/Replay'
import { ApprovalDialog, DataDialog, ReceiptDialog } from '../Dialogs'
import { LoadingPage } from '../../Loading'
import { Confirmation } from '../../Forms/Popup'
import { styled } from '@mui/system'
import { Breadcrumb } from '@refinedev/mui'

interface CustomStepLabelProps extends StepLabelProps {
  color?: string
  error?: boolean
}

const CustomStepLabel = styled(StepLabel)<CustomStepLabelProps>(({ color, error }) => {
  return {
    ...(error && {
      color,
      '.MuiStepLabel-label': {
        color, // Apply to label
      },
      '.MuiStepLabel-iconContainer .MuiSvgIcon-root': {
        color, // Apply to icon
      },
    }),
  }
})

export function CaseStepper ({
    cases,
    caseType
  }) {
  // Pagination
  const [page, setPage] = useState(0)
  const [rowPerPage, setRowPerPage] = useState(5)
  const totalRows = page * rowPerPage
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const [fetched, setFetched] = useState(false)
  const [managers, setManagers] = useState([])
  const [stepperData, setStepperData] = useState([])
  const [selectedCase, setSelectedCase] = useState<CaseDataInterface>()
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [openReceipts, setOpenReceipts] = useState<boolean>(false)
  const [receiptData, setReceiptData] = useState({})
  const [loadingApproval, setLoadingApproval] = useState<boolean>(false)
  const [loadingRejection, setLoadingRejection] = useState<boolean>(false)
  const [userAllowed, setUserAllowed] = useState<boolean>(false)
  const [openRecall, setOpenRecall] = useState<boolean>(false)

  //* Extract data from Redux
  const userEmail = useSelector((store: RootState) => store.global.user.email)
  const allUsers = useSelector((store: RootState) => store.global.allUsers)

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)

  const handleCloseSnackbar = () => setSnackbar(null)

  //* Queries
  const actionCase = async (status, caseData) => {
    const data = await queries.submitCase(caseData, status)
    return data || []
  }

  //* Handlers
  const updateHandler = async (closeStatus, caseData) => {
    try {
      let status
      if (!closeStatus) return
      if (closeStatus === 'Approved') {
        status = 'Closed'
        setLoadingApproval(true)
      } else if ( closeStatus === 'Rejected' ) {
        status = 'Approval Rejected'
        setLoadingRejection(true)
      }
      const actionRes = await actionCase(status, caseData)
      setReceiptData(actionRes.response)
      setSnackbar({ children: 'Case ' + closeStatus, severity: 'success' })
      if (closeStatus === 'Approved' || closeStatus === 'Rejected') {
        window.location.reload()
      }
    } catch (error) {
      setSnackbar({ children: 'Error actioning case', severity: 'error' })
      console.error('Could not action case: ' + caseData.case_number)
    } finally {
      setLoadingApproval(false)
      setLoadingRejection(false)
      setOpenDialog(false)
    }
  }
  const handleClickOpen = (val) => {
    setSelectedCase(val)
    setOpenDialog(true)
  }
  const handleReceiptOpen = (val) => {
    setSelectedCase(val)
    setReceiptData(val?.receipt_data)
    setOpenReceipts(true)
  }
  const handleRecallOpen = (val) => {
    setSelectedCase(val)
    setOpenRecall(true)
  }
  const handleApprovalClose = (closeStatus, caseData) => {
    updateHandler(closeStatus, caseData)
  }
  const handleRecallBtn = async (confirm) => { // the inline recall button
    if (confirm) {
      try {
        setLoadingApproval(true)
        const recallRes = await queries.recallCase(selectedCase.case_number)
        if (!recallRes) {
          return setSnackbar({ children: 'Error recalling case', severity: 'error' })
        }
        setSnackbar({ children: 'Successfully recalled case', severity: 'success' })
      } catch (error) {
        setSnackbar({ children: 'Error recalling case', severity: 'error' })
      } finally {
        setLoadingApproval(false)
        setOpenRecall(false)
      }
    } else {
      setOpenRecall(false)
    }
  }

  const getHandler = useCallback(async (allUserData) => {
    try {
      const initialManagers = extractCaseManagers(allUserData, cases[0].case_originator)
      setManagers(initialManagers)
  
      // if all case originators are the same, then just find 1 set of managers
      if (cases.every(({ case_originator }) => case_originator === cases[0].case_originator)) {
        setStepperData(
          cases.map(caseItem => [
            emailToName(caseItem.case_originator),
            ...initialManagers.map(email => (email ? emailToName(email) : 'Accounts')),
          ])
        )
      } else {
        // else find manager hierarchy for each department member
        const stepperDataArray = await Promise.all(
          cases.map(async caseItem => {
            const managerData = extractCaseManagers(allUserData, caseItem.case_originator)
            return [
              emailToName(caseItem.case_originator),
              ...managerData.map(email => (email ? emailToName(email) : 'Accounts')),
            ]
          })
        )
  
        setStepperData(stepperDataArray)
      }
    } catch (err) {
      console.error('Could not extract manager data for given cases:', err)
    } finally {
      setFetched(true)
    }
  }, [cases])
  

  //* Functions
  const stepChecker = (stepIdx, val) => {
    if (val.status === 'Closed') {
      return stepperData[stepIdx]?.length || 0
    }
    const idx = stepperData[stepIdx].findIndex(status => status.includes(emailToName(val.submitted_manager)))
    return idx
  }

  // Extract cases
  useEffect(() => {
    if (cases?.length > 0 && !fetched && allUsers) {
      getHandler(allUsers)
    }
  }, [cases, fetched, getHandler, allUsers])

  // Define allowed managers to override case
  useEffect(() => {
    const allowed = managers?.slice(managers?.indexOf(selectedCase?.submitted_manager), managers?.length || 0) // return arr of managers that can bypass case
    setUserAllowed(allowed.some(email => email?.includes(userEmail?.split('@')[0])))
  }, [selectedCase, managers, userEmail])

  if (cases?.length > 0 && !fetched) {
    return (
      <LoadingPage Breadcrumb={Breadcrumb} />
    )
  }

  return (
    <>
      <Slide in direction="left">
        <div>
          {Object.keys(cases).slice(totalRows, totalRows + rowPerPage).map((key, idx) => (
            <List
              key={idx}
              sx={{
                  listStyleType: 'disc',
                  listStylePosition: 'inside',
                  paddingTop: '21px',
                  paddingLeft: '20px',
                  paddingRight: '20px'
                }}
              >
                <div className="flex justify-between pr-[30px]">
                  <Typography variant="subtitle1" component="span" style={{ lineHeight: '1.5' }}>
                    <Link
                      key={idx}
                      component="button"
                      variant="body2"
                      onClick={() => handleClickOpen(cases[key])}
                      style={{ fontSize: '1.1rem' }}
                    >
                      <Typography variant="subtitle1" component="span">{cases[key].case_number}</Typography>
                    </Link>
                    :&nbsp;
                    <span style={{ fontSize: '1rem' }}>{cases[key].customer_name}</span>
                    <span style={{ textTransform: 'capitalize', opacity: '60%', fontSize: '0.9rem' }}>
                      &nbsp;({cases[key].subscription_name} | {cases[key].case_type})
                    </span>
                  </Typography>
                  {(caseType === 'Submitted' && cases[key]?.status === 'Awaiting Approval') && (
                    <Tooltip title="Recall Case">
                      <IconButton onClick={() => handleRecallOpen(cases[key])} color="error">
                        <ReplayIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                  {(cases[key]?.status === 'Closed' || cases[key]?.status.includes('Rejected')) && (
                    <Tooltip title="Case Receipts">
                      <IconButton onClick={() => handleReceiptOpen(cases[key])} color="warning">
                        <ReceiptLongIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
                <Box sx={{ width: '100%', paddingTop: '10px', paddingBottom: '28px', paddingLeft: '30px', paddingRight: '30px' }}>
                  <Stepper activeStep={stepChecker(idx + totalRows, cases[key]) || 1}>
                    {stepperData[idx + totalRows].map((label, index) => {
                      const labelProps: {
                        color?: string
                        error?: boolean
                      } = {}
                      if (stepChecker(idx + totalRows, cases[key]) === index) {
                        if (cases[key].status.includes('Rejected')) {
                          labelProps.color = 'red'
                          labelProps.error = true
                        } else {
                          labelProps.color = 'orange'
                          labelProps.error = true
                        }
                      }
                      return (
                        <Tooltip
                          key={index}
                          title={
                            stepChecker(idx * page, cases[key]) !== index
                              ? ''
                              : cases[key].status.includes('Rejected')
                                ? 'Case Rejected' + (cases[key].closed_at ? ` on ${formatAsDayMonthYear(cases[key].closed_at)}` : '')
                                : cases[key].status.includes('Awaiting Approval')
                                  ? 'Awaiting Approval'
                                  : 'Approved' + (cases[key].closed_at ? ` on ${cases[key].closed_at}` : '')
                          }
                        >
                          <Step key={label}>
                            <CustomStepLabel {...labelProps}>
                              {label}
                            </CustomStepLabel>
                          </Step>
                        </Tooltip>
                      )                              
                    })}
                  </Stepper>
                </Box>
              <Divider />
            </List>
          ))}
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]} 
              component="div"
              count={Object.keys(cases)?.length || 0}
              rowsPerPage={rowPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={(e) => handleChangeRowsPerPage(e)}
            />
          </Box>
        </div>
      </Slide>
      {/*----- Dialogs and Snackbars -----*/}
      {(
        caseType === 'Team' &&
        selectedCase?.status !== 'Closed' &&
        selectedCase?.status !== 'Approval Rejected' &&
        !selectedCase?.submitted_manager.includes('accounts') &&
        userAllowed // check if they are a manager allowed to override
      ) ? (
        <ApprovalDialog
          caseData={selectedCase}
          setCaseData={setSelectedCase}
          open={openDialog}
          setOpen={setOpenDialog}
          handleClose={handleApprovalClose}
          loadingApproval={loadingApproval}
          loadingRejection={loadingRejection}
        />
      ) : (
        <DataDialog
          data={selectedCase}
          open={openDialog}
          handleClose={() => setOpenDialog(false)}
        />
      )}
      <Confirmation
        openDialog={openRecall}
        handleClose={handleRecallBtn}
        itemName={'Case'}
        loading={loadingApproval}
        text={'Recall'}
      />
      <ReceiptDialog
        caseData={selectedCase}
        receiptData={receiptData}
        open={openReceipts}
        handleClose={() => setOpenReceipts(false)}
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert
            {...snackbar}
            onClose={handleCloseSnackbar}
            variant="filled"
          />
        </Snackbar>
      )}
    </>
  )
}

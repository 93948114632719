import React, { memo } from 'react'
import { Container, Grow, Typography, Skeleton } from '@mui/material'

interface LoadingPageProps {
  Breadcrumb: React.ComponentType;
}

export const LoadingPage = memo(({ Breadcrumb }: LoadingPageProps) => {
  return (
    <div className="max-w-[90vw]">
      <Grow in {...({ timeout: 500 })}>
        <Container style={{ display: 'flex', flexFlow: 'column',  margin: '0px', maxWidth: '100%', alignItems: 'start' }}>
        <div className="w-full ml-[50px]">
          <div className="flex justify-start">
            <Breadcrumb />
          </div>
          <div className="flex flex-col gap-[8px] pt-[16px] pb-[16px]">
            <div className="flex flex-row gap-[10px]">
              <Typography variant="h3" sx={{ width: '20%'}}>
                <Skeleton animation="wave" />
              </Typography>
              <Typography variant="h3" sx={{ width: '5%'}}>
                <Skeleton animation="wave" />
              </Typography>
            </div>
            <Typography variant="h3" sx={{ width: '40%'}}>
              <Skeleton variant="circular" width={40} height={40} animation="wave" />
            </Typography>
            <Typography variant="h4" sx={{ width: '40%'}}>
              <Skeleton animation="wave" />
            </Typography>
          </div>
          <Skeleton variant="rounded" sx={{ width: '75%'}} height={400} animation="wave" />
        </div>
        </Container>
      </Grow>
    </div>
  )
})

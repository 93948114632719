import { useState, useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Box, Checkbox, Chip, FormControlLabel, IconButton, Link, List, ListItem, Pagination, Tooltip, Typography } from '@mui/material'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import { Searchbar, SelectCaseType, SelectCaseOutcome } from '../Filters'
import { formatAsDayMonthYear } from '../../../utility/helpers/dateHelpers'
import { getReceiptAction } from '../../../utility/helpers/caseHelpers'
import type { CaseQueries } from '../../../interface/queries/CaseQueries'
import { useMarkCaseActionedMutation } from '../../../services/casesApi'
import { CaseStatusIcon } from '../../Icons'

export function ClosedCases({
  handleOpenDialog,
  handleOpenReceipt
}) {
  //* Extract RTK Queries
  const { closedCases } = useOutletContext<CaseQueries>()
  const [markCaseActioned] = useMarkCaseActionedMutation()

  const [closedData, setClosedData] = useState(closedCases)

  useEffect(() => {
    setClosedData(closedCases)
  }, [closedCases])

  // Pagination
  const [page, setPage] = useState(1)
  const itemsPerPage = 8
  const startIndex = (page - 1) * itemsPerPage
  const endIndex = page * itemsPerPage

  const [filteredCases, setFilteredCases] = useState(closedData)

  //* Handlers
  const handleManualAction = async (key, caseNo) => {
    try {
      // mark as actioned in the database
      const marked = await markCaseActioned({ caseNo }).unwrap()
      if (!marked) {
        throw new Error('could not mark as actioned in db')
      }

      // mark as actioned in React
      setFilteredCases(prevFormData => 
        prevFormData.map((item, index) =>
          index === Number(key) ? { ...item, manually_actioned: !item.manually_actioned } : item
        )
      )
    } catch (error) {
      console.error('Error marking case as actioned', error)
    }
  }

  return (
    <>
      <Typography variant="h5" gutterBottom sx={{ paddingTop: '20px '}}>
        Closed Cases
      </Typography>
      <div className="flex flex-row items-center justify-between">
        <Typography component="div" style={{ opacity: '70%' }}>
          There are  <Chip size="small" label={closedCases?.length || 0} color="primary" />  closed case{filteredCases?.length === 1 ? '' : 's'}.
        </Typography>
        {(closedCases?.length > 0) && (
          <div className="flex flex-row">
            <SelectCaseOutcome
              originalCases={closedCases}
              filteredCases={filteredCases}
              setFilteredCases={setFilteredCases}
            />
            <Searchbar
              originalCases={closedCases}
              filteredCases={filteredCases}
              setFilteredCases={setFilteredCases}
            />
            <SelectCaseType
              originalCases={closedCases}
              filteredCases={filteredCases}
              setFilteredCases={setFilteredCases}
            />
          </div>
        )}
      </div>
      <Typography component="div" style={{ opacity: '70%' }}>
        {filteredCases?.length !== closedCases?.length ? filteredCases?.length + ' filtered cases' : ''}
      </Typography>

      {(filteredCases?.length > 0) ? (
        <>
          {Object.keys(filteredCases).slice(startIndex, endIndex).map((key, idx) => (
            <List
              key={idx}
              sx={{
                  listStyleType: 'disc',
                  listStylePosition: 'inside'
                }}
              >
                <ListItem sx={{ display: 'list-item' }}>
                  <Link
                    key={idx}
                    component="button"
                    variant="body2"
                    onClick={() => handleOpenDialog(filteredCases[key])}
                  >
                    <Typography variant="subtitle1" component="span">{filteredCases[key]?.case_number}</Typography>
                  </Link>
                  &nbsp;
                  <span>
                    <Typography variant="body2" component="span">
                      &nbsp;{filteredCases[key]?.customer_name}
                      &nbsp;<CaseStatusIcon status={getReceiptAction(filteredCases[key]?.receipt_data, filteredCases[key]?.manually_actioned).icon} />
                      <strong className={getReceiptAction(filteredCases[key]?.receipt_data, filteredCases[key]?.manually_actioned).color}>
                        &nbsp;{getReceiptAction(filteredCases[key]?.receipt_data, filteredCases[key]?.manually_actioned).message}
                      </strong>
                    </Typography>
                    <Tooltip title={formatAsDayMonthYear(filteredCases[key]?.updated_at)}>
                      <Typography variant="body1" component="span" color="default" sx={{ opacity: '60%' }}>
                        &nbsp;({Math.round(((new Date().getTime() as number) - (new Date(filteredCases[key]?.updated_at).getTime() as number)) / (1000 * 60 * 60 * 24))} days ago)&nbsp;
                      </Typography>
                    </Tooltip>
                  </span>
                  <Tooltip title="Chargebee Link">
                    <Link target="_blank" href={`https://disruptdigital.chargebee.com/d/subscriptions/${filteredCases[key]?.subscription_id}#summary`}>
                      <IconButton color="primary">
                        <img className="flex w-[22px] max-h-[22px] opacity-[70%] items-center justify-center self-center" src={'/images/Chargebee.svg'} />
                      </IconButton>
                    </Link>
                  </Tooltip>
                  <Tooltip title="Case Receipts">
                    <IconButton onClick={() => handleOpenReceipt(filteredCases[key])} color="warning">
                      <ReceiptLongIcon />
                    </IconButton>
                  </Tooltip>
                  {(getReceiptAction(filteredCases[key]?.receipt_data, filteredCases[key]?.manually_actioned).icon === 'Warning') && (
                    <FormControlLabel control={
                      <Checkbox
                        checked={filteredCases[key]?.manually_actioned}
                        disabled={filteredCases[key]?.manually_actioned}
                        onChange={() => handleManualAction(key, filteredCases[key]?.case_number)}
                      />
                      }
                      label={<Typography variant="body2" color="textSecondary">Actioned</Typography>}
                    />
                  )}
                </ListItem>
            </List>
          ))}
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
            <Pagination count={Math.ceil((Object.keys(filteredCases)?.length || 0) / itemsPerPage)} page={page} onChange={(event, value) => setPage(value)} />
          </Box>
        </>
      ) : (
        <div className="flex flex-row items-center justify-between">
          <Typography style={{ opacity: '70%' }}>
            There are no closed cases.
          </Typography>
        </div>
      )}
    </>
  )
}

import { RegisterFormTypes, useRegister, useActiveAuthProvider, useTranslate } from '@refinedev/core'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'

interface Provider {
  name: string
  label: string
  icon: React.ReactNode
}

export function RenderProviders (providers) {
  const authProvider = useActiveAuthProvider()
  const { mutate: registerMutate, isLoading } = useRegister<RegisterFormTypes>({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  })
  const translate = useTranslate()

  return (
    <div>
      <Stack spacing={1}>
        {providers.map((provider: Provider) => {
          return (
            <Button
              key={provider.name}
              color="secondary"
              fullWidth
              variant="outlined"
              sx={{
                color: 'primary.light',
                borderColor: 'primary.light',
                textTransform: 'none',
              }}
              onClick={() =>
                registerMutate({
                  providerName: provider.name,
                })
              }
              startIcon={provider.icon}>
              {provider.label}
            </Button>
          )
        })}
      </Stack>
      <Divider sx={{ fontSize: 12, marginY: '16px' }}>{translate('pages.login.divider', 'or')}</Divider>
    </div>
  )
}

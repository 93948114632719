import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import Typography from '@mui/material/Typography'

import { getReceiptAction } from '../../../utility/helpers/caseHelpers'
import { Copied } from '../../Popover'

export function ReceiptDialog ({
  caseData = null,
  receiptData,
  open,
  handleClose
}) {
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(null)}
    >
      <DialogTitle sx={{ alignItems: 'center' }}>
        Receiptss {caseData ? 'for Case #' + caseData?.case_number : ''} <Copied toCopy={caseData?.case_number} />
      </DialogTitle>
      <DialogContent>
        {receiptData && (
          <>
          {receiptData && Object.keys(receiptData).map((key) => (
            (receiptData[key]?.length > 0) && (
              <Accordion key={key} defaultExpanded sx={{ backgroundColor: 'rgb(245 245 245)' }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                >
                  <span className="text-gray-700 dark:text-gray-100 text-sm leading-5 font-medium">
                    {key === 'productReceipts' ? 'Product Receipts:' : key === 'creditReceipts' ? 'Credit Receipts:' : key === 'refundReceipts' ? 'Refund Receipts:' : 'Promotion Receipts'}
                  </span>
                </AccordionSummary>
                <AccordionDetails>
                  <div className="flex flex-col gap-[10px]">
                    {receiptData[key].map((receipt, index) => (
                      <div key={index} className="flex gap-[10px] text-gray-500 dark:text-gray-200 text-sm leading-5 font-normal">
                        {getReceiptAction([receipt], receiptData[key].manually_actioned).icon === 'Close' && (
                          <CloseIcon color="error" sx={{ width: '18px' }} />
                        )}
                        {getReceiptAction([receipt], receiptData[key].manually_actioned).icon === 'Done' && (
                          <DoneIcon color="success" sx={{ width: '18px' }} />
                        )}
                        {getReceiptAction([receipt], receiptData[key].manually_actioned).icon === 'Warning' && (
                          <WarningAmberIcon color="warning" sx={{ width: '18px' }} />
                        )}
                        <Typography variant="body2" gutterBottom>
                          {receipt}
                        </Typography>
                      </div>
                    ))}
                  </div>
                </AccordionDetails>
              </Accordion>
            )
          ))}
        </>
        )}
        {caseData?.approval_msg && (
          <Accordion defaultExpanded sx={{ backgroundColor: 'rgb(245 245 245)' }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
            >
              <span className="text-gray-700 dark:text-gray-100 text-sm leading-5 font-medium">
                Approval Notes:
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <div className="flex flex-col gap-[10px]">
                  <div className="flex gap-[10px] text-gray-500 dark:text-gray-200 text-sm leading-5 font-normal">
                  <DoneIcon color="success" sx={{ width: '18px' }} />
                    <Typography variant="body2" gutterBottom>
                      {caseData?.approval_msg}
                    </Typography>
                  </div>
              </div>
            </AccordionDetails>
          </Accordion>
        )}
        {(!receiptData && !caseData?.approval_msg)  && (
          <Typography style={{ opacity: '70%' }}>
            No receipts to display.
          </Typography>
        )}
      </DialogContent>
      <div className="flex">
        <DialogActions>
          <Button onClick={() => handleClose()}>
            CLOSE
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

import { Button, Chip, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Divider,
  List, ListItemButton, ListItemText
 } from '@mui/material'
import { formatAsDayMonthYear } from '../../../utility/helpers/dateHelpers'

export function Chargebee ({ 
  openDialogCb,
  handleClose,
  subscriptions
}) {
  return (
    <Dialog
      open={openDialogCb}
      onClose={() => handleClose(null)}
    >
      <DialogTitle>
        Open a Subscription
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ paddingBottom: '20px' }}>
          Select a Chargebee Subscription
        </DialogContentText>
        <Divider />
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
          {(subscriptions?.map(sub => (
            <ListItemButton key={sub.id} href={process.env.REACT_APP_CHARGEBEE_URL + sub?.id} target="_blank" rel="noopener">
              {sub?.status === 'active' ? (
                <Chip label="ACTIVE" color="success" />
              ) : (
                <Chip label="INACTIVE" color="error" />
              )}
              <ListItemText primary={sub.name} secondary={'Started ' + formatAsDayMonthYear(sub?.started_at)} sx={{ paddingLeft: '12px'}} />
            </ListItemButton>
          )))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleClose(false)}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  )
}

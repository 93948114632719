import { useNavigate } from 'react-router-dom'
import Link from '@mui/material/Link'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import TuneIcon from '@mui/icons-material/Tune'
import SettingsIcon from '@mui/icons-material/Settings'

const iconSwitcher = (param) => {
  switch (param) {
    case 'preferences':
      return <SettingsIcon sx={{ width: '16px' }} />
    default:
      return <></>
  }
}

export function SettingsBreadcrumb () {
  const navigate = useNavigate()

  const url = window.location.href.endsWith('/') ? window.location.href.slice(0, -1) : window.location.href
  const urlSettings = url.split('settings/')[1] || ''

  return (
    <Breadcrumbs sx={{ paddingBottom: '20px', alignSelf: 'start' }}>
      <Link
        underline="hover"
        sx={{ cursor: 'pointer', alignItems: 'center', display: 'flex', gap: '8px' }}
        color="info"
        onClick={() => navigate('/settings')}
      >
        <TuneIcon sx={{ width: '16px' }} />
        Settings
      </Link>
      {urlSettings && (urlSettings.split('/')).map((param, idx) => (
        <Link
          underline="hover"
          sx={{ cursor: 'pointer', alignItems: 'center', display: 'flex', gap: '8px', textTransform: 'capitalize' }}
          color="info"
          onClick={() => navigate('/settings/' + urlSettings.split('/').slice(0, idx + 1).join('/'))}
          key={idx}
        >
          {iconSwitcher(param)}
          {param}
        </Link>
      ))}
    </Breadcrumbs>
  )
}

import { useState, useEffect, useCallback } from 'react'
import { gridPageCountSelector, gridPaginatedVisibleSortedGridRowEntriesSelector } from '@mui/x-data-grid-pro'
import { queries } from '../../../utility'
import { TextField, FormControl } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material'
import { useParams } from 'react-router-dom'

export function KeywordCreateForm ({
  setLoadingPopup,
  createEmptyRow,
  createQuery,
  setSnackbar,
  upsertDialog,
  setUpsertDialog,
  closeForm,
  formData,
  setFormData,
  setQueryData,
  setReloadTrigger,
  loading,
  geolocations,
  scrollToLastRow,
  apiRef
}) {

  const { customerId, productId } = useParams()
  const [overrideGeo, setOverrideGeo] = useState(false)
  const [suffix, setSuffix] = useState('')
  const [keyword, setKeyword] = useState('')

  const handleCreateQuery = async (params) => {
    try {
      setLoadingPopup(true)
      const combined = keyword.split('\n').map((item, index) => `${item}${suffix?.split('\n')[index] ? ' ' + suffix?.split('\n')[index] : ''}`) // combine keyword + suffix entered
      // Promise.all in case multiple keywords were entered
      await Promise.all(combined.map(async (key) => {
        formData.name = key
        const newRow = createEmptyRow(params)
        // Submit create query to rtk
        const createRes = await createQuery({ body: newRow, subscriptionId: productId }).unwrap()
        if (!createRes) {
          return setSnackbar({ children: 'Error creating Keywords', severity: 'error' })
        }
        setQueryData((prevData) => {
          if (!Array.isArray(prevData)) { return []}
          return [...prevData, newRow]
        })
        setSnackbar({ children: 'Successfully created Keywords', severity: 'success' })
      }))
      scrollToLastRow(
        gridPageCountSelector,
        gridPaginatedVisibleSortedGridRowEntriesSelector,
        apiRef
      )
    } catch (error) {
      console.error(error)
      setSnackbar({ children: 'Error creating Keywords', severity: 'error' })
    } finally {
      setLoadingPopup(false)
      setUpsertDialog(false)
      setReloadTrigger(prev => !prev)
    }
  }

  const loadInitialState = useCallback(async () => {
    const sub = await queries.getSubscriptions(productId)
    if (!sub) { return setSnackbar({ children: 'Error fetching subscriptions', severity: 'error' }) }
    setFormData({ ...formData, subscription_id: sub[0]?.id, geolocation_id: sub[0]?.seo_geolocation_id })
    // eslint-disable-next-line
  }, [productId])

  useEffect(() => {
    loadInitialState()
  }, [loadInitialState])

  return (
    <Dialog
      open={upsertDialog}
      onClose={closeForm}
      PaperProps={{
        component: 'form',
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            handleCreateQuery(formData)
          },
      }}
    >
      <DialogTitle style={{ padding: '10px', paddingBottom: '0px', paddingTop: '25px', alignSelf: 'center' }}>
         New Keywords
      </DialogTitle>
      <DialogContent style={{ display: 'flex', flexFlow: 'column', gap: '20px', padding: '30px', paddingTop: '10px', paddingBottom: '10px' }}>
        <FormGroup>
          <FormControlLabel control={
              <Checkbox
                size="small"
                checked={formData.in_client_reporting}
                onChange={(e) => setFormData({ ...formData, in_client_reporting: e.target.checked, updated_at: new Date() })}
              />
            }
          label="In Client Reporting?"
          componentsProps={{ typography: { variant: 'body2' } }}
        />
        </FormGroup>
        <FormGroup>
          <FormControlLabel control={
              <Checkbox
                size="small"
                checked={overrideGeo}
                onChange={(e) => setOverrideGeo(e.target.checked)}
              />}
            label="Override Website Geo-Location?"
            componentsProps={{ typography: { variant: 'body2' } }}
          />
        </FormGroup>
        <FormControl fullWidth>
          <Autocomplete
            disableClearable
            options={geolocations}
            disabled={!overrideGeo}
            getOptionLabel={(option) => option.canonical_name}
            value={geolocations.find((geo) => geo.id === formData.geolocation_id) || null}
            onChange={(event, value) => setFormData({ ...formData, geolocation_id: value ? value.id : '' })}
            filterOptions={(options, { inputValue }) =>
              options.filter((option) =>
                option.canonical_name.toLowerCase().includes(inputValue.toLowerCase())
              ).slice(0, 10) // Limiting search results to 10 to improve performance
            }
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Geo-Location"
                disabled={!overrideGeo}
                required
              />
            )}
          />
        </FormControl>
        <div className="flex gap-[5px] flex-col sm:flex-row">
          <TextField
            name="keywords"
            label="Keywords (new line for each)"
            multiline
            rows={6}
            value={keyword}
            onChange={(e) => setKeyword(e.target.value)}
            required
            inputProps={{style: {fontSize: 14}}} // input text
            InputLabelProps={{style: {fontSize: 16}}} // input label
            sx={{ width: '500px' }}
            placeholder="Enter keyword(s)"
          />
          <TextField
            name="localSuffixes"
            label="Combine with local suffixes"
            multiline
            rows={6}
            value={suffix}
            onChange={(e) => setSuffix(e.target.value)}
            inputProps={{style: {fontSize: 14}}} // input text
            InputLabelProps={{style: {fontSize: 16}}} // input label
            sx={{ width: '400px' }}
            placeholder="Enter local suffix(es)"
          />
        </div>
      </DialogContent>
      <DialogActions sx={{ display: 'flex', justifyContent: 'space-evenly', padding: '15px' }}>
        <Button style={{ borderRadius: '50px' }} variant="outlined" disabled={loading} onClick={closeForm}>
          Cancel
        </Button>
        <Button style={{ borderRadius: '50px' }} variant="contained" disabled={loading} endIcon={loading ? <CircularProgress size="0.99rem" /> : null} type="submit">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  )
}

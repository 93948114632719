import { Button, Card, CardContent, Tooltip, Typography } from '@mui/material'
import { LoadingCard } from './'

export const HubspotCard = ({
  customerId = null,
  loading = true,
  enabled = false
}) => {

  if (loading) return <LoadingCard />

  return (
    <Tooltip title={!enabled ? 'The associated Hubspot company was not found' : ''}>
      <span>
        <Button disabled={!enabled} href={`https://app.hubspot.com/contacts/${process.env.REACT_APP_HS_ACCOUNT_ID}/record/0-2/${customerId}`} target="_blank" rel="noopener" sx={{ textTransform: 'none', opacity: !enabled ? '50%' : '100%' }}>
          <Card sx={{ maxWidth: '300px', backgroundColor: !enabled ? 'rgb(235, 235, 235)' : '' }}>
            <CardContent>
              <div className="flex gap-[16px] flex-row items-center">
                <img className={`flex w-[20px] max-h-[20px] items-center justify-center self-center ${!enabled ? 'grayscale' : ''}`} src={'/images/Hubspot.svg'} />
                <Typography variant="h6" align="left">
                  Hubspot
                </Typography>
              </div>
              <Typography variant="body1" align="left" color="text.secondary">
                Access the customers' Hubspot account.
              </Typography>
            </CardContent>
          </Card>
        </Button>
      </span>
    </Tooltip>
  )
}

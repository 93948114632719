import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

export const CaseStatusIcon = ({
  status
}) => {
  switch (status) {
    case 'Close':
      return <Tooltip title="Failed to Action"><CloseIcon color="error" /></Tooltip>
    case 'Warning':
      return <Tooltip title="Manual intervention required"><WarningAmberIcon color="warning" /></Tooltip>
    case 'Done':
      return <Tooltip title="Successfully Actioned"><DoneIcon color="success" /></Tooltip>
    default:
      return null
  }
}

import React, { useState, useEffect, useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { Autocomplete, Chip, CircularProgress, TextField, Typography} from '@mui/material'
import { autocompleteClasses } from '@mui/material/Autocomplete'
import SearchIcon from '@mui/icons-material/Search'
import { queries } from '../../utility'

const getQuery = async (body) => {
  const data = await queries.getCustomers(body)
  return data || []
}

export function CustomerSearchBar({
  inactiveBox,
  brandlessBox
}) {
  const [open, setOpen] = useState<boolean>(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [customers, setCustomers] = useState([])
  const [loading, setLoading] = useState<boolean>(false)
  const [errMsg, setErrMsg] = useState({})
  const [openError, setOpenError] = useState<boolean>(false)
  const [searchError, setSearchError] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('Search customers...')

  const navigate = useNavigate()

  const fetchData = useCallback(async () => {
    try {
      setLoading(true)
      const body = await getQuery({ searchTerm })
      if (!isEmpty(body)) {
        setCustomers(body)
      } else {
        setErrMsg(body.error)
        setOpenError(true)
      }
    } catch (error) {
      console.error('Error fetching customers:', error.message)
    } finally {
      setLoading(false)
    }
  }, [setLoading, setCustomers, searchTerm])

  useEffect(() => {
    if (searchTerm?.trim()?.length > 2) {
      const delayDebounceFn = setTimeout(() => {
        fetchData()
      }, 500)
      return () => clearTimeout(delayDebounceFn)
    }
  }, [searchTerm, fetchData])

  // disable loading if popup is closed
  useEffect(() => {
    if (!open) {
      setLoading(false)
    }
  }, [open])

  const closePopper = () => setOpen(false)
  const openPopper = () => {
    if (searchTerm?.length > 1) { // only open the popper if the search meets min length
      setOpen(true)
    }
  }

  const handleInputChange = (event, value) => {
    setSearchTerm(value)
    setOpen(true)
  }

  const handleClick = (customer) => {
    setSearchTerm('') // clear the search term
    closePopper() // close the popper
    window.dispatchEvent(new Event('storage'))
    window.location.href = `/customers/show/${customer.value}`
  }

  useEffect(() => {
    if (searchTerm?.length < 3 && searchTerm?.length !== 0) {
      setSearchError(true)
      setSearchText('Search query must be longer than 2 characters.')
    } else {
      setSearchError(false)
      setSearchText('Search customers...')
    }
  }, [searchTerm])

  return (
    <Autocomplete
      disablePortal
      popupIcon={<SearchIcon />}
      sx={{
        maxWidth: '500px',
        backgroundColor: theme => theme.palette.mode === 'dark' ? 'rgb(0,0,0,1)' : 'rgba(255,255,255,1)',
        boxShadow: '0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)',
        borderRadius: '4px',
        '& .Mui-focused': {
          borderRadius: '4px'
        },
        [`& .${autocompleteClasses.popupIndicator}`]: {
          transform: 'none'
        }
      }}
      open={open}
      onOpen={openPopper}
      onClose={closePopper}
      options={customers
        .filter(customer => (inactiveBox || customer.active)) // Filter out inactive customers if inactiveBox is true
        .filter(customer => (brandlessBox || customer.brand)) // Filter out brandless customers if brandlessBox is true
        .map(customer => ({
          name: customer.name,
          brand: customer.brand,
          crm: customer.crm,
          value: customer.id,
          active: customer.active,
          key: customer.id,
          contactId: customer.contactId
        }))
      }
      getOptionLabel={(option) => option.name || ''}
      fullWidth
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            error={searchError}
            label={searchText}
            variant="filled"
            color="secondary"
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        </>
      )}
      onInputChange={handleInputChange}
      renderOption={(props, option) =>
        <li
          {...props}
          key={option.key}
          onClick={() => handleClick(option)}
        >
          <Typography
            component="div"
            variant="body1"
            sx={{
              width: '100%',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              fontSize: '14px',
            }}
          >
            <div className="flex flex-row justify-between w-full">
              <div className="flex flex-row gap-[8px] items-center">
                {(option.brand) && (
                  <img className="flex w-[16px] max-h-[16px] items-center justify-center self-center" src={`/images/logo_natural_${option.brand.toUpperCase()}.svg`}/>
                )}
                {(option.name.includes('DO NOT USE')) ? (
                  <span className="line-through max-w-[400px] overflow-hidden text-ellipsis whitespace-nowrap">{option.name}</span>
                ) : (
                  <span className="max-w-[400px] overflow-hidden text-ellipsis whitespace-nowrap">{option.name}</span>
                )}
              </div>
              <div>
                {option?.active ? (
                  <Chip label="ACTIVE" color="success" variant="outlined" size="small" />
                ) : (
                  <Chip label="INACTIVE" color="error" variant="outlined" size="small" />
                )}
              </div>
            </div>
          </Typography>
        </li>
      }
      loading={loading}
      loadingText="Loading..."
      noOptionsText="No customers found"
    />
  )
}

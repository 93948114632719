import { useForm } from '@refinedev/react-hook-form'
import * as React from 'react'
import { useContext } from 'react'
import { FormProvider } from 'react-hook-form'
import {
  LoginPageProps,
  LoginFormTypes,
  useActiveAuthProvider,
  BaseRecord,
  HttpError,
  useLogin,
  useTranslate,
  useRouterContext,
  useRouterType,
  useLink,
} from '@refinedev/core'
import { ColorModeContext } from '../../contexts/color-mode'
import { FormPropsType } from '../../pages/AuthPage'
import { layoutStyles, titleStyles } from './styles'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import Container from '@mui/material/Container'
import TextField from '@mui/material/TextField'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import type { BoxProps } from '@mui/material/Box'
import type { CardContentProps } from '@mui/material/CardContent'
import { useDocumentTitle } from '@refinedev/react-router-v6'

type LoginProps = LoginPageProps<BoxProps, CardContentProps, FormPropsType>
interface Provider {
  name: string
  label: string
  icon: React.ReactNode
}

export const LoginPage: React.FC<LoginProps> = ({
  providers,
  registerLink,
  forgotPasswordLink,
  rememberMe,
  contentProps,
  wrapperProps,
  renderContent,
  formProps,
  title,
}) => {
  const { onSubmit, ...useFormProps } = formProps || {}
  const methods = useForm<BaseRecord, HttpError, LoginFormTypes>({
    ...useFormProps,
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = methods

  const { mode, setMode } = useContext(ColorModeContext)
  const authProvider = useActiveAuthProvider()
  const { mutate: login, isLoading } = useLogin<LoginFormTypes>({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  })
  const translate = useTranslate()
  const routerType = useRouterType()
  const Link = useLink()
  const { Link: LegacyLink } = useRouterContext()
  useDocumentTitle('Sign in | Admin Panel')

  const ActiveLink = routerType === 'legacy' ? LegacyLink : Link

  const PageTitle = title === false ? null : (
    <img className="static sm:absolute left-[15px] top-[15px] w-[144px] mx-[32px] my-[24px]" src={`/images/logo_${mode === 'dark' ? 'light' : 'dark'}.svg`} />
  )
  const Content = (
    <div className="w-[100%] sm:w-[360px]">
      <CardContent sx={{ display: 'flex', flexFlow: 'column' }}>
        <div className="flex flex-col gap-[12px]">
          <Typography component="h1" variant="h5" align="center" style={{ fontSize: '32px', opacity: '87%' }}>
            Login
          </Typography>
          <Typography variant="body1" color="info" align="center" style={{ fontSize: '16px', opacity: '60%' }}>
            Welcome back to the admin dashboard
          </Typography>
        </div>
      </CardContent>
      {
        <CardContent sx={{ display: 'flex', flexFlow: 'column' }}>
          <Box
            component="form"
            onSubmit={handleSubmit((data) => {
              if (onSubmit) {
                return onSubmit(data)
              }

              return login(data)
            })}>
            <Stack spacing={2}>
              <Button
                key="google"
                fullWidth
                variant="outlined"
                sx={{
                  backgroundColor: mode === 'dark' ? 'rgba(18, 18, 18, 1)' : 'white',
                  color: mode === 'dark' ? 'white' : 'rgba(0, 0, 0, 0.87)',
                  '&:hover': { 
                    backgroundColor: mode === 'dark' ? '#30303b' : '#f0f0f0',
                    borderColor: mode === 'dark' ? 'rgba(0, 0, 0, 0.4)' : 'rgba(0, 0, 0, 0.4)'
                  },
                  textTransform: 'none',
                  fontWeight: '500',
                  lineHeight: '26px',
                  fontSize: '15px',
                  borderColor: 'rgba(0, 0, 0, 0.23)',
                  borderOpacity: '23%',
                  letterSpacing: '0.46px',
                  paddingTop: '10px',
                  paddingBottom: '10px'
                }}
                onClick={() => login({ providerName: 'google' })}
                startIcon={<img className="w-6 h-6" src="https://www.svgrepo.com/show/475656/google-color.svg" loading="lazy" alt="google logo" />}
              >
                SIGN IN WITH GOOGLE
              </Button>
            </Stack>
          </Box>
        </CardContent>
      }
    </div>

  )
  const renderProviders = () => {
    if (providers && providers.length > 0) {
      return (
        <>
          <Stack spacing={1}>
            {providers.map((provider: Provider) => (
              provider.name === 'google' ? (
                Content) :
                <Button
                  key={provider.name}
                  variant="outlined"
                  fullWidth
                  sx={{
                    color: 'primary.light',
                    borderColor: 'primary.light',
                    textTransform: 'none',
                  }}
                  onClick={() => login({ providerName: provider.name })}
                  startIcon={provider.icon}
                >
                  {provider.label}
                </Button>
            ))}
          </Stack>
        </>
      )
    }
    if (!providers || providers.length === 0) {
      return null
    }
  }


  return (
    <FormProvider {...methods}>
      <Box style={layoutStyles} {...(wrapperProps ?? {})}>
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            {renderContent ? (
              renderContent(Content, PageTitle)
            ) : (
              <>
                {PageTitle}
                {Content}
              </>
            )}
          </Box>
        </Container>
      </Box>
    </FormProvider>
  )
}

/**
 * Capitalize the first letter, or first letter of each word
 *
 * @param {string} text The text to capitalize
 * @param {boolean} onlyFirstLetter Whether to enable capitalize all words, or just the first letter
 * @returns {string}
 */
export const capitalize = (
  text: string,
  onlyFirstLetter: boolean = false
): string => {
  if (!text) return ''

  if (onlyFirstLetter) {
    const capitalized = text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()
    return capitalized
  }

  const capitalized = text
    .replaceAll('_', ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
  return capitalized
}

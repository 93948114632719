/**
 * Format date in DD/MM/YYYY format
 * 
 * @param {string | Date} date Date in string or Date format
 * @returns {string}
 */
export const formatAsDayMonthYear = (dateString: string | Date): string => {
  if (!dateString) return null
  const date = new Date(dateString)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  const formattedDay = day < 10 ? `0${day}` : `${day}`
  const formattedMonth = month < 10 ? `0${month}` : `${month}`

  return `${formattedDay}/${formattedMonth}/${year}`
}

import { Alert, AlertTitle, Button, CircularProgress, Dialog, DialogTitle,
        DialogContent, DialogContentText, DialogActions, FormControl, IconButton } from '@mui/material'
import dayjs from 'dayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import DownloadIcon from '@mui/icons-material/Download'
import { convertToCSV } from '../../../utility/helpers/miscHelpers'
import { formatAsDayMonthYear } from '../../../utility/helpers/dateHelpers'

export function ExportBacklinks ({
  data,
  dateTo,
  dateFrom,
  setDateTo,
  setDateFrom,
  openDialog,
  handleClose,
  loading,
  completed = false,
  error
}) {
  const downloadCSV = () => {
    const csvData = new Blob([convertToCSV(data)], { type: 'text/csv' })
    const csvURL = URL.createObjectURL(csvData)
    const link = document.createElement('a')
    link.href = csvURL
    link.download = `backlinks_${formatAsDayMonthYear(dateFrom.replaceAll(' ', '-'))}_to_${formatAsDayMonthYear(dateTo).replaceAll(' ', '-')}.csv`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  
  const handleDownloadCSV = () => {
    downloadCSV()
  }

  return (
    <Dialog
      open={openDialog}
      onClose={() => handleClose(null)}
    >
      <DialogTitle>
        Export Backlinks
      </DialogTitle>
      <DialogContent>
        {(!completed) ? (
          <>
            <DialogContentText sx={{ paddingBottom: '20px' }}>
              Please specify the date range to export backlinking data for.
            </DialogContentText>
            <FormControl fullWidth required>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="Date From"
                    format="DD/MM/YYYY"
                    slotProps={{ textField: { fullWidth: true, helperText: 'Defaults to 1 month prior' } }}
                    value={dayjs(dateFrom)}
                    onChange={(date) => setDateFrom(dayjs(date).format('YYYY-MM-DD'))}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </FormControl>
            <FormControl fullWidth required>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['DatePicker']}>
                  <DatePicker
                    label="Date To"
                    format="DD/MM/YYYY"
                    slotProps={{ textField: { fullWidth: true, helperText: 'Defaults to todays date' } }}
                    value={dayjs(dateTo)}
                    onChange={(date) => setDateTo(dayjs(date).format('YYYY-MM-DD'))}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </FormControl>
          </>
        ) : (
          <DialogContentText>
            {error ? (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                Error exporting backlinks.
              </Alert>
            ) : (
              <Alert severity="success">
                <AlertTitle>Success</AlertTitle>
                Your backlink data request was successful.<br />
                Date: <b>{formatAsDayMonthYear(dateFrom)}</b> to <b>{formatAsDayMonthYear(dateTo)}</b><br />
                Download CSV: 
                <IconButton onClick={() => handleDownloadCSV()} color="info">
                  <DownloadIcon />
                </IconButton>
              </Alert>
            )}
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={() => handleClose(false)}>
          {!completed ? 'Cancel' : 'Close'}
        </Button>
        {!completed && (
          <Button disabled={loading} endIcon={loading ? <CircularProgress size="0.99rem" /> : null} onClick={() => handleClose(true)} autoFocus>
            Export
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

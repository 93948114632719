import React, { useContext } from 'react'
import { useRouterContext, useLink, useRouterType } from '@refinedev/core'
import { ColorModeContext } from '../../contexts/color-mode'
import MuiLink from '@mui/material/Link'
import type { RefineLayoutThemedTitleProps } from '@refinedev/mui'

export const ThemedTitleV2: React.FC<RefineLayoutThemedTitleProps> = ({ collapsed, wrapperStyles }) => {
  const routerType = useRouterType()
  const Link = useLink()
  const { Link: LegacyLink } = useRouterContext()
  const { mode, setMode } = useContext(ColorModeContext)

  const ActiveLink = routerType === 'legacy' ? LegacyLink : Link

  return (
    <MuiLink
      to='/'
      component={ActiveLink}
      underline='none'
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: '12px',
        ...wrapperStyles,
      }}>
      {!collapsed ? (
        <img className="w-[100px] pb-[16px] pl-[16px] pt-[25px] self-center" src={`/images/logo_${mode === 'dark' ? 'light' : 'dark'}.svg`} />
      ) : (
        <img className="w-[28px] self-center" src="/images/logo_natural_DD.svg" />
      )}
    </MuiLink>
  )
}

import { Button, Card, CardContent, Typography } from '@mui/material'
import InsertLinkIcon from '@mui/icons-material/InsertLink'

export const BacklinksCard = ({
  setOpenDialogBacklink
}) => {
  return (
    <Button onClick={() => setOpenDialogBacklink(true)} sx={{ textTransform: 'none' }}>
      <Card sx={{ maxWidth: '300px' }}>
        <CardContent>
          <div className="flex gap-[16px] flex-row items-center">
            <InsertLinkIcon color="warning" />
            <Typography variant="h6" align="left">
              Export Backlinks
            </Typography>
          </div>
          <Typography variant="body1" align="left" color="text.secondary">
            Export backlinks (authorized employees only)
          </Typography>
        </CardContent>
      </Card>
    </Button>
  )
}

/**
 * Extend brand name to full name
 *
 * @param {string} short The brand name, shortened (i.e. SLX, GMT, etc.)
 * @returns {string}
 */
export const extendBrandAbbr = (short: string): string => {
  switch (short?.toUpperCase()) {
    case 'SLX':
      return 'SponsoredLinX'
    case 'GMT':
      return 'GetMoreTraffic'
    case 'SME':
      return 'Search Marketing Experts'
    case 'JDM':
      return 'Just Digital Marketing'
  }
}

import { createSlice } from '@reduxjs/toolkit'

const isDarkMode = () => {
  if (localStorage.getItem('darkMode')) {
    return JSON.parse(localStorage.getItem('darkMode'))
  }

  return window.matchMedia('(prefers-color-scheme: dark)').matches
}

const initialState = {
  darkMode: isDarkMode(),
  openReportDialog: false,
  company: {
    details: null,
    contactTokens: null,
    contactDetails: null,
    cases: null,
    clientLogins: null,
    formResults: null,
    keywords: null,
    backlinks: null,
    subscriptions: null,
    subscriptionInfo: null,
    invoices: null,
    creditCard: null
  },
  user: {
    email: null,
    managers: null,
    permissions: null,
    totalAllowed: null,
    preferences: null,
    outstandingCases: null,
    submittedCases: null,
    teamCases: null,
    partialCases: null,
    rejectedCases: null,
  },
  geolocations: null,
  closedCases: null,
  allCases: null,
  allBacklinks: null,
  allUsers: null,
  formSchema: null,
  formTypes: null,
}

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    toggleDarkMode: (state) => {
      const darkMode = !state.darkMode

      localStorage.setItem('darkMode', String(darkMode))

      if (document) {
        document.documentElement.classList.toggle('dark')
      }

      state.darkMode = darkMode
    },
    toggleOpenReportDialog: (state) => {
      const openReportDialog = !state.openReportDialog
      state.openReportDialog = openReportDialog
    },
    //*----- Company Specific
    setCompany: (state, action) => { state.company.details = action.payload },
    setBrandDetails: (state, action) => { state.company.details.brandDetails = action.payload },
    setContactTokens: (state, action) => { state.company.contactTokens = action.payload },
    setContactDetails: (state, action) => { state.company.contactDetails = action.payload },
    setCases: (state, action) => { state.company.cases = action.payload },
    setClientLogins: (state, action) => { state.company.clientLogins = action.payload },
    setFormResults: (state, action) => { state.company.formResults = action.payload },
    setKeywords: (state, action) => { state.company.keywords = action.payload },
    setBacklinks: (state, action) => { state.company.backlinks = action.payload },
    setSubs: (state, action) => { state.company.subscriptions = action.payload },
    setSubInfo: (state, action) => { state.company.subscriptionInfo = action.payload },
    updateSubInfo: (state, action) => {
      const subToUpdate = state.company.subscriptionInfo.find(sub => sub.cb_subscription_id === action.payload.id)
      if (subToUpdate) {
        Object.assign(subToUpdate, action.payload)
      }
    },
    setInvoices: (state, action) => { state.company.invoices = action.payload },
    setCreditCard: (state, action) => { state.company.creditCard = action.payload },

    //*----- User Specific
    setEmail: (state, action) => { state.user.email = action.payload },
    setManagers: (state, action) => { state.user.managers = action.payload },
    setPreferences: (state, action) => { state.user.preferences = action.payload },
    setPermissions: (state, action) => { state.user.permissions = action.payload },
    setTotalAllowed: (state, action) => { state.user.totalAllowed = action.payload },
    setOutstandingCases: (state, action) => { state.user.outstandingCases = action.payload },
    deleteOutstandingCase: (state, action) => {
      state.user.outstandingCases = state.user.outstandingCases.filter(item => item.id !== action.payload.id)
    },
    setSubmittedCases: (state, action) => { state.user.submittedCases = action.payload },
    setTeamCases: (state, action) => { state.user.teamCases = action.payload },
    setPartialCases: (state, action) => { state.user.partialCases = action.payload },
    setRejectedCases: (state, action) => { state.user.rejectedCases = action.payload },

    //*----- Global
    setGeolocations: (state, action) => { state.geolocations = action.payload },
    setClosedCases: (state, action) => { state.closedCases = action.payload },
    updateClosedCases: (state, action) => {
      const caseToUpdate = state.closedCases.find(caseItem => caseItem.case_number === action.payload.caseNo)
      if (caseToUpdate) {
        caseToUpdate[action.payload.property] = action.payload.value
      }
    },
    setAllCases: (state, action) => { state.allCases = action.payload },
    setAllBacklinks: (state, action) => { state.allBacklinks = action.payload },
    setAllUsers: (state, action) => { state.allUsers = action.payload },
    setFormSchema: (state, action) => { state.formSchema = action.payload },
    setFormTypes: (state, action) => { state.formTypes = action.payload },
  }
})

export const {
  toggleDarkMode,
  toggleOpenReportDialog,
  // Company
  setCompany,
  setBrandDetails,
  setContactTokens,
  setContactDetails,
  setCases,
  setClientLogins,
  setFormResults,
  setKeywords,
  setBacklinks,
  setSubs,
  setSubInfo,
  updateSubInfo,
  setInvoices,
  setCreditCard,
  // User
  setEmail,
  setManagers,
  setPreferences,
  setPermissions,
  setTotalAllowed,
  setOutstandingCases,
  deleteOutstandingCase,
  setSubmittedCases,
  setTeamCases,
  setPartialCases,
  setRejectedCases,
  // Global
  setGeolocations,
  setClosedCases,
  updateClosedCases,
  setAllCases,
  setAllBacklinks,
  setAllUsers,
  setFormSchema,
  setFormTypes
} = slice.actions

export const { reducer: global } = slice

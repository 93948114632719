import { useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { DataGridPro } from '@mui/x-data-grid-pro'
import { Button, Grid, IconButton, Tooltip, Typography  } from '@mui/material'
import { useContext } from 'react'
import { ColorModeContext } from '../../contexts/color-mode'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import AddIcon from '@mui/icons-material/Add'
import { canCreate, canEdit, canDelete } from '../../utility'

export function DataTable ({
  title = 'Data',
  columnFields,
  queryData,
  openCreateForm,
  sortModel,
  setSortModel,
  openUpdateForm,
  handleDialogOpen,
  CustomToolbar,
  subscriptionsCb,
  geolocations,
  permissionProp,
  handleReceiptOpen,
  handleDataDialogOpen,
  pinLeft,
  pinRight,
  autoRowHeight = false,
  goBackLevel,
  userEmail,
  managers = [],
  permissions,
  apiRef = null
 }) {
  const [showPasswordMap, setShowPasswordMap] = useState({})

  const { customerId } = useParams()

  const { mode, setMode } = useContext(ColorModeContext)

  const allowCreate = title === 'Account Product' ? false : canCreate(permissions, permissionProp)
  const allowEdit = canEdit(permissions, permissionProp)
  const allowDelete = canDelete(permissions, permissionProp)

  return (
    <Grid item xs={12}>
      <div className="flex justify-between items-center pb-[12px]">
        <div className="flex items-center gap-[12px]">
          {(goBackLevel > 0) && (
            <Tooltip title="Go back">
              <Link to={window.location.href.split('/').slice(0, -1 * goBackLevel).join('/')} rel="noopener noreferrer">
                <IconButton color="secondary" >
                  <ArrowBackIosNewIcon />
                </IconButton>
              </ Link>
            </Tooltip>
          )}
          <Typography variant="h5">
            {title}s
          </Typography>
        </div>
        {allowCreate && (
          <Button variant="contained" style={{ display: 'flex', gap: '8px' }} color="primary" onClick={() => openCreateForm(apiRef)}>
            <AddIcon /> New
          </Button>
        )}
      </div>
      <div style={{ height: '70vh' }}>
        <DataGridPro
          apiRef={apiRef}
          getRowHeight={autoRowHeight ? () => 'auto' : null}
          sx={{
            '.MuiDataGrid-columnHeaderTitle': {
              fontWeight: '500'
            },
            border: 'none'
          }}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          rows={queryData}
          columns={columnFields(openUpdateForm, handleDialogOpen, allowEdit, allowDelete, customerId, subscriptionsCb, geolocations, mode, showPasswordMap, setShowPasswordMap, handleReceiptOpen, handleDataDialogOpen, userEmail, managers)}
          slots={{
            toolbar: CustomToolbar,
          }}
          pagination
          pageSizeOptions={[25, 50, 100]}
          initialState={{ pinnedColumns: { left: pinLeft, right: pinRight } }}
        />
      </div>
    </Grid>
  )
}

/**
 * Format a number as a currency to 2 decimal places
 *
 * @param {number} value The currency as a number which we would like to format
 * @param {boolean} cents Whether the currency is in cents or not
* @returns {string}
 */
export const formatAsCurrency = (
  value: number,
  cents: boolean = true
): string => {
  const fixed = (value / (cents ? 100 : 1)).toFixed(2)
  const currencyRounded = parseFloat(fixed).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  return currencyRounded
}

import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../interface/RootState'
import { useOutletContext, useParams } from 'react-router-dom'
import { queries } from '../utility'
import { Container, Fade, Slide, Typography } from '@mui/material'
import { OTP, Chargebee, Contacts } from '../components/About/Dialogs'
import { ContactButton } from '../components/About'
import { ChargebeeCard, HubspotCard, ClientpanelCard, BillingCard, BookingCard, OTPCard } from '../components/About/Cards'
import { CompanyQueries } from '../interface/queries/CompanyQueries'

export function AboutPage () {
  const { customerId } = useParams()
  const {
    companyData,
    companyLoading,
    tokenData
  } = useOutletContext<CompanyQueries>()

  const [otp, setOtp] = useState([])
  const [openDialogContacts, setOpenDialogContacts] = useState<boolean>(false)
  const [openDialogOTP, setOpenDialogOTP] = useState<boolean>(false)
  const [openDialogCb, setOpenDialogCb] = useState<boolean>(false)
  const [loadingOTP, setLoadingOTP] = useState<boolean>(false)
  const [fetchedOTP, setFetchedOTP] = useState<boolean>(false)
  const [otpError, setOtpError] = useState<boolean>(false)

  //* Extract data from Redux
  const userEmail = useSelector((store: RootState) => store.global.user.email)
  const subscriptions = useSelector((store: RootState) => store.global.company.subscriptions)
  const permissions = useSelector((store: RootState) => store.global.user.permissions)

  const tokenExists = companyData?.active && tokenData?.length > 0

  //* Queries
  const createOTP = async (contactId) => { //! TODO CONVERT TO RTK QUERY
    const data = await queries.createOTP(contactId, companyData?.brand, userEmail)
    return data || false
  }

  //* Handlers
  const handleCloseOTP = async (generate, contactId) => {
    if (generate) {
      try {
        setLoadingOTP(true)
        const otpRes = await createOTP(contactId)
        if (!otpRes || !otpRes?.response) {
          setOtpError(true)
          throw new Error()
        }
        setOtp(otpRes?.response)
      } catch (error) {
        console.error('Could not generate OTP')
      } finally {
        setFetchedOTP(true)
        setLoadingOTP(false)
      }
    } else {
      setOpenDialogOTP(false)
    }
  }

  return (
    <>
      <Slide in direction="left">
        <Fade in>
          <Container maxWidth="lg">
            <div className="flex justify-between">
              <Typography variant="h5" sx={{ paddingTop: '24px', paddingBottom: '12px' }}>
                Linked Services
              </Typography>
              <ContactButton setOpenDialog={setOpenDialogContacts} />
            </div>
            <div className="flex flex-wrap gap-[10px] justify-center md:justify-normal items-center">
              <HubspotCard
                customerId={customerId}
                loading={false}
                enabled={Boolean(customerId)}
              />
              <ClientpanelCard
                brandAbbr={tokenData[0]?.brand_id ? tokenData[0]?.brand_id.abbreviation : ''}
                authKey={encodeURIComponent(tokenData[0]?.token)}
                customerId={customerId}
                loading={companyLoading}
                enabled={tokenExists}
              />
              <BillingCard
                brandAbbr={tokenData[0]?.brand_id ? tokenData[0]?.brand_id.abbreviation : ''}
                authKey={encodeURIComponent(tokenData[0]?.token)}
                customerId={customerId}
                loading={companyLoading}
                enabled={tokenExists}
              />
              <BookingCard
                brandAbbr={tokenData[0]?.brand_id ? tokenData[0]?.brand_id.abbreviation : ''}
                customerId={customerId}
                loading={companyLoading}
                enabled={tokenExists}
              />
              <OTPCard
                setOpenDialogOTP={setOpenDialogOTP}
                enabled={companyData?.contactId.length > 0}
                loading={!companyData}
              />
              <ChargebeeCard
                setOpenDialogCb={setOpenDialogCb}
                loading={!subscriptions}
                enabled={permissions?.chargebee}
              />
              {(!companyData?.active) && (
                <Typography style={{ opacity: '60%' }}>
                  Additional services are not available for inactive customers.
                </Typography>
              )}
            </div>
          </Container>
        </Fade>
      </Slide>
      <OTP
        otp={otp}
        contactIds={companyData?.contactId}
        openDialog={openDialogOTP}
        handleClose={handleCloseOTP}
        loading={loadingOTP}
        completed={fetchedOTP}
        error={otpError}
      />
      <Contacts
        contacts={companyData?.contactId}
        openDialog={openDialogContacts}
        setOpenDialog={setOpenDialogContacts}
      />
      {(subscriptions) && (
        <Chargebee
          openDialogCb={openDialogCb}
          handleClose={() => setOpenDialogCb(false)}
          subscriptions={subscriptions}
        />
      )}
    </>
  )
}

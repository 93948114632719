import { api } from './api'
import { queries } from '../utility'
import { setClientLogins } from '../store/global'
import { store } from '../store'

export const clientLoginsApi = api.injectEndpoints({
  reducerPath: 'clientLoginsApi',
  endpoints: (build) => ({
    getClientLogins: build.query({
      queryFn: async (arg) => {
        const customerId = arg
        const response = await queries.getLogin(customerId)
        store.dispatch(setClientLogins(response))  // Update the redux store
        return { data: response }
      },
    }),
    createClientLogin: build.mutation({
      queryFn: async (arg) => {
        const { body } = arg
        const response = await queries.createLogin(body)  // Update Supabase table
        if (response) {
          store.dispatch( // Update the redux store
            clientLoginsApi.util.updateQueryData('getClientLogins', body.company_id, (draft) => {
              draft.push(response[0])
            })
          )
        }
        return { data: response }
      },
    }),
    updateClientLogin: build.mutation({
      queryFn: async (arg) => {
        const { itemId, body } = arg
        const response = await queries.updateLogin(itemId, body) // Update Supabase table
        store.dispatch( // Update the redux store
          clientLoginsApi.util.updateQueryData('getClientLogins', body.company_id, (draft) => {
            const index = draft.findIndex(d => d.id === itemId)
            if (index !== -1) {
              draft[index] = { ...draft[index], ...body }
            }
          })
        )
        return { data: response }
      },
    }),
    deleteClientLogin: build.mutation({
      queryFn: async (arg) => {
        const { itemId, customerId } = arg
        const response = await queries.deleteLogin(itemId) // Update Supabase table
        store.dispatch( // Update redux store
          clientLoginsApi.util.updateQueryData('getClientLogins', customerId, (draft) => {
            const updated = draft.filter(d => d.id !== itemId)
            return updated
          })
        )
        return { data: response }
      },
    })
  })
})

export const {
  useGetClientLoginsQuery,
  useCreateClientLoginMutation,
  useUpdateClientLoginMutation,
  useDeleteClientLoginMutation
} = clientLoginsApi

import { useOutletContext } from 'react-router-dom'
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport } from '@mui/x-data-grid'
import { Checkbox, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { TableTemplateNew } from '../components/Table'
import { genRandomUUID } from '../utility/helpers/miscHelpers'
import { formatAsDayMonthYear } from '../utility/helpers/dateHelpers'
import { CompanyQueries } from '../interface/queries/CompanyQueries'
import { useCreateBacklinkMutation, useUpdateBacklinkMutation, useDeleteBacklinkMutation } from '../services/backlinksApi'

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

const dataTitle = 'Backlink'
const initialForm = {
  id: 0,
  seo_keyword_id: 0,
  customer_id: '',
  google_url: '',
  branded: false,
  backlink_type: '',
  niche: '',
  last_backlinked: new Date()
}
const columnFields = (openUpdateForm, handleDialogOpen, allowEdit, allowDelete) => {
  return [
    { field: 'keyword', headerName: 'Keyword', width: 125, editable: false,
      valueGetter: (value) => {
        return value?.row?.seo_keyword_id?.name || ''
      },
      valueFormatter: (value) => {
        return value?.value || ''
      },
      renderCell: (params) => (
        <div className="overflow-hidden truncate">
          {params.row.seo_keyword_id?.name}
        </div>
      )
    },
    { field: 'google_url', headerName: 'Google URL', editable: false },
    { field: 'branded', headerName: 'Branded', width: 75, editable: false, align: 'center',
      renderCell: (params) => (
        <Checkbox disabled checked={params.row.branded} />
      )
    },
    { field: 'backlink_type', headerName: 'Backlink Type', width: 115, editable: false,
      renderCell: (params) => (
        <div className="capitalize overflow-hidden truncate">
          {params.row.backlink_type}
        </div>
      )
    },
    { field: 'niche', headerName: 'Niche', width: 75, editable: false,
      renderCell: (params) => (
        <div className="capitalize  overflow-hidden truncate">
          {params.row.niche}
        </div>
      )
    },
    { field: 'last_backlinked', headerName: 'Last Backlinked', width: 145, editable: false,
      valueGetter: (value) => {
        if (!value) return value
        return formatAsDayMonthYear(value.row.last_backlinked)
      },
      valueFormatter: (value) => {
        if (value == null) return ''
        return value.value
      },
      renderCell: (params) => params.row.last_backlinked ? formatAsDayMonthYear(params.row.last_backlinked) : ''
    },
    { field: 'edit', headerName: 'Edit', disableExport: true, width: 75,
      renderCell: (params) => (
        <IconButton color="info" onClick={() => openUpdateForm(params)} disabled={!allowEdit} >
          <EditIcon />
        </IconButton>
      ),
    },
    { field: 'delete', headerName: 'Delete', disableExport: true, width: 75,
      renderCell: (params) => (
        <div className={!Number(params.row.id) ? 'opacity-[50%] pointer-events-none' : ''}>
          <IconButton color="error" onClick={() => handleDialogOpen(params, 'Delete')} disabled={!allowDelete} >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    }
  ]
}

const upsertFields = (create, formData, setFormData, customerId, geolocations) => {
  return (
    <>
      <TextField
        label="Google URL"
        fullWidth
        value={formData.google_url}
        onChange={(e) => setFormData({ ...formData, google_url: e.target.value })}
        required
      />
      <FormGroup>
        <FormControlLabel control={
            <Checkbox
              checked={formData.branded}
              onChange={(e) => setFormData({ ...formData, branded: e.target.checked })}
            />
          }
          label="Branded"
        />
      </FormGroup>
      <FormControl fullWidth>
        <InputLabel>Backlink Type</InputLabel>
        <Select
          fullWidth
          value={formData.backlink_type || ''}
          label="Backlink Type"
          onChange={(e) => setFormData({ ...formData, backlink_type: e.target.value })}
          required
        >
          <MenuItem value="none">--None--</MenuItem>
          <MenuItem value="standard">Standard (5)</MenuItem>
          <MenuItem value="premium">Premium (10)</MenuItem>
          <MenuItem value="platinum">Platinum (15)</MenuItem>
          <MenuItem value="local">Local Citation (5)</MenuItem>
        </Select>
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>Niche</InputLabel>
        <Select
          fullWidth
          value={formData.niche || ''}
          label="Niche"
          onChange={(e) => setFormData({ ...formData, niche: e.target.value })}
          required
        >
          <MenuItem value="none">--None--</MenuItem>
          <MenuItem value="general">General</MenuItem>
          <MenuItem value="automotive">Automotive</MenuItem>
          <MenuItem value="business">Business & Finance</MenuItem>
          <MenuItem value="cooking">Cooking</MenuItem>
          <MenuItem value="fashion">Fashion & Beauty</MenuItem>
          <MenuItem value="games">Games</MenuItem>
          <MenuItem value="health">Health & Fitness</MenuItem>
          <MenuItem value="home">Home Improvement</MenuItem>
          <MenuItem value="lifestyle">Lifestyle</MenuItem>
          <MenuItem value="music">Music & Entertainment</MenuItem>
          <MenuItem value="parenting">Parenting</MenuItem>
          <MenuItem value="pets">Pets</MenuItem>
          <MenuItem value="realestate">Real Estate</MenuItem>
          <MenuItem value="seo">SEO</MenuItem>
          <MenuItem value="sports">Sports</MenuItem>
          <MenuItem value="tech">Tech</MenuItem>
          <MenuItem value="travel">Travel</MenuItem>
          <MenuItem value="webdesign">Web Design</MenuItem>
          <MenuItem value="other">Other</MenuItem>
        </Select>
      </FormControl>
    </>
  )
}

const createEmptyRow = (params, customerId, keywordId) => {
  return ({
    id: genRandomUUID(),
    seo_keyword_id: parseInt(keywordId),
    customer_id: customerId,
    google_url: params.google_url,
    branded: params.branded,
    backlink_type: params.backlink_type,
    niche: params.niche,
    last_backlinked: (new Date).toISOString()
  })
}

export function BacklinkingPage () {
  const { backlinksKeywordData, backlinksKeywordLoading } = useOutletContext<CompanyQueries>()
  const [createBacklink] = useCreateBacklinkMutation()
  const [updateBacklink] = useUpdateBacklinkMutation()
  const [deleteBacklink] = useDeleteBacklinkMutation()

  return (
    <TableTemplateNew
      CustomToolbar={CustomToolbar}
      dataTitle={dataTitle}
      columnFields={columnFields}
      initialForm={initialForm}
      upsertFields={upsertFields}
      createEmptyRow={createEmptyRow}
      tableData={backlinksKeywordData}
      loading={backlinksKeywordLoading}
      createQuery={createBacklink}
      updateQuery={updateBacklink}
      deleteQuery={deleteBacklink}
      permissionProp="backlinks"
      goBackLevel={2}
    />
  )
}

import { useState, useEffect, useCallback, useRef } from 'react'
import { useSelector } from 'react-redux'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { RootState } from '../interface/RootState'
import { CaseDataInterface } from '../interface/data/CaseDataInterface'
import { Button, CircularProgress, FormControl, Grid, MenuItem, Select, Slide, TextField } from '@mui/material'
import { Checkbox, Container, Dialog, Divider, Fade, FormControlLabel, IconButton,
        InputAdornment, InputLabel, Snackbar, Tooltip, Typography, Theme, useMediaQuery } from '@mui/material'
import Alert, { AlertProps } from '@mui/material/Alert'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { InvoiceCard } from '../components/Cases/Invoices'
import { InvoiceTable } from '../components/Cases/Invoices/InvoiceTable'
import { SubscriptionCard } from '../components/Cases/Subscriptions/SubscriptionCard'
import { ApprovalLimitAlert } from '../components/Cases/Alerts'
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder'
import { useCreateCaseMutation, useUpdateCaseMutation } from '../services/casesApi'
import { getDisabledDates } from '../utility/helpers'
import dayjs from 'dayjs'

const resolutionTypes = [
  'Billing Alterations Only',
  'Cancel',
  'Pause',
  'Altered Products'
]
const cancelReasons = [
  'Act of God',
  'Breach of Platform Policies',
  'Business Closed',
  'Cannot Afford Management',
  'Cannot Afford Spend',
  'Force Cancellation - Not Funding Ads',
  'Force Cancellation - Not Paying Invoices',
  'Gone To Competitor',
  'Other',
  'RTS',
  'Sold Business',
  'To Much Work',
  'Unhappy With Service',
  'Unhappy with Results',
  'Unrealistic expectations',
  'Website Issues'
]

const createEmptyRow = (params, customerId, keywordId, productId, userEmail, managers, companyDetails) => {
  return ({
    id: 0, // temporary random uuid for the purpose of rendering table in refine
    case_type: params?.case_type || resolutionTypes[0],
    case_number: params?.case_number || '',
    customer_id: customerId || '',
    case_notes: params?.case_notes || null,
    cancel_date: params?.cancel_date ? new Date(params.cancel_date).toISOString() : null,
    cancel_reason: params?.cancel_reason || null,
    cancel_immediately: params?.cancel_immediately || false,
    cancel_endterm: params?.cancel_endterm || false,
    customer_name: companyDetails?.name || '',
    future_credit: params?.future_credit || 0,
    pause_date: params?.pause_date || null,
    pause_immediately: params?.pause_immediately || false,
    pause_endterm: params?.pause_endterm || false,
    pause_indefinitely: params?.pause_indefinitely || false,
    request_date: params?.request_date ? new Date(params.request_date).toISOString() : new Date().toISOString(),
    resume_date: params.resume_date,
    status: params?.status || 'New',
    subscription_id: params.subscription_id,
    subscription_name: params.subscription_name,
    altered_products: params.altered_products,
    invoice_refunds: params?.invoice_refunds || {},
    invoice_credits: params?.invoice_credits || {},
    submitted_by: userEmail,
    submitted_manager: managers[0] || [],
    submit_to_next: params.submit_to_next, // default to false on fresh submission. backend will handle updating as required.
    case_originator: userEmail,
    receipt_data: params.receipt_data
  })
}

export function NewCasePage() {
  const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
  const navigate = useNavigate()

  const [formData, setFormData] = useState<CaseDataInterface | null>(null)
  const [combinedSubData, setCombinedSubData] = useState(null)
  const [selectedSub, setSelectedSub] = useState(null)
  const [selectedInv, setSelectedInv] = useState(null)
  const [openSub, setOpenSub] = useState(false)
  const [openInv, setOpenInv] = useState(false)
  const selectRef = useRef(null)
  
  const { email: userEmail, managers } = useSelector((store: RootState) => store.global.user)
  const subscriptionsCb = useSelector((store: RootState) => store.global.company.subscriptions)
  const subscriptionInfo = useSelector((store: RootState) => store.global.company.subscriptionInfo)
  const invoices = useSelector((store: RootState) => store.global.company.invoices)
  const companyDetails = useSelector((store: RootState) => store.global.company.details)
  const [createCase] = useCreateCaseMutation()
  const [updateCase] = useUpdateCaseMutation()

  const { customerId, keywordId, productId, caseId } = useParams()
  
  // Modifying page for an "EDIT" scenario
  const isEdit = Boolean(caseId)
  const caseData = useSelector((store: RootState) => {
    const query = store.api.queries[`getCases("${customerId}")`]?.data
    return query
  })

  // Calculate total modification
  const totalCredits = formData?.invoice_credits ? Number(Object.values(formData?.invoice_credits).reduce((acc: number, value: number) => acc + value, 0)) : 0
  const totalRefunds = formData?.invoice_refunds ? Number(Object.values(formData?.invoice_refunds).reduce((acc: number, value: number) => acc + value, 0)) : 0
  const totalPromotion = formData?.future_credit ? Number(formData?.future_credit) : 0
  const totalModification = totalCredits + totalRefunds + totalPromotion

  const combineSubscriptionData = useCallback(() => {
    return subscriptionsCb?.map((sub) => {
      const subInfo = subscriptionInfo?.find(s => s.cb_subscription_id === sub.id)
      // Create a new object with combined data
      return {
        ...sub,
        ...(subInfo ? {
          sub_id: subInfo.id,
          domain: subInfo.domain,
          google_ads_id: subInfo.google_ads_id,
          seo_geolocation_id: subInfo.seo_geolocation_id,
          meta_ads_id: subInfo.meta_ads_id,
          tag_manager_id: subInfo.tag_manager_id,
          tag_manager_linked_to: subInfo.tag_manager_linked_to,
          analytics_property_id: subInfo.analytics_property_id,
          analytics_web_stream: subInfo.analytics_web_stream,
          analytics_linked_to: subInfo.analytics_linked_to,
          merchant_id: subInfo.merchant_id,
          merchant_linked_to: subInfo.merchant_linked_to,
          search_console_linked_to: subInfo.search_console_linked_to,
          business_profile_linked_to: subInfo.business_profile_linked_to,
        } : {})
      }
    })
  }, [subscriptionsCb, subscriptionInfo])

  useEffect(() => {
    const combined = combineSubscriptionData()
    setCombinedSubData(combined)
  }, [subscriptionsCb, subscriptionInfo, combineSubscriptionData])

  useEffect(() => {
    setSelectedSub(combinedSubData?.filter((sub) => formData?.subscription_id === sub.id)[0])
  }, [formData?.subscription_id])

  //* SNACKBAR ----------
  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)

  const handleCloseSnackbar = () => setSnackbar(null)

  // Preset the pause date, to date, and cancel dates based on the subscription cycle
  useEffect(() => {
    if (selectedSub && formData?.subscription_id && subscriptionsCb?.length > 0) {
      const newFormData = { ...formData, updated_at: new Date().toISOString() }
      if (!formData.pause_date) {
        newFormData.pause_date = selectedSub.next_billing_at
        newFormData.resume_date = new Date(Date.parse(selectedSub.next_billing_at) + (30 * 24 * 60 * 60 * 1000)).toISOString()
        newFormData.cancel_date = new Date(selectedSub.next_billing_at).toISOString()
      }
      setFormData(newFormData)
    }
  }, [subscriptionsCb, selectedSub])

  const handleSubmitQuery = async (params) => {
    if (!formData) {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth' })
      return setSnackbar({ children: `Please select a subscription`, severity: 'warning' })
    }
    try {
      const newRow = createEmptyRow(params, customerId, keywordId, productId, userEmail, managers, companyDetails)
      // Submit create query to rtk
      const res = 
        isEdit
          ? 
        await updateCase({ itemId: caseId, body: newRow, subscriptionId: productId, keywordId }).unwrap()
          :
        await createCase({ body: newRow, subscriptionId: productId, keywordId }).unwrap()
  
      if (!res) {
        return setSnackbar({ children: `Error creating Case`, severity: 'error' })
      }
      setSnackbar({ children: `Successfully created Case`, severity: 'success' })
      navigate('../cases')
    } catch (error) {
      console.error(error)
      setSnackbar({ children: `Error creating Case: ${error?.message}`, severity: 'error' })
    }
  }

  // load in form data, or set to null (depending on if EDIT or NEW page type)
  useEffect(() => {
    if (isEdit) {
      const selectedCase = caseData?.filter(c => c.id === Number(caseId))[0]
      setFormData(selectedCase)
    } else {
      setFormData(null)
    }
  }, [caseData, caseId, isEdit])

  return (
    <Slide in direction="left">
      <Fade in>
        <Container style={{ padding: '0px', paddingTop: '24px', paddingLeft: '16px', margin: '0px', maxWidth: '100%' }} ref={selectRef}>
          <Grid item xs={12}>
            <div className="flex justify-between items-center pb-[12px]">
              <div className="flex items-center gap-[12px]">
                <Tooltip title="Go back">
                  <Link to={window.location.href.split('/').slice(0, isEdit ? -2 : -1).join('/')} rel="noopener noreferrer">
                    <IconButton color="secondary" >
                      <ArrowBackIosNewIcon />
                    </IconButton>
                  </ Link>
                </Tooltip>
                <Typography variant="h5">
                  {isEdit ? 'Edit Case ' + formData?.case_number : 'Create a New Case'}
                </Typography>
              </div>
            </div>
          </Grid>

          <div className="flex flex-row">
            <div className="w-full flex flex-col gap-[20px] p-[30px] pt-[10px] pb-[10px] ">
              <div className="flex flex-row gap-[5px] items-center">
                <FormControl fullWidth>
                  <InputLabel>Subscription</InputLabel>
                  <Select
                    fullWidth
                    value={formData?.subscription_id || ''}
                    label="Subscription"
                    onChange={(e) => setFormData({ 
                      ...formData,
                      subscription_id: e.target.value,
                      subscription_name: subscriptionsCb.filter(v => v.id === e.target.value)[0].name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
                      pause_date: null // Reset the pause date, so that default values are reapplied for pause/cancel dates
                    })}
                  >
                    {(subscriptionsCb?.length === 0 || !subscriptionsCb) ? (
                      <MenuItem disabled>
                        <CircularProgress color="inherit" size={30} />
                      </MenuItem>
                    ) : (
                      subscriptionsCb.map((val) => (
                        <MenuItem key={val.id} value={val.id}>
                          {val.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')} 
                          <span style={{ textTransform: 'capitalize', opacity: '60%', fontSize: 'smaller' }}>
                            &nbsp;({val.status.replaceAll('_', ' ')}),
                          </span>
                          <span style={{ textTransform: 'capitalize', opacity: '60%', fontSize: '12px' }}>
                            &nbsp;({val.id})
                          </span>
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>
                {(!isLargeScreen) && (
                  <IconButton sx={{ height: 'max-content' }} color="warning" onClick={() => setOpenSub(true)} >
                    <ExpandMoreIcon />
                  </IconButton>
                )}
              </div>
              <FormControl>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={['DatePicker']}>
                    <DatePicker
                      label="Client Request Date"
                      format="DD/MM/YYYY"
                      slotProps={{ textField: { fullWidth: true, helperText: 'Defaults to todays date' } }}
                      value={dayjs(formData?.request_date || new Date().toISOString())}
                      onChange={(date) => setFormData({ ...formData, request_date: dayjs(date).format('YYYY-MM-DD') })}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </FormControl>
              <TextField
                label="Case Notes"
                fullWidth
                multiline
                rows={4}
                value={formData?.case_notes}
                onChange={(e) => setFormData({ ...formData, case_notes: e.target.value })}
              />
              <FormControl fullWidth>
                <InputLabel>Product Alterations</InputLabel>
                <Select
                  fullWidth
                  defaultValue={resolutionTypes[0]}
                  value={formData?.case_type || resolutionTypes[0]}
                  label="Product Alterations"
                  onChange={(e) => setFormData({ ...formData, case_type: e.target.value })}
                  required
                >
                  {resolutionTypes.map((val) => {
                    return <MenuItem key={val} value={val}>{val}</MenuItem>
                  })}
                </Select>
              </FormControl>
              {(formData?.case_type === 'Pause') && (
                <>
                  <Divider>
                    <Typography variant="body2" component="span" color="default" sx={{ opacity: '60%' }}>
                      Pause Fields
                    </Typography>
                  </Divider>
                  <div className="flex flex-row gap-[15px]">
                    <FormControl fullWidth>
                      <div className="flex flex-col gap-[8px]">
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DemoContainer components={['DatePicker']}>
                            <DatePicker
                              disabled={formData.pause_immediately || formData.pause_endterm}
                              label="Pause Date"
                              format="DD/MM/YYYY"
                              shouldDisableDate={(date) => getDisabledDates(subscriptionsCb, formData?.subscription_id, date, 'pause')}
                              slotProps={{ textField: { fullWidth: true, required: formData?.case_type === 'Pause', helperText: 'Defaults to the next billing cycle start date' } }}
                              value={dayjs(formData.pause_date)}
                              onChange={(date) => setFormData({ ...formData, pause_date: dayjs(date).format('YYYY-MM-DD') })}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                        <div className="flex flex-col">
                          <Tooltip title="The subscription will be paused immediately.">
                            <FormControlLabel control={
                              <Checkbox
                                disabled={formData.pause_endterm}
                                checked={formData.pause_immediately}
                                onChange={(e) => setFormData({ ...formData, pause_immediately: e.target.checked })}
                              />
                              }
                              label={<Typography variant="body2" color="textSecondary">Pause immediately</Typography>}
                            />
                          </Tooltip>
                          <Tooltip title="Subscription will be paused at the end of the current billing term.">
                            <FormControlLabel control={
                              <Checkbox
                                disabled={formData.pause_immediately}
                                checked={formData.pause_endterm}
                                onChange={(e) => setFormData({ ...formData, pause_endterm: e.target.checked })}
                              />
                              }
                              label={<Typography variant="body2" color="textSecondary">Pause at end of current term</Typography>}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </FormControl>
                    <FormControl fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={['DatePicker']}>
                          <DatePicker
                            disabled={formData.pause_indefinitely}
                            label="Resume Date"
                            format="DD/MM/YYYY"
                            shouldDisableDate={(date) => getDisabledDates(subscriptionsCb, formData?.subscription_id, date, 'pause')}
                            slotProps={{ textField: { fullWidth: true, required: formData?.case_type === 'Pause', helperText: 'Defaults to the next billing cycle start date + 30 days' } }}
                            value={dayjs(formData.resume_date)}
                            onChange={(date) => setFormData({ ...formData, resume_date: dayjs(date).format('YYYY-MM-DD') })}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </FormControl>
                  </div>
                </>
              )}
              {(formData?.case_type === 'Cancel') && (
                <>
                  <Divider>
                    <Typography variant="body2" component="span" color="default" sx={{ opacity: '60%' }}>
                      Cancel Fields
                    </Typography>
                  </Divider>
                  <div className="flex flex-col gap-[8px]">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={['DatePicker']}>
                        <DatePicker
                          disabled={formData.cancel_immediately || formData.cancel_endterm}
                          label="Cancel Date"
                          format="DD/MM/YYYY"
                          shouldDisableDate={(date) => getDisabledDates(subscriptionsCb, formData?.subscription_id, date, 'cancel')}
                          slotProps={{ textField: { fullWidth: true, required: formData?.case_type === 'Cancel', helperText: 'Cancels are applied on the day prior to the date specified, to ensure the product is cancelled before the next billing.' } }}
                          value={dayjs(formData.cancel_date)}
                          onChange={(date) => setFormData({ ...formData, cancel_date: dayjs(date).format('YYYY-MM-DD'), cancel_reason: formData.cancel_reason ? formData.cancel_reason.concat(' ') : null })}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                    <div className="flex flex-col w-max">
                      <Tooltip title="Cancels the subscription immediately.">
                        <FormControlLabel control={
                          <Checkbox
                            disabled={formData.cancel_endterm}
                            checked={formData.cancel_immediately}
                            onChange={(e) => setFormData({ ...formData, cancel_immediately: e.target.checked })}
                          />
                          }
                          label={<Typography variant="body2" color="textSecondary">Cancel immediately</Typography>}
                        />
                      </Tooltip>
                      <Tooltip title="Cancels at the end of the current billing cycle - (the client won't be charged another invoice)">
                        <FormControlLabel control={
                          <Checkbox
                            disabled={formData.cancel_immediately}
                            checked={formData.cancel_endterm}
                            onChange={(e) => setFormData({ ...formData, cancel_endterm: e.target.checked })}
                          />
                          }
                          label={<Typography variant="body2" color="textSecondary">Cancel at end of current term</Typography>}
                        />
                      </Tooltip>
                    </div>
                  </div>
                  <FormControl>
                    <InputLabel>Cancel Reason</InputLabel>
                    <Select
                      required
                      fullWidth
                      value={formData.cancel_reason || null}
                      label="Cancel Reason"
                      onChange={(e) => setFormData({ ...formData, cancel_reason: e.target.value })}
                    >
                      {cancelReasons.map((val) => {
                        return <MenuItem key={val} value={val}>{val}</MenuItem>
                      })}
                    </Select>
                  </FormControl>
                </>
              )}
              {(formData?.case_type === 'Altered Products') && (
                <>
                  <Divider>
                    <Typography variant="body2" component="span" color="default" sx={{ opacity: '60%' }}>
                      Altered Product Fields
                    </Typography>
                  </Divider>
                  <TextField
                    label="Altered Products"
                    required
                    fullWidth
                    multiline
                    rows={5}
                    helperText="Please enter any details for Altered Products here. Accounts will implement the reqeusted changes, if the case is approved."
                    value={formData.altered_products}
                    onChange={(e) => setFormData({ ...formData, altered_products: e.target.value })}
                  />
                </>
              )}
              <Divider>
                <Typography variant="body2" component="span" color="default" sx={{ opacity: '60%' }}>
                  Billing Alteration
                </Typography>
              </Divider>
              <InvoiceTable
                invoices={invoices}
                selectedInv={selectedInv}
                setSelectedInv={setSelectedInv}
                setOpenInv={setOpenInv}
                formData={formData}
                setFormData={setFormData}
              />
              <Divider>
                <Typography variant="body2" component="span" color="default" sx={{ opacity: '60%' }}>
                  Future Invoices
                </Typography>
              </Divider>
              <TextField
                label="Credit Account for Future Invoices"
                required
                fullWidth
                type="number"
                helperText="These credits will be added to the account and will be automatically applied to any NEW future subscription invoice until exhausted. To apply credits or refunds to an EXISTING invoice, please select the specific invoice above and apply the credits/refunds directly."
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                value={formData?.future_credit}
                onChange={(e) => setFormData({ ...formData, future_credit: parseFloat(e.target.value) })}
              />
              <ApprovalLimitAlert
                total={totalModification}
              />
              <Button variant="contained" size="large" endIcon={<CreateNewFolderIcon />} onClick={() => handleSubmitQuery(formData)}>
                {isEdit ? 'UPDATE' : 'CREATE'} CASE
              </Button>
            </div>
            {(!isLargeScreen) ? (
              <>
                <Dialog fullWidth open={openSub} onClose={() => setOpenSub(false)}>
                  <SubscriptionCard subscription={selectedSub} />
                </Dialog>
                <Dialog fullWidth open={openInv} onClose={() => setOpenInv(false)}>
                  <InvoiceCard invoice={selectedInv} />
                </Dialog>
              </>
            ) : (
              <>
                <Divider orientation="vertical" variant="middle" flexItem />
                <div className="w-[150%] flex flex-col gap-[20px] pl-[30px] self-start sticky top-[40px]">
                    <Typography variant="h5">
                      Subscription Details
                    </Typography>
                  {(selectedSub) ? (
                      <SubscriptionCard subscription={selectedSub} />
                  ) : (
                    <Typography style={{ opacity: '60%' }}>
                      Select a subscription via the dropdown to view additional details.
                    </Typography>
                  )}
                  <Divider />
                    <Typography variant="h5">
                      Invoice Details
                    </Typography>
                  {(selectedInv) ? (
                    <InvoiceCard invoice={selectedInv} />
                  ) : (
                    <Typography style={{ opacity: '60%' }}>
                      Select an invoice to view additional details.
                    </Typography>
                  )}
                </div>
              </>
            )}
          </div>
          {!!snackbar && (
            <Snackbar
              open
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              onClose={handleCloseSnackbar}
              autoHideDuration={6000}
            >
              <Alert
                {...snackbar}
                onClose={handleCloseSnackbar}
                variant="filled"
              />
            </Snackbar>
          )}
        </Container>
      </Fade>
    </Slide>
  )
}

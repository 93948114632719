/**
 * Convert a timestamp to an extended date, with timezone details
 *
 * @param {number} timestamp Timestamp number
 * @param {string} timezone Timezone string
 * @param {boolean} short Whether to return a long, or shortened date string
 * @returns {string}
 */
export const timestampToExtendedDate = (
  timestamp: number,
  timezone: string,
  short: boolean = false
): string => {
  const dateStr = new Date(timestamp * 1000).toLocaleString('en-AU', { 
    timeZone: timezone, 
    year: 'numeric', 
    month: 'short', 
    ...(short ? { day: '2-digit' } : { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false })
  })
  const dateStrWithTimezone = !short ? dateStr + ', ' + timezone : dateStr
  return dateStrWithTimezone
}

import { useForm } from '@refinedev/react-hook-form'
import * as React from 'react'
import { RegisterFormTypes, RegisterPageProps, useActiveAuthProvider, BaseRecord, HttpError, useTranslate, useRouterContext, useRouterType, useLink, useRegister } from '@refinedev/core'
import { FormPropsType } from '../../pages/AuthPage'
import { titleStyles } from './styles'
import { RenderProviders } from './RenderProviders'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import MuiLink from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import type { BoxProps } from '@mui/material/Box'
import type { CardContentProps } from '@mui/material/CardContent'
import { AuthProvider } from './AuthProvider'

type RegisterProps = RegisterPageProps<BoxProps, CardContentProps, FormPropsType>

/**
 * The register page will be used to register new users. You can use the following props for the <AuthPage> component when the type is "register".
 * @see {@link https://refine.dev/docs/api-reference/mui/components/mui-auth-page/#register} for more details.
 */
export const RegisterPage: React.FC<RegisterProps> = ({ loginLink, wrapperProps, contentProps, renderContent, providers, formProps, title }) => {
  const { onSubmit, ...useFormProps } = formProps || {}
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<BaseRecord, HttpError, RegisterFormTypes>({
    ...useFormProps,
  })

  const authProvider = useActiveAuthProvider()
  const { mutate: registerMutate, isLoading } = useRegister<RegisterFormTypes>({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  })
  const translate = useTranslate()
  const routerType = useRouterType()
  const Link = useLink()
  const { Link: LegacyLink } = useRouterContext()

  const ActiveLink = routerType === 'legacy' ? LegacyLink : Link

  const PageTitle = title === false ? null : (
    <img className="w-[200px] pb-[20px] self-center" src="./images/logo_dark.svg" />
  )

  const Content = (
    <Card {...(contentProps ?? {})}>
      <CardContent sx={{ p: '32px', '&:last-child': { pb: '32px' } }}>
        <Typography component="h1" variant="h5" align="center" style={titleStyles} color="#28b1e6" fontWeight={700}>
          {translate('pages.register.title', 'Sign up for your account')}
        </Typography>
        {(providers && providers.length > 0) && <RenderProviders providers={providers} />}
        <Box
          component="form"
          onSubmit={handleSubmit((data) => {
            if (onSubmit) {
              return onSubmit(data)
            }

            return registerMutate(data)
          })}>
          <TextField
            {...register('email', {
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: translate('pages.register.errors.validEmail', 'Invalid email address'),
              },
            })}
            id="email"
            margin="normal"
            fullWidth
            label={translate('pages.register.email', 'Email')}
            error={!!errors.email}
            helperText={errors['email'] ? errors['email'].message : ''}
            name="email"
            autoComplete="email"
            sx={{
              mt: 0,
            }}
          />
          <TextField
            {...register('password', {
              required: true,
            })}
            id="password"
            margin="normal"
            fullWidth
            name="password"
            label={translate('pages.register.fields.password', 'Password')}
            helperText={errors['password'] ? errors['password'].message : ''}
            error={!!errors.password}
            type="password"
            placeholder="●●●●●●●●"
            autoComplete="current-password"
            sx={{
              mb: 0,
            }}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={isLoading}
            sx={{
              mt: '24px',
              backgroundColor: '#28b1e6'
            }}>
            {translate('pages.register.signup', 'Sign up')}
          </Button>
          {loginLink ?? (
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              sx={{
                mt: '24px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}>
              <Typography variant="body2" component="span" fontSize="12px">
                {translate('pages.login.buttons.haveAccount', 'Have an account?')}
              </Typography>
              <MuiLink ml="4px" variant="body2" color="#28b1e6" component={ActiveLink} underline="none" to="/login" fontSize="12px" fontWeight="bold">
                {translate('pages.login.signin', 'Sign in')}
              </MuiLink>
            </Box>
          )}
        </Box>
      </CardContent>
    </Card>
  )

  return (
    <AuthProvider
      wrapperProps={wrapperProps}
      renderContent={renderContent}
      Content={Content}
      PageTitle={PageTitle}
    />
  )
}

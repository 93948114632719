import { api } from './api'
import { queries } from '../utility'
import { setFormSchema, setFormResults, setFormTypes } from '../store/global'
import { store } from '../store'

export const formsApi = api.injectEndpoints({
  reducerPath: 'formsApi',
  endpoints: (build) => ({
    getFormSchema: build.query({
      queryFn: async (arg) => {
        const formId = arg
        const response = await queries.getFormSchema(formId)
        store.dispatch(setFormSchema(response))
        return { data: response }
      },
    }),
    getFormTypes: build.query({
      queryFn: async (arg) => {
        const response = await queries.getFormTypes()
        store.dispatch(setFormTypes(response))
        return { data: response }
      },
    }),
    getFormResults: build.query({
      queryFn: async (arg) => {
        const { customerId, resultId = null } = arg
        const response = await queries.getFormResults(customerId, resultId)
        store.dispatch(setFormResults(response))
        return { data: response }
      },
    }),
    createFormResult: build.mutation({
      queryFn: async (arg) => {
        const { body } = arg
        if (!body?.formtype || body?.formtype?.length === 0) {
          return { error: 'Invalid form type' }
        } else {
          window.location.href = `forms/${body.formtype}`
          return { data: true }
        }
      },
    }),
    updateFormResult: build.mutation({
      queryFn: async (arg) => {
        const { resultId, body } = arg
        const response = await queries.updateFormResult(body)  // Update Supabase table
        store.dispatch( // Update the redux store
          formsApi.util.updateQueryData('getFormResults', { customerId: body.customer_id, resultId: null }, (draft) => {
            draft.forEach(form => {
              if (form.id === Number(resultId)) {
                Object.assign(form, {
                  data: body.data,
                  updated_at: body.updated_at,
                  updated_by: body.updated_by
                })
              }
            })
          })
        )
        return { data: response }
      },
    }),
    deleteFormResult: build.mutation({
      queryFn: async (arg) => {
        const { itemId, customerId } = arg
        const response = await queries.deleteFormResult(itemId) // Update Supabase table
        store.dispatch( // Update redux store
          formsApi.util.updateQueryData('getFormResults', { customerId, resultId: null }, (draft) => {
            const updated = draft.filter(d => d.id !== itemId)
            return updated
          })
        )
        return { data: response }
      },
    })
  }),
})

export const {
  useGetFormSchemaQuery,
  useGetFormTypesQuery,
  useGetFormResultsQuery,
  useCreateFormResultMutation,
  useUpdateFormResultMutation,
  useDeleteFormResultMutation
} = formsApi

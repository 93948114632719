import React from 'react'
import { IconButton, Link, List, ListItem, ListItemText, Skeleton, Tooltip, Typography, Grid, Box, Stepper, Step, StepLabel } from '@mui/material'
import { emailToName } from '../../../utility/helpers/miscHelpers'
import { formatAsDayMonthYear } from '../../../utility/helpers/dateHelpers'
import { getCpanelUrl } from '../../../utility/helpers/brandHelpers'
import { CaseTypeIcon } from '../../Icons'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

export function CaseDataFormatted ({
  caseData = null
}) {
  if (!caseData || Object.keys(caseData)?.length === 0) {
    return (
      <div className="p-[20px] pt-[0px]">
        <Skeleton animation="wave" height={118} />
        <Skeleton animation="wave" />
        <Skeleton animation="wave" width="50%" />
      </div>
    )
  }

  const steps = [
    { label: 'Case Originator', name: emailToName(caseData?.case_originator) },
    ...(caseData?.submitted_by !== caseData?.case_originator
      ? [{ label: 'Last Approved By', name: emailToName(caseData?.submitted_by) }]
      : []),
    { label: 'Currently With', name: emailToName(caseData?.submitted_manager) }
  ]

  return (
    <div className="p-[24px] pt-[0px]">

      <Typography variant="body1">
        <strong>Status: </strong> 
        {caseData?.status}
      </Typography>

      <Typography variant="body1">
        <strong>Submitted: </strong>
         {formatAsDayMonthYear(caseData?.request_date)}
      </Typography>

      {(['Approval Rejected', 'Closed'].includes(caseData?.status) && caseData?.closed_at) && (
        <Typography variant="body1"><strong>Closed:</strong> {formatAsDayMonthYear(caseData?.closed_at)}</Typography>
      )}

      <Typography variant="body1">
        <strong>Customer: </strong>
        {caseData?.customer_name}
        <Tooltip title="Hubspot Link">
          <Link target="_blank" href={`https://app.hubspot.com/contacts/44411866/record/0-2/${caseData?.customer_id}`}>
            <IconButton color="primary">
              <img className="flex w-[20px] max-h-[20px] items-center justify-center self-center" src={'/images/Hubspot.svg'} />
            </IconButton>
          </Link>
        </Tooltip>
        <Tooltip title="Admin Panel Link">
          <Link target="_blank" href={`${getCpanelUrl('dd')}/customers/show/${caseData?.customer_id}`}>
            <IconButton color="primary">
              <img className="flex w-[22px] max-h-[22px] items-center justify-center self-center" src={'/images/logo_natural_DD.svg'} />
            </IconButton>
          </Link>
        </Tooltip>
      </Typography>

      <Typography variant="body1">
        <strong>Case Originator: </strong>
        <Tooltip title={String(caseData?.case_originator)}>
          <>{emailToName(caseData?.case_originator)}</>
        </Tooltip>
      </Typography>

      <Typography variant="body1">
        <strong>Subscription:</strong> {caseData?.subscription_name}
        <Tooltip title="Chargebee Link">
          <Link target="_blank" href={`https://disruptdigital.chargebee.com/d/subscriptions/${caseData?.subscription_id}#summary`}>
            <IconButton color="primary">
              <img className="flex w-[22px] max-h-[22px] items-center opacity-[70%] justify-center self-center" src={'/images/Chargebee.svg'} />
            </IconButton>
          </Link>
        </Tooltip>
      </Typography>

      <Typography variant="body1"><strong>Case Type: </strong>
        <CaseTypeIcon caseData={caseData} />
        &nbsp;{caseData?.case_type}
      </Typography>

      {caseData?.case_type === 'Cancel' ? (
        <>
          {caseData.cancel_immediately ? (
            <Typography variant="body1"><strong>Date: </strong>Cancel Immediately</Typography>
          ) : caseData.cancel_endterm ? (
            <Typography variant="body1"><strong>Date: </strong>Cancel at end of term</Typography>
          ) : (
            <Typography variant="body1"><strong>Cancel Date:</strong> {formatAsDayMonthYear(caseData?.cancel_date)}</Typography>
          )}
          <Typography variant="body1"><strong>Cancel Reason:</strong> {caseData?.cancel_reason?.trim() || 'N/A'}</Typography>
        </>
      ) : caseData?.case_type === 'Pause' ? (
        <Typography variant="body1">
          <strong>Date:</strong> {caseData?.pause_immediately ? 'Pause Immediately ' : caseData?.pause_endterm ? 'Pause at End of Term ' : `${caseData?.pause_date} `} {caseData?.pause_indefinitely ? 'indefinitely' : `to ${caseData?.resume_date}`}&nbsp;
          {(!caseData?.pause_immediately && !caseData?.pause_endterm && !caseData?.indefinitely) && (
            <span style={{ textTransform: 'capitalize', opacity: '60%', fontSize: '0.9rem' }}>
              ({
                caseData?.pause_date && caseData?.resume_date ? (
                  (() => {
                    const pauseDate = new Date(caseData.pause_date)
                    const resumeDate = new Date(caseData.resume_date)
                    const diffInDays = Math.abs((resumeDate.getTime() - pauseDate.getTime()) / (1000 * 60 * 60 * 24))
                    return diffInDays < 7 
                      ? `${diffInDays} days` 
                      : `${Math.floor(diffInDays / 7)} weeks`
                  })()
                ) : 'N/A'
              })
            </span>
          )}
        </Typography>
      ) : caseData?.case_type === 'Altered Products' ? (
        <Typography variant="body1">
          <strong>Altered Products Request:<br/></strong>
          <i>
            {(caseData?.altered_products && String(caseData?.altered_products)?.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}<br/>
              </React.Fragment>
            )))}
          </i>
        </Typography>
      ) : null}

      {Object.values(caseData?.invoice_refunds).some(value => value !== "") && (
        <>
          <Typography variant="body1"><strong>Refunds:</strong></Typography>
          <List>
            {Object.entries(caseData?.invoice_refunds).map(([invoice, refund]) => (
              <ListItem key={invoice}>
                <ListItemText>
                  ● Invoice {invoice}: 
                  <span style={{ textTransform: 'capitalize', opacity: '60%', fontSize: '0.9rem' }}>
                    &nbsp;REFUND ${(Number(refund)).toFixed(2)}
                  </span>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </>
      )}
      {Object.values(caseData?.invoice_credits).some(value => value !== "") && (
        <>
          <Typography variant="body1"><strong>Credits:</strong></Typography>
          <List>
            {Object.entries(caseData?.invoice_credits).map(([invoice, refund]) => (
              <ListItem key={invoice}>
                <ListItemText>
                  ● Invoice {invoice}: 
                  <span style={{ textTransform: 'capitalize', opacity: '60%', fontSize: '0.9rem' }}>
                    &nbsp;CREDIT ${(Number(refund)).toFixed(2)}
                  </span>
                </ListItemText>
              </ListItem>
            ))}
          </List>
        </>
      )}
      {(parseFloat(caseData?.future_credit) > 0) && (
        <Typography variant="body1"><strong>Future Credit:</strong> ${(Number(caseData?.future_credit)).toFixed(2)}</Typography>
      )}

      {(!caseData?.status?.includes('Closed') && !caseData?.status?.includes('Rejected')) && (
        <>
        <Typography variant="body1" gutterBottom>
          <strong>Approval Chain:</strong>
        </Typography>
        <Stepper sx={{ marginBottom: '10px' }} activeStep={steps.length - 1} alternativeLabel>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel icon={<AccountCircleIcon sx={{ opacity: 0.5 }} />} >
                <Typography variant="body2" sx={{ display: 'flex', flexFlow: 'column' }}>
                  <Typography variant="body2" component="span" color="default" sx={{ opacity: '60%' }}>
                    {caseData?.status === 'New' && step.label === 'Currently With' ? 'Next Approver' : step.label}
                  </Typography>
                  <span>{step.name}</span>
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        </>
      )}

      {(caseData?.case_notes?.length > 0) && (
        <Typography variant="body1">
          <strong>Additional Case Notes:<br/></strong>
          <i>
            {(caseData?.case_notes && String(caseData?.case_notes)?.split('\n').map((line, index) => (
              <React.Fragment key={index}>
                {line}<br/>
              </React.Fragment>
            )))}
          </i>
        </Typography>
      )}
    </div>
  )
}

import { api } from './api'
import { queries } from '../utility'
import { setSubs, setSubInfo, updateSubInfo, setInvoices } from '../store/global'
import { store } from '../store'

export const subscriptionsApi = api.injectEndpoints({
  reducerPath: 'subscriptionsApi',
  endpoints: (build) => ({
    getSubscriptions: build.query({
      queryFn: async (arg) => {
        const customerId = arg
        const response = await queries.getSubscriptionsCb({ customerId })
        store.dispatch(setSubs(response)) // Update the redux store
        return { data: response }
      },
    }),
    getSubscriptionInfo: build.query({
      queryFn: async (arg) => {
        const subscriptionIds = arg
        const response = await queries.getSubscriptionInfo(subscriptionIds)
        store.dispatch(setSubInfo(response)) // Update the redux store
        return { data: response }
      },
    }),
    updateSubscriptionInfo: build.mutation({
      queryFn: async (arg) => {
        const { itemId, body } = arg
        const response = await queries.updateSubscriptionInfo(body.id, body) // Update Supabase table
        store.dispatch(updateSubInfo(body))
        //TODO: Look at integrating dispatch belwo into reducer above...
        const apiQueries = store.getState().api.queries
        const key = Object.keys(apiQueries).filter(e => e.includes('getSubscriptionInfo'))[0]
        const ids = apiQueries[key].originalArgs
        store.dispatch(
          subscriptionsApi.util.updateQueryData('getSubscriptionInfo', ids, (draft) => {
            draft.forEach(sub => {
              if (sub.cb_subscription_id === body.id) {
                Object.assign(sub, body)
              }
            })
          })
        )
        return { data: response }
      },
    }),
    getInvoices: build.query({
      queryFn: async (arg) => {
        const customerId = arg
        const response = await queries.getInvoices({ customerId })
        store.dispatch(setInvoices(response)) // Update the redux store
        return { data: response }
      },
    }),
  })
})

export const {
  useGetSubscriptionsQuery,
  useGetSubscriptionInfoQuery,
  useUpdateSubscriptionInfoMutation,
  useGetInvoicesQuery
} = subscriptionsApi

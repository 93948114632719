import { Button, Card, CardContent, Tooltip, Typography } from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { getCpanelUrl } from '../../../utility/helpers/brandHelpers'
import { LoadingCard } from './'

export const BookingCard = ({
  brandAbbr = 'slx',
  customerId = null,
  loading = true,
  enabled = false
}) => {

  if (loading) return <LoadingCard />

  return (
    <Tooltip title={!enabled ? 'No associated contacts were found for this company' : ''}>
      <span>
        <Button disabled={!enabled} href={`https://${getCpanelUrl(brandAbbr, 'report')}/calendar/schedule/?recordId=${customerId}`} target="_blank" rel="noopener" sx={{ textTransform: 'none', opacity: !enabled ? '50%' : '100%' }}>
          <Card sx={{ maxWidth: '300px', backgroundColor: !enabled ? 'rgb(235, 235, 235)' : '' }}>
            <CardContent>
              <div className="flex gap-[16px] flex-row items-center">
                <CalendarMonthIcon fontSize="medium" color="error" sx={{ filter: !enabled ? 'grayscale(100%)' : '' }} />
                <Typography variant="h6" align="left">
                  Booking Link
                </Typography>
              </div>
              <Typography variant="body1" align="left" color="text.secondary">
                Access the customers' booking link directly.
              </Typography>
            </CardContent>
          </Card>
        </Button>
      </span>
    </Tooltip>
  )
}

import { api } from './api'
import { queries } from '../utility'
import { setKeywords } from '../store/global'
import { store } from '../store'

export const keywordsApi = api.injectEndpoints({
  reducerPath: 'keywordsApi',
  endpoints: (build) => ({
    getKeywords: build.query({
      queryFn: async (arg) => {
        const subscriptionId = arg
        const response = await queries.getKeywords(subscriptionId)
        store.dispatch(setKeywords(response))
        return { data: response }
      },
    }),
    createKeyword: build.mutation({
      queryFn: async (arg) => {
        const { body, subscriptionId } = arg
        const response = await queries.createKeyword(body) // Update Supabase table
        if (response) {
          //! TODO: think of a more elegant solution for the reducer below (modifies query directly)
          store.dispatch( // Update the redux store
            keywordsApi.util.updateQueryData('getKeywords', subscriptionId, (draft) => {
              draft.push(response[0])
            })
          )
        }
        return { data: response }
      },
    }),
    updateKeyword: build.mutation({
      queryFn: async (arg) => {
        const { itemId, body, subscriptionId } = arg
        const response = await queries.updateKeyword(itemId, body) // Update Supabase table
        //! TODO: think of a more elegant solution for the reducer below (modifies query directly)
        store.dispatch( // Update the redux store
          keywordsApi.util.updateQueryData('getKeywords', subscriptionId, (draft) => {
            draft.forEach(key => {
              if (Number(key.id) === Number(itemId)) {
                Object.assign(key, body)
              }
            })
          })
        )
        return { data: response }
      },
    }),
    deleteKeyword: build.mutation({
      queryFn: async (arg) => {
        const { itemId, customerId, subscriptionId } = arg
        const response = await queries.deleteKeyword(itemId) // Update Supabase table
        store.dispatch( // Update redux store
          keywordsApi.util.updateQueryData('getKeywords', subscriptionId, (draft) => {
            const updated = draft.filter(d => d.id !== itemId)
            return updated
          })
        )
        return { data: response }
      },
    }),
  }),
})

export const {
  useGetKeywordsQuery,
  useCreateKeywordMutation,
  useUpdateKeywordMutation,
  useDeleteKeywordMutation
} = keywordsApi

export const genericCard = {
  type: 'generic',
  pattern: /^\d+$/,
  blocks: [
    [4, 4, 4, 4]
  ],
  code: {
    name: 'CVV',
    size: 3
  },
  logo: 'generic-card-logo.svg',
  background: 'bg-gradient-to-rl from-orange-600 to-orange-400'
}

export const cardFormats = [
  {
    type: 'uatp',
    pattern: /^(?!1800)1\d{0,14}/,
    blocks: [
      [4, 5, 6]
    ],
    code: {
      name: 'CCV',
      size: 3
    }
  },
  {
    type: 'amex',
    pattern: /^3[47]\d{0,13}/,
    blocks: [
      [4, 6, 5]
    ],
    code: {
      name: 'CID',
      size: 4
    },
    logo: 'amex-card-logo.svg',
    background: 'bg-gradient-to-l from-gray-900 via-slate-500 to-gray-900 dark:bg-gradient-to-tr dark:from-base-900 dark:to-base-600'
  },
  {
    type: 'discover',
    pattern: /^(?:6011|65\d{0,2}|64[4-9]\d?)\d{0,12}/,
    blocks: [
      [4, 4, 4, 4]
    ],
    code: {
      name: 'CID',
      size: 3
    },
    logo: 'discover-card-logo.svg',
    background: 'bg-gradient-to-l from-cyan-800 via-cyan-600 to-cyan-800 dark:bg-gradient-to-tr dark:from-base-900 dark:to-base-600'
  },
  {
    type: 'diners',
    name: 'Diners Club',
    pattern: /^3(?:0([0-5]|9)|[689]\d?)\d{0,11}/,
    blocks: [
      [4, 6, 4]
    ],
    code: {
      name: 'CVV',
      size: 3
    },
    background: 'bg-gradient-to-l from-gray-900 via-slate-500 to-gray-900 dark:bg-gradient-to-tr dark:from-base-900 dark:to-base-600'
  },
  {
    type: 'mastercard',
    pattern: /^(5[1-5]\d{0,2}|22[2-9]\d{0,1}|2[3-7]\d{0,2})\d{0,12}/,
    blocks: [
      [4, 4, 4, 4]
    ],
    code: {
      name: 'CVC',
      size: 3
    },
    logo: 'mastercard-card-logo.svg',
    background: 'bg-gradient-to-tr from-orange-600 to-orange-400 dark:bg-gradient-to-tr dark:from-base-900 dark:to-base-600'
  },
  {
    type: 'dankort',
    pattern: /^(5019|4175|4571)\d{0,12}/,
    blocks: [
      [4, 4, 4, 4]
    ],
    code: {
      name: 'CCV',
      size: 3
    }
  },
  {
    type: 'instapayment',
    pattern: /^63[7-9]\d{0,13}/,
    blocks: [
      [4, 4, 4, 4]
    ],
    code: {
      name: 'CCV',
      size: 3
    }
  },
  {
    type: 'jcb15',
    pattern: /^(?:2131|1800)\d{0,11}/,
    blocks: [
      [4, 6, 5]
    ],
    code: {
      name: 'CVV',
      size: 3
    }
  },
  {
    type: 'jcb',
    pattern: /^(?:35\d{0,2})\d{0,12}/,
    blocks: [
      [4, 4, 4, 4]
    ],
    code: {
      name: 'CVV',
      size: 3
    },
    background: 'bg-gradient-to-l from-gray-900 via-slate-500 to-gray-900 dark:bg-gradient-to-tr dark:from-base-900 dark:to-base-600'
  },
  {
    type: 'maestro',
    pattern: /^(?:5[0678]\d{0,2}|6304|67\d{0,2})\d{0,12}/,
    blocks: [
      [4, 4, 4, 4],
      [4, 4, 5],
      [4, 6, 5],
      [4, 4, 4, 4, 3]
    ],
    code: {
      name: 'CVC',
      size: 3
    },
    background: 'bg-gradient-to-tr from-orange-600 to-orange-400 dark:bg-gradient-to-tr dark:from-base-900 dark:to-base-600'
  },
  {
    type: 'mir',
    pattern: /^220[0-4]\d{0,12}/,
    blocks: [
      [4, 4, 4, 4]
    ],
    code: {
      name: 'CCV',
      size: 3
    }
  },
  {
    type: 'visa',
    pattern: /^4\d{0,15}/,
    blocks: [
      [4, 4, 4, 4],
      [4, 4, 4, 4, 3]
    ],
    code: {
      name: 'CVV',
      size: 3
    },
    logo: 'visa-card-logo.svg',
    background: 'bg-gradient-to-tr from-slate-700 to-slate-400 dark:bg-gradient-to-tr dark:from-base-900 dark:to-base-600'
  },
  {
    type: 'unionPay',
    pattern: /^(62|81)\d{0,14}/,
    blocks: [
      [4, 4, 4, 4],
      [6, 13]
    ],
    code: {
      name: 'CVN',
      size: 3
    },
    logo: 'unionpay-card-logo.svg',
    background: 'bg-gradient-to-l from-gray-900 via-slate-500 to-gray-900 dark:bg-gradient-to-tr dark:from-base-900 dark:to-base-600'
  }
]

const luhnCheck = ((arr) => {
  return function (ccNum) {
    let len = ccNum.length
    let bit = 1
    let sum = 0
    let val
    while (len) {
      val = parseInt(ccNum.charAt(--len), 10)
      sum += (bit ^= 1) ? arr[val] : val
    }
    return sum && sum % 10 === 0
  }
})([0, 2, 4, 6, 8, 1, 3, 5, 7, 9])

const findCardByNumber = (cardNumber) => (
  cardFormats.find((item) => item.pattern.test(cardNumber))
)

const blockSum = (block) => (
  block.reduce((acc, current) => acc + current, 0)
)

const isNumberValid = (value) => {
  const card = findCardByNumber(value)

  return (
    !!card
    && card.blocks.some((block) => blockSum(block) === value.length)
    && /^\d+$/.test(value)
    && luhnCheck(value)
  )
}

const isNameValid = (value) => (
  value.length >= 3
  && value.length <= 40
  && /^[a-zA-Z ]*$/.test(value)
)

const isExpiryValid = (month, year) => {
  if (!month || !year) { return false }
  const value = month + '/' + year
  const expiry = value.split('/')
  const monthParsed = parseInt(expiry[0])
  const yearParsed = parseInt(expiry[1])
  const currentYear = parseInt(new Date().getFullYear().toString().substring(2, 4))
  const thisMonth = new Date().getMonth() + 1

  return (
    yearParsed > currentYear
    || (yearParsed === currentYear && monthParsed > thisMonth)
  )
}

const isCodeValid = (value, cardType = null) => {
  if (!value) { return false }
  const card = cardFormats.find((item) => item.type === cardType)
  const maxSize = card?.code?.size || 3
  return (
    value?.toString()?.length === maxSize
    && /^\d+$/.test(value)
  )
}

export const validateCard = (
  body
): string => {
  // Validation checks
  if (!isNumberValid(body.cardNumber)) {
    return 'Invalid card number'
  }
  if (!isNameValid(body.nameOnCard)) {
    return 'Invalid card name'
  }
  if (!isExpiryValid(body.expiryMonth, body.expiryYear)) {
    return 'Invalid expiry'
  }
  if (!isCodeValid(body.cvv)) {
    return 'Invalid cvv'
  }

  // All checks passed
  return 'valid'

}


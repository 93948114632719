import { useState, useEffect } from 'react'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import { getReceiptAction } from '../../../utility/helpers/caseHelpers'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'

export function SelectCaseOutcome ({
    originalCases,
    filteredCases,
    setFilteredCases
  }) {
  const [filter, setFilter] = useState('All')

  useEffect(() => {
    if (filter !== 'All') {
      const newCases = originalCases.filter(c => getReceiptAction(c?.receipt_data, c?.manually_actioned).message === filter)
      setFilteredCases(newCases)
    } else {
      setFilteredCases(originalCases)
    }
  }, [filter, originalCases, setFilteredCases])

  return (
    <FormControl sx={{ m: 1, width: 200 }} size="small">
      <InputLabel>Filter by case outcome</InputLabel>
      <Select
        label="Filter by case status"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
      >
        <MenuItem value="All">
          All
        </MenuItem>
        <MenuItem value="Actioned">
          <DoneIcon color="success" sx={{ marginRight: 1 }} />
          Actioned
        </MenuItem>
        <MenuItem value="Failed to Action">
          <CloseIcon color="error" sx={{ marginRight: 1 }} />
          Failed
        </MenuItem>
        <MenuItem value="Manually Action">
          <WarningAmberIcon color="warning" sx={{ marginRight: 1 }} />
          Manually Action
        </MenuItem>
      </Select>
      <FormHelperText>Filter by Success, Error etc.</FormHelperText>
    </FormControl>
  )
}

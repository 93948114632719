import { useDocumentTitle } from '@refinedev/react-router-v6'
import Typography from '@mui/material/Typography'
import { SearchContainer } from '../components/SearchBar'

export function HomePage () {
  useDocumentTitle('Home | Admin Panel')

  return (
    <>
      <Typography variant="h4" align="center" gutterBottom style={{ padding: '25px', paddingBottom: '0px' }}>
        Welcome to the<br />Administrator Dashboard
      </Typography>
      <Typography variant="body1" align="center" gutterBottom style={{ paddingLeft: '50px', paddingRight: '50px', paddingBottom: '20px' }}>
        Search a customer to get started
      </Typography>
      <div className="flex flex-row items-center justify-center">
        <SearchContainer fullSize={true} />
      </div>
    </>
  )
}

import { useOutletContext } from 'react-router-dom'
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport } from '@mui/x-data-grid'
import { MenuItem, Select, IconButton, TextField } from '@mui/material'
import { TableTemplateNew } from '../components/Table'
import { validateCard } from '../utility/helpers/cardHelpers'
import { genRandomUUID } from '../utility/helpers/miscHelpers'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { CompanyQueries } from '../interface/queries/CompanyQueries'
import { useCreateCreditCardMutation, useUpdateCreditCardMutation, useDeleteCreditCardMutation } from '../services/cardApi'

interface DataProps {
  id?: number,
  cardNumber?: string,
  nameOnCard?: string,
  expiryMonth?: string,
  expiryYear?: string,
  cvv?: string
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

const dataTitle = 'Credit Card'
const initialForm: DataProps = {
  id: 0,
  cardNumber: '',
  nameOnCard: '',
  expiryMonth: '',
  expiryYear: ''
}
const columnFields = (openUpdateForm, handleDialogOpen, allowEdit, allowDelete) => {
  return [
    { field: 'cardNumber', headerName: 'Card Number', width: 200, editable: false,
      renderCell: (params) => params?.row?.cardNumber ? params.row.cardNumber.replace(/(.{4})/g, '$1-').slice(0, -1) : 'N/A'
    },
    { field: 'nameOnCard', headerName: 'Card Name', width: 150, editable: false },
    { field: 'expiryMonth', headerName: 'Expiry Month', width: 150, editable: false },
    { field: 'expiryYear', headerName: 'Expiry Year', width: 150, editable: false },
    { field: 'edit', headerName: 'Edit', width: 75,
      renderCell: (params) => (
        <IconButton color="info" onClick={() => openUpdateForm(params)} disabled={!allowEdit} >
          <EditIcon />
        </IconButton>
      ),
    },
    { field: 'delete', headerName: 'Delete', width: 75,
      renderCell: (params) => (
        <IconButton color="error" onClick={() => handleDialogOpen(params, 'Delete')} disabled={!allowDelete} >
          <DeleteIcon />
        </IconButton>
      ),
    }
  ]
}

const upsertFields = (create, formData, setFormData) => {
  return (
    <>
      <TextField
        label="Card Number"
        required
        fullWidth
        value={formData.cardNumber}
        onChange={(e) => setFormData({ ...formData, cardNumber: e.target.value })}
        inputProps={{ maxLength : 19 }}
      />
      <TextField
        label="Card Name"
        required
        fullWidth
        value={formData.nameOnCard}
        onChange={(e) => setFormData({ ...formData, nameOnCard: e.target.value })}
      />
      <Select
        fullWidth
        required
        label="Expiry Month"
        value={formData.expiryMonth}
        onChange={(e) => setFormData({ ...formData, expiryMonth: e.target.value })}
      >
        {[...Array(13 - 1)].map((_, index) => {
          const month = index + 1
          return <MenuItem key={month} value={month < 10 ? `0${month}` : month}>{month}</MenuItem>
        })}
      </Select>
      <Select
        fullWidth
        required
        label="Expiry Year"
        value={formData.expiryYear < 2000 ? '20' + formData.expiryYear : formData.expiryYear}
        onChange={(e) => setFormData({ ...formData, expiryYear: e.target.value })}
      >
        {[...Array(11)].map((_, index) => {
          const year = new Date().getFullYear() + index
          return <MenuItem key={year} value={year}>{year}</MenuItem>
        })}
      </Select>
      <TextField
        label="CVV"
        required
        fullWidth
        value={formData.cvv}
        onChange={(e) => setFormData({ ...formData, cvv: e.target.value })}
        inputProps={{ maxLength : 4 }}
      />
    </>
  )
}

const createEmptyRow = (params, customerId) => {
  return ({
    id: genRandomUUID(), // temporary random uuid for the purpose of rendering table in refine
    cardNumber: params.cardNumber,
    nameOnCard: params.nameOnCard,
    expiryMonth: params.expiryMonth,
    expiryYear: params.expiryYear,
    cvv: params.cvv,
    customer_id: customerId
  })
}

const validateFunc = (params) => {
  const validateMsg = validateCard(params)
  if (validateMsg !== 'valid') {
    throw new Error(validateMsg)
  } else {
    return validateMsg
  }
}

export function CreditCardPage () {
  const { creditCardData, creditCardLoading } = useOutletContext<CompanyQueries>()
  const [createCreditCard] = useCreateCreditCardMutation()
  const [updateCreditCard] = useUpdateCreditCardMutation()
  const [deleteCreditCard] = useDeleteCreditCardMutation()

  return (
    <TableTemplateNew
      CustomToolbar={CustomToolbar}
      dataTitle={dataTitle}
      columnFields={columnFields}
      initialForm={initialForm}
      upsertFields={upsertFields}
      createEmptyRow={createEmptyRow}
      tableData={creditCardData}
      loading={creditCardLoading}
      createQuery={createCreditCard}
      updateQuery={updateCreditCard}
      deleteQuery={deleteCreditCard}
      permissionProp="cards"
      validateParams={validateFunc}
    />
  )
}

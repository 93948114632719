type ChipColors = 'default' | 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success'

/**
 * Return an mui color scheme for a specified case status
 *
 * @param {string} status The case status string
 * @returns {ChipColors}
 */
export const getStatusColour = (status: string): ChipColors => {
  if (!status) return 'primary'

  if (status.includes('Awaiting')) {
    return 'warning'
  } else if (status.includes('Rejected')) {
    return 'error'
  } else if (status.includes('Closed')) {
    return 'success'
  } else {
    return 'info'
  }
}

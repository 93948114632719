import { Container, Typography } from '@mui/material'
import { SettingsBreadcrumb as Breadcrumb } from '../components/Breadcrumbs'
import { useDocumentTitle } from '@refinedev/react-router-v6'
import { SettingsTabs } from '../components/Tabs'

export function SettingsPage() {
  useDocumentTitle('Settings | Admin Panel')

  return (
    <div className="max-w-[90vw]">
      <Container style={{ display: 'flex', flexFlow: 'column',  margin: '0px', maxWidth: '100%', alignItems: 'start' }}>
        <div className="w-max ml-[50px]">
          <div className="flex justify-start">
            <Breadcrumb />
          </div>
          <div className="flex flex-col gap-[8px] pt-[16px] pb-[16px] justify-center">
            <div className="flex flex-row gap-[10px] items-center">
              <Typography variant="h4">
                Settings
              </Typography>
            </div>
            <Typography variant="body1" sx={{ display: 'flex', gap: '16px' }}>
              Access additional tools and services.
            </Typography>
          </div>
        </div>
        <SettingsTabs />
      </Container>
    </div>
  )
}

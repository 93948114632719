import { memo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Link from '@mui/material/Link'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import HomeIcon from '@mui/icons-material/Home'
import WorkIcon from '@mui/icons-material/Work'

export const CasesBreadcrumb = memo(() => {
  const navigate = useNavigate()
  return (
    <Breadcrumbs sx={{ paddingBottom: '10px', alignSelf: 'start' }}>
      <Link
        underline="hover"
        sx={{ cursor: 'pointer', alignItems: 'center', display: 'flex', gap: '8px' }}
        onClick={() => navigate('/home')}
        color="info"
      >
        <HomeIcon sx={{ width: '16px' }} />
        Home
      </Link>
      <Link
        underline="hover"
        sx={{ cursor: 'pointer', alignItems: 'center', display: 'flex', gap: '8px' }}
        onClick={() => navigate('/cases')}
        color="info"
      >
        <WorkIcon sx={{ width: '16px' }} />
        Cases
      </Link>
    </Breadcrumbs>
  )
})

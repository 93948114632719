import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useLogout, useActiveAuthProvider } from '@refinedev/core'
import DarkModeOutlined from '@mui/icons-material/DarkModeOutlined'
import LightModeOutlined from '@mui/icons-material/LightModeOutlined'
import AppBar from '@mui/material/AppBar'
import Avatar from '@mui/material/Avatar'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Tooltip from '@mui/material/Tooltip'
import TuneIcon from '@mui/icons-material/Tune'
import WorkIcon from '@mui/icons-material/Work'
import Logout from '@mui/icons-material/Logout'
import { useGetIdentity } from '@refinedev/core'
import { HamburgerMenu, RefineThemedLayoutV2HeaderProps } from '@refinedev/mui'
import { ColorModeContext } from '../../contexts/color-mode'
import { SearchContainer } from '../SearchBar'
import { useTheme } from '@mui/material/styles'

type MetaDataProps = {
  name: string
  picture: string
}

type IUser = {
  id: number
  name: string
  avatar: string
  user_metadata: MetaDataProps
}

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({ isSticky = true }) => {
  const theme = useTheme()
  const { mode, setMode } = useContext(ColorModeContext)

  const { data: user } = useGetIdentity<IUser>()
  const authProvider = useActiveAuthProvider()
  const { mutate: mutateLogout } = useLogout({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  })
  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleLogout = () => {
    mutateLogout()
  }

  useEffect(() => {
    // update body class based on color mode, so that the css file can access dark mode
    document.body.classList.remove('light-mode', 'dark-mode')
    document.body.classList.add(mode === 'light' ? 'light-mode' : 'dark-mode')
  }, [mode])

  return (
    <AppBar sx={{ backgroundColor: theme.palette.primary.main, py: '4px' }} position={isSticky ? 'sticky' : 'relative'}>
      <Toolbar>
        <Stack direction="row" width="100%" justifyContent="flex-end" alignItems="center">
          <HamburgerMenu />
          <SearchContainer />
          <Stack direction="row" width="100%" justifyContent="flex-end" alignItems="center">
            <Tooltip title="Toggle Dark Mode">
              <IconButton
                color="inherit"
                onClick={() => {
                  setMode()
                }}>
                {mode === 'dark' ? <LightModeOutlined /> : <DarkModeOutlined />}
              </IconButton>
            </Tooltip>

            {(user?.avatar || user?.name) && (
              <Stack direction="row" gap="16px" alignItems="center" justifyContent="center">
                {user?.name && (
                  <Typography
                    sx={{
                      display: {
                        xs: 'none',
                        sm: 'inline-block',
                      },
                    }}
                    variant="subtitle2">
                    {user?.user_metadata?.name || user?.name}
                  </Typography>
                )}
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2, '&:hover': { opacity: '0.75' } }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <Avatar src={user?.user_metadata?.picture || user?.avatar} alt={user?.name} />
                  </IconButton>
                </Tooltip>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Toolbar>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
            '&::before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: 'background.paper',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={() => navigate('/cases')}>
          <ListItemIcon>
            <WorkIcon fontSize="small" />
          </ListItemIcon>
          Cases
        </MenuItem>
        <MenuItem onClick={() => navigate('/settings')}>
          <ListItemIcon>
            <TuneIcon fontSize="small" />
          </ListItemIcon>
          Settings
        </MenuItem>
        <MenuItem onClick={() => handleLogout()}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
    </AppBar>
  )
}

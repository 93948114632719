import { useState, useEffect, useCallback } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport } from '@mui/x-data-grid'
import { Autocomplete, Checkbox, CircularProgress, FormControl, FormControlLabel, FormGroup, IconButton, TextField, Tooltip } from '@mui/material'
import { Link as MuiLink } from '@mui/material'
import InsertLinkIcon from '@mui/icons-material/InsertLink'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import { TableTemplateNew } from '../components/Table'
import { genRandomUUID } from '../utility/helpers/miscHelpers'
import { formatAsDayMonthYear } from '../utility/helpers/dateHelpers'
import { CompanyQueries } from '../interface/queries/CompanyQueries'
import { useCreateKeywordMutation, useUpdateKeywordMutation, useDeleteKeywordMutation } from '../services/keywordsApi'

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

const dataTitle = 'Keyword'
const initialForm = {
  id: 0,
  name: '',
  subscription_id: 0,
  in_client_reporting: false,
  geolocation_id: 0
}
const columnFields = (openUpdateForm, handleDialogOpen, allowEdit, allowDelete, customerId, subscriptionsCb, geolocations) => {
  return [
    { field: 'url', headerName: 'URL', width: 150, editable: false,
      renderCell: (params) => (
        <div className="overflow-hidden truncate">
          <MuiLink href={params?.row?.url} underline="hover" target="_blank" color="secondary">
            {params.row.url}
          </MuiLink>
        </div>
      )
    },
    { field: 'name', headerName: 'Keyword', width: 200, editable: false },
    { field: 'geolocation_id', headerName: 'Geo-Location', width: 125, editable: false,
      valueGetter: (value) => {
        if (!value) return value
        return value.row.geolocation_id ? geolocations.find(geo => geo.id === value.row.geolocation_id)?.name : ''
      },
      valueFormatter: (value) => {
        return value?.value || ''
      },
      renderCell: (params) => (
        (geolocations?.length === 0) ? (
          <CircularProgress size="0.9rem" color="inherit" />
        ) : (
          <span className="overflow-hidden truncate">
            {params.row.geolocation_id ? geolocations.find(geo => geo.id === params.row.geolocation_id)?.name : ''}
          </span>
        )
      )
    },
    { field: 'in_client_reporting', headerName: 'Client Reporting', width: 125, editable: false, align: 'center',
      renderCell: (params) => (
        <Checkbox disabled checked={params.row.in_client_reporting} />
      )
    },
    { field: 'last_backlinked', headerName: 'Last Backlinked', width: 145, editable: false,
      valueGetter: (value) => {
        if (!value) return value
        return value.row.last_backlinked ? formatAsDayMonthYear(value.row.last_backlinked) : '~'
      },
      valueFormatter: (value) => {
        if (value === null) return ''
        return value.value
      },
      renderCell: (params) => params.row.last_backlinked ? formatAsDayMonthYear(params.row.last_backlinked) : '~',
    },
    { field: 'search_volume', headerName: 'Search Volume', width: 115, editable: false,
      renderCell: (params) => params.row.search_volume,
    },
    { field: 'position_desktop', headerName: 'Position Desktop', width: 125, editable: false,
      renderCell: (params) => params.row.position_desktop,
    },
    { field: 'position_mobile', headerName: 'Position Mobile', width: 125, editable: false,
      renderCell: (params) => params.row.position_mobile,
    },
    { field: 'position_date', headerName: 'Position Date', width: 125, editable: false,
      renderCell: (params) => params.row.position_date,
    },
    { field: 'backlinks', headerName: 'Backlinks', width: 90,
      renderCell: (params) => (
        <Tooltip title="Manage backlinks associated with this product.">
          <Link to={`${params?.row?.id}/backlinks`} rel="noopener noreferrer">
            <IconButton color="warning" onClick={() => openUpdateForm(params)} disabled={!allowEdit} >
              <InsertLinkIcon />
            </IconButton>
          </Link>
        </Tooltip>
      )
    },
    { field: 'edit', headerName: 'Edit', disableExport: true, width: 75,
      renderCell: (params) => (
        <IconButton color="info" onClick={() => openUpdateForm(params)} disabled={!allowEdit} >
          <EditIcon />
        </IconButton>
      ),
    },
    { field: 'delete', headerName: 'Delete', disableExport: true, width: 75,
      renderCell: (params) => (
        <IconButton color="error" onClick={() => handleDialogOpen(params, 'Delete')} disabled={!allowDelete} >
          <DeleteIcon />
        </IconButton>
      ),
    }
  ]
}

const upsertFields = (create, formData, setFormData, customerId, geolocations) => {
  return (
    <>
      <TextField
        label="Keyword"
        fullWidth
        value={formData.name}
        onChange={(e) => setFormData({ ...formData, name: e.target.value, updated_at: new Date().toISOString() })}
        required
      />
      <FormGroup>
        <FormControlLabel control={
            <Checkbox
              checked={formData.in_client_reporting}
              onChange={(e) => setFormData({ ...formData, in_client_reporting: e.target.checked, updated_at: new Date().toISOString() })}
            />
          }
        label="In Client Reporting?"
      />
      </FormGroup>
      <FormControl fullWidth>
        <Autocomplete
          disableClearable
          options={geolocations}
          getOptionLabel={(option) => option.canonical_name}
          value={geolocations.find((geo) => geo.id === formData.geolocation_id) || null}
          onChange={(event, value) => setFormData({ ...formData, geolocation_id: value ? value.id : '' })}
          filterOptions={(options, { inputValue }) =>
            options.filter((option) =>
              option.canonical_name.toLowerCase().includes(inputValue.toLowerCase())
            ).slice(0, 10) // Limiting search results to 10 to improve performance
          }
          renderInput={(params) => (
            <TextField
              {...params}
              fullWidth
              label="Geo-Location"
            />
          )}
        />
      </FormControl>
    </>
  )
}

const createEmptyRow = (params) => {
  return ({
    id: genRandomUUID(),
    name: params.name,
    geolocation_id: params.geolocation_id,
    subscription_id: params.subscription_id,
    in_client_reporting: params.in_client_reporting
  })
}

export function KeywordsPage () {
  const { keywordsData, keywordsLoading, backlinksCustomerData } = useOutletContext<CompanyQueries>()
  const [createKeyword] = useCreateKeywordMutation()
  const [updateKeyword] = useUpdateKeywordMutation()
  const [deleteKeyword] = useDeleteKeywordMutation()

  const [combinedKeyword, setCombinedKeyword] = useState([])

  const combinedKeywordData = useCallback(() => {
    const backlinkMapping = backlinksCustomerData?.reduce((acc, { seo_keyword_id, last_backlinked }) => {
      if (!acc[seo_keyword_id] || new Date(acc[seo_keyword_id]) < new Date(last_backlinked)) {
          acc[seo_keyword_id] = last_backlinked
      }
      return acc
    }, {})
    const updatedKeywords = keywordsData?.map(keyword => ({
      ...keyword,
      last_backlinked: backlinkMapping[keyword.id] || null
    }))
  
    return updatedKeywords || []
  }, [backlinksCustomerData, keywordsData])

  useEffect(() => {
    const combined = combinedKeywordData()
    setCombinedKeyword(combined)
  }, [keywordsData, backlinksCustomerData, combinedKeywordData])

  return (
    <TableTemplateNew
      CustomToolbar={CustomToolbar}
      dataTitle={dataTitle}
      columnFields={columnFields}
      initialForm={initialForm}
      upsertFields={upsertFields}
      createEmptyRow={createEmptyRow}
      tableData={combinedKeyword}
      loading={keywordsLoading}
      createQuery={createKeyword}
      updateQuery={updateKeyword}
      deleteQuery={deleteKeyword}
      permissionProp="keywords"
      pinLeft={['name']}
      pinRight={['backlinks', 'edit']}
      goBackLevel={2}
    />
  )
}

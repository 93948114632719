import { api } from './api'
import { queries } from '../utility'
import { sortCases } from '../utility/helpers/caseHelpers'
import { setCases, setAllCases, setOutstandingCases, deleteOutstandingCase,
  setSubmittedCases, setTeamCases, setPartialCases,
  setRejectedCases, setClosedCases, updateClosedCases } from '../store/global'
import { store } from '../store'

export const casesApi = api.injectEndpoints({
  reducerPath: 'casesApi',
  endpoints: (build) => ({
    getCases: build.query({
      queryFn: async (arg) => {
        const customerId = arg
        const response = await queries.getCases(customerId)
        store.dispatch(setCases(response))
        return { data: response }
      },
    }),
    createCase: build.mutation({
      queryFn: async (arg) => {
        const { body } = arg
        const response = await queries.createCase(body)
        if (response) {
          store.dispatch(
            casesApi.util.updateQueryData('getCases', body.customer_id, (draft) => {
              draft.push(response[0])
            })
          )
        }
        return { data: response }
      },
    }),
    updateCase: build.mutation({
      queryFn: async (arg) => {
        const { itemId, body } = arg
        const response = await queries.updateCase(itemId, body)
        store.dispatch(
          casesApi.util.updateQueryData('getCases', body.customer_id, (draft) => {
            draft.forEach(key => {
              if (Number(key.id) === Number(itemId)) {
                Object.assign(key, body)
              }
            })
          })
        )
        return { data: response }
      },
    }),
    deleteCase: build.mutation({
      queryFn: async (arg) => {
        const { itemId, customerId } = arg
        const response = await queries.delCase(itemId)
        store.dispatch(
          casesApi.util.updateQueryData('getCases', customerId, (draft) => {
            const updated = draft.filter(d => d.id !== itemId)
            return updated
          })
        )
        return { data: response }
      },
    }),
    submitCase: build.mutation({
      queryFn: async (arg) => {
        const { body, status, userEmail } = arg
        const response = await queries.submitCase(body, status)
        if (!response) return false
        const updatedBody = { ...body, receipt_data: response?.response ? response?.response : null }
        // If submitted a new case, change status to Awaiting Approval
        if (!status) {
          store.dispatch(
            casesApi.util.updateQueryData('getCases', updatedBody.customer_id, (draft) => {
              const index = draft.findIndex(d => d.id === updatedBody.id)
              if (index !== -1) {
                draft[index].status = 'Awaiting Approval'
              }
            })
          )
        } else {
        // If approved/rejected, change status appropiately and store receipts
          if (userEmail) { // if submitting case from case summary screen
            store.dispatch(
              casesApi.util.updateQueryData('getOutstandingCases', userEmail, (draft) => {
                const updated = draft.filter(d => d.id !== updatedBody.id)
              })
            )
            store.dispatch(
              casesApi.util.updateQueryData('getClosedCases', undefined, (draft) => {
                draft.push(updatedBody)
              })
            )
          } else { // from customer case page
            store.dispatch(
              casesApi.util.updateQueryData('getCases', updatedBody.customer_id, (draft) => {
                const index = draft.findIndex(d => d.id === updatedBody.id)
                if (index !== -1) {
                  draft[index].status = status
                  draft[index].receipt_data = updatedBody.receipt_data
                }
              })
            )
          }
        }
        return { data: response }
      },
    }),
    getAllCases: build.query({
      queryFn: async (arg) => {
        const response = await queries.getAllCases()
        const sorted = sortCases(response)
        store.dispatch(setAllCases(sorted))
        return { data: sorted }
      },
    }),
    getOutstandingCases: build.query({
      queryFn: async (arg) => {
        const email = arg
        const response = await queries.getOutstandingCases(email)
        const sorted = sortCases(response)
        store.dispatch(setOutstandingCases(sorted))
        return { data: sorted }
      },
    }),
    getSubmittedCases: build.query({
      queryFn: async (arg) => {
        const email = arg
        const response = await queries.getSubmittedCases(email)
        const sorted = sortCases(response)
        store.dispatch(setSubmittedCases(sorted))
        return { data: sorted }
      },
    }),
    getClosedCases: build.query({
      queryFn: async (arg) => {
        const response = await queries.getClosedCases()
        const sorted = sortCases(response)
        store.dispatch(setClosedCases(sorted))
        return { data: sorted }
      },
    }),
    getTeamCases: build.query({
      queryFn: async (arg) => {
        const email = arg
        const response = await queries.getTeamCases(email)
        store.dispatch(setTeamCases(response))
        return { data: response }
      },
    }),
    getPartialCases: build.query({
      queryFn: async (arg) => {
        const email = arg
        const response = await queries.getPartialCases(email)
        const sorted = sortCases(response)
        store.dispatch(setPartialCases(sorted))
        return { data: sorted }
      },
    }),
    getRejectedCases: build.query({
      queryFn: async (arg) => {
        const email = arg
        const response = await queries.getRejectedCases(email)
        const sorted = sortCases(response)
        store.dispatch(setRejectedCases(sorted))
        return { data: sorted }
      },
    }),
    markCaseActioned: build.mutation({
      queryFn: async (arg) => {
        const { caseNo } = arg
        const response = await queries.markCaseActioned(caseNo)
        store.dispatch(updateClosedCases({
          caseNo,
          property: 'manually_actioned',
          value: true
        }))
        store.dispatch(
          casesApi.util.updateQueryData('getClosedCases', undefined, (draft) => {
            const index = draft.findIndex(d => d.case_number === caseNo)
            if (index !== -1) {
              draft[index].manually_actioned = true
            }
          })
        )
        return { data: response }
      },
    }),
  }),
})

export const {
  useGetCasesQuery,
  useCreateCaseMutation,
  useUpdateCaseMutation,
  useDeleteCaseMutation,
  useSubmitCaseMutation,
  useGetAllCasesQuery,
  useGetOutstandingCasesQuery,
  useGetSubmittedCasesQuery,
  useGetClosedCasesQuery,
  useGetTeamCasesQuery,
  useGetPartialCasesQuery,
  useGetRejectedCasesQuery,
  useMarkCaseActionedMutation
} = casesApi

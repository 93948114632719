import { useState, useEffect, memo } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../interface/RootState'
import { useOutletContext, useParams } from 'react-router-dom'
import type { CaseQueries } from '../../interface/queries/CaseQueries'
import { ApprovalDialog, DataDialog, ReceiptDialog } from './Dialogs'
import { SelectCaseType, Searchbar } from './Filters'
import { emailToName } from '../../utility/helpers/miscHelpers'
import { formatAsDayMonthYear } from '../../utility/helpers/dateHelpers'
import Alert, { AlertProps } from '@mui/material/Alert'
import { Box, Chip, Container, Divider, Fade, IconButton, Link, List, ListItem, Pagination, TextField, Typography, Slide, Snackbar, Tooltip } from '@mui/material'
import { CaseTypeIcon } from '../Icons'
import { ClosedCases } from './ClosedCases'
import { PartialCases } from './PartialCases'
import { RejectedCases } from './RejectedCases'
import { useSubmitCaseMutation } from '../../services/casesApi'

export function OutstandingCases() {
  // Pagination
  const [page, setPage] = useState(1)
  const itemsPerPage = 8
  const startIndex = (page - 1) * itemsPerPage
  const endIndex = page * itemsPerPage

  const [loadingApproval, setLoadingApproval] = useState<boolean>(false)
  const [loadingRejection, setLoadingRejection] = useState<boolean>(false)
  const [receiptData, setReceiptData] = useState({})
  const [selectedCase, setSelectedCase] = useState({})
  const [openApproval, setOpenApproval] = useState<boolean>(false)
  const [openGeneric, setOpenGeneric] = useState<boolean>(false)
  const [openReceipts, setOpenReceipts] = useState<boolean>(false)

    //* Extract RTK Queries
  const { outstandingCases } = useOutletContext<CaseQueries>()
  const userEmail = useSelector((store: RootState) => store.global.user.email)
  const [submitCase] = useSubmitCaseMutation()

  const [filteredCases, setFilteredCases] = useState(outstandingCases)

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)

  const handleCloseSnackbar = () => setSnackbar(null)

  const { caseNo: caseIdURL } = useParams()

  //* Handlers
  const updateHandler = async (closeStatus, caseData) => {
    try {
      let status
    
      if (!closeStatus) return
    
      if (closeStatus === 'Approved') {
        status = 'Closed'
        setLoadingApproval(true)
      } else if (closeStatus === 'Rejected') {
        status = 'Approval Rejected'
        setLoadingRejection(true)
      }
    
      const actionRes = await submitCase({ body: caseData, status, userEmail }).unwrap()
    
      if (!actionRes) {
        throw new Error('Could not action case.')
      }

      if (actionRes && actionRes?.response?.length === 0) {
        // If an intermediate approval, just show snackbar
        setSnackbar({ children: `Case passed on to next authority in chain: ${emailToName(caseData?.submitted_manager)}`, severity: 'success' })
      } else {
        // If a final approval, show receipts
        setReceiptData(actionRes.response)
        setSnackbar({ children: 'Case ' + closeStatus, severity: 'success' })
        if (closeStatus === 'Approved') {
          setOpenReceipts(true)
        }
      }
      const filtered = outstandingCases.filter(item => item.id !== caseData.id)
      setFilteredCases(filtered)
    } catch (error) {
      setSnackbar({ children: `Error actioning case #${caseData.case_number}.`, severity: 'error' })
      console.error('Could not action case: ' + caseData.case_number, error)
    } finally {
      setLoadingApproval(false)
      setLoadingRejection(false)
      setOpenApproval(false)
    }
  }

  const handleClickOpen = (val) => {
    setSelectedCase(val)
    setOpenApproval(true)
  }
  const handleClickGeneric = (val) => {
    setSelectedCase(val)
    setOpenGeneric(true)
  }
  const handleOpenReceipt = (val) => {
    setSelectedCase(val)
    setReceiptData(val?.receipt_data)
    setOpenReceipts(true)
  }
  const handleApprovalClose = (closeStatus, caseData, setCaseData, approvalMsg) => {
    const updatedCases = { ...caseData, approval_msg: approvalMsg }
    if (approvalMsg) {
      setCaseData(updatedCases)
    }
    updateHandler(closeStatus, updatedCases)
  }

  // Automatically display approval if clicking in on from the email
  useEffect(() => {
    if (caseIdURL && outstandingCases?.length !== 0) {
      const foundCase = outstandingCases.find(caseItem => caseItem?.case_number === caseIdURL)
      if (foundCase) { 
        setOpenApproval(true)
        setSelectedCase(foundCase)
      }
    }
  }, [caseIdURL, outstandingCases])

  return (
    <>
      <Slide in direction="left">
        <Fade in>
          <Container maxWidth="lg" style={{ padding: '0px', paddingTop: '24px', paddingLeft: '16px' }}>
            <Typography variant="h5" gutterBottom>
              Approvals
            </Typography>
            <div className="flex flex-row items-center justify-between">
              <Typography component="div" style={{ opacity: '70%' }}>
                You have <Chip size="small" label={outstandingCases?.length || 0} color="primary" /> outstanding case{outstandingCases?.length === 1 ? '' : 's'} to approve or deny.
              </Typography>
              {(outstandingCases?.length > 0) && (
                <div className="flex flex-row">
                  <Searchbar
                    originalCases={outstandingCases}
                    filteredCases={filteredCases}
                    setFilteredCases={setFilteredCases}
                  />
                  <SelectCaseType
                    originalCases={outstandingCases}
                    filteredCases={filteredCases}
                    setFilteredCases={setFilteredCases}
                  />
                </div>
              )}
            </div>
            <Typography component="div" style={{ opacity: '70%' }}>
              {filteredCases?.length !== outstandingCases?.length ? filteredCases?.length + ' filtered cases' : ''}
            </Typography>

            {(filteredCases?.length > 0) ? (
              <>
              {Object.keys(filteredCases).slice(startIndex, endIndex).map((key, idx) => (
                <List
                  key={idx}
                  sx={{
                      listStyleType: 'disc',
                      listStylePosition: 'inside'
                    }}
                  >
                    <ListItem sx={{ display: 'list-item' }}>
                      <Link
                        key={idx}
                        component="button"
                        variant="body2"
                        onClick={() => handleClickOpen(filteredCases[key])}
                      >
                        <Typography variant="subtitle1" component="span">{filteredCases[key]?.case_number}</Typography>
                      </Link>
                      &nbsp;
                      <span>
                        <CaseTypeIcon caseData={filteredCases[key]} />
                        <Typography variant="body2" component="span">
                          &nbsp;{filteredCases[key]?.customer_name} 
                        </Typography>
                        <Typography variant="body2" component="span" sx={{ opacity: '75%' }}>
                          &nbsp; From {emailToName(filteredCases[key]?.case_originator)}
                        </Typography>
                        <Tooltip title={formatAsDayMonthYear(filteredCases[key]?.updated_at)}>
                          <Typography variant="body1" component="span" color="default" sx={{ opacity: '60%' }}>
                            &nbsp;({Math.round(((new Date().getTime() as number) - (new Date(filteredCases[key]?.updated_at).getTime() as number)) / (1000 * 60 * 60 * 24))} days old)
                          </Typography>
                        </Tooltip>
                      </span>
                      {userEmail && (
                        <>
                          {(userEmail === process.env.REACT_APP_CASE_SYSTEM_APPROVER) && (
                            <Tooltip title="Chargebee Link">
                              <Link target="_blank" href={`https://disruptdigital.chargebee.com/d/subscriptions/${filteredCases[key]?.subscription_id}#summary`}>
                                <IconButton color="primary">
                                  <img className="flex w-[22px] max-h-[22px] items-center opacity-[70%] justify-center self-center" src={'/images/Chargebee.svg'} />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          )}
                        </>
                      )}
                    </ListItem>
                  </List>
                ))}
                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                  <Pagination count={Math.ceil((Object.keys(filteredCases)?.length || 0) / itemsPerPage)} page={page} onChange={(event, value) => setPage(value)} />
                </Box>
              </>
            ) : (
              <div className="flex flex-row items-center justify-between">
                <Typography style={{ opacity: '70%' }}>
                  You have no outstanding cases to action.
                </Typography>
              </div>
            )}
            <Divider sx={{ paddingBottom: '20px' }} />
            {userEmail && (
              <>
                {(userEmail === process.env.REACT_APP_CASE_SYSTEM_APPROVER) && (
                  <>
                    <ClosedCases
                      handleOpenDialog={handleClickGeneric}
                      handleOpenReceipt={handleOpenReceipt}
                    />
                    <Divider sx={{ paddingBottom: '20px' }} />
                  </>
                )}
              </>
            )}
            <PartialCases
              handleOpenDialog={handleClickGeneric}
              handleOpenReceipt={handleOpenReceipt}
            />
            <Divider sx={{ paddingBottom: '20px' }} />
            <RejectedCases
              handleOpenDialog={handleClickGeneric}
              handleOpenReceipt={handleOpenReceipt}
            />
          </Container>
        </Fade>
      </Slide>
      <ApprovalDialog
        caseData={selectedCase}
        setCaseData={setSelectedCase}
        open={openApproval}
        setOpen={setOpenApproval}
        handleClose={handleApprovalClose}
        loadingApproval={loadingApproval}
        loadingRejection={loadingRejection}
      />
      <DataDialog
        data={selectedCase}
        open={openGeneric}
        handleClose={() => setOpenGeneric(false)}
      />
      <ReceiptDialog
        caseData={selectedCase}
        receiptData={receiptData}
        open={openReceipts}
        handleClose={() => setOpenReceipts(false)}
      />
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert
            {...snackbar}
            onClose={handleCloseSnackbar}
            variant="filled"
          />
        </Snackbar>
      )}
    </>
  )
}

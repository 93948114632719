import { Button, Card, CardContent, Typography } from '@mui/material'
import KeyIcon from '@mui/icons-material/Key'

export const OTPCard = ({
  setOpenDialogOTP
}) => {
  return (
    <Button onClick={() => setOpenDialogOTP(true)} sx={{ textTransform: 'none' }}>
      <Card sx={{ maxWidth: '300px' }}>
        <CardContent>
          <div className="flex gap-[16px] flex-row items-center">
            <KeyIcon />
            <Typography variant="h6" align="left">
              Generate OTP
            </Typography>
          </div>
          <Typography variant="body1" align="left" color="text.secondary">
            Generate an OTP for a customer without a Hubspot Company.
          </Typography>
        </CardContent>
      </Card>
    </Button>
  )
}

import { useState, useEffect } from 'react'
import { Box, Chip, IconButton, InputAdornment, Link, Paper, Pagination,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField,
  Tooltip, Typography, Skeleton, Stack, Theme, useMediaQuery } from '@mui/material'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import { formatAsCurrency } from '../../../../utility/helpers/currencyHelpers'
import { timestampToDate } from '../../../../utility/helpers/dateHelpers'
import { InvoiceSearch } from './InvoiceSearch'

interface Invoice {
  id: string
  total: number
  base_currency_code: string
  status: string
  amount_paid?: number
  amount_adjusted?: number
  issued_credit_notes: {
    cn_id: string
    cn_total: number
    cn_status: string
  }[]
}

export function InvoiceTable ({
  invoices,
  selectedInv = null,
  setSelectedInv = null,
  setOpenInv = null,
  formData,
  setFormData
}) {
  const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('xl'))

  const [invFiltered, setInvFiltered] = useState(null)

  //* PAGINATIONS
  const [page, setPage] = useState(1)
  const itemsPerPage = 10
  const startIndex = (page - 1) * itemsPerPage
  const endIndex = page * itemsPerPage

  //* FUNCTIONS
  const calcMaxCredit = (key) => {
    const invoiceList = invFiltered || []
    const invoice = Object.values<Invoice>(invoiceList).find(val => val.id === invoiceList[key]?.id)
    // Total - Paid
    return invoice ? (invoice.total - invoice.amount_paid) / 100 : 0
  }

  const calcMaxRefund = (key) => {
    const invoiceList = invFiltered || []
    const invoice = Object.values<Invoice>(invoiceList).find(val => val.id === invoiceList[key]?.id)
    //  Paid - Refunds
    return invoice ? (invoice.amount_paid - invoice.issued_credit_notes.filter(note => note.cn_status === 'refunded').reduce((total, note) => total + note.cn_total, 0)) / 100 : 0
  }

    //* HANDLERS
  const handleViewInvoice = (invoice: Invoice, selectedRow = false): void => {
    if (isLargeScreen || !isLargeScreen && !selectedRow) {
      setOpenInv(true)
      setSelectedInv(invoice)
    }
  }
  const handleMaximiseCredit = (key) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      invoice_credits: {
        ...prevFormData?.invoice_credits,
        [invFiltered[key].id]: calcMaxCredit(key)
      }
    }))
  }
  const handleMaximiseRefund = (key) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      invoice_refunds: {
        ...prevFormData?.invoice_refunds,
        [invFiltered[key].id]: calcMaxRefund(key)
      }
    }))
  }

  useEffect(() => {
    if (invoices) {
      setInvFiltered(invoices[0][formData?.subscription_id])
    }
  }, [invoices, formData])

  // reset page on filtering
  useEffect(() => {
    setPage(1)
  }, [invFiltered])

  return (
    <>
      {(invoices && invoices?.length !== 0) ? (
        <>
          {(invFiltered && invoices[0][formData?.subscription_id]) ? (
            <>
              <InvoiceSearch
                invoices={invoices[0][formData?.subscription_id]}
                setInv={setInvFiltered}
              />
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Invoice #</TableCell>
                      <TableCell>Status</TableCell>
                      <Tooltip title="The total amount already paid against the invoice">
                        <TableCell sx={{ width: '120px', textAlign: 'center' }}>
                          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Paid</div>
                        </TableCell>
                      </Tooltip>
                      <TableCell sx={{ width: '120px', textAlign: 'center' }}>
                        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Due</div>
                      </TableCell>
                      <Tooltip title="The credits applied to the invoice">
                        <TableCell sx={{ width: '120px', textAlign: 'center' }}>
                          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Credits</div>
                        </TableCell>
                      </Tooltip>
                      <Tooltip title="The refunds issued to the customer">
                        <TableCell sx={{ width: '120px', textAlign: 'center' }}>
                          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Refunds</div>
                        </TableCell>
                      </Tooltip>
                      <Tooltip title="This includes any discounts applied to the invoice">
                        <TableCell sx={{ width: '120px', textAlign: 'center' }}>
                          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Discount</div>
                        </TableCell>
                      </Tooltip>
                      <Tooltip title="The date the invoice was generated at">
                        <TableCell sx={{ width: '120px', textAlign: 'center' }}>
                          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Generated</div>
                        </TableCell>
                      </Tooltip>
                      <Tooltip title="The date the invoice is due">
                        <TableCell sx={{ width: '120px', textAlign: 'center' }}>
                          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Due</div>
                        </TableCell>
                      </Tooltip>
                      <Tooltip title="Enter any credits/refunds you would like applied to the invoice. Any number exceeding your limit will require approval by your manager.">
                        <TableCell sx={{ width: '130px', textAlign: 'center' }}>
                          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>Credit/Refund</div>
                        </TableCell>
                      </Tooltip>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Object.keys(invFiltered).slice(startIndex, endIndex).map((key, index) => (
                      <TableRow
                        hover={true}
                        selected={selectedInv === invFiltered[key]}
                        onClick={() => handleViewInvoice(invFiltered[key], true)}
                        key={key}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      >
                        <TableCell component="th" scope="row">
                          <Link sx={{ width: '80px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', display: 'block', cursor: 'pointer' }} onClick={() => handleViewInvoice(invFiltered[key])}>
                            #{invFiltered[key]?.id}
                          </Link>
                        </TableCell>
                        <TableCell>
                          <Chip label={invFiltered[key]?.status.toUpperCase().replaceAll('_', ' ').replace('PAYMENT ', '')} color={invFiltered[key]?.status === 'paid' ? 'success' : 'error'} variant="outlined" />
                        </TableCell>
                        <TableCell sx={{ width: '120px', textAlign: 'center' }}>
                          ${formatAsCurrency(invFiltered[key]?.amount_paid)}
                        </TableCell>
                        <TableCell sx={{ width: '120px', textAlign: 'center' }}>
                          ${formatAsCurrency(invFiltered[key]?.amount_due)}
                        </TableCell>
                        <TableCell sx={{ width: '120px', textAlign: 'center' }}>
                          -${formatAsCurrency(invFiltered[key]?.amount_adjusted)}
                        </TableCell>
                        <TableCell sx={{ width: '120px', textAlign: 'center' }}>
                          ${formatAsCurrency(invFiltered[key]?.line_items.reduce((sum, item) => sum + (item?.discount_amount || 0), 0))}
                        </TableCell>
                        <TableCell sx={{ width: '120px', textAlign: 'center' }}>
                          {timestampToDate(invFiltered[key]?.generated_at, invFiltered[key]?.tax_origin?.country)}
                        </TableCell>
                        <TableCell sx={{ width: '120px', textAlign: 'center' }}>
                          {timestampToDate(invFiltered[key]?.due_date, invFiltered[key]?.tax_origin?.country)}
                        </TableCell>
                        <TableCell sx={{ width: '130px', padding: '0px', textAlign: 'center' }}>
                          <TextField
                            key={`credit-${invFiltered[key]?.id}`} 
                            sx={{ width: '130px', padding: '0px' }}
                            size="small"
                            placeholder="Credit"
                            InputProps={{
                              endAdornment: <InputAdornment position="start">
                                <Tooltip title="Maximise Credit">
                                  <IconButton sx={{ padding: '0px '}} onClick={() => handleMaximiseCredit(key)}><ArrowUpwardIcon /></IconButton>
                                </Tooltip>
                              </InputAdornment>,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              inputProps: { min: 0, max: calcMaxCredit(key), step: 0.01 }
                            }}
                            value={formData?.invoice_credits ? formData?.invoice_credits[invFiltered[key]?.id] : ''}
                            onChange={(e) => setFormData(prevFormData => ({
                              ...prevFormData,
                              invoice_credits: {
                                ...prevFormData?.invoice_credits,
                                [invFiltered[key].id]: e.target.value
                              },
                              invoice_refunds: {
                                ...prevFormData?.invoice_refunds,
                                [invFiltered[key].id]: prevFormData?.invoice_refunds && prevFormData?.invoice_refunds[invFiltered[key]?.id] ? prevFormData?.invoice_refunds[invFiltered[key]?.id] : ''
                              }
                            }))}
                          />
                          <TextField
                            key={`refund-${invFiltered[key]?.id}`} 
                            sx={{ width: '130px', padding: '0px' }}
                            size="small"
                            placeholder="Refund"
                            InputProps={{
                              endAdornment: <InputAdornment position="start">
                                <Tooltip title="Maximise Refund">
                                  <IconButton sx={{ padding: '0px '}} onClick={() => handleMaximiseRefund(key)}><ArrowUpwardIcon /></IconButton>
                                </Tooltip>
                              </InputAdornment>,
                              startAdornment: <InputAdornment position="start">$</InputAdornment>,
                              inputProps: { min: 0, max: calcMaxRefund(key), step: 0.01 }
                            }}
                            value={formData?.invoice_refunds ? formData?.invoice_refunds[invFiltered[key]?.id] : ''}
                            onChange={(e) => setFormData(prevFormData => ({
                              ...prevFormData,
                              invoice_refunds: {
                                ...prevFormData?.invoice_refunds,
                                [invFiltered[key].id]: e.target.value
                              },
                              invoice_credits: {
                                ...prevFormData?.invoice_credits,
                                [invFiltered[key].id]: prevFormData?.invoice_credits && prevFormData?.invoice_credits[invFiltered[key].id] ? prevFormData?.invoice_credits[invFiltered[key].id] : ''
                              }
                            }))}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                <Pagination count={Math.ceil((Object.keys(invFiltered)?.length || 0) / itemsPerPage)} page={page} onChange={(event, value) => setPage(value)} />
              </Box>
            </>
          ) : (
            <Typography style={{ opacity: '60%', width: '652px' }}>
              No active invoices were found.
            </Typography>
          )}
        </>
      ) : (
        <Stack spacing={1}>
          <Skeleton variant="rounded" width={"auto"} height={60} animation="wave" />
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} animation="wave" />
          <Skeleton variant="text" width={"50%"} sx={{ fontSize: '1rem' }} animation="wave" />
        </Stack>
      )}
    </>
  )
}

import { Dialog, DialogTitle, DialogContent, Divider,
  DialogContentText, DialogActions, Button } from '@mui/material'
import { CaseDataFormatted } from './CaseDataFormatted'
import { Copied } from '../../Popover'

export function DataDialog ({
  data,
  open,
  handleClose
}) {
  return (
    <Dialog
      open={open}
      onClose={() => handleClose(null)}
    >
      <DialogTitle sx={{ alignItems: 'center' }}>
        Case #{data?.case_number} <Copied toCopy={data?.case_number} />
      </DialogTitle>
      <Divider/>
      <DialogContent>
        <DialogContentText>
          Case Summary
        </DialogContentText>
        <CaseDataFormatted caseData={data} />
      </DialogContent>
      <Divider/>
      <div className="flex">
        <DialogActions>
          <Button onClick={() => handleClose()}>
            CLOSE
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

export const formatDate = (dateString) => {
  if (!dateString) return null
  const date = new Date(dateString)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  const formattedDay = day < 10 ? `0${day}` : `${day}`
  const formattedMonth = month < 10 ? `0${month}` : `${month}`

  return `${formattedDay}/${formattedMonth}/${year}`
}

export const formatGoogleAdsId = (id) => {
  const digits = id.replace(/\D/g, '')
  if (digits.length >= 10) {
    const firstGroup = digits.slice(0, 3)
    const secondGroup = digits.slice(3, 6)
    const thirdGroup = digits.slice(6, 10)
    return `${firstGroup}-${secondGroup}-${thirdGroup}`
  } else {
    return digits.match(/\d{1,3}/g)?.join('-') || ''
  }
}

export const genRandomUUID = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    const r = Math.random() * 16 | 0,
      v = c == 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

//* Credit card helper functions
export const genericCard = {
  type: 'generic',
  pattern: /^\d+$/,
  blocks: [
    [4, 4, 4, 4]
  ],
  code: {
    name: 'CVV',
    size: 3
  },
  background: 'bg-gradient-to-rl from-orange-600 to-orange-400'
}

export const cardFormats = [
  {
    type: 'uatp',
    pattern: /^(?!1800)1\d{0,14}/,
    blocks: [
      [4, 5, 6]
    ],
    code: {
      name: 'CCV',
      size: 3
    }
  },
  {
    type: 'amex',
    pattern: /^3[47]\d{0,13}/,
    blocks: [
      [4, 6, 5]
    ],
    code: {
      name: 'CID',
      size: 4
    },
    background: 'bg-gradient-to-l from-gray-900 via-slate-500 to-gray-900 dark:bg-gradient-to-tr dark:from-base-900 dark:to-base-600'
  },
  {
    type: 'discover',
    pattern: /^(?:6011|65\d{0,2}|64[4-9]\d?)\d{0,12}/,
    blocks: [
      [4, 4, 4, 4]
    ],
    code: {
      name: 'CID',
      size: 3
    },
    background: 'bg-gradient-to-l from-cyan-800 via-cyan-600 to-cyan-800 dark:bg-gradient-to-tr dark:from-base-900 dark:to-base-600'
  },
  {
    type: 'diners',
    name: 'Diners Club',
    pattern: /^3(?:0([0-5]|9)|[689]\d?)\d{0,11}/,
    blocks: [
      [4, 6, 4]
    ],
    code: {
      name: 'CVV',
      size: 3
    },
    background: 'bg-gradient-to-l from-gray-900 via-slate-500 to-gray-900 dark:bg-gradient-to-tr dark:from-base-900 dark:to-base-600'
  },
  {
    type: 'mastercard',
    pattern: /^(5[1-5]\d{0,2}|22[2-9]\d{0,1}|2[3-7]\d{0,2})\d{0,12}/,
    blocks: [
      [4, 4, 4, 4]
    ],
    code: {
      name: 'CVC',
      size: 3
    },
    background: 'bg-gradient-to-tr from-orange-600 to-orange-400 dark:bg-gradient-to-tr dark:from-base-900 dark:to-base-600'
  },
  {
    type: 'dankort',
    pattern: /^(5019|4175|4571)\d{0,12}/,
    blocks: [
      [4, 4, 4, 4]
    ],
    code: {
      name: 'CCV',
      size: 3
    }
  },
  {
    type: 'instapayment',
    pattern: /^63[7-9]\d{0,13}/,
    blocks: [
      [4, 4, 4, 4]
    ],
    code: {
      name: 'CCV',
      size: 3
    }
  },
  {
    type: 'jcb15',
    pattern: /^(?:2131|1800)\d{0,11}/,
    blocks: [
      [4, 6, 5]
    ],
    code: {
      name: 'CVV',
      size: 3
    }
  },
  {
    type: 'jcb',
    pattern: /^(?:35\d{0,2})\d{0,12}/,
    blocks: [
      [4, 4, 4, 4]
    ],
    code: {
      name: 'CVV',
      size: 3
    },
    background: 'bg-gradient-to-l from-gray-900 via-slate-500 to-gray-900 dark:bg-gradient-to-tr dark:from-base-900 dark:to-base-600'
  },
  {
    type: 'maestro',
    pattern: /^(?:5[0678]\d{0,2}|6304|67\d{0,2})\d{0,12}/,
    blocks: [
      [4, 4, 4, 4],
      [4, 4, 5],
      [4, 6, 5],
      [4, 4, 4, 4, 3]
    ],
    code: {
      name: 'CVC',
      size: 3
    },
    background: 'bg-gradient-to-tr from-orange-600 to-orange-400 dark:bg-gradient-to-tr dark:from-base-900 dark:to-base-600'
  },
  {
    type: 'mir',
    pattern: /^220[0-4]\d{0,12}/,
    blocks: [
      [4, 4, 4, 4]
    ],
    code: {
      name: 'CCV',
      size: 3
    }
  },
  {
    type: 'visa',
    pattern: /^4\d{0,15}/,
    blocks: [
      [4, 4, 4, 4],
      [4, 4, 4, 4, 3]
    ],
    code: {
      name: 'CVV',
      size: 3
    },
    background: 'bg-gradient-to-tr from-slate-700 to-slate-400 dark:bg-gradient-to-tr dark:from-base-900 dark:to-base-600'
  },
  {
    type: 'unionPay',
    pattern: /^(62|81)\d{0,14}/,
    blocks: [
      [4, 4, 4, 4],
      [6, 13]
    ],
    code: {
      name: 'CVN',
      size: 3
    },
    background: 'bg-gradient-to-l from-gray-900 via-slate-500 to-gray-900 dark:bg-gradient-to-tr dark:from-base-900 dark:to-base-600'
  }
]

const luhnCheck = ((arr) => {
  return function (ccNum) {
    let len = ccNum.length
    let bit = 1
    let sum = 0
    let val
    while (len) {
      val = parseInt(ccNum.charAt(--len), 10)
      sum += (bit ^= 1) ? arr[val] : val
    }
    return sum && sum % 10 === 0
  }
})([0, 2, 4, 6, 8, 1, 3, 5, 7, 9])

const findCardByNumber = (cardNumber) => (
  cardFormats.find((item) => item.pattern.test(cardNumber))
)

const blockSum = (block) => (
  block.reduce((acc, current) => acc + current, 0)
)

export const getCardDetails = (cardNumber) => ({
  ...genericCard,
  ...(findCardByNumber(cardNumber) || {})
})

export const formatCardNumber = (value) => {
  const card = getCardDetails(value)
  const parts = []
  let currentPosition = 0

  const block = card.blocks.find((b) => blockSum(b) === value.length) || card.blocks[0]

  block.forEach((blockLength) => {
    parts.push(value.slice(currentPosition, currentPosition + blockLength))
    currentPosition += blockLength
  })

  if (value.length > currentPosition) {
    parts.push(value.slice(currentPosition))
  }

  return parts.join(' ').trim()
}

export const isNumberValid = (value) => {
  if (!value) { return false }
  const card = findCardByNumber(value)

  return (
    !!card
    && card.blocks.some((block) => blockSum(block) === value.length)
    && /^\d+$/.test(value)
    && luhnCheck(value)
  )
}

export const isNameValid = (value) => (
  value.length >= 3
  && value.length <= 40
  && /^[a-zA-Z ]*$/.test(value)
)

export const isExpiryValid = (month, year) => {
  if (!month || !year) { return false }
  const value = month + '/' + year
  const expiry = value.split('/')
  const monthParsed = parseInt(expiry[0])
  const yearParsed = parseInt(expiry[1])
  const currentYear = parseInt(new Date().getFullYear().toString().substring(2, 4))
  const thisMonth = new Date().getMonth() + 1

  return (
    yearParsed > currentYear
    || (yearParsed === currentYear && monthParsed > thisMonth)
  )
}

export const isCodeValid = (value, cardType = null) => {
  if (!value) { return false }
  const card = cardFormats.find((item) => item.type === cardType)
  const maxSize = card?.code?.size || 3
  return (
    value?.toString()?.length === maxSize
    && /^\d+$/.test(value)
  )
}

export const validateCard = (body) => {
  // Validation checks
  if (!isNumberValid(body.cardNumber)) {
    return 'Invalid card number'
  }
  if (!isNameValid(body.nameOnCard)) {
    return 'Invalid card name'
  }
  if (!isExpiryValid(body.expiryMonth, body.expiryYear)) {
    return 'Invalid expiry'
  }
  if (!isCodeValid(body.cvv)) {
    return 'Invalid cvv'
  }

  // All checks passed
  return 'valid'

}

export const extendBrandName = (short) => {
  switch (short?.toUpperCase()) {
    case 'SLX':
      return 'SponsoredLinX'
    case 'GMT':
      return 'GetMoreTraffic'
    case 'SME':
      return 'Search Marketing Experts'
    case 'JDM':
      return 'Just Digital Marketing'
  }
}

export const extractUrl = (short, path = 'clientpanel') => {
  const brand = short?.toLowerCase()

  // FIXME: re-enable when fixed
  // if (process.env.NODE_ENV === 'development') {
  //   return brand !== 'dd' ? `cp.${brand}.local` : ''
  // }
  if (process.env.REACT_APP_NODE_ENV === 'development' as string) {
    return brand !== 'dd' ? `cp.${brand}.local` : ''
  }
  if (process.env.REACT_APP_NODE_ENV === 'staging' as string) {
    path = 'cp.staging'
  }
  switch (brand) {
    case 'slx':
      return `${path}.sponsoredlinx.com`
    case 'gmt':
      return `${path}.getmoretraffic.com.au`
    case 'sme':
      return `${path}.searchmarketingexperts.com.au`
    case 'jdm':
      return `${path}.justdigitalmarketing.com.au`
    case 'dd':
      return 'https://clientpanel.disrupthub.com.au'
  }
}

export const productStatusCheck = (status, overflow = true) => {
  const resultStyle = !['active', 'trial', 'non_renewing'].includes(status) ? 'opacity-[60%]' : ''
  const overflowStyle = overflow ? ' overflow-hidden truncate' : ''
  return resultStyle + overflowStyle
}

export const caseStatusCheck = (status) => {
  const resultStyle = ['Closed', 'Awaiting Approval'].includes(status) ? 'opacity-[50%] pointer-events-none' : ''
  return resultStyle
}

export const extendCRM = (short) => {
  switch (short?.toUpperCase()) {
    case 'HS':
      return 'Hubspot'
    case 'SF':
      return 'Salesforce'
  }
}

export const emailToName = (email) => {
  if (!email || email?.length === 0) return email
  const name = email?.split('@')[0]
  if (!name || name?.length === 0) return email
  if (name?.split('.')?.length === 1) {
    // Split according to dashes in name
    return name?.split('-').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ') || email
  } else {
    // Split according to . in name
    return name?.split('.').map(s => s.charAt(0).toUpperCase() + s.slice(1)).join(' ') || email
  }
}

export const receiptToStatus = (receiptData, manuallyActioned) => {
  if (!receiptData) return {
    message: '~',
    color: 'text-black-500',
    icon: ''
  }

  const flattened = Object.values(receiptData).flat()

  if (!manuallyActioned && flattened.some(receipt =>
    (receipt as string)?.toUpperCase().includes('RECORDED') ||
    (receipt as string)?.toUpperCase().includes('ALTERED PRODUCT') ||
    (receipt as string)?.toUpperCase().includes('MANUAL')
  )) {
    return {
      message: 'Manually Action',
      color: 'text-orange-500',
      icon: 'Warning'
    }
  } else if (!manuallyActioned && flattened.some(receipt =>
    (receipt as string)?.toUpperCase().includes('FAILED')
  )) {
    return {
      message: 'Failed to Action',
      color: 'text-red-500',
      icon: 'Close'
    }
  } else {
    return {
      message: 'Actioned',
      color: 'text-green-600',
      icon: 'Done'
    }
  }
}

export const statusToColor = (status) => {
  if (!status) return 'default'

  if (status.includes('Awaiting')) {
    return 'warning'
  } else if (status.includes('Rejected')) {
    return 'error'
  } else if (status.includes('Closed')) {
    return 'success'
  } else {
    return 'info'
  }
}

export const capitalizeFirstLetter = (string) => {
  if (!string) return ''
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
}

export const capitalizeFirstLetters = (string) => {
  if (!string) return ''
  return string
    .replaceAll('_', ' ')
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export const latestForms = (data) => {
  // filter forms to only show latest form versions for each type
  const filteredData = Object.values(data?.reduce((acc, form) => {
    const id = form.form_version_id.form_id.id
    if (!acc[id] || new Date(form.updated_at) > new Date(acc[id].updated_at)) {
      acc[id] = form
    }
    return acc
  }, {})) || data
  return filteredData
}

export const sortCases = (cases, property = 'updated_at') => {
  const sortedCases = [...cases].sort((a, b) => new Date(b[property]).getTime() - new Date(a[property]).getTime())
  return sortedCases
}

export const formatInvoices = (invoiceJson) => {
  const formattedInv = Object.entries(JSON.parse(invoiceJson)).map(([invoice, amount]) => `Invoice #${invoice}: $${amount || 0}`).join('\n')
  return formattedInv
}

export const formatReceipts = (receiptJson) => {
  if (!receiptJson || receiptJson.length <= 1 || receiptJson === 'null') return ''
  const { creditReceipts, refundReceipts, productReceipts, promotionReceipts } = JSON.parse(receiptJson)
  return [
    creditReceipts?.length > 0 ? `Credit Receipts:\n${creditReceipts.join('\n')}` : null,
    refundReceipts?.length > 0 ? `Refund Receipts:\n${refundReceipts.join('\n')}` : null,
    productReceipts?.length > 0 ? `Product Receipts:\n${productReceipts.join('\n')}` : null,
    promotionReceipts?.length > 0 ? `Promotion Receipts:\n${promotionReceipts.join('\n')}` : null
  ].filter(Boolean).join('\n\n')
}

export const convertToCSV = (objArray) => {
  if (objArray.length === 0) return ''

  const headers = Object.keys(objArray[0])

  const csvContent = [
    headers.join(','),
    ...objArray.map(row =>
      headers.map(fieldName => JSON.stringify(row[fieldName], (key, value) => value === null ? '' : value)).join(',')
    )
  ].join('\n')

  return csvContent
}

export const formatDateOnTimezone = (timestamp, timezone, short = false) => {
  const formatted = new Date(timestamp * 1000).toLocaleString('en-AU', { 
    timeZone: timezone, 
    year: 'numeric', 
    month: 'short', 
    ...(short ? { day: '2-digit' } : { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: false })
  })
  const finalStr = !short ? formatted + ', ' + timezone : formatted
  return finalStr
}

export const timestampToDate = (timestamp, country = 'AU') => {
  return new Date(timestamp * 1000).toLocaleDateString('en-' + country)
}

export const formatCurrency = (value, cents = true) => { // format a currency with 2 fixed decimal places, and comma for thousands
  const fixed = (cents ? value / 100 : value)?.toFixed(2)
  const formatted = parseFloat(fixed).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  return formatted
}

export const getManagerChain = (allUserData, email) => {
  const managerData = []
  let username = (email || '').split('@')[0] || ''
  let i = 0
  while (username && username !== process.env.REACT_APP_CASE_SYSTEM_APPROVER) {
    if (i === 12) break // only look for 12 manager chains, to avoid infinite loops
    const userData = allUserData?.filter(user => user?.email?.split('%')[0].includes(username))
    username = userData[0]?.manager
    managerData.push(username)
    i++
  }

  return managerData
}

// Calculate which days to disable in a cancel case's calendar
export const getDisabledDates = (subscriptions, selectedSubId, date, caseType) => {
  const sub = subscriptions.find(e => e.id === selectedSubId) || null
  if (!sub) return null // dont lock any dates if no subscription selected

  //* CANCEL CASE DATES TO LOCK
  if (caseType === 'cancel') {
    let period, diff, lockDate

    const nextBillingDate = new Date(sub.next_billing_at)
    const calendarDate = new Date(date)
    
    // adjusting dates to be at midnight for comparison
    calendarDate.setHours(0, 0, 0, 0)
    nextBillingDate.setHours(0, 0, 0, 0)

    // Do not allow selection of any dates prior to the next billing date, for all date periods
    if (calendarDate < nextBillingDate) {
      return true
    }

    // Calculate different logic for each different period
    switch (sub.recurring_period_unit as 'day' | 'week' | 'month' | 'year') {
      case 'day':
        period = sub.recurring_period
        diff = Math.round((calendarDate.getTime() - nextBillingDate.getTime()) / (1000 * 60 * 60 * 24))
        lockDate = diff < 0 || diff % period !== 0
        break
      case 'week':
        period = sub.recurring_period * 7
        diff = Math.round((calendarDate.getTime() - nextBillingDate.getTime()) / (1000 * 60 * 60 * 24))
        lockDate = diff < 0 || diff % period !== 0
        break
      case 'month':
        let adjustedNextBilling = nextBillingDate
        // check if the billing date is an outlier date (i.e. 29 30 or 31), so that we can lock the max date in each month
        if (nextBillingDate.getDate() > new Date(calendarDate.getFullYear(), calendarDate.getMonth() + 1, 0).getDate()) {
          adjustedNextBilling = new Date(calendarDate.getFullYear(), calendarDate.getMonth() + 1, 0)
        }
        period = sub.recurring_period
        diff = Math.round((calendarDate.getDate() - adjustedNextBilling.getDate()))
        lockDate = diff < 0 || diff % period !== 0 || diff > 0
        break
      case 'year':
        // ! TODO: check if leap year !TODO
        period = sub.recurring_period //! TODO account for 2 yearly, or more than 1 year subscriptions
        if (nextBillingDate.getDate() === calendarDate.getDate() && nextBillingDate.getMonth() === calendarDate.getMonth()) {
          lockDate = false // allow selection of date if same month and and day as prev year
        } else {
          lockDate = true // otherwise lock date
        }
        break 
      default:
        period = 30 // default to 30 days if unit is unrecognized
        diff = Math.round((calendarDate.getTime() - nextBillingDate.getTime()) / (1000 * 60 * 60 * 24))
        lockDate = diff < 0 || diff % period !== 0
    }
    return lockDate
  }
  //* PAUSE CASE DATES TO LOCK
  if (caseType === 'pause') {
    const nextBillingDate = new Date(sub.next_billing_at)
    const calendarDate = new Date(date)

    calendarDate.setHours(0, 0, 0, 0)
    nextBillingDate.setHours(0, 0, 0, 0)
    const diff = Math.round((calendarDate.getTime() - nextBillingDate.getTime()) / (1000 * 60 * 60 * 24))

    return diff < 0
  }
}

export const scrollToLastRow = (
  gridPageCountSelector,
  gridPaginatedVisibleSortedGridRowEntriesSelector,
  apiRef
) => {
  const allPages = gridPageCountSelector(apiRef)
  apiRef.current.setPage(allPages)
  const allRows = gridPaginatedVisibleSortedGridRowEntriesSelector(apiRef)
  const rowIndex = allRows.length - 1
  if (!apiRef || !apiRef?.current) {
    return
  }
  const gridRoot = apiRef.current.rootElementRef?.current?.querySelector('.MuiDataGrid-virtualScroller')
  if (gridRoot) {
    const start = gridRoot.scrollTop
    const rowHeight = 52
    const targetScrollTop = rowIndex * rowHeight
    const duration = 1000
    const startTime = performance.now()
    const scroll = (currentTime) => {
      const elapsed = currentTime - startTime
      const progress = Math.min(elapsed / duration, 1)
      const ease = progress * (2 - progress)
      gridRoot.scrollTop = start + (targetScrollTop - start) * ease
      if (progress < 1) {
        requestAnimationFrame(scroll)
      }
    }
    requestAnimationFrame(scroll)
  } else {
    apiRef.current.scrollToIndexes({ rowIndex })
  }
}

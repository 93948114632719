import { useGetIdentity } from '@refinedev/core'
import { CasesBreadcrumb as Breadcrumb } from '../components/Breadcrumbs'
import { CaseTabs } from '../components/Tabs'
import { LoadingPage } from '../components/Loading'
import { Alert, Container, Typography } from '@mui/material'
import { useGetAllCasesQuery, useGetOutstandingCasesQuery, useGetSubmittedCasesQuery,
  useGetTeamCasesQuery, useGetClosedCasesQuery, useGetPartialCasesQuery, useGetRejectedCasesQuery } from '../services/casesApi'
import { useGetAllUsersQuery } from '../services/permissionsApi'

interface UserProps {
  email: string
}

export function CaseSummaryPage() {
  const { data: userData, isLoading: userLoading }: { data: UserProps, isLoading: boolean } = useGetIdentity()

  // Initialize RTK Queries using RTK Query hooks
  const { data: allCasesData = [], isLoading: allCasesLoading } = useGetAllCasesQuery()
  const { data: allUsersData = [], isLoading: allUsersLoading } = useGetAllUsersQuery()
  const { data: outstandingData = [], isLoading: outstandingCasesLoading } = useGetOutstandingCasesQuery(userData?.email, { skip: !userData?.email })
  const { data: submittedData = [], isLoading: submittedCasesLoading } = useGetSubmittedCasesQuery(userData?.email, { skip: !userData?.email })
  const { data: teamCases = [], isLoading: teamCasesLoading } = useGetTeamCasesQuery(userData?.email, { skip: !userData?.email })
  const { data: closedCases = [], isLoading: closedCasesLoading } = useGetClosedCasesQuery()
  // const { data: managers, isLoading: managersLoading } = useGetManagersQuery(userData?.email, { skip: !userData?.email }) //! TODO conveert to rtk query (manager chain)
  const { data: partialCases = [], isLoading: partialCasesLoading } = useGetPartialCasesQuery(userData?.email, { skip: !userData?.email })
  const { data: rejectedCases = [], isLoading: rejectedCasesLoading } = useGetRejectedCasesQuery(userData?.email, { skip: !userData?.email })

  // Handle error state
  if (!userData) {
    return (
      <Container maxWidth="lg">
        <Alert severity="error" style={{ padding: '15px', marginBottom: '15px' }}>
          Bamboo user information could not be found. Please contact IT for assistance.
        </Alert>
      </Container>
    )
  }

  // Determine overall loading state
  const loading = userLoading || outstandingCasesLoading || submittedCasesLoading || teamCasesLoading || closedCasesLoading || allCasesLoading

  if (loading) {
    return <LoadingPage Breadcrumb={Breadcrumb} />
  }

  return (
    <div>
      <Container>
        <Breadcrumb />
        <div className="flex flex-col gap-[8px] pt-[16px] pb-[16px]">
          <Typography variant="h4">
            Cases
          </Typography>
          <Typography variant="body1" sx={{ display: 'flex', gap: '16px' }}>
            View a summary of your cases here.
          </Typography>
        </div>
        <CaseTabs
          outstandingCases={outstandingData}
          submittedCases={submittedData}
          teamCases={teamCases}
          allCasesData={allCasesData}
          allCasesLoading={allCasesLoading}
          closedCases={closedCases}
          partialCases={partialCases}
          rejectedCases={rejectedCases}
        />
      </Container>
    </div>
  )
}

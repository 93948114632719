import { Button, CircularProgress, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material'

export function Confirmation ({ 
  openDialog,
  handleClose,
  itemName,
  loading,
  text,
  subText = ''
}) {
  return (
    <Dialog
      open={openDialog}
      onClose={() => handleClose(null)}
    >
      <DialogTitle>
        {`${text} ${itemName}?`}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to {text} this {itemName}?<br/>
          <i>{subText}</i>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={() => handleClose(false)}>
          No
        </Button>
        <Button disabled={loading} endIcon={loading ? <CircularProgress size="0.99rem" /> : null} onClick={() => handleClose(true)} autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  )
}

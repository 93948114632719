import { useOutletContext } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { useGetIdentity } from '@refinedev/core'
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport } from '@mui/x-data-grid'
import { TableTemplateNew } from '../components/Table'
import { CircularProgress, FormControl, IconButton, Link as MuiLink, MenuItem, Select, Tooltip } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import { formatAsDayMonthYear } from '../utility/helpers/dateHelpers'
import { emailToName, genRandomUUID } from '../utility/helpers/miscHelpers'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { CompanyQueries } from '../interface/queries/CompanyQueries'
import { useCreateFormResultMutation, useDeleteFormResultMutation } from '../services/formsApi'

interface DataProps {
  id?: string,
  url?: string,
  company_id?: string
  username?: string
  password?: string
  type?: string
  note?: string
  created_by?: string
  created_at?: Date
  updated_at?: Date
  updated_by?: string
  formtype?: number
}

interface UserProps {
  email: string
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

const dataTitle = 'Form'
const initialForm: DataProps = {
  id: '',
  url: '',
  company_id: '',
  username: '',
  type: '',
  password: '',
  note: '',
  created_by: '',
  created_at: new Date(),
  updated_at: new Date(),
  updated_by: '',
  formtype: 0
}
const columnFields = (openUpdateForm, handleDialogOpen, allowEdit, allowDelete, customerId, subscriptionsCb, geolocations, mode, showPasswordMap, setShowPasswordMap, handleReceiptOpen, handleDataDialogOpen) => {
  return [
    { field: 'form_version_id', headerName: 'Form Type', width: 250, editable: false,
      renderCell: (params) => (
        <Tooltip title="Click to quickly view the form data">
          <MuiLink
            onClick={() => handleDataDialogOpen(params.row)}
            variant="body2"
            color="primary"
            sx={{ cursor: 'pointer', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
          >
            {params?.row?.form_version_id?.form_id?.name}
          </MuiLink>
        </Tooltip>
      )
    },
    { field: 'subscription_id', headerName: 'Subscription', width: 125, editable: false,
      renderCell: (params) => {
        if (subscriptionsCb?.length === 0 || null) {
          return <CircularProgress size="1rem" color="primary" />
        } else {
          const subscription = subscriptionsCb?.find(e => e.id === params?.row?.subscription_id)
          return (
            <Tooltip title={subscription ? subscription.name : null}>
              <div className="overflow-hidden truncate">
                {subscription ? subscription.name : null}
              </div>
            </Tooltip>
          )
        }
      }
    },
    { field: 'created_at', headerName: 'Create Date', width: 125, editable: false,
      renderCell: (params) => (
        <Tooltip title={new Date(params.row.created_at).toLocaleString()}>
          <div className="overflow-hidden truncate">
            {formatAsDayMonthYear(params.row.created_at)}
          </div>
        </Tooltip>
      )
    },
    { field: 'updated_at', headerName: 'Last Modified', width: 125, editable: false,
      renderCell: (params) => (
        <Tooltip title={new Date(params.row.updated_at).toLocaleString()}>
          <div className="overflow-hidden truncate">
            {formatAsDayMonthYear(params.row.updated_at)}
          </div>
        </Tooltip>
      )
    },
    { field: 'updated_by', headerName: 'Modified By', width: 185, editable: false,
      renderCell: (params) => (
        <Tooltip title={params.row.updated_by}>
          <div className="flex flex-row gap-[7px] overflow-hidden truncate">
            <AccountCircleIcon sx={{ opacity: '50%'}} />
            <div className="overflow-hidden truncate">
              {emailToName(params.row.updated_by)}
            </div>
          </div>
        </Tooltip>
      )
    },
    { field: 'edit', headerName: 'Edit', width: 75,
      renderCell: (params) => (
        <Link to={`${window.location.pathname}/${params?.row?.form_version_id?.form_id?.id}/${params.row.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
          <IconButton color="info">
            <EditIcon />
          </IconButton>
        </Link>
      )
    },
    { field: 'delete', headerName: 'Delete', width: 75,
      renderCell: (params) => (
        <IconButton color="error" onClick={() => handleDialogOpen(params, 'Delete')} disabled={!allowDelete} >
          <DeleteIcon />
        </IconButton>
      ),
    }
  ]
}

const upsertFields = (create, formData, setFormData, customerId, geolocations, formTypes) => {
  return (
    <>
      <FormControl fullWidth>
        <InputLabel>Form Type</InputLabel>
        <Select
          fullWidth
          value={formData.formtype || ''}
          label="Form Type"
          onChange={(e) => setFormData({ ...formData, formtype: e.target.value })}
          required
        >
          {(formTypes?.length === 0 || !formTypes) ? (
            <MenuItem disabled>
              <CircularProgress color="inherit" size={30} />
            </MenuItem>
          ) : (
            formTypes?.map((val) => (
              <MenuItem key={val.id} value={val.id}>{val.name}</MenuItem>
            ))
          )}
        </Select>
      </FormControl>
    </>
  )
}

const createEmptyRow = (params, customerId, keywordId, productId, userEmail) => {
  return ({
    id: genRandomUUID(),
    url: params.url,
    username: params.username,
    type: params.type,
    password: params.password,
    note: params.note,
    created_by: params.created_by,
    created_at: new Date,
    updated_at: new Date,
    updated_by: userEmail,
    company_id: customerId,
    formtype: params.formtype
  })
}

export function AllFormsPage () {
  const { data: userData }: { data: UserProps } = useGetIdentity()
  initialForm.created_by = userData?.email || ''

  const { formResultsData, formResultsLoading } = useOutletContext<CompanyQueries>()
  const [createFormResult] = useCreateFormResultMutation()
  const [deleteFormResult] = useDeleteFormResultMutation()

  return (
    <TableTemplateNew
      CustomToolbar={CustomToolbar}
      dataTitle={dataTitle}
      columnFields={columnFields}
      initialForm={initialForm}
      upsertFields={upsertFields}
      createEmptyRow={createEmptyRow}
      tableData={formResultsData}
      loading={formResultsLoading}
      createQuery={createFormResult}
      deleteQuery={deleteFormResult}
      permissionProp="forms"
    />
  )
}

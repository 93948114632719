import { useEffect } from 'react'
import { useLogout, useActiveAuthProvider } from '@refinedev/core'

export const clearStorage = () => {
  localStorage.clear()
  sessionStorage.clear()
  alert('Session cleared.\nPlease log back in.')
}

// Clear session, localStorage and cookies with CTRL + SHIFT + C
export function ClearCacheAndCookies () {
  const authProvider = useActiveAuthProvider()
  const { mutate: mutateLogout } = useLogout({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  })
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.ctrlKey && event.shiftKey && event.key === 'C') {
        clearStorage()
        mutateLogout()
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [mutateLogout])

  return (
    <>
    </>
  )
}

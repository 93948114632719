import { useState, useEffect } from 'react'
import { Box, Chip, Typography, Paper, Grid, Divider, Slide, Tooltip } from '@mui/material'
import { capitalize } from '../../../utility/helpers/miscHelpers'
import { extendBrandAbbr } from '../../../utility/helpers/brandHelpers'
import { formatAsCurrency } from '../../../utility/helpers/currencyHelpers'
import { timestampToExtendedDate } from '../../../utility/helpers/dateHelpers'

export function InvoiceCard ({
  invoice
}) {
  const [show, setShow] = useState(true)

  const totalRefund = invoice?.issued_credit_notes.reduce((sum, item) => sum + (item?.cn_status === 'refunded' ? item?.cn_total : 0), 0)

  useEffect(() => {
    setShow(false)
  }, [invoice])

  return (
    <Slide in={show} direction="left" onExited={() => setShow(true)}>
      <Paper elevation={3} sx={{ padding: 3, maxWidth: '1000px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
          <Chip
            label={`${invoice?.status?.toUpperCase().replaceAll('_', ' ')} ${invoice?.status === 'paid' ? 'on ' + timestampToExtendedDate(invoice?.paid_at, invoice?.site_details_at_creation?.timezone, true) : ''}`}
            color={invoice?.status === 'paid' ? 'success' : 'error'}
            variant="outlined"
          />
          {(totalRefund > 0) && (
            <Chip label={`$${formatAsCurrency(totalRefund)} Refund Issued`} variant="outlined" color="primary" />
          )}
        </Box>

        <Typography variant="h6" component="div" gutterBottom fontWeight="400">
          Invoice for <b>${formatAsCurrency(invoice?.total)}</b> {invoice?.currency_code}
        </Typography>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', marginBottom: 2 }}>
          <Tooltip title="Invoice Number">
            <Chip label={`🔑 ${invoice?.id}`} />
          </Tooltip>
          <Tooltip title="Business Entity">
            <Chip
              icon={<img className="flex w-[22px] max-h-[22px] items-center justify-center self-center" src={`/images/logo_natural_${invoice?.business_entity_id}.svg`} />}
              label={extendBrandAbbr(invoice?.business_entity_id)}
            />
          </Tooltip>
        </Box>

        <Typography variant="body2"><span className="opacity-[60%]">Invoiced On: </span>{timestampToExtendedDate(invoice?.generated_at, invoice?.site_details_at_creation?.timezone)}</Typography>
        <Typography variant="body2"><span className="opacity-[60%]">Amount Due: </span>${formatAsCurrency(invoice?.amount_due)}</Typography>
        <Typography variant="body2"><span className="opacity-[60%]">Amount Paid: </span>${formatAsCurrency(invoice?.amount_paid)}</Typography>
        <Typography variant="body2"><span className="opacity-[60%]">Due Date: </span>{timestampToExtendedDate(invoice?.due_date, invoice?.site_details_at_creation?.timezone)}</Typography>

        <Divider sx={{ marginY: 2 }} />

        {/* Line Item Titles */}
        <Grid container spacing={2} sx={{ marginBottom: 2 }}>
          <Grid item xs>
            <Typography variant="body2" fontWeight="bold">Items</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2" fontWeight="bold" sx={{ opacity: '70%' }}>Price</Typography>
            <Typography variant="body2" sx={{ opacity: '70%' }}>({invoice?.price_type.includes('tax_inclusive') ? 'Incl. Tax' : 'Excl. Tax'})</Typography>
          </Grid>
          {(invoice?.discounts) && (
            <Grid item xs={2}>
              <Typography variant="body2" fontWeight="bold" sx={{ opacity: '70%' }}>Discount</Typography>
              <Typography variant="body2" sx={{ opacity: '70%' }}>({invoice?.price_type.includes('tax_inclusive') ? 'Incl. Tax' : 'Excl. Tax'})</Typography>
            </Grid>
          )}
          <Grid item xs={2}>
            <Typography variant="body2" fontWeight="bold" sx={{ opacity: '70%' }}>Tax</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="body2" fontWeight="bold" sx={{ opacity: '70%' }}>Amount ({invoice?.currency_code})</Typography>
            <Typography variant="body2" sx={{ opacity: '70%' }}>({invoice?.price_type.includes('tax_inclusive') ? 'Incl. Tax' : 'Excl. Tax'})</Typography>
          </Grid>
        </Grid>

        {/* Line Item Values */}
        {(invoice?.line_items.map((line, idx) => (
          <Grid container spacing={2} sx={{ marginTop: 1 }} key={idx}>
            <Grid item xs>
              <Typography variant="body2" gutterBottom>
                {line?.description}
              </Typography>
              <Typography variant="caption" sx={{ opacity: '70%' }}>
                {timestampToExtendedDate(line?.date_from, invoice?.site_details_at_creation?.timezone, true)}
                &nbsp;-&nbsp;
                {timestampToExtendedDate(line?.date_to, invoice?.site_details_at_creation?.timezone, true)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2">
                ${formatAsCurrency(line?.unit_amount)}
              </Typography>
            </Grid>
            {(invoice?.discounts) && (
              <Grid item xs={2}>
                <Typography variant="body2">
                  ${formatAsCurrency(line?.discount_amount)}
                </Typography>
              </Grid>
            )}
            <Grid item xs={2}>
              <Tooltip title={line?.is_taxed ? `${line?.tax_rate}%` : capitalize(line?.tax_exempt_reason?.replaceAll('_', ' '))}>
                <Typography variant="body2">${formatAsCurrency(line?.tax_amount)}</Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              <Typography variant="body2" fontWeight="bold">${line?.discount_amount > 0 ? formatAsCurrency(line?.amount - line?.discount_amount) : formatAsCurrency(line?.amount)}</Typography>
            </Grid>
          </Grid>
        )))}

        <Divider sx={{ marginY: 2 }} />

        <div className="flex flex-row justify-end gap-[50px] items-end">
          {/* Total Titles */}
          <div className="flex flex-col items-end gap-[8px]">
            <Typography variant="body2" sx={{ opacity: '70%' }}>
              Sub Total
            </Typography>
            {(invoice?.taxes) && (
              <Typography variant="body2" sx={{ opacity: '70%' }}>
                {invoice?.taxes[0].description}
              </Typography>
            )}
            <Typography variant="body2" sx={{ opacity: '70%' }}>
              {(invoice?.price_type.includes('tax_inclusive')) ? (
                <span>Total Inclusive {invoice?.taxes ? 'of '  +invoice?.taxes[0].description : ''}</span>
              ) : (
                <span>Total </span>
              )}
            </Typography>
            {(invoice?.amount_adjusted > 0) && (
              <Typography variant="body2" sx={{ opacity: '70%' }}>
                Credits
              </Typography>
            )}
            {(invoice?.amount_paid > 0 && invoice?.amount_to_collect > 0) && (
              <Typography variant="body2" sx={{ opacity: '70%' }}>
                Amount Paid
              </Typography>
            )}
            <Typography variant="h6" sx={{ opacity: '70%' }}>
              Amount
              {(invoice?.amount_to_collect > 0) ? (
                <span> Due</span>
              ) : (
                <span> Paid</span>
              )}
            </Typography>
          </div>
          
          {/* Total Values */}
          <div className="flex flex-col items-end gap-[8px]">
            <Typography variant="body2" fontWeight="bold">
              ${formatAsCurrency(invoice?.sub_total - invoice?.tax)}
            </Typography>
            {(invoice?.taxes) && (
              <Typography variant="body2" fontWeight="bold">
                ${formatAsCurrency(invoice?.taxes[0].amount)}
              </Typography>
            )}
            <Typography variant="body2" fontWeight="bold">
              ${formatAsCurrency(invoice?.total)}
            </Typography>
            {(invoice?.amount_adjusted > 0) && (
              <Typography variant="body2" fontWeight="bold">
                -${formatAsCurrency(invoice?.amount_adjusted)}
              </Typography>
            )}
            {(invoice?.amount_paid > 0 && invoice?.amount_to_collect > 0) && (
              <Typography variant="body2" fontWeight="bold">
                -${formatAsCurrency(invoice?.amount_paid)}
              </Typography>
            )}
            <Typography variant="h6">
            {invoice?.amount_to_collect === 0 && invoice?.amount_paid !== 0 ? '-' : ''}${invoice?.amount_to_collect > 0 ? formatAsCurrency(invoice?.amount_to_collect) : formatAsCurrency(invoice?.amount_paid)}
            </Typography>
          </div>
        </div>
      </Paper>
    </Slide>
  )
}

import { api } from './api'
import { queries } from '../utility'
import { setPermissions, setManagers, setAllUsers, setTotalAllowed } from '../store/global'
import { store } from '../store'
import { extractCaseManagers } from '../utility/helpers/caseHelpers'

export const permissionsApi = api.injectEndpoints({
  reducerPath: 'permissionsApi',
  endpoints: (build) => ({
    getPermissions: build.query({
      queryFn: async (arg) => {
        const userData = arg
        const response = await queries.getUserPermissions({ name: userData?.user_metadata?.full_name, email: userData?.email })
        store.dispatch(setPermissions(response?.permissions))
        store.dispatch(setTotalAllowed(response?.totalAllowed))
        return { data: response }
      },
    }),
    getManagers: build.query({
      queryFn: async (arg) => {
        const email = arg
        const users = await queries.getAllUsers()
        const response = extractCaseManagers(users, email)
        store.dispatch(setManagers(response))
        return { data: response }
      },
    }),
    getAllUsers: build.query({
      queryFn: async (arg) => {
        const response = await queries.getAllUsers()
        store.dispatch(setAllUsers(response))
        return { data: response }
      },
    }),
  }),
})

export const {
  useGetPermissionsQuery,
  useGetManagersQuery,
  useGetAllUsersQuery
} = permissionsApi

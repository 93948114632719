import { useOutletContext } from 'react-router-dom'
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport } from '@mui/x-data-grid'
import { Chip, IconButton } from '@mui/material'
import { TableTemplateNew } from '../components/Table'
import { Link } from 'react-router-dom'
import { genRandomUUID } from '../utility/helpers/miscHelpers'
import { Button, Checkbox, Divider, FormControlLabel, InputAdornment, InputLabel, Link as MuiLink, Skeleton, Tooltip, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import ReplayIcon from '@mui/icons-material/Replay'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import SendIcon from '@mui/icons-material/Send'
import { emailToName } from '../utility/helpers/miscHelpers'
import { formatAsDayMonthYear } from '../utility/helpers/dateHelpers'
import { getDisabledStyle, getStatusColour } from '../utility/helpers/caseHelpers'
import dayjs from 'dayjs'
import { CompanyQueries } from '../interface/queries/CompanyQueries'
import { useCreateCaseMutation, useUpdateCaseMutation, useDeleteCaseMutation } from '../services/casesApi'

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

const columnFields = (openUpdateForm, handleDialogOpen, allowEdit, allowDelete, customerId, subscriptionsCb, geolocations, mode, showPasswordMap, setShowPasswordMap, handleReceiptOpen, handleCaseOpen, userEmail, managers) => {
  return [
    { field: 'subscription', headerName: 'Subscription', width: 400, editable: false,
      renderCell: (params) => {
        if (!subscriptionsCb || subscriptionsCb?.length === 0) {
          return ( 
            <div className={'p-[16px] ' + getDisabledStyle(params.row.status)}>
              <Skeleton animation="wave" width={333} height={24} />
              <Skeleton animation="wave" width={83} height={20} />
            </div>
          )
        } else {
          const subscription = subscriptionsCb.find(e => e.id === params?.row?.subscription_id)
          return subscription
            ?
          <div className="p-[16px]">
            <div className={getDisabledStyle(params.row.status)}>
              <Typography variant="body2" sx={{ opacity: '87%' }}>
                {subscription.name}
              </Typography>
            </div>
            <MuiLink
              onClick={() => handleCaseOpen(params.row)}
              variant="body2"
              color="inherit"
              sx={{ opacity: '60%', cursor: 'pointer' }}
            >
              {params.row.case_number}
            </MuiLink>
          </div>
            :
          null
        }
      }
    },
    { field: 'status', headerName: 'Status', width: 155, editable: false,
      renderCell: (params) => (
        <div className={getDisabledStyle(params.row.status) + ' cursor-default pointer-events-auto'}>
          <Tooltip title={
            params.row.status.includes('Rejected') && params.row.approval_msg?.length > 0
              ?
            `This case was rejected with the following note from Accounts: ${params.row.approval_msg}`
              :
            params.row.status.includes('Awaiting')
              ?
            `This case is currently sitting with ${emailToName(params.row.submitted_manager)}`
              :
            null
            }>
            <span className="flex flex-col gap-[5px]">
              <Chip
                label={params.row.status === 'Closed' ? 'Approved' : params.row.status}
                color={getStatusColour(params.row.status)}
                size="small"
              />
              {params.row.status.includes('Awaiting') && (
                <Typography variant="caption" gutterBottom sx={{ display: 'flex', flexFlow: 'row', alignItems: 'center', gap: '4px', opacity: '90%' }}>
                  <AccountCircleIcon sx={{ opacity: '75%'}} />
                  {`By ${emailToName(params.row.submitted_manager)}`}
                </Typography>
              )}
            </span>
          </Tooltip>
        </div>
      )
    },
    { field: 'request_date', headerName: 'Date Requested', width: 120, editable: false,
      renderCell: (params) => (
        <div className={getDisabledStyle(params.row.status)}>
          {formatAsDayMonthYear(params.row.request_date)}
        </div>
      )
    },
    { field: 'case_type', headerName: 'Alteration', width: 180, editable: false,
      renderCell: (params) => (
        <div className={getDisabledStyle(params.row.status)}>
          {
            params.row.case_type === 'Cancel' ? `Cancel (${params.row.cancel_immediately ? 'Immediately' : params.row.cancel_endterm ? 'End of Term' : params.row.cancel_date})`
              :
            params.row.case_type === 'Pause' ? `Pause (${params.row.pause_immediately ? 'Immediately' : params.row.pause_endterm ? 'End of Term' : params.row.pause_date + ' to ' + params.row.resume_date})`
              :
            params.row.case_type === 'Altered Products' ? 'Altered Products'
              :
            'Billings Alterations Only'
          }
        </div>
      )
    },
    { field: 'invoice_credits', headerName: 'Credit Amount', width: 150, editable: false,
      renderCell: (params) => {
        const invoiceCredits: Record<string, string | number> = params.row.invoice_credits || {}
        if (Object.keys(invoiceCredits)?.length !== 0) {
          const totalCredit = Object.values(invoiceCredits).reduce((sum: number, value) => {
            const numberValue = typeof value === 'number' ? value : parseFloat(value)
            return sum + (numberValue || 0)
          }, 0)
          return (
            <div className={getDisabledStyle(params.row.status)}>
              ${totalCredit}
            </div>
          )
        } else {
          return <span>-</span>
        }
      }
    },
    { field: 'invoice_refunds', headerName: 'Refund Amount', width: 150, editable: false,
      renderCell: (params) => {
        const invoiceRefunds: Record<string, string | number> = params.row.invoice_refunds || {}
        if (Object.keys(invoiceRefunds)?.length === 0) {
          return <span>-</span>
        }
        
        const totalRefund = Object.values(invoiceRefunds).reduce((sum: number, value) => {
          const numberValue = typeof value === 'number' ? value : parseFloat(value)
          return sum + (numberValue || 0)
        }, 0)

        return (
          <div className={getDisabledStyle(params.row.status)}>
            ${totalRefund}
          </div>
        )
      }
    },
    { field: 'closed_at', headerName: 'Date Closed', width: 120, editable: false,
      renderCell: (params) => (
        <div className={getDisabledStyle(params.row.status)}>
          {params.row.closed_at ? formatAsDayMonthYear(params.row.closed_at) : '-'}
        </div>
      )
    },
    { field: 'edit', headerName: 'Edit', width: 75,
      renderCell: (params) => (
        <div className={getDisabledStyle(params.row.status)}>
          <Link to={'edit/' + params.row.id} style={{ textDecoration: 'none', color: 'inherit' }}>
            <IconButton color="info">
              <EditIcon />
            </IconButton>
          </Link>
        </div>
      ),
    },
    { field: 'delete', headerName: 'Delete', width: 75,
      renderCell: (params) => (
        <div className={params.row.case_number.length === 0 ? 'opacity-[50%] pointer-events-none' : getDisabledStyle(params.row.status)}>
          <IconButton color="error" onClick={() => handleDialogOpen(params, 'Delete')} disabled={!allowDelete} >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
    { field: 'submit', headerName: 'Submit', width: 150,
      renderCell: (params) => (
        <div>
          {(params.row?.status.includes('New') || (params.row?.status.includes('Rejected'))) && (
            <Button variant="contained" color="success" endIcon={params.row?.status.includes('New') ? <SendIcon /> : ''} onClick={() => {
              const updatedRow = {
                ...params.row,
                submitted_manager: managers?.[0] || null,
                submitted_by: userEmail
              }
              handleDialogOpen(updatedRow, 'Submit')
            }}
            disabled={subscriptionsCb?.length == 0 || !subscriptionsCb}>
              {params.row?.status.includes('New') ? 'SUBMIT' : params.row?.status.includes('Rejected') ? 'RESUBMIT' : ''}
            </Button>
          )}
          {(params.row?.status.includes('Awaiting Approval')) && (
            <Button variant="contained" color="info" endIcon={<ReplayIcon />} onClick={() => handleDialogOpen(params.row, 'Recall')}>
              RECALL
            </Button>
          )}
        </div>
      ),
    },
    { field: 'receipts', headerName: 'Receipts', width: 75,
      renderCell: (params) => (
        <div>
          <IconButton color="warning" onClick={() => handleReceiptOpen(params.row)} disabled={!params.row?.receipt_data} >
            <ReceiptLongIcon />
          </IconButton>
        </div>
      ),
    },
  ]
}

export function CustomerCasesPage () {
  const { casesData, casesLoading } = useOutletContext<CompanyQueries>()
  const [createCase] = useCreateCaseMutation()
  const [updateCase] = useUpdateCaseMutation()
  const [deleteCase] = useDeleteCaseMutation()

  return (
    <TableTemplateNew
      CustomToolbar={CustomToolbar}
      dataTitle="Case"
      columnFields={columnFields}
      tableData={casesData}
      loading={casesLoading}
      createQuery={createCase}
      updateQuery={updateCase}
      deleteQuery={deleteCase}
      permissionProp="cases"
      pinRight={['edit', 'submit', 'receipts']}
      autoRowHeight={true}
    />
  )
}

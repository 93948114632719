import Tooltip from '@mui/material/Tooltip'

export const OtherIcon = ({
  mode
}) => {
  return (
    <Tooltip title="Other">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g>
          <path fillRule="evenodd" clipRule="evenodd" d="M2 12C2 6.48 6.48 2 12 2C17.52 2 22 6.48 22 12C22 17.52 17.52 22 12 22C6.48
            22 2 17.52 2 12ZM4 12C4 16.42 7.58 20 12 20C16.42 20 20 16.42 20 12C20 7.58 16.42 4 12 4C7.58 4 4 7.58 4 12ZM11 10.9999C11.5523 10.4476
            12.4477 10.4476 13 10.9999C13.5523 11.5522 13.5523 12.4476 13 12.9999C12.4477 13.5522 11.5523 13.5522 11 12.9999C10.4477 12.4476 10.4477
            11.5522 11 10.9999ZM15 10.9999C15.5523 10.4476 16.4477 10.4476 17 10.9999C17.5523 11.5522 17.5523 12.4476 17 12.9999C16.4477 13.5522 15.5523
            13.5522 15 12.9999C14.4477 12.4476 14.4477 11.5522 15 10.9999ZM7.00003 10.9999C7.55231 10.4476 8.44774 10.4476 9.00003 10.9999C9.55231 11.5522
            9.55231 12.4476 9.00003 12.9999C8.44774 13.5522 7.55231 13.5522 7.00003 12.9999C6.44774 12.4476 6.44774 11.5522 7.00003 10.9999Z"
            fill={mode === 'dark' ? "white" : "black"}
            fillOpacity="0.56"
          />
        </g>
      </svg>
    </Tooltip>
  )
}

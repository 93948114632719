/**
 * Convert a shortened brand name to the clientpanel URL
 *
 * @param {string} short The brand name, shortened (i.e. SLX, GMT, etc.)
 * @param {string} path The clientpanel path
 * @returns {string}
 */
export const getCpanelUrl = (
  short: string,
  path: string = 'clientpanel'
): string => {
  const brand = short?.toLowerCase()

  if (process.env.REACT_APP_NODE_ENV === 'development' as string) {
    return brand !== 'dd' ? `cp.${brand}.local` : ''
  }
  if (process.env.REACT_APP_NODE_ENV === 'staging' as string) {
    path = 'cp.staging'
  }
  switch (brand) {
    case 'slx':
      return `${path}.sponsoredlinx.com`
    case 'gmt':
      return `${path}.getmoretraffic.com.au`
    case 'sme':
      return `${path}.searchmarketingexperts.com.au`
    case 'jdm':
      return `${path}.justdigitalmarketing.com.au`
    case 'dd':
      return 'https://clientpanel.disrupthub.com.au'
  }
}

import { Button, Card, CardContent, Tooltip, Typography } from '@mui/material'
import KeyIcon from '@mui/icons-material/Key'
import { LoadingCard } from './'

export const OTPCard = ({
  setOpenDialogOTP,
  enabled = false,
  loading = true
}) => {

  if (loading) return <LoadingCard />

  return (
    <Tooltip title={!enabled ? 'No associated contacts were found for this company' : ''}>
      <span>
        <Button disabled={!enabled} onClick={() => setOpenDialogOTP(true)} sx={{ textTransform: 'none', opacity: !enabled ? '50%' : '100%' }}>
          <Card sx={{ maxWidth: '300px', backgroundColor: !enabled ? 'rgb(235, 235, 235)' : '' }}>
            <CardContent>
              <div className="flex gap-[16px] flex-row items-center">
                <KeyIcon sx={{ filter: !enabled ? 'grayscale(100%)' : '' }} />
                <Typography variant="h6" align="left">
                  Generate OTP
                </Typography>
              </div>
              <Typography variant="body1" align="left" color="text.secondary">
                Generate a One-Time-Password, valid for 1 hour.
              </Typography>
            </CardContent>
          </Card>
        </Button>
      </span>
    </Tooltip>
  )
}

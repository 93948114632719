import { useState, useEffect, useContext } from 'react'
import { useParams } from 'react-router-dom'
import { Accordion, AccordionDetails, AccordionSummary, Chip, Typography, Slide } from '@mui/material'
import { SeoIcon, GoogleAdsIcon, MetaAdsIcon } from '../../../components/Icons'
import { ColorModeContext } from '../../../contexts/color-mode'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

export function SubscriptionCard ({
  subscription
}) {
  const { mode, setMode } = useContext(ColorModeContext)
  const [show, setShow] = useState(true)
  const { caseId } = useParams()

  const isEdit = Boolean(caseId)

  useEffect(() => {
    setShow(false)
  }, [subscription])

  const iconMap = {
    seo: <SeoIcon mode={mode} />,
    ads: <GoogleAdsIcon mode={mode} />,
    '-ga': <GoogleAdsIcon mode={mode} />,
    adwords: <GoogleAdsIcon mode={mode} />,
    facebook: <MetaAdsIcon />,
    social: <MetaAdsIcon />,
  }
  
  const icon = Object.keys(iconMap).find(key => subscription?.name?.toLowerCase().includes(key)) || null
  
  const labelMap = {
    SeoIcon: 'www.google.com',
    GoogleAdsIcon: '123-456-7777',
    MetaAdsIcon: '12312313213',
  }

  const subscriptionPeriod = `Billed Every ${subscription?.recurring_period} ${subscription?.recurring_period > 1 ? subscription?.recurring_period_unit + 's' : subscription?.recurring_period_unit}`

  const Card = (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h6" component="div" gutterBottom fontWeight="600">
          {subscription?.name.split(' Plan ')[0] + ' Plan'}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ padding: 3, paddingTop: 0, maxWidth: '1000px' }}>
        <Typography variant="body2" component="div" gutterBottom sx={{ opacity: '70%', marginBottom: '20px' }}>
          {subscription?.name.split(' Plan ')[1]}
        </Typography>
        <div className="flex gap-[8px] flex-wrap">
          <Chip label={subscription?.status?.toUpperCase()} color={subscription?.status === 'active' ? 'success' : 'error'} variant="filled" />
          <Chip label={'Subscription ID ' + subscription?.id} variant="filled" />
          <Chip label={'AUD'} variant="filled" />
          <Chip label={subscriptionPeriod} variant="filled" />
          <Chip sx={{ paddingLeft: '9px' }} avatar={iconMap[icon]} label={labelMap[iconMap[icon]?.type?.name] || ''} variant="filled" />
        </div>
      </AccordionDetails>
    </Accordion>
  )

  return (
    <>
      {(!isEdit) ? (
        <Slide in={show} direction="left" onExited={() => setShow(true)}>
          {Card}
        </Slide>
      ) : (
        <>
          {Card}
        </>
      )}
    </>
  )
}

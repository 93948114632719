import { useState } from 'react'
import { IconButton, Popover, Typography } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

export function Copied ({ 
  toCopy
}) {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null)

  const handleCopyBtn = (e) => {
    setOpenPopover(e.currentTarget)
    navigator.clipboard.writeText(toCopy)
  }

  return (
    <>
      <IconButton
        color="default"
        onClick={(e) => handleCopyBtn(e)}
      >
        <ContentCopyIcon sx={{ width: '20px' }} />
      </IconButton>
      <Popover
        open={Boolean(openPopover)}
        anchorEl={openPopover}
        onClose={() => setOpenPopover(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <Typography onMouseLeave={() => setOpenPopover(null)} sx={{ p: 2 }}>Copied!</Typography>
      </Popover>
    </>
  )
}

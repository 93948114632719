import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, 
  DialogActions, Divider, List, ListItem, ListItemText, Typography } from '@mui/material'
import { Copied } from '../../Popover'

export function Contacts ({ 
  contacts,
  openDialog,
  setOpenDialog
}) {
  return (
    <Dialog
      open={openDialog}
      fullWidth
      onClose={() => setOpenDialog(false)}
    >
      <DialogTitle>
        Contacts
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{ paddingBottom: '20px' }}>
          View the contact emails, and associated id's
        </DialogContentText>
        <Divider />
        {(contacts && contacts.length > 0) && (
          <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            {(contacts?.map(contact => (
              <ListItem key={contact.id}>
                <Copied toCopy={contact?.email} />
                <ListItemText primary={contact.email} secondary={'Contact ID: ' +contact.id} sx={{ paddingLeft: '12px'}} />
              </ListItem>
            )))}
          </List>
        )}
        {(!contacts || contacts.length === 0)  && (
          <Typography style={{ opacity: '70%' }}>
            No contacts associated with this company.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

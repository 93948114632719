/**
 * Convert an array of objects to CSV
 *
 * @param {Object[]} objArray An array of objects
 * @returns {string}
 */
export const convertToCSV = (objArray: object[]): string => {
  if (objArray.length === 0) return ''

  const headers = Object.keys(objArray[0])

  const csvContent = [
    headers.join(','),
    ...objArray.map(row =>
      headers.map(fieldName => JSON.stringify(row[fieldName], (key, value) => value === null ? '' : value)).join(',')
    )
  ].join('\n')

  return csvContent
}

/**
 * Format an invoice JSON
 *
 * @param {string} invoiceJson The invoice JSON as a string
 * @returns {string}
 */
export const formatInvoices = (invoiceJson: string): string => {
  const formattedInv = Object.entries(JSON.parse(invoiceJson)).map(([invoice, amount]) => `Invoice #${invoice}: $${amount || 0}`).join('\n')
  return formattedInv
}

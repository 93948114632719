import { useState, useEffect } from 'react'
import { FormControl, IconButton, InputLabel, InputAdornment, OutlinedInput, FormHelperText } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import SearchIcon from '@mui/icons-material/Search'

export function Searchbar ({
    originalCases,
    filteredCases,
    setFilteredCases
  }) {
  const [filter, setFilter] = useState('')

  useEffect(() => {
    setFilteredCases(
      originalCases.filter(c =>
        c.customer_name?.toLowerCase().includes(filter?.toLowerCase()) ||
        c.case_number?.includes(filter?.toLowerCase()) ||
        c.case_originator?.includes(filter?.toLowerCase())
      )
    )
  }, [filter, originalCases, setFilteredCases])

  return (
    <FormControl sx={{ m: 1, width: 200 }} variant="outlined" size="small">
      <InputLabel>Search for case</InputLabel>
      <OutlinedInput
        label="Search for case"
        placeholder="Case Name or #"
        value={filter}
        size="small"
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              onClick={() => setFilter('')}
              edge="end"
            >
              {filter.length > 0 ? <ClearIcon /> : <SearchIcon />}
            </IconButton>
          </InputAdornment>
        }
        onChange={(e) => setFilter(e.target.value)}
      />
      <FormHelperText>Filter by case name or #</FormHelperText>
    </FormControl>
  )
}

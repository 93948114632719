import { memo, useState, useEffect, useCallback } from 'react'
import { useDispatch } from 'react-redux'
import authProvider from '../../authProvider'
import { useGetPermissionsQuery, useGetManagersQuery } from '../../services/permissionsApi'
import { setEmail } from '../../store/global'

interface UserProps {
  email?: string | null
}

export const Permissions = memo(() => {
  const [user, setUser] = useState(null)
  const dispatch = useDispatch()

  useGetPermissionsQuery(user, { skip: !user })
  useGetManagersQuery(user?.email, { skip: !user?.email })
  
  const getIdentity = useCallback(async () => {
    try {
      const userData: UserProps = await authProvider.getIdentity()
      if (userData) {
        dispatch(setEmail(userData?.email))
        setUser(userData)
      }
    } catch (error) {
      console.error('Failed to get user identity', error)
    }
  }, [dispatch, setUser])

  useEffect(() => {
    getIdentity()
  }, [getIdentity])

  return null
})

import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../interface/RootState'
import { canRead, queries } from '../utility'
import { Container, Fade, Skeleton, Slide, Typography } from '@mui/material'
import { CompanylessOTP } from '../components/About/Dialogs'
import { BugReportCard, OTPCard, BacklinksCard } from '../components/Settings/Cards'
import { useGetAllBacklinksQuery } from '../services/backlinksApi'
import { ExportBacklinks } from '../components/Settings/Dialogs'
import type { BacklinksDataInterface } from  '../interface/data/BacklinksDataInterface'

interface Contact {
  active: boolean
  id: string
  email: string
  firstName: string
  lastName: string
  createDate: string
  lastModifiedDate: string
}

const oneMonthPrior = (new Date(new Date().setMonth(new Date().getMonth() - 1))).toISOString()
const today = (new Date()).toISOString()

export function ToolsPage() {
  // OTP states
  const [contact, setContact] = useState<Contact>()
  const [otp, setOtp] = useState([])
  const [openDialogOTP, setOpenDialogOTP] = useState<boolean>(false)
  const [loadingOTP, setLoadingOTP] = useState<boolean>(false)
  const [fetchedOTP, setFetchedOTP] = useState<boolean>(false)
  const [otpError, setOtpError] = useState<boolean>(false)
  // Backlink Export State
  const [openDialogBacklink, setOpenDialogBacklink] = useState<boolean>(false)
  const [backlinkDateFrom, setBacklinkDateFrom] = useState<string>(oneMonthPrior)
  const [backlinkDateTo, setBacklinkDateTo] = useState<string>(today)
  const [backlinkConfirm, setBacklinkConfirm] = useState<boolean>(false)

  //*--- Initialie RTK Queries
  const { data: backlinkData = [], isLoading: backlinkLoading, isSuccess: backlinkSuccess, isError: backlinkError } = useGetAllBacklinksQuery<BacklinksDataInterface>({ dateFrom: backlinkDateFrom, dateTo: backlinkDateTo }, { skip: !backlinkConfirm })

  //* Extract data from Redux
  const { email: userEmail, permissions } = useSelector((store: RootState) => store.global.user)

  //* Queries
  const getQuery = async (contactEmail) => {
    const data = await queries.getContactDetails(contactEmail)
    return data || false
  }
  const createOTP = async (contactEmail, brandId) => {
    const data = await queries.createOTP(contactEmail, brandId, userEmail)
    return data || false
  }

  //* Handlers
  const handleCloseOTP = async (generate, contactEmail, brandId) => {
    if (!generate) {
      setOpenDialogOTP(false)
      return
    }
    try {
      setLoadingOTP(true)
      // extract assocaited contact id based on email
      const contactData = await getQuery(contactEmail)
      if (contactData) {
        setContact(contactData)
      } else {
        throw new Error('Could not find the associated contact')
      }
      // now create the otp for the conrtact
      const otpRes = await createOTP(Number(contactData?.id), brandId)
      if (!otpRes || !otpRes?.response) {
        throw new Error('Could not create OTP for contact')
      }
      setOtp(otpRes?.response)
    } catch (error) {
      console.error('Error:', error)
      setOtpError(true)
    } finally {
      setFetchedOTP(true)
      setLoadingOTP(false)
    }
  }
  const handleCloseBacklink = async (generate) => {
    if (generate) {
      setBacklinkConfirm(true)
    } else {
      setOpenDialogBacklink(false)
    }
  }

  return (
    <>
      <Slide in direction="left">
        <Fade in>
          <Container maxWidth="lg">
            <div className="flex justify-between">
              <Typography variant="h5" sx={{ paddingTop: '24px', paddingBottom: '12px' }}>
                Additional Tools
              </Typography>
            </div>
            <div className="flex flex-wrap gap-[10px] justify-center md:justify-normal items-center">
              <BugReportCard />
              {!permissions && (
                <>
                  <Skeleton animation="wave" width="316px" height="130px" />
                  <Skeleton animation="wave" width="316px" height="130px" />
                </>
              )}
              {canRead(permissions, 'otp') && (
                <OTPCard
                  setOpenDialogOTP={setOpenDialogOTP}
                />
              )}
              {canRead(permissions, 'exportbacklinks') && (
                <BacklinksCard
                  setOpenDialogBacklink={setOpenDialogBacklink}
                />
              )}
            </div>
          </Container>
        </Fade>
      </Slide>
      <CompanylessOTP
        otp={otp}
        openDialog={openDialogOTP}
        handleClose={handleCloseOTP}
        loading={loadingOTP}
        completed={fetchedOTP}
        error={otpError}
      />
      <ExportBacklinks
        data={backlinkData}
        dateTo={backlinkDateTo}
        dateFrom={backlinkDateFrom}
        setDateTo={setBacklinkDateTo}
        setDateFrom={setBacklinkDateFrom}
        openDialog={openDialogBacklink}
        handleClose={handleCloseBacklink}
        loading={backlinkLoading}
        completed={backlinkConfirm}
        error={backlinkError}
      />
    </>
  )
}

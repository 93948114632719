import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { layoutStyles } from './styles'

export const AuthProvider = ({ wrapperProps, renderContent, Content, PageTitle }) => {
  return (
    <Box component="div" style={layoutStyles} {...(wrapperProps ?? {})}>
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          height: '100vh',
        }}>
        {renderContent ? (
          renderContent(Content, PageTitle)
        ) : (
          <>
            {PageTitle}
            {Content}
          </>
        )}
      </Container>
    </Box>
  )
}

import React, { useState } from 'react'
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, 
  DialogTitle, FormControlLabel, FormGroup, IconButton, Tooltip
 } from '@mui/material'
import SortIcon from '@mui/icons-material/Sort'

export function CustomerSearchFilter({
  fullSize,
  inactiveBox,
  setInactiveBox,
  brandlessBox,
  setBrandlessBox
}) {
  const [open, setOpen] = useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = (event: React.SyntheticEvent<unknown>, reason?: string) => {
    if (reason !== 'backdropClick') {
      setOpen(false)
    }
  }

  return (
    <>
      <Tooltip title="Search Filters">
        <IconButton onClick={handleClickOpen} size="small" sx={{ color: fullSize ? '#757575' : '#ffffff', marginLeft: '5px' }}>
          <SortIcon />  
        </IconButton>
      </Tooltip>
      <Dialog disableEscapeKeyDown open={open} onClose={handleClose}>
        <DialogTitle>
          Search Filters
        </DialogTitle>
        <DialogContent>
          <Box component="form" sx={{ display: 'flex', flexWrap: 'wrap', flexFlow: 'column' }}>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={inactiveBox} onChange={(e) => setInactiveBox(e.target.checked)} />} label="Show Inactive Customers" />
            </FormGroup>
            <FormGroup>
              <FormControlLabel control={<Checkbox checked={brandlessBox} onChange={(e) => setBrandlessBox(e.target.checked)} />} label="Show Unbranded Customers" />
            </FormGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

import 'tailwindcss/tailwind.css'
import { useEffect } from 'react'
import { Refine, Authenticated } from '@refinedev/core'
import { DevtoolsProvider } from '@refinedev/devtools'
import { RefineKbar, RefineKbarProvider } from '@refinedev/kbar'
import { ErrorComponent, notificationProvider, RefineSnackbarProvider } from '@refinedev/mui'
import { dataProvider, liveProvider } from '@refinedev/supabase'
import GlobalStyles from '@mui/material/GlobalStyles'
import CssBaseline from '@mui/material/CssBaseline'
import { BrowserRouter, Route, Routes, Outlet } from 'react-router-dom'
import routerBindings, { NavigateToResource, CatchAllNavigate, UnsavedChangesNotifier } from '@refinedev/react-router-v6'
import { supabaseClient } from './utility'
import { ColorModeContextProvider } from './contexts/color-mode'
import { Header } from './components/Header'
import { OutstandingCases, SubmittedCases, TeamCases, CasesTable } from './components/Cases'
import authProvider from './authProvider'
import { HomePage } from './pages/HomePage'
import { CustomerPage } from './pages/CustomerPage'
import { BacklinkingPage } from './pages/BacklinkingPage'
import { AccountProductsPage } from './pages/AccountProductsPage'
import { KeywordsPage } from './pages/KeywordsPage'
import { SettingsPage } from './pages/SettingsPage'
import { SipPage } from './pages/SipPage'
import { AllFormsPage } from './pages/AllFormsPage'
import { FormPage } from './pages/FormPage'
import { ToolsPage } from './pages/ToolsPage'
import { AboutPage } from './pages/AboutPage'
import { ClientLoginsPage } from './pages/ClientLoginsPage'
import { CreditCardPage } from './pages/CreditCardPage'
import { CustomerCasesPage } from './pages/CustomerCasesPage'
import { NewCasePage } from './pages/NewCasePage'
import { CaseSummaryPage } from './pages/CaseSummaryPage'
import { ThemedLayoutV2 } from './components/layout'
import { LoginPage, ForgotPasswordPage, RegisterPage } from './components/auth'
import HomeIcon from '@mui/icons-material/Home'
import TuneIcon from '@mui/icons-material/Tune'
import WorkOutlineIcon from '@mui/icons-material/WorkOutline'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import { ClearCacheAndCookies } from './components/Cookies/ClearCacheAndCookies'
import { Permissions } from './components/Permissions'
import { BugReport } from './components/iFrame/BugReport'

function App () {
  useEffect(() => {
    LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_KEY)
  }, []) // initial page load

  return (
    <BrowserRouter>
      <RefineKbarProvider>
        <ColorModeContextProvider>
          <Permissions />
          <CssBaseline />
          <GlobalStyles styles={{ html: { WebkitFontSmoothing: 'auto' } }} />
          <RefineSnackbarProvider>
            <DevtoolsProvider>
              <Refine
                dataProvider={dataProvider(supabaseClient)}
                liveProvider={liveProvider(supabaseClient)}
                authProvider={authProvider}
                routerProvider={routerBindings}
                notificationProvider={notificationProvider}
                resources={[{
                  name: 'home',
                  list: '/home',
                  meta: {
                    label: 'Home',
                    canDelete: false,
                    icon: <HomeIcon />
                  }
                }, {
                  meta: {
                    label: 'Customer',
                    route: '/customers'
                  },
                  name: 'customers',
                  show: '/customers/show/:id'
                }, {
                  name: 'cases',
                  list: '/cases',
                  meta: {
                    label: 'Cases',
                    canDelete: false,
                    icon: <WorkOutlineIcon/>
                  }
                }, {
                  name: 'settings',
                  list: '/settings',
                  meta: {
                    label: 'Settings',
                    canDelete: false,
                    icon: <TuneIcon/>
                  }
                }]}
                options={{
                  syncWithLocation: true,
                  warnWhenUnsavedChanges: true
                }}>
                <ClearCacheAndCookies />
                <BugReport />
                <Routes>
                  <Route
                    element={
                      <Authenticated key="authenticated-inner" fallback={<CatchAllNavigate to="/login" />}>
                        <ThemedLayoutV2 Header={() => <Header isSticky={true} />}>
                          <Outlet />
                        </ThemedLayoutV2>
                      </Authenticated>
                    }>
                    <Route index element={<NavigateToResource resource="home" />} />
                    <Route path="/home" element={<HomePage />} />
                    <Route
                      path="/customers/show/:customerId"
                      element={
                        <CustomerPage />
                      }>
                      <Route index element={<AboutPage />} />
                      <Route path="products" element={<AccountProductsPage />} />
                      <Route path="products/:productId/keywords" element={<KeywordsPage />} />
                      <Route path="products/:productId/keywords/:keywordId/backlinks" element={<BacklinkingPage />} />
                      <Route path="forms" element={<AllFormsPage />} />
                      <Route path="forms/:formId" element={<FormPage />} />
                      <Route path="forms/:formId/:resultId" element={<FormPage />} />
                      <Route path="clientlogins" element={<ClientLoginsPage />} />
                      <Route path="creditcard" element={<CreditCardPage />} />
                      <Route path="cases" element={<CustomerCasesPage />} />
                      <Route path="cases/new" element={<NewCasePage />} />
                      <Route path="cases/edit/:caseId" element={<NewCasePage />} />
                    </Route>

                    <Route
                      path="/cases"
                      element={
                        <CaseSummaryPage />
                      }>
                      <Route index element={<OutstandingCases />} />
                      <Route path="outstanding" element={<OutstandingCases />} />
                      <Route path="outstanding/:caseNo" element={<OutstandingCases />} />
                      <Route path="submitted" element={<SubmittedCases />} />
                      <Route path="team" element={<TeamCases />} />
                      <Route path="summary" element={<CasesTable />} />
                    </Route>

                    <Route
                      path="/settings"
                      element={
                        <SettingsPage />
                      }>
                      <Route index element={<ToolsPage />} />
                      <Route path="/settings/sip" element={<SipPage />} />
                    </Route>
                    
                    <Route path="*" element={<ErrorComponent />} />
                  </Route>
                  <Route
                    element={
                      <Authenticated key="authenticated-outer" fallback={<Outlet />}>
                        <NavigateToResource />
                      </Authenticated>
                    }>
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/register" element={<RegisterPage />} />
                    <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                  </Route>
                </Routes>

                <RefineKbar />
                <UnsavedChangesNotifier />
              </Refine>
            </DevtoolsProvider>
          </RefineSnackbarProvider>
        </ColorModeContextProvider>
      </RefineKbarProvider>
    </BrowserRouter>
  )
}

export default App

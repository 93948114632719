import { api } from './api'
import { queries } from '../utility'
import { setCreditCard } from '../store/global'
import { store } from '../store'

export const cardApi = api.injectEndpoints({
  reducerPath: 'cardApi',
  endpoints: (build) => ({
    getCreditCard: build.query({
      queryFn: async (arg) => {
        const customerId = arg
        const response = await queries.getCreditCard(customerId)
        store.dispatch(setCreditCard(response))
        return { data: response }
      },
    }),
    createCreditCard: build.mutation({
      queryFn: async (arg) => {
        const { body, customerId } = arg
        const response = await queries.createCreditCard(body, customerId) // Update Supabase table
        if (response) {
          store.dispatch( // Update the redux store
            cardApi.util.updateQueryData('getCreditCard', customerId, (draft) => {
              draft.push(response[0])
            })
          )
        }
        return { data: response }
      },
    }),
    updateCreditCard: build.mutation({
      queryFn: async (arg) => {
        const { itemId, body, customerId } = arg
        const response = await queries.updateCreditCard(body, customerId) // Update Supabase table
        store.dispatch( // Update the redux store
          cardApi.util.updateQueryData('getCreditCard', customerId, (draft) => {
            const index = draft.findIndex(d => d.id === itemId)
            if (index !== -1) {
              draft[index] = { ...draft[index], ...body }
            }
          })
        )
        return { data: response }
      },
    }),
    deleteCreditCard: build.mutation({
      queryFn: async (arg) => {
        const { itemId, customerId, subscriptionId } = arg
        const response = await queries.deleteCreditCard(customerId) // Update Supabase table
        store.dispatch( // Update redux store
          cardApi.util.updateQueryData('getCreditCard', customerId, (draft) => {
            const updated = draft.filter(d => d.id !== itemId)
            return updated
          })
        )
        return { data: response }
      },
    }),
  }),
})

export const {
  useGetCreditCardQuery,
  useCreateCreditCardMutation,
  useUpdateCreditCardMutation,
  useDeleteCreditCardMutation
} = cardApi

import { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import { Chip, Container, Divider, Fade, Slide, Typography } from '@mui/material'
import { Searchbar, SelectCaseStatus } from './Filters'
import type { CaseQueries } from '../../interface/queries/CaseQueries'
import { CaseStepper } from './CaseStepper'

export function SubmittedCases() {
  const { submittedCases, setSubmittedCases } = useOutletContext<CaseQueries>()
  const [filteredCases, setFilteredCases] = useState(submittedCases)

  return (
    <Slide in direction="left">
      <Fade in>
        <Container maxWidth="lg" style={{ padding: '0px', paddingTop: '24px', paddingLeft: '16px' }}>
          <Typography variant="h5" gutterBottom>
            Submitted Cases
          </Typography>
          <div className="flex flex-row items-center justify-between">
            <Typography component="div" style={{ opacity: '70%' }}>
              You have  <Chip size="small" label={submittedCases?.length || 0} color="primary" />  Submitted case{submittedCases?.length === 1 ? '' : 's'}.
            </Typography>
            {(submittedCases?.length > 0) && (
              <div className="flex flex-row">
                <Searchbar
                  originalCases={submittedCases}
                  filteredCases={filteredCases}
                  setFilteredCases={setFilteredCases}
                />
                <SelectCaseStatus
                  originalCases={submittedCases}
                  filteredCases={filteredCases}
                  setFilteredCases={setFilteredCases}
                />
              </div>
            )}
          </div>
          <Typography component="div" style={{ opacity: '70%' }}>
            {filteredCases?.length !== submittedCases?.length ? filteredCases?.length + ' filtered cases' : ''}
          </Typography>

          {(submittedCases?.length > 0) ? (
            <>
              <Divider sx={{ paddingBottom: '20px' }} />
              <CaseStepper
                cases={filteredCases}
                caseType="Submitted"
              />
            </>
          ) : (
            <Typography style={{ opacity: '70%' }}>
              You have no Submitted cases to review.
            </Typography>
          )}
        </Container>
      </Fade>
    </Slide>
  )
}

/**
 * Convert a timestamp to a Date
 *
 * @param {number} timestamp Timestamp number
 * @param {string} country Country to set Locale to
 * @returns {string}
 */
export const timestampToDate = (
  timestamp: number,
  country: string = 'AU'
): string => {
  return new Date(timestamp * 1000).toLocaleDateString('en-' + country)
}

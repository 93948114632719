import React from 'react'
import { useOutletContext } from 'react-router-dom'
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport } from '@mui/x-data-grid'
import { TableTemplateNew } from '../Table'
import { Chip, IconButton, Link, Tooltip, } from '@mui/material'
import { genRandomUUID } from '../../utility/helpers/miscHelpers'
import { formatAsDayMonthYear } from '../../utility/helpers/dateHelpers'
import { emailToName } from '../../utility/helpers/miscHelpers'
import { formatInvoices } from '../../utility/helpers/subscriptionHelpers'
import { getCpanelUrl} from '../../utility/helpers/brandHelpers'
import { formatReceiptSummary, getStatusColour } from '../../utility/helpers/caseHelpers'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { CaseTypeIcon } from '../Icons'
import { CaseQueries } from '../../interface/queries/CaseQueries'

interface DataProps {}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

const dataTitle = 'Case'
const initialForm: DataProps = {
}
const columnFields = () => {
  return [
    { field: 'case_number', headerName: 'Case Number', width: 125, editable: false,
      renderCell: (params) => params.row.case_number
    },
    { field: 'status', headerName: 'Status', width: 155, editable: false,
      renderCell: (params) => (
        <Tooltip title={
          params.row.status.includes('Rejected') && params.row.approval_msg?.length > 0
            ?
          `This case was rejected with the following note from Accounts: ${params.row.approval_msg}`
            :
          params.row.status.includes('Awaiting')
            ?
          `This case is currently sitting with ${emailToName(params.row.submitted_manager)}`
            :
          null
          }>
          <Chip
            label={params.row.status === 'Closed' ? 'Approved' : params.row.status}
            color={getStatusColour(params.row.status)}
            size="small"
          />
        </Tooltip>
      )
    },
    { field: 'case_type', headerName: 'Case Type', width: 160, editable: false, description: 'The type of case, can be a Pause, Cancel, Bulling Alterations of Altered Products',
      renderCell: (params) => (
        <div className="overflow-hidden truncate flex items-center gap-[5px]">
          <CaseTypeIcon caseData={params.row} />
          {(params.row.case_type)}
        </div>
      )
    },
    { field: 'case_originator', headerName: 'Case Originator', width: 145, editable: false,
      renderCell: (params) => (
        <div className="flex flex-row gap-[7px] overflow-hidden truncate">
          <AccountCircleIcon sx={{ opacity: '50%'}} />
          {emailToName(params.row.case_originator)}
        </div>
      )
    },
    { field: 'request_date', headerName: 'Request Date', width: 125, editable: false,
      valueGetter: (value) => {
        if (!value) return value
        return formatAsDayMonthYear(value.row.request_date)
      },
      valueFormatter: (value) => {
        if (value == null) return ''
        return value.value
      },
    },
    { field: 'customer_name', headerName: 'Company', width: 165, editable: false, description: 'Admin Panel company link',
      renderCell: (params) => (
        <div className="overflow-hidden truncate">
          <Tooltip title="Admin Panel Link">
            <Link target="_blank" href={`${getCpanelUrl('dd')}/customers/show/${params.row.customer_id}`}>
              <IconButton color="primary">
                <img className="flex w-[22px] max-h-[22px] items-center justify-center self-center" src={'/images/logo_natural_DD.svg'} />
              </IconButton>
              {params.row.customer_name}
            </Link>
          </Tooltip>
        </div>
      )
    },
    { field: 'subscription_name', headerName: 'Subscription', width: 165, editable: false,
      renderCell: (params) => (
        <div className="overflow-hidden truncate">
          <Tooltip title="Chargebee Link">
            <Link target="_blank" href={`https://disruptdigital.chargebee.com/d/subscriptions/${params.row.subscription_id}#summary`}>
              <IconButton color="primary">
                <img className="flex w-[22px] max-h-[22px] items-center opacity-[70%] justify-center self-center" src={'/images/Chargebee.svg'} />
              </IconButton>
              {params.row.subscription_name}
            </Link>
          </Tooltip>
        </div>
      )
    },
    { field: 'case_notes', headerName: 'Case Notes', width: 155, editable: false, description: 'Case notes entered by the case originator',
      renderCell: (params) => (
        <div className="overflow-hidden truncate">
          {(params.row.case_notes && String(params.row.case_notes)?.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}<br/>
            </React.Fragment>
          )))}
        </div>
      )
    },
    { field: 'pause_date', headerName: 'Pause Date', width: 125, editable: false,
      valueGetter: (value) => {
        if (!value) return value
        return value.row.case_type === 'Pause' ? formatAsDayMonthYear(value.row.pause_date) : null
      },
      valueFormatter: (value) => {
        if (value == null) return ''
        return value.value
      },
    },
    { field: 'resume_date', headerName: 'Resume Date', width: 125, editable: false,
      valueGetter: (value) => {
        if (!value) return value
        return value.row.case_type === 'Pause' ? formatAsDayMonthYear(value.row.resume_date) : null
      },
      valueFormatter: (value) => {
        if (value == null) return ''
        return value.value
      },
    },
    { field: 'cancel_date', headerName: 'Cancel Date', width: 125, editable: false,
      valueGetter: (value) => {
        if (!value) return value
        return value.row.case_type === 'Cancel' ? formatAsDayMonthYear(value.row.cancel_date) : null
      },
      valueFormatter: (value) => {
        if (value == null) return ''
        return value.value
      },
    },
    { field: 'altered_products', headerName: 'Altered Products', width: 125, editable: false,
      renderCell: (params) => (
        <div className="overflow-hidden truncate">
          {(params.row.altered_products && String(params.row.altered_products)?.split('\n').map((line, index) => (
            <React.Fragment key={index}>
              {line}<br/>
            </React.Fragment>
          )))}
        </div>
      )
    },
    { field: 'future_credit', headerName: 'Future Credit', width: 125, editable: false,
      renderCell: (params) => params.row.future_credit > 0 ? `$${params.row.future_credit}` : null
    },
    { field: 'cancel_reason', headerName: 'Cancel Reason', width: 125, editable: false,
      renderCell: (params) => (
        <div className="overflow-hidden truncate">
          {params.row.cancel_reason}
        </div>
      )
    },
    { field: 'invoice_refunds', headerName: 'Invoice Refunds', width: 225, editable: false,
      valueGetter: (value) => {
        if (!value) return value
        return JSON.stringify(value.row.invoice_refunds)
      },
      valueFormatter: (value) => {
        if (value == null || value?.value === '{}') return ''
      },
      renderCell: (value) => {
        return (
          <div className="whitespace-pre-wrap">
            {formatInvoices(value.value)}
          </div>
        )
      }
    },
    { field: 'invoice_credits', headerName: 'Invoice Credits', width: 225, editable: false,
      valueGetter: (value) => {
        if (!value) return value
        return JSON.stringify(value.row.invoice_credits)
      },
      valueFormatter: (value) => {
        if (value == null || value?.value === '{}') return ''
      },
      renderCell: (value) => {
        return (
          <div className="whitespace-pre-wrap">
            {formatInvoices(value.value)}
          </div>
        )
      }
    },
    { field: 'submitted_manager', headerName: 'Currently With', width: 125, editable: false,
      renderCell: (params) => (
        <div className="flex flex-row gap-[7px] overflow-hidden truncate">
          <AccountCircleIcon sx={{ opacity: '50%'}} />
          {emailToName(params.row.submitted_manager)}
        </div>
      )
    },
    { field: 'submit_to_next', headerName: 'Requires Manager Approval?', width: 125, editable: false,
      renderCell: (params) => params.row.submit_to_next
    },
    { field: 'submitted_by', headerName: 'Last Approved By', width: 125, editable: false,
      renderCell: (params) => (
        <div className="flex flex-row gap-[7px] overflow-hidden truncate">
          <AccountCircleIcon sx={{ opacity: '50%'}} />
          {emailToName(params.row.submitted_by)}
        </div>
      )
    },
    { field: 'cancel_immediately', headerName: 'Cancel Immediately', width: 125, editable: false,
      renderCell: (params) => params.row.cancel_immediately
    },
    { field: 'cancel_endterm', headerName: 'Cancel End of Term', width: 125, editable: false,
      renderCell: (params) => params.row.cancel_endterm
    },
    { field: 'pause_immediately', headerName: 'Pause Immediately', width: 125, editable: false,
      renderCell: (params) => params.row.pause_immediately
    },
    { field: 'pause_endterm', headerName: 'Pause End of Term', width: 125, editable: false,
      renderCell: (params) => params.row.pause_endterm
    },
    { field: 'pause_indefinitely', headerName: 'Pause Indefinitely', width: 125, editable: false,
      renderCell: (params) => params.row.pause_indefinitely
    },
    { field: 'approval_msg', headerName: 'Approval Message', width: 200, editable: false,
      renderCell: (params) => params.row.approval_msg
    },
    { field: 'updated_at', headerName: 'Last Updated', width: 125, editable: false,
      valueGetter: (value) => {
        if (!value) return value
        return formatAsDayMonthYear(value.row.updated_at)
      },
      valueFormatter: (value) => {
        if (value == null) return ''
        return value.value
      },
    },
    { field: 'created_at', headerName: 'Created At', width: 125, editable: false,
      valueGetter: (value) => {
        if (!value) return value
        return formatAsDayMonthYear(value.row.created_at)
      },
      valueFormatter: (value) => {
        if (value == null) return ''
        return value.value
      },
    },
    { field: 'deleted_at', headerName: 'Deleted At', width: 125, editable: false,
      renderCell: (params) => params.row.deleted_at ? formatAsDayMonthYear(params.row.deleted_at) : null
    },
    { field: 'receipt_data', headerName: 'Receipt Data', width: 1000, editable: false,
      valueGetter: (value) => {
        if (!value) return value
        return JSON.stringify(value.row.receipt_data)
      },
      valueFormatter: (value) => {
        if (value == null) return ''
      },
      renderCell: (value) => {
        return (
          <div className="whitespace-pre-wrap">
            {formatReceiptSummary(value.value)}
          </div>
        )
      }
    },
  ]
}

const upsertFields = () => {}
const createEmptyRow = () => {
  return ({
    id: genRandomUUID(), // temporary random uuid for the purpose of rendering table in refine
  })
}

export function CasesTable () {
  const { allCasesData, allCasesLoading } = useOutletContext<CaseQueries>()

  return (
    <TableTemplateNew
      CustomToolbar={CustomToolbar}
      dataTitle={dataTitle}
      columnFields={columnFields}
      initialForm={initialForm}
      upsertFields={upsertFields}
      createEmptyRow={createEmptyRow}
      tableData={allCasesData}
      loading={allCasesLoading}
      tableSortDir="desc"
      permissionProp="case_summary"
      pinLeft={['case_number']}
      autoRowHeight={true}
    />
  )
}

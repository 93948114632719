/* eslint-disable  @typescript-eslint/no-explicit-any */
import { AuthBindings } from '@refinedev/core'
import { supabaseClient } from './utility'

const authProvider: AuthBindings = {
  login: async ({ email, password, providerName }) => {
    // sign in with oauth
    try {
      if (providerName) {
        let redirectUri = window.location.href
        if (window.location.href.includes('login?to')) { // redirect to a specific url
          redirectUri = window.location.href.replaceAll('%2F', '/').replace('/login?to=', '')
        }
        const { data, error } = await supabaseClient.auth.signInWithOAuth({
          provider: providerName,
          options: {
            redirectTo: redirectUri
          }
        })

        if (error) {
          return {
            success: false,
            error,
          }
        }

        if (data?.url) {
          return {
            success: true,
            redirectTo: '/',
          }
        }
      }

      // sign in with email and password
      const { data, error } = await supabaseClient.auth.signInWithPassword({
        email,
        password,
      })

      if (error) {
        return {
          success: false,
          error,
        }
      }

      if (data?.user) {
        return {
          success: true,
          redirectTo: '/',
        }
      }
    } catch (error: any) {
      return {
        success: false,
        error,
      }
    }

    return {
      success: false,
      error: {
        message: 'Login failed',
        name: 'Invalid email or password',
      },
    }
  },
  register: async ({ email, password }) => {
    try {
      const { data, error } = await supabaseClient.auth.signUp({
        email,
        password,
      })

      if (error) {
        return {
          success: false,
          error,
        }
      }

      if (data) {
        return {
          success: true,
          redirectTo: '/',
        }
      }
    } catch (error: any) {
      return {
        success: false,
        error,
      }
    }

    return {
      success: false,
      error: {
        message: 'Register failed',
        name: 'Invalid email or password',
      },
    }
  },
  forgotPassword: async ({ email }) => {
    try {
      const { data, error } = await supabaseClient.auth.resetPasswordForEmail(email, {
        redirectTo: `${window.location.origin}/update-password`,
      })

      if (error) {
        return {
          success: false,
          error,
        }
      }

      if (data) {
        return {
          success: true,
        }
      }
    } catch (error: any) {
      return {
        success: false,
        error,
      }
    }

    return {
      success: false,
      error: {
        message: 'Forgot password failed',
        name: 'Invalid email',
      },
    }
  },
  updatePassword: async ({ password }) => {
    try {
      const { data, error } = await supabaseClient.auth.updateUser({
        password,
      })

      if (error) {
        return {
          success: false,
          error,
        }
      }

      if (data) {
        return {
          success: true,
          redirectTo: '/',
        }
      }
    } catch (error: any) {
      return {
        success: false,
        error,
      }
    }
    return {
      success: false,
      error: {
        message: 'Update password failed',
        name: 'Invalid password',
      },
    }
  },
  logout: async () => {
    const { error } = await supabaseClient.auth.signOut()

    if (error) {
      return {
        success: false,
        error,
      }
    }

    return {
      success: true,
      redirectTo: '/',
    }
  },
  onError: async (error) => {
    console.error(error)
    return { error }
  },
  check: async () => {
    try {
      const { data } = await supabaseClient.auth.getSession()
      const { session } = data

      if (!session) {
        return {
          authenticated: false,
          error: {
            message: 'Check failed',
            name: 'Session not found',
          },
          logout: true,
          redirectTo: '/login',
        }
      }
    } catch (error: any) {
      return {
        authenticated: false,
        error: error || {
          message: 'Check failed',
          name: 'Not authenticated',
        },
        logout: true,
        redirectTo: '/login',
      }
    }

    return {
      authenticated: true,
    }
  },
  getIdentity: async () => {
    const { data } = await supabaseClient.auth.getUser()

    if (data?.user) {
      return {
        ...data.user,
        name: data.user.email,
      }
    }

    // Log the user out if the session is not found
    await supabaseClient.auth.signOut()
    return {
      authenticated: false,
      error: {
        message: 'Check failed',
        name: 'Session not found',
      },
      logout: true,
      redirectTo: '/login',
    }
  },
}

export default authProvider

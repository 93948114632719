import Tooltip from '@mui/material/Tooltip'
import FacebookIcon from '@mui/icons-material/Facebook'

export const MetaAdsIcon = () => {
  return (
    <Tooltip title="Meta Ads">
      <FacebookIcon sx={{ opacity: '66%' }} />
    </Tooltip>
  )
}

import { useState, useEffect, useCallback } from 'react'
import { Link, useOutletContext } from 'react-router-dom'
import { ClickupIcon, SeoIcon, GoogleAdsIcon, GoogleAnalyticsIcon, GoogleMerchantIcon, GoogleTagManagerIcon, MetaAdsIcon, OtherIcon } from '../components/Icons'
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport } from '@mui/x-data-grid'
import { Chip, TextField, FormControl, IconButton } from '@mui/material'
import { Link as MuiLink } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import Autocomplete from '@mui/material/Autocomplete'
import KeyIcon from '@mui/icons-material/Key'
import Tooltip from '@mui/material/Tooltip'
import InputAdornment from '@mui/material/InputAdornment'
import { GetIconByProduct } from '../components/Icons'
import { TableTemplateNew } from '../components/Table'
import { Copied } from '../components/Popover'
import { formatAsDayMonthYear } from '../utility/helpers/dateHelpers'
import { formatGoogleAdsId, getSubscriptionStyle } from '../utility/helpers/subscriptionHelpers'
import { capitalize } from '../utility/helpers/miscHelpers'
import { CompanyQueries } from '../interface/queries/CompanyQueries'
import { useUpdateSubscriptionInfoMutation } from '../services/subscriptionsApi.js'

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

const dataTitle = 'Account Product'
const initialForm = {
  id: 0,
  name: '',
  product_type: '',
  recurring: false,
  recurring_period_unit: 0,
  recurring_period: '',
  status: '',
  created_at: new Date(),
  domain: '',
  google_ads_id: '',
  meta_ads_id: '',
  project_link: '',
  seo_geolocation_id: 29889,
  tag_manager_id: '',
  tag_manager_linked_to: '',
  analytics_property_id: '',
  analytics_web_stream: '',
  analytics_linked_to: '',
  merchant_id: '',
  merchant_linked_to: '',
  search_console_linked_to: '',
  business_profile_linked_to: '',
  started_at: new Date(),
  brand_id: 8
}
const columnFields = (openUpdateForm, handleDialogOpen, allowEdit, allowDelete, customerId, subscriptionsCb, geolocations, mode) => {
  return [
    { field: 'name', headerName: 'Product', width: 275, editable: false,
      renderCell: (params) => (
        <Tooltip title={params.id}>
          <span className={getSubscriptionStyle(params.row.status, false) + ' p-[12px]'}>
            {params.row.name}
          </span>
        </Tooltip>
      )
    },
    {
      field: 'product_type', headerName: 'Type', width: 60, editable: false,
        renderCell: (params) => (
          <div className={getSubscriptionStyle(params.row.status)}>
            <GetIconByProduct name={params?.row?.name} mode={mode} />
          </div>
        )
    },
    { field: 'recurring', headerName: 'Recurring', width: 125, editable: false,
      renderCell: (params) => (
        <div className={getSubscriptionStyle(params.row.status)}>
          {params.row.recurring ? 'Subscription' : 'One-Time Fee'}
        </div>
      )
    },
    { field: 'recurring_period', headerName: 'Period', editable: false,
      renderCell: (params) => (
        <div className={getSubscriptionStyle(params.row.status, false)}>
          Every {params.row.recurring_period} {params.row.recurring_period > 1 ? params.row.recurring_period_unit + 's' : params.row.recurring_period_unit}
        </div>
      )
    },
    { field: 'status', headerName: 'Status', editable: false,
      renderCell: (params) => (
        (params.row.status?.toLowerCase().includes('active') && (
            <Tooltip title="This product full access to the Client Panel.">
              <div className={getSubscriptionStyle(params.row.status)}>
                <Chip
                  label="Active"
                  color="success"
                  size="small"
                />
              </div>
            </Tooltip>
          )
        ||
        (['trial', 'non_renewing'].includes(params.row.status?.toLowerCase()) && (
          <Tooltip title="This a non renewing, or trial product, which has temporary access to the Client Panel.">
            <div className={getSubscriptionStyle(params.row.status)}>
              <Chip
                label="Temporary"
                color="warning"
                size="small"
              />
            </div>
          </Tooltip>  
        )
        ) ||
        (['paused', 'cancelled', 'transferred', 'inactive'].includes(params.row.status?.toLowerCase()) && (
          <Tooltip title="This product is not considered as active, and will not be displayed in the Client Panel.">
            <div className={getSubscriptionStyle(params.row.status)}>
              <Chip
                label={capitalize(params.row.status, true)}
                color="error"
                size="small"
              />
            </div>
          </Tooltip>  
        )
        ) ||
        ('Other') // default value
      ))
    },
    { field: 'edit', headerName: 'Edit', width: 75,
      renderCell: (params) => (
        <IconButton color="info" onClick={() => openUpdateForm(params)} disabled={!allowEdit} >
          <EditIcon />
        </IconButton>
      )
    },
    { field: 'started_at', headerName: 'Start Date', width: 125, editable: false,
      renderCell: (params) => (
        <div className={getSubscriptionStyle(params.row.status)}>
          {formatAsDayMonthYear(params.row.started_at)}
        </div>
      )
    },
    { field: 'domain', headerName: 'SEO Website',  minWidth: 150, editable: false,
      renderCell: (params) => (
        <div className={'flex flex-row ' + getSubscriptionStyle(params.row.status)}>
          {(params?.row?.domain?.length > 0) && (
            <Tooltip title="Keywords">
              <Link to={`${params?.row?.id}/keywords`} rel="noopener noreferrer">
                <IconButton color="primary" >
                  <KeyIcon />
                </IconButton>
              </Link>
            </Tooltip>
          )}
          <MuiLink
            underline="hover" 
            color="secondary"
            component="button"
            sx={{ cursor: 'pointer', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
            onClick={() => window.open(`https://${params?.row?.domain}`, '_blank', 'noopener,noreferrer')}
          >
            {params?.row?.domain}
          </MuiLink>
        </div>
      )
    },
    { field: 'google_ads_id', headerName: 'Google Ads ID', width: 150, editable: false,
      renderCell: (params) => (
        params.row.google_ads_id ?
        <div className={getSubscriptionStyle(params.row.status)}>
          <span>{formatGoogleAdsId(params.row.google_ads_id)}</span>
          <Copied toCopy={formatGoogleAdsId(params.row.google_ads_id)} />
        </div>
        : null
      ),
    },
    { field: 'meta_ads_id', headerName: 'Meta Ads ID', editable: false,
      renderCell: (params) => (
        params.row.meta_ads_id ?
        <div className={getSubscriptionStyle(params.row.status)}>
          {params.row.meta_ads_id}
          <Copied toCopy={params.row.meta_ads_id} />
        </div>
        : null
      )
    },
    { field: 'project_link', headerName: 'Project Link', editable: false,
      renderCell: (params) => (
        <div className={getSubscriptionStyle(params.row.status)}>
          {params.row.project_link}
        </div>
      )
    },
    { field: 'seo_geolocation_id', headerName: 'Geo-Location',  editable: false, description: 'The Geo-location for the SEO Website',
      renderCell: (params) => (
        <span className="overflow-hidden truncate">
          {params.row.seo_geolocation_id ? geolocations.find(geo => geo.id === params.row.seo_geolocation_id)?.name : ''}
        </span>
      )
    },
    { field: 'tag_manager_id', headerName: 'GTM ID',  editable: false, description: 'The Google Tag Manager account ID',
      renderCell: (params) => (
        <div className={getSubscriptionStyle(params.row.status)}>
          {params.row.tag_manager_id}
        </div>
      )
    },
    { field: 'tag_manager_linked_to', headerName: 'GTM Linked', editable: false, description: 'The email address the Google Tag Manager account is linked to',
      renderCell: (params) => (
        <div className={getSubscriptionStyle(params.row.status)}>
          {params.row.tag_manager_linked_to}
        </div>
      )
    },
    { field: 'analytics_property_id', headerName: 'GA Property ID', editable: false, description: 'The Google Analytics Property ID',
      renderCell: (params) => (
        <div className={getSubscriptionStyle(params.row.status)}>
          {params.row.analytics_property_id}
        </div>
      )
    },
    { field: 'analytics_web_stream', headerName: 'GA Web Stream', editable: false, description: 'The Google Analytics Web Stream',
      renderCell: (params) => (
        <div className={getSubscriptionStyle(params.row.status)}>
          {params.row.analytics_web_stream}
        </div>
      )
    },
    { field: 'analytics_linked_to', headerName: 'GA Linked',  editable: false, description: 'The email address the Google Analytics account is linked to',
      renderCell: (params) => (
        <div className={getSubscriptionStyle(params.row.status)}>
          {params.row.analytics_linked_to}
        </div>
      )
    },
    { field: 'merchant_id', headerName: 'GMC ID',  editable: false, description: 'The Google Merchant Center ID',
      renderCell: (params) => (
        <div className={getSubscriptionStyle(params.row.status)}>
          {params.row.merchant_id}
        </div>
      )
    },
    { field: 'merchant_linked_to', headerName: 'GMC Linked',  editable: false, description: 'The email address the Google Merchant Center account is linked to',
      renderCell: (params) => (
        <div className={getSubscriptionStyle(params.row.status)}>
          {params.row.merchant_linked_to}
        </div>
      )
    },
    { field: 'search_console_linked_to', headerName: 'GSC Linked',  editable: false, description: 'The email address the Google Search Console account is linked to',
      renderCell: (params) => (
        <div className={getSubscriptionStyle(params.row.status)}>
          {params.row.search_console_linked_to}
        </div>
      )
    },
    { field: 'busness_profile_linked_to', headerName: 'GBP Linked',  editable: false, description: 'The email address the Google Business Profile account is linked to',
      renderCell: (params) => (
        <div className={getSubscriptionStyle(params.row.status)}>
          {params.row.business_profile_linked_to}
        </div>
      )
    },
    { field: 'created_at', headerName: 'Create Date', width: 125, editable: false,
      renderCell: (params) => (
        <div className={getSubscriptionStyle(params.row.status)}>
          {formatAsDayMonthYear(params.row.created_at)}
        </div>
      )
    },
  ]
}

const upsertFields = (create, formData, setFormData, customerId, geolocations, filteredFormTypes, subscriptionsCb, showPassword, handleClickShowPassword, invoices, mode) => {
  return (
    <>
      {(formData?.name?.toLowerCase().includes('seo')) && (
        <>
          <TextField
            label="Domain"
            fullWidth
            placeholder="www.website.com"
            helperText="Enter the SEO domain"
            value={formData.domain}
            onChange={(e) => setFormData({ ...formData, domain: e.target.value })}
          />
          <FormControl fullWidth>
            <Autocomplete
              disableClearable
              options={geolocations}
              getOptionLabel={(option) => option.canonical_name}
              value={geolocations.find((geo) => geo.id === formData.seo_geolocation_id) || null}
              onChange={(event, value) => setFormData({ ...formData, seo_geolocation_id: value ? value.id : '' })}
              filterOptions={(options, { inputValue }) =>
                options.filter((option) =>
                  option.canonical_name.toLowerCase().includes(inputValue.toLowerCase())
                ).slice(0, 10) // Limiting search results to 10 to improve performance
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Geo-Location"
                  fullWidth
                />
              )}
            />
          </FormControl>
        </>
      )}
      {((formData?.name?.toLowerCase().includes('facebook')) ||
        (formData?.name?.toLowerCase().includes('social'))) && (
        <TextField
          label="Meta Ads ID"
          fullWidth
          type="string"
          placeholder="1111222233334444"
          helperText="Enter the 16 digit Meta Ads ID"
          inputProps={{ maxLength : 18 }}
          value={formData.meta_ads_id}
          onChange={(e) => setFormData({ ...formData, meta_ads_id: e.target.value })}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <MetaAdsIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      {((formData?.name?.toLowerCase().includes('ads')) ||
        (formData?.name?.toLowerCase().includes('-ga')) ||
        (formData?.name?.toLowerCase().includes('adwords')) ||
        (formData?.name?.toLowerCase().includes('seo')) ||
        (formData?.name?.toLowerCase().includes('youtube'))) && (
        <>
          <TextField
            label="Google Ads ID"
            fullWidth
            type="string"
            placeholder="123-456-7890"
            helperText="Enter the 10 digit Google Ads ID"
            value={formatGoogleAdsId(formData.google_ads_id)}
            inputProps={{ maxLength : 12 }}
            onChange={(e) => setFormData({ ...formData, google_ads_id: formatGoogleAdsId(e.target.value) })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <GoogleAdsIcon mode={mode} />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Google Tag Manager ID"
            fullWidth
            placeholder="GTM-XXXXXXXXX"
            value={formData.tag_manager_id}
            onChange={(e) => setFormData({ ...formData, tag_manager_id: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <GoogleTagManagerIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Google Tag Manager Linked To"
            fullWidth
            value={formData.tag_manager_linked_to}
            onChange={(e) => setFormData({ ...formData, tag_manager_linked_to: e.target.value })}
          />
          <TextField
            label="Google Analytics Property ID"
            fullWidth
            value={formData.analytics_property_id}
            onChange={(e) => setFormData({ ...formData, analytics_property_id: e.target.value })}
          />
          <TextField
            label="Google Analytics Web Stream"
            fullWidth
            placeholder="G-XXXXXXX"
            value={formData.analytics_web_stream}
            onChange={(e) => setFormData({ ...formData, analytics_web_stream: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <GoogleAnalyticsIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Google Analytics Linked To"
            fullWidth
            value={formData.analytics_linked_to}
            onChange={(e) => setFormData({ ...formData, analytics_linked_to: e.target.value })}
          />
          <TextField
            label="Google Merchant ID"
            fullWidth
            placeholder="1456723"
            value={formData.merchant_id}
            onChange={(e) => setFormData({ ...formData, merchant_id: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <GoogleMerchantIcon />
                </InputAdornment>
              ),
            }}
          />
          <TextField
            label="Google Merchant Linked To"
            fullWidth
            value={formData.merchant_linked_to}
            onChange={(e) => setFormData({ ...formData, merchant_linked_to: e.target.value })}
          />
          <TextField
            label="Google Search Console Linked To"
            fullWidth
            value={formData.search_console_linked_to}
            onChange={(e) => setFormData({ ...formData, search_console_linked_to: e.target.value })}
          />
          <TextField
            label="Google Business Profile Linked To"
            fullWidth
            value={formData.business_profile_linked_to}
            onChange={(e) => setFormData({ ...formData, business_profile_linked_to: e.target.value })}
          />
        </>
      )}
      {((formData?.name?.toLowerCase().includes('difm')) ||
        (formData?.name?.toLowerCase().includes('converto')) ||
        (formData?.name?.toLowerCase().includes('web')) ) && (
        <>
          <TextField
            label="Project Link"
            fullWidth
            type="string"
            placeholder="https://app.clickup.com/..."
            helperText="Enter the Clickup Project Link for the DIFM/Converto product"
            value={formData.project_link}
            inputProps={{ maxLength : 500 }}
            onChange={(e) => setFormData({ ...formData, project_link: e.target.value })}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ClickupIcon mode={mode} />
                </InputAdornment>
              ),
            }}
          />
        </>
      )}
    </>
  )
}
const createEmptyRow = (params, customerId) => {}

export function AccountProductsPage () {
  const { subscriptionsData, subscriptionsLoading, subscriptionInfoData, brandDetailsData } = useOutletContext<CompanyQueries>()
  const [updateSubscriptionInfo] = useUpdateSubscriptionInfoMutation()
  
  const [combinedSubData, setCombinedSubData] = useState([])

  const combineSubscriptionData = useCallback(() => {
    return subscriptionsData?.map((sub) => {
      const subInfo = subscriptionInfoData?.find(s => s.cb_subscription_id === sub.id)
      // Create a new object with combined data
      return {
        ...sub,
        ...(subInfo ? {
          sub_id: subInfo.id,
          domain: subInfo.domain,
          google_ads_id: subInfo.google_ads_id,
          seo_geolocation_id: subInfo.seo_geolocation_id,
          meta_ads_id: subInfo.meta_ads_id,
          tag_manager_id: subInfo.tag_manager_id,
          tag_manager_linked_to: subInfo.tag_manager_linked_to,
          analytics_property_id: subInfo.analytics_property_id,
          analytics_web_stream: subInfo.analytics_web_stream,
          analytics_linked_to: subInfo.analytics_linked_to,
          merchant_id: subInfo.merchant_id,
          merchant_linked_to: subInfo.merchant_linked_to,
          search_console_linked_to: subInfo.search_console_linked_to,
          business_profile_linked_to: subInfo.business_profile_linked_to,
          brand_id: brandDetailsData?.id
        } : {})
      }
    })
  }, [subscriptionsData, subscriptionInfoData])

  initialForm.brand_id = brandDetailsData ? Number(brandDetailsData?.id) : null

  useEffect(() => {
    const combined = combineSubscriptionData()
    setCombinedSubData(combined)
  }, [subscriptionsData, subscriptionInfoData, combineSubscriptionData])

  return (
    <TableTemplateNew
      CustomToolbar={CustomToolbar}
      dataTitle={dataTitle}
      columnFields={columnFields}
      initialForm={initialForm}
      upsertFields={upsertFields}
      createEmptyRow={createEmptyRow}
      tableData={combinedSubData}
      loading={subscriptionsLoading}
      updateQuery={updateSubscriptionInfo}
      permissionProp="products"
      autoRowHeight={true}
      pinRight={['edit']}
      goBackLevel={1}
    />
  )
}

import { useState } from 'react'
import { CircularProgress, Dialog, DialogTitle, DialogContent, Divider,
  DialogContentText, DialogActions, Button, TextField } from '@mui/material'
import { CaseDataFormatted } from './CaseDataFormatted'
import { Copied } from '../../Popover'

export function ApprovalDialog ({
  caseData,
  setCaseData,
  open,
  setOpen,
  handleClose,
  loadingApproval,
  loadingRejection
}) {
  const [approvalMsg, setApprovalMsg] = useState<string>('')
  const handleBtn = (action) => {
    if (action) {
      handleClose(action, caseData, setCaseData, approvalMsg)
    } else {
      handleClose()
    }
    setApprovalMsg('') // empty approval msg
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle sx={{ alignItems: 'center' }}>
        Approve Case #{caseData?.case_number} <Copied toCopy={caseData?.case_number} />
      </DialogTitle>
      <Divider />
      <DialogContent>
        <DialogContentText>
          Do you want to approve the following case?
        </DialogContentText>
        <CaseDataFormatted caseData={caseData} />
      </DialogContent>
      <div className="mx-[15px] my-[5px]">
        <TextField
          disabled={loadingApproval || loadingRejection}
          name="approvalmessage"
          label="Message to case originator (Optional)"
          multiline
          rows={3}
          fullWidth
          value={approvalMsg}
          onChange={(e) => setApprovalMsg(e.target.value)}
          placeholder="Type a response to the case originator here. This message is optional."
        />
      </div>
      <div className="flex justify-between">
        <DialogActions>
          <Button disabled={loadingApproval || loadingRejection} onClick={() => handleBtn('')}>
            CLOSE
          </Button>
        </DialogActions>
        <DialogActions>
          <Button
            disabled={loadingApproval || loadingRejection}
            endIcon={loadingRejection ? <CircularProgress size="0.99rem" /> : null}
            onClick={() => handleBtn('Rejected')}
          >
            REJECT
          </Button>
          <Button
            disabled={loadingApproval || loadingRejection}
            endIcon={loadingApproval ? <CircularProgress size="0.99rem" /> : null}
            onClick={() => handleBtn('Approved')}
          >
            APPROVE
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

import { useOutletContext } from 'react-router-dom'
import { Link as MuiLink } from '@mui/material'
import { useGetIdentity } from '@refinedev/core'
import { GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarExport } from '@mui/x-data-grid'
import { TextField } from '@mui/material'
import { TableTemplateNew } from '../components/Table'
import { Copied } from '../components/Popover'
import { genRandomUUID } from '../utility/helpers/miscHelpers'
import { formatAsDayMonthYear } from '../utility/helpers/dateHelpers'
import { Select, MenuItem, IconButton } from '@mui/material'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DeleteIcon from '@mui/icons-material/Delete'
import EditIcon from '@mui/icons-material/Edit'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import FormControl from '@mui/material/FormControl'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import { CompanyQueries } from '../interface/queries/CompanyQueries'
import { useCreateClientLoginMutation, useUpdateClientLoginMutation, useDeleteClientLoginMutation } from '../services/clientLoginsApi'

interface DataProps {
  id?: string,
  url?: string,
  company_id?: string
  username?: string
  password?: string
  type?: string
  note?: string
  created_by?: string
  created_at?: Date
  updated_at?: Date
}

interface UserProps {
  email: string
}

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarExport />
    </GridToolbarContainer>
  )
}

const dataTitle = 'Client Login'
const initialForm: DataProps = {
  id: '',
  url: '',
  company_id: '',
  username: '',
  type: '',
  password: '',
  note: '',
  created_by: '',
  created_at: new Date(),
  updated_at: new Date()
}
const columnFields = (openUpdateForm, handleDialogOpen, allowEdit, allowDelete, customerId, subscriptionsCb, geolocations, mode, showPasswordMap, setShowPasswordMap) => {
  return [
    { field: 'url', headerName: 'URL', editable: false,
    renderCell: (params) => (
      <div className="overflow-hidden truncate">
        <MuiLink href={params?.row?.url?.includes('http') ? params.row.url : `//${params.row.url}`} underline="hover" target="_blank" color="secondary">
          {params.row.url}
        </MuiLink>
      </div>
    ) },
    { field: 'username', headerName: 'Username', width: 150, editable: false,
      renderCell: (params) => (
        <div className="overflow-hidden truncate">
          <Copied toCopy={params.row.username} />
          <span>{params.row.username}</span>
        </div>
      ),
    },
    { field: 'password', headerName: 'Password', width: 150, editable: false,
      renderCell: (params) => (
        <div className="flex gap-[2px] items-center">
          <IconButton
            onClick={() => setShowPasswordMap(prevState => ({ ...prevState, [params.row.id]: !prevState[params.row.id] }))}
            edge="end"
          >
            {showPasswordMap[params.row.id] ? <VisibilityOff /> : <Visibility />}
          </IconButton>
          <IconButton
            color="default"
            onClick={() => { navigator.clipboard.writeText(customerId.startsWith('00') ? params.row.password : params.row.password) }} >
            <ContentCopyIcon sx={{ width: '20px' }} />
          </IconButton>
          {(showPasswordMap[params.row.id]) ? (
            <span className="overflow-hidden truncate">{customerId.startsWith('00') ? params.row.password : params.row.password}</span>
          ) : (
            <span className="text-[12px] overflow-hidden truncate">●●●●●●●</span>
          )}
        </div>
      ),
    },
    { field: 'type', headerName: 'Type', width: 100, editable: false },
    { field: 'note', headerName: 'Note', width: 150, editable: false },
    { field: 'created_at', headerName: 'Create Date', width: 125, editable: false,
      renderCell: (params) => formatAsDayMonthYear(params.row.created_at),
    },
    { field: 'created_by', headerName: 'Created By', width: 125, editable: false },
    { field: 'updated_at', headerName: 'Last Modified', width: 125, editable: false,
      renderCell: (params) => formatAsDayMonthYear(params.row.created_at),
    },
    { field: 'edit', headerName: 'Edit', width: 75,
      renderCell: (params) => (
        <IconButton color="info" onClick={() => openUpdateForm(params)} disabled={!allowEdit} >
          <EditIcon />
        </IconButton>
      ),
    },
    { field: 'delete', headerName: 'Delete', width: 75,
      renderCell: (params) => (
        <IconButton color="error" onClick={() => handleDialogOpen(params, 'Delete')} disabled={!allowDelete} >
          <DeleteIcon />
        </IconButton>
      ),
    }
  ]
}

const upsertFields = (create, formData, setFormData, customerId, geolocations, subscriptionsCb, formTypes, showPassword, handleClickShowPassword) => {
  return (
    <>
      <TextField
        label="URL"
        fullWidth
        value={formData.url}
        onChange={(e) => setFormData({ ...formData, url: e.target.value })}
        required
      />
      <FormControl variant="outlined">
        <InputLabel htmlFor="username">Username</InputLabel>
        <OutlinedInput
          type="text"
          endAdornment={
            <IconButton
              color="default"
              edge="end"
              onClick={() => { navigator.clipboard.writeText(formData.username)}} >
              <ContentCopyIcon />
            </IconButton>
          }
          label="Username"
          onChange={(e) => setFormData({ ...formData, username: e.target.value })}
          value={formData.username}
          required
        />
      </FormControl>
      <FormControl variant="outlined">
        <InputLabel htmlFor="password">Password</InputLabel>
        <OutlinedInput
          type={showPassword ? 'text' : 'password'}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Password"
          onChange={(e) => setFormData({ ...formData, password: e.target.value })}
          required
        />
      </FormControl>
      <FormControl fullWidth>
        <InputLabel>Type</InputLabel>
        <Select
          fullWidth
          value={formData.type || ''}
          label="Type"
          onChange={(e) => setFormData({ ...formData, type: e.target.value })}
          required
        >
          <MenuItem key="WordPress" value="WordPress">WordPress</MenuItem>
          <MenuItem key="Shopify" value="Shopify">Shopify</MenuItem>
          <MenuItem key="Drupal" value="Drupal">Drupal</MenuItem>
          <MenuItem key="Magneto" value="Magneto">Magneto</MenuItem>
          <MenuItem key="Wix" value="Squarespace">Squarespace</MenuItem>
          <MenuItem key="Joomla" value="Joomla">Joomla</MenuItem>
          <MenuItem key="Blogger" value="Blogger">Blogger</MenuItem>
          <MenuItem key="Other" value="Other">Other</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="Note"
        multiline
        rows={4}
        fullWidth
        value={formData.note}
        onChange={(e) => setFormData({ ...formData, note: e.target.value })}
      />
    </>
  )
}

const createEmptyRow = (params, customerId) => {
  return ({
    id: genRandomUUID(),
    url: params.url,
    username: params.username,
    type: params.type,
    password: params.password,
    note: params.note,
    created_by: params.created_by,
    created_at: (new Date).toISOString(),
    updated_at: (new Date).toISOString(),
    company_id: customerId
  })
}

export function ClientLoginsPage () {
  const { data: userData }: { data: UserProps } = useGetIdentity()
  initialForm.created_by = userData?.email || ''

  const { clientLoginsData, clientLoginsLoading } = useOutletContext<CompanyQueries>()
  const [createClientLogin] = useCreateClientLoginMutation()
  const [updateClientLogin] = useUpdateClientLoginMutation()
  const [deleteClientLogin] = useDeleteClientLoginMutation()

  return (
    <TableTemplateNew
      CustomToolbar={CustomToolbar}
      dataTitle={dataTitle}
      columnFields={columnFields}
      initialForm={initialForm}
      upsertFields={upsertFields}
      createEmptyRow={createEmptyRow}
      tableData={clientLoginsData}
      loading={clientLoginsLoading}
      createQuery={createClientLogin}
      updateQuery={updateClientLogin}
      deleteQuery={deleteClientLogin}
      permissionProp="logins"
    />
  )
}

import { createRoot } from 'react-dom/client'
import reportWebVitals from './reportWebVitals'
import App from './App'
import { store } from './store'
import { Provider } from 'react-redux'

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

const darkMode = localStorage.getItem('colorMode')
if (darkMode === 'dark') {
  document.body.className = 'dark-mode' // Set body class to 'dark-mode'
}

root.render(
  <Provider store={store}>
    <App />
  </Provider>
)

reportWebVitals()

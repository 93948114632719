import { api } from './api'
import { queries } from '../utility'
import { setBacklinks, setAllBacklinks } from '../store/global'
import { store } from '../store'

export const backlinksApi = api.injectEndpoints({
  reducerPath: 'backlinksApi',
  endpoints: (build) => ({
    getBacklinksCustomer: build.query({
      queryFn: async (arg) => {
        const customerId = arg
        const response = await queries.getBacklinksForCustomer(customerId)
        store.dispatch(setBacklinks(response))
        return { data: response }
      },
    }),
    getBacklinksKeyword: build.query({
      queryFn: async (arg) => {
        const keywordId = arg
        const response = await queries.getBacklinksForKeyword(keywordId)
        //! TODO: Figure out how to save this in store without confusing with data above
        return { data: response }
      },
    }),
    getAllBacklinks: build.query({
      queryFn: async (arg) => {
        const { dateFrom, dateTo } = arg
        const response = await queries.getAllBacklinks(dateFrom, dateTo)
        store.dispatch(setAllBacklinks(response))
        return { data: response }
      },
    }),
    createBacklink: build.mutation({
      queryFn: async (arg) => {
        const { body, subscriptionId, keywordId } = arg
        const response = await queries.createBacklink(body) // Update Supabase table
        if (response) {
          store.dispatch( // Update the redux store
            backlinksApi.util.updateQueryData('getBacklinksKeyword', keywordId, (draft) => {
              draft.push(response[0])
            })
          )
        }
        return { data: response }
      },
    }),
    updateBacklink: build.mutation({
      queryFn: async (arg) => {
        const { itemId, body, keywordId } = arg
        const response = await queries.updateBacklink(body, itemId) // Update Supabase table
        store.dispatch( // Update the redux store
          backlinksApi.util.updateQueryData('getBacklinksKeyword', keywordId, (draft) => {
            const index = draft.findIndex(d => d.id === itemId)
            if (index !== -1) {
              draft[index] = { ...draft[index], ...body }
            }
          })
        )
        return { data: response }
      },
    }),
    deleteBacklink: build.mutation({
      queryFn: async (arg) => {
        const { itemId, customerId, keywordId } = arg
        const response = await queries.deleteBacklink(itemId) // Update Supabase table
        store.dispatch( // Update redux store
          backlinksApi.util.updateQueryData('getBacklinksKeyword', keywordId, (draft) => {
            const updated = draft.filter(d => d.id !== itemId)
            return updated
          })
        )
        return { data: response }
      },
    }),
  }),
})

export const {
  useGetBacklinksCustomerQuery,
  useGetBacklinksKeywordQuery,
  useGetAllBacklinksQuery,
  useCreateBacklinkMutation,
  useUpdateBacklinkMutation,
  useDeleteBacklinkMutation
} = backlinksApi

/**
 * Format a number as a currency to 2 decimal places
 *
 * @param {number} value The currency as a number which we would like to format
 * @returns {string}
 */
export const formatAsCurrency = (
  value: number
): string => {
  const fixed = (value / 100).toFixed(2)
  const currencyRounded = parseFloat(fixed).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  })
  return currencyRounded
}

import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import PausePresentationIcon from '@mui/icons-material/PausePresentation'
import PresentToAllIcon from '@mui/icons-material/PresentToAll'
import FilePresentIcon from '@mui/icons-material/FilePresent'

export const CaseTypeIcon = ({
  caseData
}) => {
  if (!caseData || !caseData?.case_type) {
    return <></>
  }
  return (
    <>
      {caseData?.case_type === 'Cancel' && <Tooltip title="Cancel Case"><CancelPresentationIcon color="error" /></Tooltip>}
      {caseData?.case_type === 'Pause' && <Tooltip title="Pause Case"><PausePresentationIcon color="warning" /></Tooltip>}
      {caseData?.case_type.includes('Billing') && <Tooltip title="Billings Alteration Case"><FilePresentIcon color="success" /></Tooltip>}
      {caseData?.case_type.includes('Altered') && <Tooltip title="Altered Products Case"><PresentToAllIcon color="info" /></Tooltip>}
    </>
  )
}

import { Box, Button, DialogActions, DialogTitle, Dialog, Skeleton } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../interface/RootState'
import { toggleOpenReportDialog } from '../../store/global'
import { useDispatch } from 'react-redux'
import { useState } from 'react'

export function BugReport () {
  const dispatch = useDispatch()
  const openReportDialog = useSelector((store: RootState) => store.global.openReportDialog)
  const [loading, setLoading] = useState(true)

  const closeTicketRequest = () => {
    dispatch(toggleOpenReportDialog())
    setLoading(true)
  }

  const handleIframeLoad = () => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }

  return (
    <Dialog
      open={openReportDialog}
      onClose={() => closeTicketRequest()}
      fullWidth={true}
      sx={{
        '& .MuiPaper-root': {
          height: '100vh',
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
        },
      }}
    >
      {loading && (
        <>
          <br />
          <Box
            sx={{
              padding: 2,
              width: '100%',
              maxWidth: 600,
              margin: '0 auto',
              display: 'flex',
              flexDirection: 'column',
              gap: 2,
            }}
          >
            <Skeleton animation="wave" variant="rectangular" width="60%" height={100} />
            <Skeleton animation="wave" variant="rectangular" width="90%" height={50} />
            <Skeleton animation="wave" variant="rectangular" width="100%" height={56} />
            <Skeleton variant="rectangular" width="100%" height={100} />
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Skeleton animation="wave" variant="rectangular" width="48%" height={56} />
              <Skeleton animation="wave" variant="rectangular" width="48%" height={56} />
            </Box>
            <Skeleton animation="wave" variant="rectangular" width="100%" height={56} />
            <Skeleton animation="wave" variant="rectangular" width="50%" height={56} />
            <Skeleton animation="wave" variant="rectangular" width="50%" height={56} />
            <Skeleton animation="wave" variant="rectangular" width="25%" height={40} />
          </Box>
        </>
      )}
      <iframe
        className="clickup-embed clickup-dynamic-height"
        src="https://forms.clickup.com/6958176/f/6mb30-22076/B4ENT6IY6O4YTXM6N2"
        onLoad={handleIframeLoad}
        width="100%"
        height="100%"
        style={{ display: loading ? 'none' : 'block' }} // Hide iframe while loading
      />
      <script async src="https://app-cdn.clickup.com/assets/js/forms-embed/v1.js"></script>
      <DialogActions>
        <Button onClick={() => closeTicketRequest()}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

import { useId } from 'react'
import Tooltip from '@mui/material/Tooltip'

export const GoogleAdsIcon = ({
  mode
}) => {
  const AdsClickFilled = useId()

  return (
    <Tooltip title="Google Ads">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id={AdsClickFilled}>
          <path d="M21.4761 15.4414L15.4214 4.95703C14.7144 3.73047 13.437 3 12.0034 3C10.5698 3 9.28858 3.73047 8.58154 4.95703L2.52686
            15.4414C1.82373 16.6602 1.82373 18.1211 2.53076 19.3438C3.23779 20.5703 4.49951 21.3008 5.90186 21.3008C7.29639 21.3008 8.59326 20.5508
            9.28858 19.3477L12.0034 14.6523L14.7144 19.3438C15.4097 20.5508 16.7065 21.2969 18.1011 21.2969C19.5034 21.2969 20.7651 20.5664 21.4722
            19.3398C22.1792 18.1172 22.1792 16.6602 21.4761 15.4414ZM16.4331 18.3516L10.3784 7.86719C10.0269 7.25781 10.023 6.57031 10.3667 5.97266C10.7183
            5.36719 11.3433 4.99219 11.9995 4.99219C12.3159 4.99219 12.6245 5.07812 12.9214 5.25L12.9565 5.26953C13.4839 5.57031 13.5269 5.64453 17.7573
            12.9805C18.3394 13.9883 18.9995 15.1328 19.7495 16.4336C20.2769 17.3477 19.9644 18.5234 19.0464 19.0508C18.7534 19.2188 18.4253 19.3086 18.0894
            19.3086C17.4136 19.3086 16.7769 18.9414 16.4331 18.3516ZM9.27295 15.4023C8.58936 14.2422 7.33154 13.5 5.95264 13.4844L8.64404 8.82812C8.82373
            9.15625 9.13623 9.69531 9.71826 10.7031C10.019 11.2188 10.3901 11.8633 10.8511 12.6641L9.27295 15.4023ZM4.13623 18.1055C3.91358 17.5547 3.95654
            16.9453 4.25342 16.4336C4.59326 15.8438 5.22998 15.4766 5.91358 15.4766C6.24951 15.4766 6.57764 15.5664 6.87061 15.7344C7.31592 15.9922 7.63233
            16.4023 7.76514 16.8984C7.89795 17.3906 7.82764 17.9102 7.57373 18.3516C7.23389 18.9414 6.59717 19.3086 5.91358 19.3086C5.73389 19.3086 5.5542
            19.2852 5.38233 19.2344C4.81201 19.0703 4.35889 18.6563 4.13623 18.1055Z"
            fill={mode === 'dark' ? "white" : "black"}
            fillOpacity="0.56"
        />
        </g>
      </svg>
    </Tooltip>
  )
}

import { useState, useEffect, memo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { CircularProgress } from '@mui/material'
import Link from '@mui/material/Link'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import HomeIcon from '@mui/icons-material/Home'
import PersonIcon from '@mui/icons-material/Person'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import LockIcon from '@mui/icons-material/Lock'
import PostAddIcon from '@mui/icons-material/PostAdd'
import InventoryIcon from '@mui/icons-material/Inventory'
import WorkIcon from '@mui/icons-material/Work'
import type { CompanyDataInterface } from '../../interface/data/CompanyDataInterface'
import type { SubDataInterface } from '../../interface/data/SubDataInterface'

const iconSwitcher = (idx, param, customerName, customerBrand) => {
  if (idx === 0 && !customerName || param === 'loading') {
    return <CircularProgress color="inherit" size={10} />
  } else if (idx === 0 && customerBrand) {
    return <img className="flex w-[16px] max-h-[16px] items-center justify-center self-center" src={`/images/logo_natural_${customerBrand.toUpperCase()}.svg`} />
  }
  switch (param) {
    case 'creditcard':
      return <CreditCardIcon sx={{ width: '16px' }} />
    case 'clientlogins':
      return <LockIcon sx={{ width: '16px' }} />
    case 'forms':
      return <PostAddIcon sx={{ width: '16px' }} />
    case 'form':
      return <PostAddIcon sx={{ width: '16px' }} />
    case 'products':
      return <InventoryIcon sx={{ width: '16px' }} />
    case 'cases':
      return <WorkIcon sx={{ width: '16px' }} />
    default:
      return <></>
  }
}

const urlHandler = (formTitle, subscriptions) => {
  const url = window.location.href.endsWith('/') ? window.location.href.slice(0, -1) : window.location.href
  const rightUrl = url.split('customers/show/')[1]
  if (url.includes('forms') && rightUrl.split('/')?.length === 3) {
    return rightUrl.slice(0, -2).concat(`/${formTitle}`)
  } else if (url.includes('forms') && rightUrl.split('/')?.length === 4) {
    return rightUrl.slice(0, -5).concat(`/${formTitle}`)
  } else if (url.includes('backlinks')) { // show the subscription name if backlinks page
    if (!subscriptions || subscriptions?.length === 0) { return rightUrl.replace(/(products\/)[^/]+(\/backlinks)/, '$1loading$2') }
    const subscriptionId = rightUrl.split('/').find(part => subscriptions?.some(sub => sub.id === part))
    const subscription = subscriptions?.find(sub => sub && sub.id === subscriptionId) // Added check for undefined subscription
    const updatedUrl = subscription ? rightUrl.replace(subscriptionId, subscription.name) : rightUrl // Check if subscription is defined
    return updatedUrl
  } else if (url.includes('keywords')) { // show the subscription name if keywords page
    if (!subscriptions || subscriptions?.length === 0) { return rightUrl.replace(/(products\/)[^/]+(\/keywords)/, '$1loading$2') }
    const subscriptionId = rightUrl.split('/').find(part => subscriptions?.some(sub => sub.id === part))
    const subscription = subscriptions?.find(sub => sub && sub.id === subscriptionId) // Added check for undefined subscription
    const updatedUrl = subscription ? rightUrl.replace(subscriptionId, subscription.name) : rightUrl // Check if subscription is defined
    return updatedUrl 
  } else {
    return rightUrl
  }
}

interface CustomerBreadcrumbProps {
  companyData: CompanyDataInterface
  subscriptionsData: SubDataInterface
}

export const CustomerBreadcrumb = memo((props: CustomerBreadcrumbProps) => {
  const {
    subscriptionsData,
    companyData
  } = props

  const [customerName, setCustomerName] = useState(null)
  const [customerBrand, setCustomerBrand] = useState(null)

  const navigate = useNavigate()
  const { productId } = useParams()

  let urlCustomer = urlHandler('', subscriptionsData)
  urlCustomer = urlCustomer.endsWith('/') ? urlCustomer.slice(0, -1) : urlCustomer

  useEffect(() => {
    setCustomerName(companyData?.name)
    setCustomerBrand(companyData?.brand)
  }, [companyData])

  return (
    <Breadcrumbs sx={{ paddingBottom: '10px', alignSelf: 'start' }}>
      <Link
        underline="hover"
        sx={{ cursor: 'pointer', alignItems: 'center', display: 'flex', gap: '8px' }}
        onClick={() => navigate('/home')}
        color="info"
      >
        <HomeIcon sx={{ width: '16px' }} />
        Home
      </Link>
      <Link
        underline="hover"
        sx={{ cursor: 'pointer', alignItems: 'center', display: 'flex', gap: '8px' }}
        onClick={() => navigate('/home')}
        color="info"
      >
        <PersonIcon sx={{ width: '16px' }} />
        Customer
      </Link>
      {(urlCustomer.split('/')).map((param, idx) => (
        <Link
          underline="hover"
          sx={{
            cursor: 'pointer',
            alignItems: 'center',
            display: 'flex',
            gap: '8px',
            textTransform: 'capitalize',
            paddingLeft: idx === 0 && !customerName
              ? '11px'
              : '0px'
          }}
          onClick={() => {
            const urlParts = urlCustomer.split('/')
            let newUrl, navigateUrl
            if (urlCustomer.includes('keywords')) { // for keywords and backlinks pages, navigate to the page based on the url (as the breadcrumb will show different values to url)
              newUrl = urlParts.slice(0, 2).join('/') + '/' + productId + '/' + urlParts.slice(3, 99).join('/')
              navigateUrl =
                idx === 4 || idx === 2
                  ?
                newUrl.split('/').slice(0, 4).join('/')
                  :
                newUrl.split('/').slice(0, idx + 1).join('/')
              navigate('/customers/show/' + navigateUrl)
            } else {
              navigateUrl = urlParts.slice(0, idx + 1).join('/')
              navigate('/customers/show/' + navigateUrl)
            }
          }}
          key={idx}
          color="info"
        >
          {iconSwitcher(idx, param, customerName, customerBrand)}
          {idx === 0 && customerName
              ? customerName
              : param === 'creditcard'
              ? 'Credit Cards'
              : param == 'clientlogins'
              ? 'Client Logins'
              : param}
        </Link>
      ))}
    </Breadcrumbs>
  )
})

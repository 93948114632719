import { useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import type { CaseQueries } from '../../../interface/queries/CaseQueries'
import { Box, Chip, IconButton, Link, List, ListItem, Pagination, Tooltip, Typography } from '@mui/material'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import { getCpanelUrl } from '../../../utility/helpers/brandHelpers'

export function RejectedCases({
  handleOpenDialog,
  handleOpenReceipt
}) {
  // Pagination
  const [page, setPage] = useState(1)
  const itemsPerPage = 8
  const startIndex = (page - 1) * itemsPerPage
  const endIndex = page * itemsPerPage

  //* Extract data from Redux
  const { rejectedCases } = useOutletContext<CaseQueries>()

  return (
    <>
      <Typography variant="h5" gutterBottom sx={{ paddingTop: '20px '}}>
        Rejected
      </Typography>
      {(rejectedCases?.length > 0) ? (
        <>
          <Typography component="div" style={{ opacity: '70%' }}>
            You have <Chip size="small" label={rejectedCases?.length || 0} color="primary" />  case{rejectedCases?.length === 1 ? ' which has ' : 's which have '} been rejected.
          </Typography>
          <>
            {Object.keys(rejectedCases).slice(startIndex, endIndex).map((key, idx) => (
              <List
                key={idx}
                sx={{
                    listStyleType: 'disc',
                    listStylePosition: 'inside'
                  }}
                >
                  <ListItem sx={{ display: 'list-item' }}>
                    <Link
                      target="_blank"
                      href={`${getCpanelUrl('dd')}/customers/show/${rejectedCases[key].customer_id}/cases`}
                      key={idx}
                      variant="body2"
                    >
                      <Typography variant="subtitle1" component="span">{rejectedCases[key]?.case_number}</Typography>
                    </Link>
                    &nbsp;
                    <span>
                      <Typography variant="body2" component="span">
                        &nbsp;{rejectedCases[key]?.customer_name}
                      </Typography>
                      <Typography variant="body1" component="span" color="default" sx={{ opacity: '60%' }}>
                        &nbsp;{Math.round(((new Date().getTime() as number) - (new Date(rejectedCases[key]?.updated_at).getTime() as number)) / (1000 * 60 * 60 * 24))} days ago&nbsp;
                      </Typography>
                    </span>
                    <Tooltip title="Chargebee Link">
                      <Link target="_blank" href={`https://disruptdigital.chargebee.com/d/subscriptions/${rejectedCases[key]?.subscription_id}#summary`}>
                        <IconButton color="primary">
                          <img className="flex w-[22px] max-h-[22px] items-center opacity-[70%] justify-center self-center" src={'/images/Chargebee.svg'} />
                        </IconButton>
                      </Link>
                    </Tooltip>
                    <Tooltip title="Case Receipts">
                      <IconButton onClick={() => handleOpenReceipt(rejectedCases[key])} color="warning">
                        <ReceiptLongIcon />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
              </List>
            ))}
          </>
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
            <Pagination count={Math.ceil((Object.keys(rejectedCases)?.length || 0) / itemsPerPage)} page={page} onChange={(event, value) => setPage(value)} />
          </Box>
        </>
      ) : (
        <Typography style={{ opacity: '70%' }}>
          You have no cases that have been rejected.
        </Typography>
      )}
    </>
  )
}

import { FormResultsDataInterface } from '../../../interface/data/FormResultsDataInterface'

/**
 * Sort form data by latest
 *
 * @param {FormResultsDataInterface[]} formData Form data array
 * @returns {FormResultsDataInterface[]}
 */
export const sortByLatestForms = (
  formData: FormResultsDataInterface[]
): FormResultsDataInterface[] => {
  // filter forms to only show latest form versions for each type
  const filteredData = Object.values(formData.reduce((acc, form) => {
    const id = form.form_version_id.form_id.id
    if (!acc[id] || new Date(form.updated_at) > new Date(acc[id].updated_at)) {
      acc[id] = form
    }
    return acc;
  }, {} as Record<string, FormResultsDataInterface>)) || formData
  return filteredData
}

/**
 * Format the receipts json data as a single string
 *
 * @param {string} receiptJson Receipt Data as JSON string
 * @returns {string | null}
 */
export const formatReceiptSummary = (
  receiptJson: string
): string | null => {
  if (!receiptJson || receiptJson.length <= 1 || receiptJson === 'null') return null

  const { creditReceipts, refundReceipts, productReceipts, promotionReceipts } = JSON.parse(receiptJson)

  const receiptArr = [
    creditReceipts?.length > 0 ? `Credit Receipts:\n${creditReceipts.join('\n')}` : null,
    refundReceipts?.length > 0 ? `Refund Receipts:\n${refundReceipts.join('\n')}` : null,
    productReceipts?.length > 0 ? `Product Receipts:\n${productReceipts.join('\n')}` : null,
    promotionReceipts?.length > 0 ? `Promotion Receipts:\n${promotionReceipts.join('\n')}` : null
  ]
  const receiptStr = receiptArr.filter(Boolean).join('\n\n')
  return receiptStr
}

import { supabaseClient } from './'

//! TODO: MOVE THESE QUERIES INTO API
export const queries = {
  //*--------------- Client Logins ---------------
  getLogin: async (customerId) => {
    const { data, error } = await supabaseClient
      // .from('decrypted_client_logins') //! Temporarily disabled
      .from('client_logins')
      .select('*')
      .eq('company_id', customerId)
    if (error) {
      console.error('Error fetching client logins (supabase):', error.message)
      return []
    }
    return data
  },
  createLogin: async (body) => {
    const { data, error } = await supabaseClient
      .from('client_logins')
      .insert(body)
      .select()
    if (error) {
      console.error('Error creating new client login (supabase):', error.message)
      return false
    }
    return data
  },
  updateLogin: async (itemId, body) => {
    const { data, error } = await supabaseClient
      .from('client_logins')
      .update(body)
      .eq('id', itemId)
    if (error) {
      console.error('Error updating client login (supabase):', error.message)
      return false
    }
    return true
  },
  deleteLogin: async (itemId) => {
    const { data, error } = await supabaseClient
      .from('client_logins')
      .delete()
      .eq('id', itemId)
    if (error) {
      console.error('Error deleting client login (supabase):', error.message)
      return false
    }
    return true
  },

  //*--------------- SEO Keywords ---------------
  getKeywords: async (subscriptionId) => {
    // Fetch cb_subscription_id from subscriptions table
    const { data: subscriptionData, error: subscriptionError } = await supabaseClient
      .from('subscriptions')
      .select('id')
      .eq('cb_subscription_id', subscriptionId)
  
    if (subscriptionError) {
      console.error('Error fetching subscription data:', subscriptionError.message)
      return []
    }
  
    // Extract cb_subscription_id from the fetched subscription data
    const id = subscriptionData.length > 0 ? subscriptionData[0].id : null
  
    // If cb_subscription_id is null, return an empty array
    if (!id) {
      console.error('Subscription not found.')
      return []
    }
  
    // Fetch SEO keywords using the extracted cb_subscription_id
    const { data: seoData, error: seoError } = await supabaseClient
      .from('seo_keywords')
      .select('*')
      .eq('subscription_id', id)
      .is('deleted_at', null)

    if (seoError) {
      console.error('Error fetching SEO keywords:', seoError.message)
      return []
    }

    // Fetch SEO keyword positions
    const keywords = seoData.map(k => k.id)
    const { data: seoPos, error: seoPosError } = await supabaseClient
      .from('seo_keyword_positions')
      .select('*')
      .in('seo_keyword_id', keywords)
      .order('date', { ascending: false })

    if (seoPosError) {
      console.error('Error fetching SEO keyword positions:', seoPosError.message)
      return []
    }

    // Create a map for quick lookup of positions by keyword ID
    const keywordPositionsMap = seoPos.reduce((acc, pos) => {
      if (!acc[pos.seo_keyword_id]) {
          acc[pos.seo_keyword_id] = []
      }
      acc[pos.seo_keyword_id].push(pos)
      return acc
    }, {})

    // Enhance each keyword object with its positions
    const enhancedSeoData = seoData.map(keyword => {
      const positions = keywordPositionsMap[keyword.id] || null
      return {
          ...keyword,
          url: positions ? positions[0].url : null,
          position_desktop: positions ? positions[0].position_desktop : null,
          position_mobile: positions ? positions[0].position_mobile : null,
          search_volume: positions ? positions[0].search_volume : null,
          position_date: positions ? positions[0].date : null
      }
    })

    return enhancedSeoData || seoData
  },
  createKeyword: async (body) => {
    const { id, ...bodyWithoutId } = body // remove temporary id, and let supabase generate sequential int id
    const { data, error } = await supabaseClient
      .from('seo_keywords')
      .insert(bodyWithoutId)
      .select()
    if (error) {
      console.error('Error creating new SEO keyword:', error.message)
      return false
    }
    return data
  },
  updateKeyword: async (itemId, body) => {
      const { data, error } = await supabaseClient
      .from('seo_keywords')
      .update(body)
      .eq('id', itemId)
    if (error) {
      console.error('Error updating SEO keywords:', error.message)
      return false
    }
    return true
  },
  deleteKeyword: async (itemId) => {
    // Now delete the associated keyword
    const { data, error } = await supabaseClient
      .from('seo_keywords')
      .update({ deleted_at: new Date() })
      .eq('id', itemId)
    if (error) {
      console.error('Error deleting SEO keywords:', error.message)
      return false
    }
    return true
  },

  //*--------------- Backlinks ---------------
  getBacklinksForKeyword: async (keywordId) => {
    const { data, error } = await supabaseClient
      .from('backlinks')
      .select('*,seo_keyword_id(name)')
      .eq('seo_keyword_id', keywordId)
    if (error) {
      console.error('Error fetching backlinks', error.message)
      return []
    }
    return data || []
  },
  getBacklinksForCustomer: async (customerId) => {
    const { data, error } = await supabaseClient
      .from('backlinks')
      .select('*')
      .eq('customer_id', String(customerId))
    if (error) {
      console.error('Error fetching backlinks', error.message)
      return []
    }
    return data || []
  },
  getAllBacklinks: async (dateFrom, dateTo) => {
    const { data, error } = await supabaseClient
      .from('backlinks')
      .select('*')
      .gte('last_backlinked', dateFrom)
      .lte('last_backlinked', dateTo)
    if (error) {
      console.error('Error fetching all backlinks', error.message)
      return []
    }
    const formattedData = data.map(item => {
      const { id, customer_id, ...rest } = item
      return {
        ...rest,
        last_backlinked: (new Date(item.last_backlinked)).toLocaleString().replace(',', ' '),
        created_at: item.created ? (new Date(item.created_at)).toLocaleString().replace(',', ' ') : '',
        backlink_id: item.id,
        hubspot_company_id: item.customer_id,
        admin_url: `https://clientpanel.disrupthub.com.au/customers/show/${item.customer_id}`,
      }
    })
    return formattedData || []
  },
  updateBacklink: async (body, id) => {
    const excludedColumns = ['customer_id', 'subscription_id', 'seo_keyword_id'] // exclude certain columns from being updated
    excludedColumns.forEach(column => delete body[column])
    const { data, error } = await supabaseClient
      .from('backlinks')
      .update(body)
      .eq('id', id)
    if (error) {
      console.error('Error updating backlink:', error.message)
      return false
    }
    return true
  },
  createBacklink: async (body) => {
    const { id, ...bodyWithoutId } = body // remove temporary id, and let supabase generate sequential int id
    const { data, error } = await supabaseClient
      .from('backlinks')
      .insert(bodyWithoutId)
      .select('*, seo_keyword_id ( name )')
    if (error) {
      console.error('Error creating backlink:', error.message)
      return false
    }
    return data
  },
  deleteBacklink: async (id) => {
    const { data, error } = await supabaseClient
      .from('backlinks')
      .delete()
      .eq('id', id)
    if (error) {
      console.error('Error deleting backlink:', error.message)
      return false
    }
    return true
  },

  //*--------------- Credit Card ---------------
  getCreditCard: async (customerId) => {
    try {
      const response = await supabaseClient.functions.invoke('getCreditCard', {
        body: {
          customerId
        }
      })
      if (response?.data?.message.includes('eWay error') || response?.data?.message.includes('Unauthorized')) {
        throw new Error('No credit card details were found.')
      }
      const card = response.data.card
      const cardDetails = [{
        id: card.numberMasked,
        cardNumber: card.numberMasked,
        nameOnCard: card.name,
        expiryMonth: card.expiryMonth,
        expiryYear: card.expiryYear
      }]
      return cardDetails
    } catch (error) {
      console.error(error.message)
      return []
    }
  },
  createCreditCard: async (body, customerId) => {
    try {
      const response = await supabaseClient.functions.invoke('createCreditCard', {
        body: {
          customerId,
          body
        }
      })
      if (response?.data?.message) {
        return false
      }
      return true
    } catch (error) {
      console.error('Error creating credit card:', error.message)
      return false
    }
  },
  updateCreditCard: async (body, customerId) => {
    try {
      const response = await supabaseClient.functions.invoke('updateCreditCard', {
        body: {
          customerId,
          body
        }
      })
      if (response?.data?.message) {
        return false
      }
      return true
    } catch (error) {
      console.error('Error updating credit card:', error.message)
      return false
    }
  },
  deleteCreditCard: async (customerId) => {
    try {
      const response = await supabaseClient.functions.invoke('delCreditCard', {
        body: {
          customerId
        }
      })
      if (response?.data?.message) {
        return false
      }
      return true
    } catch (error) {
      console.error('Error deleting credit card:', error.message)
      return false
    }
  },

  //*--------------- User Preferences ---------------
  getPreferences: async (email) => {
    const { data, error } = await supabaseClient
      .from('user_preferences')
      .select('*')
      .eq('email', email)
    if (error) {
      console.error('Error fetching preferences:', error.message)
      return []
    }
    return (data || [])
  },
  updatePreferences: async (email, body) => {
    const { data, error } = await supabaseClient
      .from('user_preferences')
      .upsert(
        [
          {
            email,
            ...body,
          }
        ],
        { onConflict: 'email' }
      )
    if (error) {
      console.error('Error upserting preferences:', error.message)
      return false
    }
    return true
  },

  //*--------------- Geolocations ---------------
  getGeolocations: async () => {
    const { data, error } = await supabaseClient
      .from('geolocations')
      .select('*')
      .or('canonical_name.ilike.%New Zealand%,canonical_name.ilike.%Australia%')
    if (error) {
      console.error('Error fetching geolocations:', error.message)
      return []
    }
    data.forEach(item => {
      item.canonical_name = item.canonical_name
        .replace(/Australian Capital Territory/g, 'ACT')
        .replace(/New South Wales/g, 'NSW')
        .replace(/Western Australia/g, 'WA')
        .replace(/New Zealand/g, 'NZ')
        .replace(/South Australia/g, 'SA')
        .replace(/Northern Territory/g, 'NT')
        .replace(/Queensland/g, 'QLD')
        .replace(/Victoria/g, 'VIC')
        .replace(/Tasmania/g, 'TAS')
        .replace(/Australia/g, 'AU')
      item.canonical_name = item.canonical_name.replace(/,/g, ', ')
    })
    return data
  },

  //*--------------- Customers ---------------
  getCustomers: async (body) => {
    try {
      const response = await supabaseClient.functions.invoke('customerQuery', { body })
      return response.data
    } catch (error) {
      console.error('Error fetching customers:', error.message)
      return []
    }
  },

  //*--------------- Customer Details ---------------
  getCompanyDetails: async (customerId) => {
    try {
      const response = await supabaseClient.functions.invoke('getCompanyDetails', {
        body: {
          customerId
        }
      })
      if (!response.data) { throw new Error('no company found') }
      return response.data
    } catch (error) {
      console.error('Error fetching compant details:', error?.message)
      return null
    }
  },

  //*--------------- Customer Details ---------------
  getContactDetails: async (email) => {
    try {
      const response = await supabaseClient.functions.invoke('getContactDetails', {
        body: {
          email
        }
      })
      if (!response.data) { throw new Error('no contact found') }
      return response.data
    } catch (error) {
      console.error('Error fetching contact details:', error?.message)
      return null
    }
  },

  //*--------------- Form Schema ---------------
  getFormTypes: async () => {
    const { data, error } = await supabaseClient
      .from('forms')
      .select('id,name')
      .order('name', { ascending: true })
    if (error) {
      console.error('error fetching form types:', error.message)
    }
    return data ? data : null
  },

  //*--------------- Form Schema ---------------
  getFormSchema: async (formId) => {
    const { data, error } = await supabaseClient
      .from('form_versions')
      .select('schema_json,form_id (name),version,id')
      .eq('form_id', formId)
      .order('version', { ascending: false })
      .limit(1)
    if (error) {
      console.error('error fetching schema:', error.message)
    }
    return data ? data[0] : null
  },

  //*--------------- Form Results ---------------
  getFormResults: async (customerId, resultId) => {
    let query = supabaseClient
      .from('form_results')
      .select('*, form_version_id (id, schema_json, form_id (id,name))')
      .eq('customer_id', customerId)
    if (resultId) {
      query = query.eq('id', resultId)
    }
    const { data, error } = await query
    if (error) {
      console.error('error fetching form results:', error.message)
    }
    return data ? data : null
  },
  updateFormResult: async (body) => {
    const { data, error } = await supabaseClient
      .from('form_results')
      .insert(body)
    if (error) {
      console.error('error creating form:', error.message)
      return false
    }
    return true
  },
  deleteFormResult: async (resultId) => {
    const { data, error } = await supabaseClient
      .from('form_results')
      .delete()
      .eq('id', resultId)
    if (error) {
      console.error('error deleting form:', error.message)
      return false
    }
    return true
  },

  //*--------------- Supabase Subscriptions ---------------
  getSubscriptions: async (subscriptionId) => {
    const { data, error } = await supabaseClient
      .from('subscriptions')
      .select('*')
      .eq('cb_subscription_id', subscriptionId)
    if (error) {
      console.error('error fetching supabase subscriptions:', error.message)
      return []
    }
    return data
  },

  //*--------------- Chargebee Subscriptions ---------------
  getSubscriptionsCb: async (body) => {
    try {
      const response = await supabaseClient.functions.invoke('productsQuery', { body })
      if (!response.data) { throw new Error('no subscriptions found') }
      return response.data
    } catch (error) {
      console.error('Error fetching subscriptions:', error.message)
      return null
    }
  },

  //*--------------- Chargebee Subscription Info ---------------
  getSubscriptionInfo: async (subscriptionIds) => {
    const { data, error } = await supabaseClient
      .from('subscriptions')
      .select('*')
      .in('cb_subscription_id', subscriptionIds)
    if (error) {
      console.error('Error fetching subscriptions:', error.message)
      return []
    }
    return data
  },
  updateSubscriptionInfo: async (subscriptionId, body) => {
    const { id, started_at, created_at, name, product_type, recurring, recurring_period_unit, recurring_period, status, ...bodySupabase } = body // trim chargebee subscription items, so that we are only upserting supabase fields
      const { data, error } = await supabaseClient
        .from('subscriptions')
        .upsert(
          { ...bodySupabase,cb_subscription_id: subscriptionId },
          { onConflict: 'cb_subscription_id' }
        )
        .eq('cb_subscription_id', subscriptionId)
    if (error) {
      console.error('Error updating subscriptions:', error.message)
      return false
    }
    return true
  },

  //*--------------- Permissions ---------------
  getUserPermissions: async (body) => {
    try {
      const response = await supabaseClient.functions.invoke('getPermissions', { body })
      return response.data
    } catch (error) {
      console.error('Error fetching permissions from Supabase:', error.message)
      return []
    }
  },

  //*--------------- Brand ---------------
  getBrandDetails: async (brandAbbr) => {
    const abbr = brandAbbr?.toUpperCase() || 'DD'
    const { data, error } = await supabaseClient
      .from('brands')
      .select('id,name,abbreviation,hs_business_unit_id,cb_business_entity_id')
      .eq('abbreviation', abbr)
    if (error) {
      console.error('Error fetching brand details:', error.message)
      return []
    }
    return data
  },

  //*--------------- Cases ---------------------
  getCases: async (customerId) => {
    const { data, error } = await supabaseClient
      .from('cases')
      .select('*')
      .eq('customer_id', customerId)
      .is('deleted_at', null)
    if (error) {
      console.error('Error fetching Cases:', error.message)
      return []
    }
    return data
  },
  getAllCases: async () => {
    const { data, error } = await supabaseClient
      .from('cases')
      .select('*')
    if (error) {
      console.error('Error fetching All Cases:', error.message)
      return []
    }
    return data
  },
  getClosedCases: async () => {
    const { data, error } = await supabaseClient
      .from('cases')
      .select('*')
      .eq('status', 'Closed')
      .is('deleted_at', null)
    if (error) {
      console.error('Error fetching Closed Cases:', error.message)
      return []
    }
    return data
  },
  getOutstandingCases: async (email) => {
    const username = (email || '').split('@')[0] || ''
    const { data, error } = await supabaseClient
      .from('cases')
      .select('*')
      .ilike('submitted_manager', `%${username}%`)
      .eq('status', 'Awaiting Approval')
      .is('deleted_at', null)
    if (error) {
      console.error('Error fetching Outstanding Cases:', error.message)
      return []
    }
    return data
  },
  getSubmittedCases: async (email) => {
    const username = (email || '').split('@')[0] || ''
    const { data, error } = await supabaseClient
      .from('cases')
      .select('*')
      .ilike('case_originator', `%${username}%`)
      .neq('status', 'New')
      .is('deleted_at', null)
    if (error) {
      console.error('Error fetching Submitted Cases:', error.message)
      return []
    }
    return data
  },
  getPartialCases: async (email) => {
    const username = (email || '').split('@')[0] || ''
    const { data, error } = await supabaseClient
      .from('cases')
      .select('*')
      .ilike('case_originator', `%${username}%`)
      .eq('status', 'New')
      .is('deleted_at', null)
    if (error) {
      console.error('Error fetching Partially Submitted Cases:', error.message)
      return []
    }
    return data
  },
  getRejectedCases: async (email) => {
    const username = (email || '').split('@')[0] || ''
    const { data, error } = await supabaseClient
      .from('cases')
      .select('*')
      .ilike('case_originator', `%${username}%`)
      .eq('status', 'Approval Rejected')
      .is('deleted_at', null)
    if (error) {
      console.error('Error fetching Rejected Cases:', error.message)
      return []
    }
    return data
  },
  getAllUsers: async () => {
    const { data, error } = await supabaseClient
      .from('users')
      .select('id,email,manager')
    if (error) {
      console.error('Error fetching all users:', error.message)
      return []
    }
    return data
  },
  getTeamCases: async (email) => {
    const username = email?.split('@')[0] || ''

    // Extract the user's department
    const { data: depData, error: depError } = await supabaseClient
      .from('users')
      .select('department_id')
      .ilike('email', `%${username}%`)

    if (depError || !depData) {
      console.error('Error fetching Department:', depError?.message || 'No department data found.')
      return []
    }
  
    const departmentId = depData[0].department_id
  
    // Extract emails of users in the same department
    const { data: userEmails, error: userEmailsError } = await supabaseClient
      .from('users')
      .select('email')
      .eq('department_id', departmentId)
  
    if (userEmailsError || !userEmails) {
      console.error('Error fetching user emails:', userEmailsError?.message || 'No user emails found.');
      return []
    }
  
    const emailList = userEmails.map(user => user.email);
  
    // Extract the team's cases based on the list of emails
    const { data: casesData, error: casesError } = await supabaseClient
      .from('cases')
      .select('*')
      .neq('status', 'New')
      .in('case_originator', emailList)
      .is('deleted_at', null)
    if (casesError) {
      console.error('Error fetching Team Cases:', casesError.message)
      return []
    }
  
    return casesData
  },
  createCase: async (body) => {
    const { id, case_number, ...bodyWithoutId } = body // remove temporary id, and let supabase generate sequential int id
    const username = (body.case_originator || '').split('@')[0] || ''
    // Find the users bamboo alias (they may have logged in with another)
    const { data: userData, error: userError } = await supabaseClient
    .from('users')
    .select('email')
    .ilike('email', `%${username}%`)
    if (userError || !userData) {
      console.error('Error fetching email:', userError?.message || 'No bamboo alias found.')
      return false
    }
    bodyWithoutId.case_originator = userData[0].email
    
    // Create the case
    const { data, error } = await supabaseClient
      .from('cases')
      .insert(bodyWithoutId)
      .select()
    if (error) {
      console.error('Error creating new Case:', error.message)
      return false
    }
    return data // return the resultant row, to save to redux (contains generated id from supabase)
  },
  updateCase: async (itemId, body) => {
    const { id, ...bodyWithoutId } = body
    const { data, error } = await supabaseClient
      .from('cases')
      .update(bodyWithoutId)
      .eq('id', itemId)
      .is('deleted_at', null)
    if (error) {
      console.error('Error updating Case:', error.message)
      return false
    }
    return true
  },
  delCase: async (itemId) => {
    const { data, error } = await supabaseClient
      .from('cases')
      .update({ deleted_at: new Date().toISOString() })
      .eq('id', itemId)
    if (error) {
      console.error('Error deleting Case:', error.message)
      return false
    }
    return true
  },
  submitCase: async (caseForm, actionStatus) => {
    try {
      const response = await supabaseClient.functions.invoke('submitCase', { body: { caseForm, actionStatus } })
      if (response.data.error) throw new Error(response.data.error)
      return response.data
    } catch (error) {
      console.error('Error submitting case:', error.message)
      return false
    }
  },
  recallCase: async (caseNo) => {
    try {
      const response = await supabaseClient.functions.invoke('recallCase', { body: { caseNo } })
      return true
    } catch (error) {
      console.error('Error recalling case:', error.message)
      return false
    }
  },

  markCaseActioned: async (caseNo) => {
    const { data, error } = await supabaseClient
      .from('cases')
      .update({ manually_actioned: true })
      .eq('case_number', caseNo)
    if (error) {
      console.error('Error marking case as actioned:', error.message)
      return false
    }
    return true
  },

  //*--------------- Chargebee Invoices ---------------
  getInvoices: async (body) => {
    try {
      const response = await supabaseClient.functions.invoke('getInvoices', { body })
      if (!response.data) return []
      return [response.data]
    } catch (error) {
      console.error('Error fetching invoices:', error.message)
      return []
    }
  },

  //*--------------- Contact Token ---------------
  getContactTokens: async (contactIds) => {
    const { data, error } = await supabaseClient
      .from('contact_tokens')
      .select('*, brand_id (abbreviation)')
      .in('contact_id', contactIds.map(contact => parseInt(contact.id)))
      .eq('token_type', 'AUTH')
    if (error) {
      console.error('Error fetching Contact Tokens:', error.message)
      return []
    }
    return data || []
  },

  //*--------------- OTP ---------------
  createOTP: async (contactId, brandAbbr, userEmail) => {
    try {
      const response = await supabaseClient.functions.invoke('createOTP', { body: { contactId, brandAbbr, userEmail } })
      if (!response.data) return false
      return response.data
    } catch (error) {
      console.error('Error creating OTP (edge function):', error.message)
      return false
    }
  },
}

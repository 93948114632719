import { useState, useEffect } from 'react'
import { Alert, AlertTitle, Slide } from '@mui/material'
import { useSelector } from 'react-redux'
import { RootState } from '../../../interface/RootState'
import { formatAsCurrency } from '../../../utility/helpers/currencyHelpers'
import { emailToName } from '../../../utility/helpers/miscHelpers'

export function ApprovalLimitAlert ({
  total
}) {
  const [show, setShow] = useState(true)

  const totalAllowed = useSelector((store: RootState) => store.global.user.totalAllowed) || 0
  const managers = useSelector((store: RootState) => store.global.user.managers)

  return (
    <>
      {(total > totalAllowed) ? (
        <Slide in={show} direction="right" onExited={() => setShow(true)}>
          <Alert severity="warning">
            <AlertTitle><b>Approval Limit Exceeded</b></AlertTitle>
            Modification of <b>${formatAsCurrency(total)}</b> exceeds your <b>${formatAsCurrency(totalAllowed)}</b> limit.<br />
            This case will be submitted to your manager {emailToName(managers[0])} for approval.
          </Alert>
        </Slide>
      ) : (
        <Slide in={show} direction="right" onExited={() => setShow(true)}>
          <Alert severity="success">
            This case will be submitted directly to Accounts for approval.
          </Alert>
        </Slide>
      )}
    </>
  )
}

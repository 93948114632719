import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../interface/RootState'
import { useParams } from 'react-router-dom'
import { LoadingTable } from '../Loading'
import { DataTable } from '../Table'
import { Confirmation, UpsertForm } from '../Forms/Popup'
import { GridSortDirection, GridSortItem } from '@mui/x-data-grid'
import LinearProgress from '@mui/material/LinearProgress'
import { Container, Typography, Paper, Snackbar } from '@mui/material'
import Alert, { AlertProps } from '@mui/material/Alert'
import { RestrictedAlert } from '../Alert'
import { canRead, queries } from '../../utility'
import Slide from '@mui/material/Slide'
import Fade from '@mui/material/Fade'

interface ParamProps {
  id: number
  case_number: string
}

export function TableTemplate ({
  CustomToolbar,
  dataTitle,
  columnFields,
  initialForm,
  upsertFields,
  createEmptyRow,
  createQuery = null,
  getQuery = null,
  updateQuery = null,
  deleteQuery = null,
  refreshOnUpdate = true, // refresh the page on row update?
  tableSortBy = 'created_at', // what to sort the table by
  tableSortDir = 'asc' as GridSortDirection,
  permissionProp = null,
  pinLeft = [''],
  pinRight = ['edit', 'delete'],
  autoRowHeight = false,
  goBackLevel = 0
}) {
  //* STATES ----------
  const [create, setCreate] = useState<boolean>(true)
  const [formOpen, setFormOpen] = useState<boolean>(false)
  const [openDialog, setOpenDialog] = useState<boolean>(false)
  const [openReceipts, setOpenReceipts] = useState<boolean>(false)
  const [openCase, setOpenCase] = useState<boolean>(false)
  const [caseData, setCaseData] = useState({})
  const [receiptData, setReceiptData] = useState({})
  const [sortModel, setSortModel] = useState<GridSortItem[]>([{ field: tableSortBy, sort: tableSortDir }])
  const [method, setMethod] = useState<string>('')

  const [param, setParams] = useState<ParamProps>()

  const [loading, setLoading] = useState<boolean>(true)
  const [loadingPopup, setLoadingPopup] = useState<boolean>(false)
  const [queryData, setQueryData] = useState([])
  const [formData, setFormData] = useState(initialForm)
  const [reloadTrigger, setReloadTrigger] = useState(false)

  const { customerId, keywordId, productId } = useParams()

  //* Extract data from Redux
  const { email: userEmail, managers, permissions } = useSelector((store: RootState) => store.global.user)
  const geolocations = useSelector((store: RootState) => store.global.geolocations)
  const subscriptionsCb = useSelector((store: RootState) => store.global.company.subscriptions)

  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)

  const handleCloseSnackbar = () => setSnackbar(null)

  //* HOOKS ----------
  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        const extractedData = await getQuery(formData, customerId, keywordId, productId)
        setQueryData(extractedData)
      } catch (error) {
        setSnackbar({ children: `Error fetching ${dataTitle}s`, severity: 'error' })
      } finally {
        setLoading(false)
      }
    })()
    // eslint-disable-next-line
  }, [customerId, dataTitle, getQuery, reloadTrigger])

  //* CRUD HANDLERS ----------
  const handleCreateQuery = async (params) => {
    try {
      setLoadingPopup(true)
      const newRow = createEmptyRow(params, customerId, keywordId, productId, userEmail, managers)
      const createRes = await createQuery(newRow, customerId, formData)
      if (!createRes || createRes.error) {
        return setSnackbar({ children: `Error creating ${dataTitle}`, severity: 'error' })
      }
      setQueryData((prevData) => {
        if (!Array.isArray(prevData)) { return []}
        return [...prevData, newRow]
      })
      setSnackbar({ children: `Successfully created ${dataTitle}`, severity: 'success' })
    } catch (error) {
      setSnackbar({ children: `Error creating ${dataTitle}: ${error?.message}`, severity: 'error' })
    } finally {
      setLoadingPopup(false)
      setFormOpen(false)
      setReloadTrigger(prev => !prev) // force a reload of the table on creation/failure to create new row, so that the table reflects live database
    }
  }
  const handleUpdateQuery = async (params) => {
    try {
      setLoadingPopup(true)
  
      // Update local state to re-render table with updated details
      const updatedRow = {}
      Object.keys(formData).forEach((key) => {
        updatedRow[key] = params[key] || formData[key]
      })
      setFormData(updatedRow)

      // create copy of object without id
      const trimId = {}
      for (const key in updatedRow) {
        if (key !== 'id') {
          trimId[key] = updatedRow[key]
        }
      }

      // Update Supabase  table
      const update = await updateQuery(params.id, customerId, trimId)
      if (!update || update.error) {
        return setSnackbar({ children: `Error updating ${dataTitle}`, severity: 'error' })
      }
      setQueryData((prevData) => {
        const idx = prevData.findIndex((k) => k.id === params.id)
        return idx !== -1 ? [...prevData.slice(0, idx), updatedRow, ...prevData.slice(idx + 1)] : [...prevData, updatedRow]
      })
      setSnackbar({ children: `Successfully updated ${dataTitle}`, severity: 'success' })
    } catch (error) {
      setSnackbar({ children: `Error updating ${dataTitle}: ${error?.message}`, severity: 'error' })
    } finally {
      setLoadingPopup(false)
      setFormOpen(false)
      if (refreshOnUpdate) { setReloadTrigger(prev => !prev) } // force a reload of the table on row update, so that the table reflects live database
    }
  }
  const handleDeleteQuery = async (confirm) => {
    if (confirm) {
      try {
        setLoadingPopup(true)
        const delRes = await deleteQuery(param.id, customerId)
        if (!delRes || delRes.error) {
          return setSnackbar({ children: `Error deleting ${dataTitle}`, severity: 'error' })
        }
        const updatedData = queryData.filter((data) => String(data.id) !== String(param.id))
        setQueryData(updatedData)
        setSnackbar({ children: `Successfully deleted ${dataTitle}`, severity: 'success' })
      } catch (error) {
        setSnackbar({ children: `Error deleting ${dataTitle}`, severity: 'error' })
      } finally {
        setLoadingPopup(false)
        setOpenDialog(false)
      }
    } else {
      setOpenDialog(false)
    }
  }

  const handleSubmitBtn = async (confirm, body) => { // the inline submit button used in the table row
    if (confirm) {
      try {
        setLoadingPopup(true)
        const submitRes = await queries.submitCase(body, null)
        if (!submitRes) {
          return setSnackbar({ children: 'Error submitting case', severity: 'error' })
        }
        setFormData({ ...formData })
        setQueryData(prevQueryData => {
          const updatedQueryData = [...prevQueryData]
          const index = updatedQueryData.findIndex(caseItem => caseItem.case_number === body.case_number)
          if (index !== -1) {
            updatedQueryData[index] = { ...updatedQueryData[index], status: 'Awaiting Approval' }
          }
          return updatedQueryData
        })
        if (submitRes.response.productReceipts) {
          setSnackbar({ children: 'Case was automatically approved', severity: 'success' })
          setReceiptData(submitRes.response)
          setOpenReceipts(true)
        } else {
          setSnackbar({ children: 'Successfully submitted case', severity: 'success' })
        }
      } catch (error) {
        setSnackbar({ children: 'Error submitting case', severity: 'error' })
      } finally {
        setLoadingPopup(false)
        setOpenDialog(false)
      }
    } else {
      setOpenDialog(false)
    }
  }

  const handleRecallBtn = async (confirm, caseNo) => { // the inline recall button
    if (confirm) {
      try {
        setLoadingPopup(true)
        const recallRes = await queries.recallCase(caseNo)
        if (!recallRes) {
          return setSnackbar({ children: 'Error recalling case', severity: 'error' })
        }
        setFormData({ ...formData })
        setQueryData(prevQueryData => {
          const updatedQueryData = [...prevQueryData]
          const index = updatedQueryData.findIndex(caseItem => caseItem.case_number === caseNo)
          if (index !== -1) {
            updatedQueryData[index] = { ...updatedQueryData[index], status: 'New' }
          }
          return updatedQueryData
        })      
        setSnackbar({ children: 'Successfully recalled case', severity: 'success' })
      } catch (error) {
        setSnackbar({ children: 'Error recalling case', severity: 'error' })
      } finally {
        setLoadingPopup(false)
        setOpenDialog(false)
      }
    } else {
      setOpenDialog(false)
    }
  }

   //* FORM HANDLERS ----------
  // Handle delete dialog boxes
  const handleDialogOpen = (params, dialogMethod) => {
    setOpenDialog(true)
    setParams(params)
    setMethod(dialogMethod)
  }
  const handleDialogClose = (confirm) => {
    if (method === 'Delete') { handleDeleteQuery(confirm) }
    if (method === 'Submit') { handleSubmitBtn(confirm, param) }
    if (method === 'Recall') { handleRecallBtn(confirm, param?.case_number) }
  }
  // Handle create/update forms
  const openCreateForm = () => {
    setCreate(true) // set to display create form
    setFormOpen(true)
  }
  // Update the form state in react
  const openUpdateForm = (params) => {
    setCreate(false)
    const toSetForm = formData
    Object.keys(formData).forEach((key) => {
      // Check for undefined or null, do not fallback on FALSE values
      if (params.row[key] !== undefined && params.row[key] !== null) {
        toSetForm[key] = params.row[key]
      } else {
        toSetForm[key] = formData[key]
      }
    })
    setFormData(toSetForm)
    setFormOpen(true)
  }
  const closeForm = () => {
    setFormOpen(false)
    setFormData(initialForm)
  }
  const handleReceiptOpen = (receipts) => {
    setReceiptData(receipts)
    setOpenReceipts(true)
  }
  const handleCaseOpen = (selectedCase) => {
    setCaseData(selectedCase)
    setOpenCase(true)
  }

  //* RENDERS ----------
  if (loading || canRead(permissions, permissionProp) === null) {
    return (
      <Slide in direction="left">
        <div>
          <Fade in>
            <Container maxWidth="lg">
              <Typography variant="h5" sx={{ paddingTop: '24px', paddingBottom: '12px' }} >
                Loading...
              </Typography>
              <LinearProgress color="secondary" />
              <Paper elevation={3} style={{ padding: '20px' }}>
                <LoadingTable />
              </Paper>
            </Container>
          </Fade>
        </div>
      </Slide>
    )
  }
  if (!canRead(permissions, permissionProp)) {
    return <RestrictedAlert />
  }

  return (
    <Slide in direction="left">
      <Fade in>
        <Container style={{ padding: '0px', paddingTop: '24px', paddingLeft: '16px', margin: '0px', maxWidth: '100%' }}>
          <DataTable
            title={dataTitle}
            columnFields={columnFields}
            queryData={queryData}
            openCreateForm={openCreateForm}
            sortModel={sortModel}
            setSortModel={setSortModel}
            openUpdateForm={openUpdateForm}
            handleDialogOpen={handleDialogOpen}
            CustomToolbar={CustomToolbar}
            subscriptionsCb={subscriptionsCb}
            geolocations={geolocations}
            permissionProp={permissionProp}
            handleReceiptOpen={handleReceiptOpen}
            handleDataDialogOpen={handleCaseOpen}
            pinLeft={pinLeft}
            pinRight={pinRight}
            autoRowHeight={autoRowHeight}
            goBackLevel={goBackLevel}
            userEmail={userEmail}
            managers={managers}
            permissions={permissions}
          />
          <UpsertForm
            upsertFields={upsertFields}
            title={dataTitle}
            upsertDialog={formOpen}
            closeForm={closeForm}
            formData={formData}
            setFormData={setFormData}
            isCreate={create}
            handleUpdateQuery={handleUpdateQuery}
            handleCreateQuery={handleCreateQuery}
            loading={loadingPopup}
          />
          <Confirmation
            openDialog={openDialog}
            handleClose={handleDialogClose}
            itemName={dataTitle}
            loading={loadingPopup}
            text={method}
          />
          {!!snackbar && (
            <Snackbar
              open
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              onClose={handleCloseSnackbar}
              autoHideDuration={6000}
            >
              <Alert
                {...snackbar}
                onClose={handleCloseSnackbar}
                variant="filled"
              />
            </Snackbar>
          )}
        </Container>
      </Fade>
    </Slide>
  )
}

import { useState, useEffect } from 'react'
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DoneIcon from '@mui/icons-material/Done'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty'

export function SelectCaseStatus ({
    originalCases,
    filteredCases,
    setFilteredCases
  }) {
  const [filter, setFilter] = useState('All')

  useEffect(() => {
    if (filter !== 'All') {
      const newCases = originalCases.filter(c => c.status === filter)
      setFilteredCases(newCases)
    } else {
      setFilteredCases(originalCases)
    }
  }, [filter, originalCases, setFilteredCases])

  return (
    <FormControl sx={{ m: 1, width: 200 }} size="small">
      <InputLabel>Filter by case status</InputLabel>
      <Select
        label="Filter by case status"
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
      >
        <MenuItem value="All">
          All
        </MenuItem>
        <MenuItem value="Awaiting Approval">
          <HourglassEmptyIcon color="warning" sx={{ marginRight: 1 }} />
          Awaiting Approval
        </MenuItem>
        <MenuItem value="Approval Rejected">
          <CloseIcon color="error" sx={{ marginRight: 1 }} />
          Rejected
        </MenuItem>
        <MenuItem value="Closed">
          <DoneIcon color="success" sx={{ marginRight: 1 }} />
          Approved
        </MenuItem>
      </Select>
      <FormHelperText>Filter by case status</FormHelperText>
    </FormControl>
  )
}

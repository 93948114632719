/**
 * Sort cases by a specified property
 *
 * @param {Object} allCases All Cases object
 * @param {string} property Property to sort by
 * @returns {Object}
 */
export const sortCases = (
  allCases: object[],
  property: string = 'updated_at'
): object => {
  const sortedCases = [...allCases].sort((a, b) => new Date(b[property]).getTime() - new Date(a[property]).getTime())
  return sortedCases
}

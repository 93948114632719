const hasAccess = (permissions, prop, action) => {
  if (!permissions) {
    return null // still loading
  }
  if (Object.keys(permissions)?.length === 0) {
    return false // empty object -> no permissions
  }

  return permissions[prop] === true
    ? true
    : !!permissions[prop]?.[action]
}

export const canRead = (permissions, prop) => hasAccess(permissions, prop, 'read')
export const canCreate = (permissions, prop) => hasAccess(permissions, prop, 'write')
export const canEdit = (permissions, prop) => hasAccess(permissions, prop, 'edit')
export const canDelete = (permissions, prop) => hasAccess(permissions, prop, 'delete')

import { api } from './api'
import { queries } from '../utility'
import { setCompany, setContactTokens, setContactDetails, setBrandDetails } from '../store/global'
import { store } from '../store'

export const companyApi = api.injectEndpoints({
  reducerPath: 'companyApi',
  endpoints: (build) => ({
    getCompanyDetails: build.query({
      queryFn: async (arg) => {
        const customerId = arg
        const response = await queries.getCompanyDetails(customerId)
        store.dispatch(setCompany(response))
        return { data: response }
      },
    }),
    getContactTokens: build.query({
      queryFn: async (arg) => {
        const customer = arg
        const response = await queries.getContactTokens(customer?.contactId)
        store.dispatch(setContactTokens(response))
        return { data: response }
      },
    }),
    getContactDetails: build.query({
      queryFn: async (arg) => {
        const contactEmail = arg
        const response = await queries.getContactDetails(contactEmail)
        store.dispatch(setContactDetails(response))
        return { data: response }
      },
    }),
    getBrandDetails: build.query({
      queryFn: async (arg) => {
        const brandAbbr = arg
        const response = await queries.getBrandDetails(brandAbbr)
        store.dispatch(setBrandDetails(response[0]))
        return { data: response[0] }
      },
    }),
  }),
})

export const {
  useGetCompanyDetailsQuery,
  useGetContactTokensQuery,
  useGetContactDetailsQuery,
  useGetBrandDetailsQuery
} = companyApi

import { configureStore } from '@reduxjs/toolkit'
import { global } from './global'
import { subscriptionsApi } from '../services/subscriptionsApi.js'
import { geolocationsApi } from '../services/geolocationsApi'
import { formsApi } from '../services/formsApi'
import { clientLoginsApi } from '../services/clientLoginsApi'
import { permissionsApi } from '../services/permissionsApi'

export const store = configureStore({
  reducer: {
    global,
    [subscriptionsApi.reducerPath]: subscriptionsApi.reducer,
    [geolocationsApi.reducerPath]: geolocationsApi.reducer,
    [formsApi.reducerPath]: formsApi.reducer,
    [clientLoginsApi.reducerPath]: clientLoginsApi.reducer,
    [permissionsApi.reducerPath]: permissionsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(
        subscriptionsApi.middleware,
        geolocationsApi.middleware,
        formsApi.middleware,
        clientLoginsApi.middleware,
        permissionsApi.middleware
      ),
})

export type RootState = ReturnType<typeof store.getState>

import { toggleOpenReportDialog } from '../../../store/global'
import { useDispatch } from 'react-redux'
import { Button, Card, CardContent, Typography } from '@mui/material'
import BugReportIcon from '@mui/icons-material/BugReport'

export const BugReportCard = () => {
  const dispatch = useDispatch()

  const openTicketRequest = () => {
    dispatch(toggleOpenReportDialog())
  }

  return (
    <Button onClick={() => openTicketRequest()} sx={{ textTransform: 'none' }}>
      <Card sx={{ maxWidth: '300px' }}>
        <CardContent>
          <div className="flex gap-[16px] flex-row items-center">
            <BugReportIcon color="primary" />
            <Typography variant="h6" align="left">
              Report a Bug
            </Typography>
          </div>
          <Typography variant="body1" align="left" color="text.secondary">
            Submit a Ticket to IT to report any bugs, or request any new features.
          </Typography>
        </CardContent>
      </Card>
    </Button>
  )
}

import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../interface/RootState'
import { queries } from '../utility'
import { Alert, Button, CircularProgress, Container, Typography, TextField, Paper } from '@mui/material'
import { LoadingTable } from '../components/Loading'
import LinearProgress from '@mui/material/LinearProgress'
import Snackbar from '@mui/material/Snackbar'
import Grow from '@mui/material/Grow'
import { AlertProps } from '@mui/material/Alert'
import SendIcon from '@mui/icons-material/Send'

interface DataProps {
  id?: string,
  email?: string,
  firstname?: string
  lastname?: string
  sip_username?: string
  decrypted_sip_password?: string
  sip_password?: string
  created_at?: Date
}

const dataTitle = 'Preference'
const initialForm: DataProps = {
  email: '',
  firstname: '',
  lastname: '',
  sip_username: '',
  created_at: new Date(),
}

//* CRUD functions from Supabase
const getQuery = async (userEmail) => {
  const data = await queries.getPreferences(userEmail)
  return data || []
}
const updateQuery = async (userEmail, body) => {
  const data = await queries.updatePreferences(userEmail, body)
  return data
}

export function SipPage () {
  const [loading, setLoading] = useState(true)
  const [loadingSpinner, setLoadingSpinner] = useState(false)
  const [formData, setFormData] = useState(initialForm)
  const [snackbar, setSnackbar] = useState<Pick<
    AlertProps,
    'children' | 'severity'
  > | null>(null)

  //* Extract data from Redux
  const userEmail = useSelector((store: RootState) => store.global.user.email)

  const handleCloseSnackbar = () => setSnackbar(null)

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        // Check if userData is not undefined and has actually loaded
        if (userEmail) {
          const extractedData = await getQuery(userEmail)
          if (extractedData[0]) {
            extractedData[0].sip_password = extractedData[0].decrypted_sip_password
            delete extractedData[0].decrypted_sip_password
          }
          initialForm.email = userEmail
          setFormData(extractedData[0] || initialForm)
        }
      } catch (error) {
        console.error(`Error fetching ${dataTitle}:`, error.message)
      } finally {
        setLoading(false)
      }
    })()
  }, [userEmail])

  const handleUpdateQuery = async () => {
    try {
      setLoadingSpinner(true)
      // Update Supabase  table
      const updateRes = await updateQuery(userEmail || '', formData)
      if (!updateRes) {
        setSnackbar({ children: 'Error updating preferences', severity: 'error' })
      } else {
        setSnackbar({ children: 'Successfully updated preferences', severity: 'success' })
      }
    } catch (error) {
      console.error(`Error updating ${dataTitle}:`, error.message)
    } finally {
      setLoadingSpinner(false)
    }
  }

  //* RENDERS ----------
  if (!userEmail) {
    return (
      <Container maxWidth="lg">
        <Typography variant="h5" align="center" gutterBottom style={{ padding: '10px' }}>
          Session timed out.
        </Typography>
      </Container>
    )
  }
  
  return (
    <Container maxWidth="lg">
      <Typography variant="h5" align="center" gutterBottom style={{ padding: '15px' }}>
        Update SIP Credentials
      </Typography>
      <Grow in {...({ timeout: 250 })}>
        <div className="flex flex-col gap-[15px] items-center">
          <TextField
            required
            className="w-full sm:w-[400px]"
            label="First Name"
            value={formData.firstname}
            onChange={(e) => setFormData((prevData) => ({ ...prevData, firstname: e.target.value }))}
          />
          <TextField
            required
            className="w-full sm:w-[400px]"
            label="Last Name"
            value={formData.lastname}
            onChange={(e) => setFormData((prevData) => ({ ...prevData, lastname: e.target.value }))}
          />
          <TextField
            required
            className="w-full sm:w-[400px]"
            label="SIP Username"
            value={formData.sip_username}
            onChange={(e) => setFormData((prevData) => ({ ...prevData, sip_username: e.target.value }))}
          />
          <TextField
            required
            className="w-full sm:w-[400px]"
            label="SIP Password"
            value={formData.sip_password}
            onChange={(e) => setFormData((prevData) => ({ ...prevData, sip_password: e.target.value }))}
          />
          <Button style={{ display: 'flex', gap: '8px', borderRadius: '50px' }} size="large" onClick={handleUpdateQuery} variant="contained" 
                  endIcon={loadingSpinner ? <CircularProgress size="0.9rem" color="inherit" /> : <SendIcon />}>
            Update
          </Button>
        </div>
      </Grow>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert
            {...snackbar}
            onClose={handleCloseSnackbar}
            variant="filled"
          />
        </Snackbar>
      )}
    </Container>
  )
}

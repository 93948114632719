import { useState } from 'react'
import { Alert, AlertTitle, Button, CircularProgress, Dialog, DialogTitle,
        DialogContent, DialogContentText, DialogActions, FormControl,
        InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { Copied } from '../../Popover'

export function CompanylessOTP ({ 
  otp,
  openDialog,
  handleClose,
  loading,
  completed = false,
  error
}) {
  const [selectedContact, setSelectedContact] = useState<string>('')
  const [selectedBrand, setSelectedBrand] = useState<string>('SLX')
  
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={() => handleClose(null)}
      >
        <DialogTitle>
          Generate OTP
        </DialogTitle>
        <DialogContent>
          {(!completed) ? (
            <>
              <DialogContentText sx={{ paddingBottom: '20px' }}>
                Would you like to generate a One-Time-Password?<br />
                <i>This temporary password will expire within 1 hour of generation.</i>
              </DialogContentText>
              <FormControl fullWidth required>
                <div className="flex flex-col gap-[20px]">
                  <TextField
                    label="Contact Email"
                    fullWidth
                    value={selectedContact}
                    onChange={(e) => setSelectedContact(e.target.value)}
                    type="text"
                    required
                    helperText="Please enter the contacts email address"
                    placeholder="john@gmail.com"
                    disabled={loading}
                  />
                  <FormControl required>
                    <InputLabel>Brand</InputLabel>
                    <Select
                      label="Brand"
                      value={selectedBrand}
                      onChange={(e) => setSelectedBrand(e.target.value)}
                      required
                      disabled={loading}
                    >
                      <MenuItem key={1} value={'SLX'}>SponsoredLinX</MenuItem>
                      <MenuItem key={3} value={'GMT'}>Get More Traffic</MenuItem>
                      <MenuItem key={5} value={'SME'}>Search Marketing Experts</MenuItem>
                      <MenuItem key={7} value={'JDM'}>Just Digital Marketing</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </FormControl>
            </>
          ) : (
            <DialogContentText>
              {error ? (
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  Error generating OTP. Please check the email/brand entered and try again.
                </Alert>
              ) : (
                <Alert severity="success">
                  <AlertTitle>Success</AlertTitle>
                  Your unique OTP (One-Time Password) was generated:
                  <div className="flex flex-col w-max pt-[16px]">
                    <span>
                      Email: <b>{selectedContact}</b>
                      <Copied toCopy={selectedContact} />
                    </span>
                    <span>
                      OTP: <b>{otp}</b>
                      <Copied toCopy={otp} />
                    </span>
                  </div>
                </Alert>
              )}
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          <Button disabled={loading} onClick={() => handleClose(false)}>
            {!completed ? 'Cancel' : 'Close'}
          </Button>
          {!completed && (
            <Button disabled={loading} endIcon={loading ? <CircularProgress size="0.99rem" /> : null} onClick={() => handleClose(true, selectedContact, selectedBrand)} autoFocus>
              Generate
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

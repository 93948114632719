/**
 * Get the styling for a subscription based on its status
 *
 * @param {string} status The subscription status
 * @param {string} overflow Whether to enable overflow styling or not
 * @returns {string}
 */
export const getSubscriptionStyle = (
  status: string,
  overflow: boolean = true
): string => {
  const resultStyle = !['active', 'trial', 'non_renewing'].includes(status) ? 'opacity-[60%]' : ''
  const overflowStyle = overflow ? ' overflow-hidden truncate' : ''
  return resultStyle + overflowStyle
}

import { useState, useEffect } from 'react'
import { Dialog, DialogTitle, DialogContent, Divider, DialogActions, Button } from '@mui/material'
import { FormDataFormatted } from './FormDataFormatted'

export function DataDialog ({
  data,
  open,
  handleClose,
  loading = false
}) {
  const [formattedData, setFormattedData] = useState(null)

  useEffect(() => {
    if (Object.keys(data).length !== 0) {
      // extract the labels from the schema json
      const elements = data.form_version_id.schema_json.elements.flatMap(el => el.elements || [])
      const labels = Object.fromEntries(Object.keys(data.data).map(key => [key, elements.find(e => e.name === key)?.label || key]))
      const combined = Object.keys(labels).reduce((acc, key) => ({ ...acc, [key]: { label: labels[key], value: data.data[key] } }), {})
      setFormattedData(combined)
    }
  }, [data])

  if (!formattedData) return null

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={() => handleClose(null)}
    >
      <DialogTitle>
        {data?.form_version_id?.form_id?.name}
      </DialogTitle>
      <Divider/>
      <DialogContent sx={{ width: '100%' }}>
        <FormDataFormatted formData={formattedData} />
      </DialogContent>
      <Divider/>
      <div className="flex self-end">
        <DialogActions>
          <Button variant="contained" size="medium" disabled={loading} onClick={() => handleClose()}>
            CLOSE
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  )
}

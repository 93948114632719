import { useState, useEffect, memo } from 'react'
import { useLocation, Link, Outlet } from 'react-router-dom'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { Box, Tab } from '@mui/material'

const routes = [ // append routes you want to showcase on tabs here
  { name: 'sip', label: 'sip credentials' }
]

export const SettingsTabs = memo((props) => {
  const { pathname } = useLocation()
  const [value, setValue] = useState('/')

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  useEffect(() => {
    const tabValue = routes.find(route => pathname.includes(route?.name))?.name || '/'
    setValue(tabValue)
  }, [pathname])

  return (
    <div className="pt-[16px] self-start mx-[25px]" style={{ width: '-webkit-fill-available'}} >
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }} >
          <TabList onChange={handleChange} aria-label="customer tabs" variant="scrollable">
            <Tab label="Tools" value="/" component={Link} to="" />
            {routes.map((route, index) => (
              <Tab
                key={index}
                label={route.label}
                value={route.name} 
                component={Link}
                to={route.name}
              />
            ))}
          </TabList>
        </Box>
        {[...Array(routes?.length)].map((_, index) => (
          <TabPanel key={index} value={index.toString()} sx={{ padding: '0px' }} />
        ))}
      </TabContext>
      <Outlet context={props} />
    </div>
  )
})

/**
 * Get the resultant status of a case receipt
 *
 * @param {Object} receiptData Receipt Data
 * @param {boolean} manuallyActioned Whether the case has been marked has actioned by Accounts, or not
 * @returns {{ message: string, color: string, icon: string }} An object containing information on the associated status
 */
export const getReceiptAction = (
  receiptData: object,
  manuallyActioned: boolean
): { message: string, color: string, icon: string } => {
  if (!receiptData) return {
    message: '~',
    color: 'text-black-500',
    icon: ''
  }

  const flattened = Object.values(receiptData).flat()

  if (!manuallyActioned && flattened.some(receipt =>
    (receipt as string)?.toUpperCase().includes('RECORDED') ||
    (receipt as string)?.toUpperCase().includes('ALTERED PRODUCT') ||
    (receipt as string)?.toUpperCase().includes('MANUAL')
  )) {
    return {
      message: 'Manually Action',
      color: 'text-orange-500',
      icon: 'Warning'
    }
  } else if (!manuallyActioned && flattened.some(receipt =>
    (receipt as string)?.toUpperCase().includes('FAILED')
  )) {
    return {
      message: 'Failed to Action',
      color: 'text-red-500',
      icon: 'Close'
    }
  } else {
    return {
      message: 'Actioned',
      color: 'text-green-600',
      icon: 'Done'
    }
  }
}

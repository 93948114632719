import { useState } from 'react'
import { CustomerSearchBar, CustomerSearchFilter } from './'

export function SearchContainer({
  fullSize = false
}) {
  const [inactiveBox, setInactiveBox] = useState(false)
  const [brandlessBox, setBrandlessBox] = useState(false)

  return (
    <>
      <CustomerSearchBar
        inactiveBox={inactiveBox}
        brandlessBox={brandlessBox}
      />
      <CustomerSearchFilter
        fullSize={fullSize}
        inactiveBox={inactiveBox}
        setInactiveBox={setInactiveBox}
        brandlessBox={brandlessBox}
        setBrandlessBox={setBrandlessBox}
      />
    </>
  )
}
